import RoundelGrid from "../shared/RoundelGrid"
import { RoundelProps } from "../shared/Roundel"

const HomePageRoundels = ({
  roundels,
  isCycleToWork,
}: {
  roundels: RoundelProps[]
  isCycleToWork?: boolean
}) => {
  return (
    <RoundelGrid
      roundels={roundels}
      background="white"
      isCycleToWork={isCycleToWork}
    />
  )
}

export default HomePageRoundels
