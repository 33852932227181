import { KeyboardEvent, useState } from "react"
import classNames from "classnames"
import Tab from "./Tab"
import {
  AllCategoriesColumns,
  SavingTypeColumns,
  SubCategoryItemProps,
} from "./Column"
import { gtmLifestyleTopNavClick } from "../gtm-events/CustomGtmEvents"
import { Link } from "@mui/material"
export type MegaMenuItemProps = {
  name: string
  link: string
  isBold?: boolean
  subcategories: SubCategoryItemProps[]
}
const Item = ({
  item,
  active,
  setActive,
}: {
  item: MegaMenuItemProps
  active: boolean
  setActive: (state: boolean) => void
}) => {
  const { name, isBold, link, subcategories } = item
  const [subCategory, setSubCategory] = useState("")
  const lifestylePageType = () => {
    const pathname = window.location.pathname
    if (pathname.includes("/landing")) {
      return "Landing Page"
    } else if (pathname.includes("/products")) {
      return "Products Page"
    }
    if (pathname.includes("/local_benefits")) {
      return "Local Benefits"
    } else {
      return "Landing Page"
    }
  }
  const navTypeSelected = lifestylePageType() || ""
  const handleAction = (name: string, subcategories: string) => {
    gtmLifestyleTopNavClick(
      navTypeSelected,
      name,
      subcategories ? subCategory : name
    )
    setActive(false)
  }
  const handleSubCategoryClick = (data: string) => {
    setSubCategory(data)
    gtmLifestyleTopNavClick(navTypeSelected, name, data)
  }

  const actionKey = (e: KeyboardEvent) => {
    if (e.key == "ArrowDown") {
      e.preventDefault()
      setActive(true)
    } else if (e.key == "ArrowUp") {
      e.preventDefault()
      setActive(false)
    }
  }

  return (
    <>
      <Link
        className={classNames("mega-menu-item", {
          "mega-menu-item--active": active,
          "mega-menu-item--bold": isBold,
        })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        onClick={() => handleAction(name, subCategory)}
        href={link}
        onKeyDown={actionKey}
      >
        {name}
      </Link>
      {name === "Categories" && (
        <Tab active={active} setActive={setActive}>
          <AllCategoriesColumns
            subcategories={subcategories}
            setActive={setActive}
            handleSubCategory={handleSubCategoryClick}
          />
        </Tab>
      )}
      {name === "Savings types" && (
        <Tab active={active} setActive={setActive}>
          <SavingTypeColumns
            subcategories={subcategories}
            setActive={setActive}
            handleSubCategory={handleSubCategoryClick}
          />
        </Tab>
      )}
    </>
  )
}

export default Item
