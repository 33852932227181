import React, { useCallback, useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  css,
  Divider,
  Grid,
  Link,
  styled,
} from "@mui/material"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate, useParams } from "react-router-dom"
import { ListComponent } from "./ListComponent"
import { Item } from "./ManageBenefitsGroupsContext"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useUpdatePositionsMutation } from "../../graphqGenaretedTypes"
import Alert from "../shared/Alert"

const HealthAndWellbeingDnd = ({
  listItem,
  link,
  translationName,
  canEdit,
}: {
  listItem: Item[]
  link: string
  translationName: string
  canEdit: boolean
}) => {
  const [cards, setCards] = useState<Item[]>(listItem)
  const [isEditing, setIsEditing] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [updatePositionsMutation] = useUpdatePositionsMutation()
  const params = useParams()

  const { t, ready } = useTranslation(translationName)
  const navigate = useNavigate()
  useEffect(() => {
    setCards(listItem)
  }, [listItem])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards]

      const dragId = updatedCards[dragIndex].position
      const hoverId = updatedCards[hoverIndex].position

      const draggedCard = updatedCards[dragIndex]
      updatedCards[dragIndex] = updatedCards[hoverIndex]
      updatedCards[hoverIndex] = draggedCard

      updatedCards[dragIndex] = {
        ...updatedCards[dragIndex],
        position: dragId,
      }
      updatedCards[hoverIndex] = {
        ...updatedCards[hoverIndex],
        position: hoverId,
      }

      return updatedCards
    })
  }, [])

  const handleClick = async () => {
    if (isEditing) {
      const { data } = await updatePositionsMutation({
        variables: {
          organisationId: params.organisationId ?? "",
          entityName: "LocalScheme",
          items: cards
            .filter((card) => card.locked === false)
            .map((card) => ({ id: card.id, position: card.position })),
        },
      })

      const errors = data?.updatePositions?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      }
    }

    setIsEditing(!isEditing)
  }

  const renderCard = useCallback(
    (card: Item, index: number) => (
      <ListComponent
        key={card.id}
        index={index}
        id={card.id}
        text={card.label}
        hidden={!card.enabled}
        locked={card.locked}
        moveCard={moveCard}
        isEditing={isEditing}
      />
    ),
    [moveCard, isEditing]
  )
  const shouldDisableEditButton = useCallback(() => {
    // Count the number of cards that are NOT locked and have valid IDs
    const validCardsCount = cards.filter(
      (card) => !card.locked && !isNaN(parseInt(card.id))
    ).length

    // Disable the button if there are less than two valid cards
    return validCardsCount < 2
  }, [cards])
  const handleButton = () => {
    navigate(link)
  }
  if (!ready) {
    return null
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {alertMessage && (
          <Alert
            severity={alertSeverity}
            message={alertMessage}
            key={errorCount}
          />
        )}
        <RequestsToOpenContainer>
          <GridContainer id="manage_wellbeing">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <MainText style={{ paddingBottom: "2rem" }}>
                <h3>{t("header")}</h3>
              </MainText>
            </div>
            <ListContainerBox>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {canEdit && (
                  <LayoutButton
                    variant="text"
                    onClick={handleClick}
                    disabled={shouldDisableEditButton()}
                  >
                    {!isEditing ? (
                      <>
                        <ModeEditOutlineOutlinedIcon
                          sx={{ paddingRight: "0.5rem" }}
                        />
                        {t("editLayout")}
                      </>
                    ) : (
                      <>
                        <SaveOutlinedIcon sx={{ paddingRight: "0.5rem" }} />
                        {t("saveLayout")}
                      </>
                    )}
                  </LayoutButton>
                )}
              </div>

              <ContainerGrid container spacing={2}>
                {cards.map((card, i) => renderCard(card, i))}
                {canEdit && !isEditing && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ paddingRight: "1.5625rem", paddingLeft: "15px" }}
                  >
                    <ItemBox>
                      <AddNewItemText href={link}>
                        {t("newWellbeingText")}
                      </AddNewItemText>

                      <LabelButton
                        onClick={handleButton}
                        aria-label="Add item"
                        role="button"
                        tabIndex={0}
                      >
                        <AddIcon aria-label="Add item" aria-hidden="false" />
                      </LabelButton>
                    </ItemBox>
                    <Divider />
                  </Grid>
                )}
              </ContainerGrid>
            </ListContainerBox>
          </GridContainer>
        </RequestsToOpenContainer>
      </div>
    </DndProvider>
  )
}

export default HealthAndWellbeingDnd

const ContainerGrid = styled(Grid)`
  padding: 3rem;
  padding-top: 1rem;
  @media screen and (max-width: 900px) {
    padding: 0.2rem;
  }
`
const LabelButton = styled(Button)`
  padding: 0rem;
`
const LayoutButton = styled(Button)`
  padding-right: 1.25rem;
  width: 9.6875rem;
  @media screen and (max-width: 900px) {
    padding-top: 3rem;
  }
`
const ListContainerBox = styled(Box)`
  ${({ theme }) => css`
    && {
      width: 100%;
      background-color: ${theme.palette.white.main};
      color: ${theme.palette.primary.main};
      border-radius: 0.5rem;
      min-height: 15.625rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
      @media screen and (max-width: 900px) {
        padding: 0rem;
      }
    }
  `}
`

const ItemBox = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const AddNewItemText = styled(Link)`
  ${({ theme }) => css`
    && {
      color: ${theme.palette.primary.main};
      font-weight: bold;
      margin: 0.9rem;
      text-decoration: none;
      margin-top: 0.4rem;
    }
  `}
`

const MainText = styled(Box)`
  && {
    font-weight: bold;
  }
`

const GridContainer = styled(Container)`
  && {
    max-width: 75rem;
    width: 93%;
    margin: 0 auto;
    padding-bottom: 1.875rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

export const RequestsToOpenContainer = styled("div")`
  ${({ theme }) => css`
    padding: 0 2rem;
    padding-top: 0.8rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
      margin: auto;
      padding: 0 0.2rem;
      padding-top: 0.8rem;
    }
  `}
`
