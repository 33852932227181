import Appsignal from "@appsignal/javascript"
import { plugin } from "@appsignal/plugin-window-events"

declare global {
  const appSignalPushKey: string
  const appSignalActive: boolean
  const appSignalAppNamespace: string
  const appSignalRevision: string
}

export default function initAppSignal() {
  if (appSignalActive) {
    const client = new Appsignal({
      key: appSignalPushKey,
      namespace: appSignalAppNamespace,
      revision: appSignalRevision,
    })

    client.use(plugin({}))

    return client
  }
}
