import ScrollToTop from "../../shared/ScrollToTop"
import {
  useLocalBenefitStoreData,
  LocalBenefitStoreContext,
} from "./LocalBenefitStoreContext"
import { Container, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import classNames from "classnames"
import { useMediaQuery, useTheme } from "@mui/material"
import Topbar from "../../shared/store/topbar/Topbar"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import MobileGrid from "./MobileGrid"
import { DesktopGrid } from "./DesktopGrid"
import Pagination from "../../shared/Pagination"
import { useSearchParams } from "react-router-dom"
import NoResults from "../../store/NoResults"
import {
  gtmLsProductPageLoad,
  triggerGtmLifestyleSavingsSearch,
} from "../../shared/gtm-events/CustomGtmEvents"

export const LocalBenefitStore = function ({
  showTopFilters: showTopFilters = true,
}) {
  const { data } = useLocalBenefitStoreData()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [searchParams, setSearchParams] = useSearchParams()
  const pageNumberInUrl = Number(searchParams.get("page"))
  const searchTerm = searchParams.get("search")
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  useEffect(() => {
    // update "page" in url when the state of totalPages has changed
    // with current architect, a page is re-rendered if url param is updated
    // so this useEffect is redundant now but it exists for future proof purpose
    if (data.totalPages !== 0 && pageNumberInUrl > data.totalPages) {
      const paramsToUpdate = updatedSearchParams(searchParams, [
        { paramName: "page", value: data.totalPages.toString() },
      ])

      setSearchParams(paramsToUpdate)
    }
  })

  useEffect(() => {
    // scroll to top of the page after clicking on pagination number buttons
    window.scrollTo(0, 0)
    if (!data.loading && !window.triggerGtmSearchEvent) {
      triggerGtmLifestyleSavingsSearch(data.totalEntries)
    }
  }, [data.discounts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPageLoaded && !data.loading) {
      gtmLsProductPageLoad()
      setIsPageLoaded(true)
    }
  }, [data.loading, isPageLoaded])

  return (
    <LocalBenefitStoreContext.Provider value={data}>
      <>
        <div className="store-main-container global-main-container--gray">
          <Container>
            <div className="topbar-container">
              <Topbar
                showTopFilters={showTopFilters}
                loading={data.loading}
                totalEntries={data.totalEntries}
                showSortBy={false}
                isLifestyleFilters={true}
                isLocalBenefits={window.location.pathname.includes(
                  "/local_benefits"
                )}
              />
            </div>
            <div>
              <Grid
                item
                xs={12}
                sx={{
                  margin: "2rem 0 1.5rem 0",
                }}
              >
                <div style={{ position: "relative" }}>
                  {data.loading && <div className="blur-overlay" />}
                  {data?.discounts && data.discounts.length > 0 ? (
                    <div
                      className={classNames("store-container", {
                        "store-container--mobile": !isDesktop,
                      })}
                      style={{ columnGap: "1rem" }}
                    >
                      {isDesktop ? (
                        <DesktopGrid benefitCardData={[...data.discounts]} />
                      ) : (
                        <MobileGrid benefitCardData={[...data.discounts]} />
                      )}
                    </div>
                  ) : (
                    <>
                      {!data.loading && <NoResults searchTerm={searchTerm} />}
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                {data.totalPages > 1 && (
                  <div>
                    <Pagination pageCount={data.totalPages} />
                  </div>
                )}
              </Grid>
            </div>
            <div>
              <ScrollToTop showBelow={500} />
            </div>
          </Container>
        </div>
      </>
    </LocalBenefitStoreContext.Provider>
  )
}
