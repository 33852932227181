import { Container, styled, Typography, css } from "@mui/material"
import { Box } from "@mui/system"
export const MainBox = styled(Container)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 69.25rem;
    width: 93%;
    padding: 0px;
  }
`
export const BoxLayout = styled(Box)`
  ${({ theme }) => css`
    &&& {
      width: 100%;
      padding: 0px;
      min-height: 20.9375rem;
      max-height: 597px;
      height: 100%;
      background: transparent linear-gradient(0deg, #2fa6da 0%, #351960 100%) 0%
        0% no-repeat padding-box;
      box-shadow: ${theme.boxShadow};
      border-radius: ${theme.boxBorderRadius};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`
export const MainText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    font-size: ${theme.typography.h3.fontSize};
    margin-bottom: 1rem;
    // margin-top: 4.125rem;
    line-height: ${theme.typography.h3.lineHeight};
  `}
`
export const TrustPilotImage = styled(Box)`
  width: 14.375rem;
  height: 5.5rem;
`
export const CardContainer = styled(Box)`
  max-width: 1500px;

  max-height: 500px;
  width: 1500px;
  height: 261px;
  background-color: gray;
  margin-right: 2rem;
  display: flex;
`
export const Card = styled(Box)`
  && {
    max-width: 285px !important;
    height: 261px;

    color: "black";
    text-align: center;

    border-radius: ${({ theme }) => theme.boxBorderRadius};
  }
`
export const TextContainer = styled(Box)`
  && {
    height: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`
