import { styled, css } from "@mui/material"
import { Button } from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import { FamilyCareProps } from "./FamilyCare"
import {
  ImageGrid,
  ContentContainer,
  MainGrid,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  MainBox,
  ButtonContainer,
  ImageContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"

const HowbenifitWorks: React.FC<FamilyCareProps> = ({ link }) => {
  const childrenStuding = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenStuding.png"
  )
  return (
    <MainBoxContainer>
      <ContentContainer>
        <MainGrid container>
          <ImageGrid item xs={12} md={7.5}>
            <ImageContainerStyle src={childrenStuding} alt="ChildrenStuding" />
          </ImageGrid>
          <ContentGrid item xs={12} md={4.5}>
            <TextContainer>
              <TextTitle>How it works</TextTitle>
              <ParagraphText>
                Once you have located your child care provider of choice, simply
                create an order and, once approved, a fixed monthly amount will
                be taken from your salary for an agreed length of time
              </ParagraphText>

              <ButtonContainer>
                <ButtonStyle href={link} component={"a"}>
                  Explore Family Pay
                </ButtonStyle>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
        </MainGrid>
      </ContentContainer>
    </MainBoxContainer>
  )
}
export default HowbenifitWorks

export const MainBoxContainer = styled(MainBox)`
  background: linear-gradient(rgb(11, 126, 176), rgb(53, 24, 95));
  width: 100%;
  background: linear-gradient(rgb(11, 126, 176), rgb(53, 24, 95)) 0% no-repeat
    padding-box;
`

export const ImageContainerStyle = styled(ImageContainer)`
  max-width: 553px;
  height: 325px;
`

export const ButtonStyle = styled(Button)`
  ${({ theme }) => css`
    && {
      max-width: 314px;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 314px;
      }
    }
  `}
`
