import Card from "@mui/material/Card"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { CardActions, Link, Button } from "@mui/material"
import { useState } from "react"
import DoneIcon from "@mui/icons-material/Done"
import { addBasketItem } from ".././basket-page/BasketContext"
import Alert from "../../shared/Alert"
import Shiitake from "shiitake"
import { useMediaQuery, useTheme } from "@mui/material"
import {
  gtmProductClick,
  gtmAddToCart,
  gtmCycleToWorkProductClick,
  gtmCycleToWorkAddToCart,
} from "../../shared/gtm-events/CustomGtmEvents"
import { getProductStyles } from "./style/ProductStyle"
import Theme from "../../../theme/mui-theme"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import {
  clickedObjectIDsAfterSearch,
  clickedObjectIDs,
} from "../../../utils/algoliaEvents"

export type ProductType = {
  id: number
  brochureGroupId: number
  title: string
  image: string
  lowertag: boolean
  price: string
  totalAmount: string
  altnativeImgText: string
  flashdealtag: boolean
  link: string
  productTagName?: string
  productTagImage?: string
  term: number
  brand: string
  variant: string
  category: string
  availableSizes: string
  sku: string
  storeName: string
  department: string
  supplierName?: string
}

export type ProductCardProps = {
  index: number
  product: ProductType
  showAddToOrder?: boolean
  changeStateFromChild?: (totalItems: number, monthlyPrice: number) => void
  isResponsive?: boolean
  isCycleToWork?: boolean
  algoliaQueryId?: string
  isRecommendedProduct?: string
  isRecommendedTab?: boolean
  isFrequentlyBrought?: boolean
  isSimilarProduct?: string
  youMayAlsoLike?: string
  frequentlyBrought?: string
}

const ProductCard = ({
  product,
  showAddToOrder = false,
  changeStateFromChild,
  isResponsive = true,
  isCycleToWork,
  index,
  algoliaQueryId,
  isRecommendedProduct,
  isRecommendedTab,
  isFrequentlyBrought,
  isSimilarProduct,
  youMayAlsoLike,
  frequentlyBrought,
}: ProductCardProps) => {
  const [isTabPressed, setIsTabPressed] = useState(false)
  const [addOrder, setAddOrder] = useState(false)
  const { id, brochureGroupId } = product
  const [showAlertMessage, setShowAlertMessage] = useState("")
  const { t, ready } = useTranslation("store")
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [searchParams] = useSearchParams()
  const pageNumberInUrl = Number(searchParams.get("page"))
  const resultsPerPage = Number(searchParams.get("results_per_page") || "40")
  const isRecommendationClicked =
    isRecommendedProduct || isRecommendedTab || isFrequentlyBrought
      ? "Recommended object clicked"
      : ""
  let cardIndex = index
  if (pageNumberInUrl > 1) {
    cardIndex = pageNumberInUrl > 1 ? resultsPerPage + index : index
  }

  if (pageNumberInUrl > 2) {
    cardIndex =
      pageNumberInUrl > 2
        ? (pageNumberInUrl - 1) * resultsPerPage + index
        : index
  }

  const classes = getProductStyles(Theme)

  const addedToCard = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault()
    if (!addOrder) {
      addBasketItemMutation()
        .then((data) => {
          const totalItems = data?.data?.addBasketItem?.basket?.count || 0
          const monthlyPrice =
            data?.data?.addBasketItem?.basket?.monthlyPrice || 0.0
          const errorMessageLength =
            data?.data?.addBasketItem?.errors?.length || 0

          setShowAlertMessage("")
          if (errorMessageLength > 0) {
            setShowAlertMessage(data?.data?.addBasketItem?.errors[0] || "")
            return setAddOrder(false)
          }
          if (changeStateFromChild) {
            changeStateFromChild(totalItems, monthlyPrice)
          }
          setAddOrder(true)

          !isCycleToWork && gtmAddToCart(product)
          isCycleToWork && gtmCycleToWorkAddToCart(product)
        })
        .catch(() => {
          // TODO - display error message
        })
    }
  }

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault()
      setIsTabPressed(true)
    }
  }
  const onKeyDown = () => {
    setIsTabPressed(false)
  }
  const [addBasketItemMutation] = addBasketItem(id, brochureGroupId, "")

  if (!ready) {
    return null
  }

  return (
    <>
      {showAlertMessage.length > 0 && (
        <Alert severity={"error"} message={showAlertMessage} />
      )}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          data-name={product.title}
          data-id={product.id}
          data-sku={product.sku}
          data-store={product.storeName}
          data-total-amount={parseFloat(product.totalAmount).toFixed(2)}
          data-brand={product.brand}
          data-category={product.category}
          data-variant={product.variant}
          data-department={product.department}
          data-supplier-name={product.supplierName}
          component={Link}
          href={product.link}
          onClick={(event) => {
            const productDetails = product
            if (!isCycleToWork) {
              if (isSimilarProduct) {
                gtmProductClick(product, isSimilarProduct)
              } else if (youMayAlsoLike) {
                gtmProductClick(product, youMayAlsoLike)
              } else if (frequentlyBrought) {
                gtmProductClick(product, frequentlyBrought)
              } else if (!event.isDefaultPrevented()) {
                gtmProductClick(product, "product-click")
              }
            }
            !algoliaQueryId &&
              clickedObjectIDs([product.id.toString()], isRecommendationClicked)
            const customerUserId = window.localStorage.getItem("customerUserId")
            const currentUserOrg = window.localStorage.getItem("currentOrg")

            algoliaQueryId &&
              clickedObjectIDsAfterSearch(
                algoliaQueryId,
                [product.id.toString()],
                [cardIndex]
              )
            isCycleToWork && gtmCycleToWorkProductClick(productDetails)
            algoliaQueryId &&
              window.localStorage.setItem(
                product.id + "clicked" + customerUserId + currentUserOrg,
                algoliaQueryId
              )
          }}
          underline="none"
          className="gtm-product-box"
          sx={{
            ...classes.CardBase,
            ...(isResponsive && classes.CardResponsive),
            ...(showAddToOrder ? classes.AddBtnCardBase : {}),
            ...(showAddToOrder && isResponsive && classes.AddBtnCardResponsive),
            ...(isTabPressed ? classes.TabPressed : {}),
          }}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        >
          <Box
            sx={{
              ...classes.CardImgContainerBase,
              ...(isResponsive && classes.CardImgContainerResponsive),
              ...(showAddToOrder && classes.AddBtnCardImgContainerBase),
              ...(showAddToOrder &&
                isResponsive &&
                classes.AddBtnCardImgContainerResponsive),
            }}
          >
            <Box
              component="img"
              src={product.image}
              sx={classes.CardImg}
              alt={product.altnativeImgText}
              loading="lazy"
            />
          </Box>
          <CardContent
            sx={{
              padding: "0.5rem 0",
              ...(showAddToOrder
                ? classes.CardContentAddOrder
                : classes.CardContentBase),
              ...(!showAddToOrder &&
                isResponsive &&
                classes.CardContentResponsive),
            }}
          >
            <Box
              component="div"
              sx={{
                ...classes.titleContainerBase,
                ...(isResponsive && classes.titleContainerResponsive),
              }}
              title={product.title}
              data-cy="card-title"
            >
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  ...classes.titleBase,
                  ...(isResponsive && classes.titleResponsive),
                }}
              >
                <Shiitake
                  lines={isMobile ? 4 : 3}
                  className="product-card-shiitake-title"
                >
                  {product.title}
                </Shiitake>
              </Typography>
            </Box>
            <Typography gutterBottom mt={1} variant="h1" sx={classes.price}>
              £{parseFloat(product.price).toFixed(2)}
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              sx={{
                ...classes.priceTagLineBase,
                ...(isResponsive && classes.priceTagLineResponsive),
              }}
            >
              {t("productCard.perMonth")}
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              sx={{
                ...classes.priceTagLineBase,
                ...(isResponsive && classes.priceTagLineResponsive),
              }}
            >
              ({product.term} {t("productCard.monthTerm")})
            </Typography>

            {showAddToOrder && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    ...classes.orderBtnBase,
                    ...(isResponsive && classes.orderBtnResponsive),
                    ...(addOrder
                      ? classes.addedOrderBtnBase
                      : classes.showAddToOrderBtn),
                    ...(!addOrder &&
                      isResponsive &&
                      classes.addedOrderBtnResponsive),
                  }}
                  onClick={(event) => addedToCard(event)}
                >
                  {addOrder ? (
                    <>
                      <DoneIcon
                        sx={{
                          ...classes.addedOrderIconBase,
                          ...(isResponsive && classes.addedOrderIconResponsive),
                        }}
                      />{" "}
                      &nbsp; {t("productCard.addedToOrder")}
                    </>
                  ) : (
                    t("productCard.addToOrder")
                  )}
                </Button>
              </Box>
            )}
          </CardContent>
          {JSON.parse(product.availableSizes).length > 1 && (
            <CardActions
              sx={{
                display: product.lowertag ? "flex" : "none",
                ...classes.lowerTagBase,
                ...(isResponsive && classes.lowerTagResponsive),
              }}
            >
              <Typography
                sx={{
                  ...classes.lowerTagTextBase,
                  ...(isResponsive && classes.lowerTagTextResponsive),
                }}
              >
                {t("productCard.optionsAvailable")}
              </Typography>
            </CardActions>
          )}
        </Card>
        {product.productTagImage ? (
          <Box
            component="div"
            sx={{
              ...classes.flashDealTagBase,
              ...(isResponsive && classes.flashDealTagResponsive),
            }}
          >
            <Box
              component="img"
              src={product.productTagImage}
              alt={product.productTagName}
              title={product.productTagName}
              sx={classes.flashDealTagImg}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  )
}

export default ProductCard
