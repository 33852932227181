const ASSET_BUCKET = window.ENV["ASSET_BUCKET"]

export const BuildAwsImageUrl = async (locale: string, imageName: string) => {
  if (!imageName) {
    return ""
  }

  const imageUrl = await validateUrl(locale, imageName)

  return imageUrl
}

const validateUrl = async (locale: string, imageName: string) => {
  let imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/${locale}/${imageName}`

  try {
    const response = await fetch(imageUrl)
    if (response.ok) {
      imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/${locale}/${imageName}`
    } else {
      imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/en-GB/${imageName}`
    }

    // Extracting file size
    const contentLengthHeader = response.headers.get("content-length")
    const size = contentLengthHeader ? parseInt(contentLengthHeader) : null

    return { imageUrl, size }
  } catch (error) {
    imageUrl = `https://${ASSET_BUCKET}.s3.eu-west-2.amazonaws.com/en-GB/${imageName}`
    return { imageUrl, size: null }
  }
}

// Note: we created this function because we cannot use hook conditionaly. so to get array we need to use function
