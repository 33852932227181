import { useMediaQuery, useTheme } from "@mui/material"
import Item, { MegaMenuItemProps } from "./Item"
import { useState } from "react"

export type MegaMenuProps = {
  categories: MegaMenuItemProps[]
}

const LifestyleMegaMenu = ({
  categories,
}: {
  categories: MegaMenuItemProps[]
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeItem, setActiveItem] = useState(-1)

  const setActive = (state: boolean, index: number) => {
    if (state === true) {
      setActiveItem(index)
    } else {
      setActiveItem(-1)
    }
  }

  return (
    <>
      {isDesktop && (
        <div className="mega-menu">
          {categories?.map((item: MegaMenuItemProps, index: number) => {
            return (
              <Item
                active={activeItem === index}
                setActive={(state: boolean) => setActive(state, index)}
                item={item}
                key={`ls-mega-menu-${item.name}`}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default LifestyleMegaMenu
