import React from "react"
import { Box } from "@mui/system"
import { styled, css } from "@mui/material"
import { useEffect } from "react"
import { triggerIframeGtmEvent } from "../../../shared/gtm-events/CustomGtmEvents"

const VIDEO_URL = "https://player.vimeo.com/video/789737085?h=5026b1f8f0"

export const VideoContent = () => {
  useEffect(() => {
    triggerIframeGtmEvent()
  })

  return (
    <VideoContainer>
      <StyledIframe
        src={VIDEO_URL}
        allow="autoplay; fullscreen; picture-in-picture"
      ></StyledIframe>
    </VideoContainer>
  )
}
const VideoContainer = styled(Box)`
  ${({ theme }) => css`
    padding-bottom: 1rem;
    height: 80%;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      height: 90%;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: unset;
      padding-bottom: unset;
      padding-right: 1rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding-right: unset;
    }
  `}
`

const StyledIframe = styled("iframe")`
  border: none;
  width: 100%;
  height: 100%;
`
