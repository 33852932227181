import { Box, styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const PayrollGivingFootnote = () => {
  const { t, ready } = useTranslation("quotePage")
  const noteOne = t("footNote.noteOne")
  const noteTwo = t("footNote.noteTwo")
  if (!ready) {
    return null
  }
  return (
    <Container>
      <Text>{noteOne}</Text>
      <Text>{noteTwo}</Text>
    </Container>
  )
}

export default PayrollGivingFootnote

const Container = styled(Box)`
  width: 96%;
  max-width: 30.9375rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.3125rem;
`

const Text = styled(Typography)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 0.125rem;
`
