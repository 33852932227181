import { createContext } from "react"
import {
  GetCurrentUserDataQueryHookResult,
  GetStaticLayoutDataQueryHookResult,
  useGetCurrentUserDataQuery,
  useGetStaticLayoutDataQuery,
} from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import extractSubdomain from "../../utils/extractSubdomain"

export type StaticLayoutContextData = {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  currentUserId?: number
  loading: boolean
  backendUser: boolean
  reporter: boolean
  isOrganisationSettingUser: boolean
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
  stopRegistration: boolean
}

const defaultValue: StaticLayoutContextData = {
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  currentUserId: undefined,
  backendUser: false,
  reporter: false,
  isOrganisationSettingUser: false,
  employeeOrganisations: [],
  loading: true,
  stopRegistration: false,
}

const valueFromQueryResult = function (
  result:
    | GetStaticLayoutDataQueryHookResult
    | GetCurrentUserDataQueryHookResult,
  organisationId?: string
): StaticLayoutContextData {
  const data = result.data
  const loading = result.loading

  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  return {
    privacyNoticeUrl: privacyNoticeUrl,
    organisationPrivacyNotice:
      data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
    currentUserId: data?.currentUser?.id,
    employeeOrganisations: data?.currentUser?.employeeOrganisations || [],
    backendUser: data?.currentUser?.backendUser || false,
    reporter: data?.employeeOrganisation?.reporter || false,
    isOrganisationSettingUser:
      data?.currentUser?.isOrganisationSettingUser || false,
    loading: loading,
    stopRegistration: data?.portalProvider?.stopRegistration || false,
  }
}

export const useStaticLayoutData = function (): StaticLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const layoutData = useGetStaticLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
    skip: params.organisationId === undefined,
  })
  const currentUrl = location.href
  const subdomain = extractSubdomain(currentUrl)
  const userData = useGetCurrentUserDataQuery({
    variables: {
      subdomain: subdomain === "Vivup" ? undefined : subdomain,
    },
    errorPolicy: "all",
  })

  return valueFromQueryResult(
    params.organisationId ? layoutData : userData,
    params.organisationId
  )
}

export const StaticLayoutContext = createContext(defaultValue)
