import { css, styled } from "@mui/material"
import { RedInnerBox, SubmitButton } from "../shared/mainPageStyles"
import { SubTitleHeading } from "../shared/headingStyles"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

export const InnerBox = styled(RedInnerBox)`
  ${({ theme }) => css`
    padding: 1.75rem 1.125rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      align-items: center;
    }
  `}
`

export const ProceedButton = styled(SubmitButton)`
  ${({ theme }) => css`
    margin-top: 1.4375rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 18.125rem;
    }
  `}
`

export const InfoIcon = styled(InfoOutlinedIcon)`
  margin-right: 0.75rem;
  margin-left: 0.5rem;
`

export const Subheader = styled(SubTitleHeading)`
  ${({ theme }) => css`
    max-width: none;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 2.125rem;
    }
  `}
`
