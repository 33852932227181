import { Box, Button, Typography, css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import BottomBenefitWindowInfo from "./BottomBenefitWindowInfo"
import useIsMobileView from "../../utils/useIsMobileView"
import { WindowStatusType } from "../layout/store/getSchemeStatus"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

const NoOpenSchemeContent = ({
  windowStatus,
}: {
  windowStatus: WindowStatusType
}) => {
  const { t, i18n, ready } = useTranslation("noOpenScheme")
  const isMobileView = useIsMobileView()
  const desktop = useBuildAwsImageUrl(i18n.language, "store/desktop.jpg")
  const mobile = useBuildAwsImageUrl(i18n.language, "store/mobile.jpg")

  const handleClick = () => {
    window.location.href =
      "https://vivupbenefits.co.uk/access-benefits/helpdesk"
  }

  if (!ready) {
    return null
  }
  return (
    <OuterContainer>
      <FlexContainer>
        <Text>
          <Paragraph>{t("mainText.paraOne")}</Paragraph>
          <Paragraph>{t("mainText.paraTwo")}</Paragraph>
          <SubHeader>{t("mainText.subHeader")}</SubHeader>
          <Paragraph>{t("mainText.paraThree")}</Paragraph>
        </Text>
        <Image
          src={isMobileView ? mobile : desktop}
          alt="home_and_electronics_image"
        />
      </FlexContainer>
      <BottomBenefitWindowInfo windowStatus={windowStatus} />

      <ContactButton variant="gradient" onClick={handleClick}>
        {t("button")}
      </ContactButton>
    </OuterContainer>
  )
}

export default NoOpenSchemeContent

const ContactButton = styled(Button)`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto;
    max-width: 18.25rem;
    width: 86%;
    margin-bottom: 2rem;
    margin-top: 1.75rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 2.25rem auto;
    }
  `}
`

const Image = styled("img")`
  ${({ theme }) => css`
    margin: 2.25rem auto;
    max-width: 20.9375rem;
    width: 100%;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 488px;
      margin-top: 0;
      max-width: initial;
    }
  `}
`

const SubHeader = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 1rem;
    font-weight: bold;
  `}
`

const Paragraph = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 0.875rem;
    line-height: 1rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      line-height: initial;
    }
  `}
`

const Text = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

const FlexContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      column-gap: 2.25rem;
    }
  `}
`

const OuterContainer = styled(Box)`
  ${({ theme }) => css`
    width: 87%;
    margin: 2rem auto;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 70%;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
      max-width: 1061px;
    }
  `}
`
