import GenericField from "../../shared/form/GenericField"
import { getNonEditableWorkEmailField } from "./groupRegFields"
interface NonEditableEmailFieldProps {
  label: string
}
const NonEditableEmailField: React.FC<NonEditableEmailFieldProps> = ({
  label,
}) => {
  const fieldOpacity = {
    opacity: 0.7, // Adjust the opacity value
  }
  return (
    <>
      <GenericField
        textFieldProps={getNonEditableWorkEmailField(label)}
        inlineTextFieldProps={{
          style: fieldOpacity,
          InputLabelProps: {
            shrink: false,
          },
          InputProps: {
            readOnly: true,
            disabled: true,
          },
        }}
      />
    </>
  )
}
export default NonEditableEmailField
