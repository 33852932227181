import { useContext } from "react"
import { useMediaQuery, useTheme } from "@mui/material"
import { Button, Chip } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import { StoreContext } from "../../store/StoreContext"
import { LSStoreContext } from "../../lifestyle-savings/LSStoreContext"
import capitalise from "../../../utils/capitalise"
import classNames from "classnames"
import i18n from "../../../i18n"

export type PillContainerProps = {
  isLifestyleFilters?: boolean
  showPills?: boolean
  watchedFilterParams?: {
    param: string
    prepend?: boolean
  }[]
  clearButtonLabel?: string
}

export default function PillContainer({
  watchedFilterParams = [
    {
      param: "promo",
    },
    { param: "brands" },
    { param: "trend" },
    { param: "saving" },
    { param: "categories" },
    { param: "savingTypes" },
    { param: "min", prepend: true },
    { param: "max", prepend: true },
  ],
  showPills = true,
  clearButtonLabel,
  isLifestyleFilters = false,
}: PillContainerProps) {
  const contextToUse = isLifestyleFilters ? LSStoreContext : StoreContext
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { filters } = useContext(contextToUse)
  const [searchParams, setSearchParams] = useSearchParams()

  const getDefaultClearText = () =>
    i18n.t("lifestyleSavings:store.pillContainer.defaultClearText")

  const isInFilters = function (param: string, value: string): boolean {
    switch (param) {
      case "promo":
        return filters.promotions?.includes(value)
      case "trend":
        return filters.promotions?.map((obj) => obj.id).includes(value)
      case "brands":
        return isLifestyleFilters
          ? filters.brands?.map((obj) => obj.id).includes(value)
          : filters.brands?.includes(value)
      case "categories":
        return isLifestyleFilters
          ? filters.categories?.map((obj) => obj.id).includes(value)
          : filters.categories?.includes(value)
      case "savingTypes":
        return isLifestyleFilters
          ? filters.savingTypes?.map((obj) => obj.id).includes(value)
          : filters.savingTypes?.includes(value)
    }
    return true
  }

  const renderFilterLabel = function (param: string, value: string) {
    switch (param) {
      case "promo":
        return value
      case "trend":
        return renderObjLabel(filters.promotions, value)
      case "brands":
        return isLifestyleFilters
          ? renderObjLabel(filters.brands, value)
          : value
      case "categories":
        return isLifestyleFilters
          ? renderObjLabel(filters.categories, value)
          : value
      case "savingTypes":
        return isLifestyleFilters
          ? renderObjLabel(filters.savingTypes, value)
          : value
      case "min":
        return value
      case "max":
        return value
    }
  }

  const renderObjLabel = function (
    objects: { id: string; name: string }[],
    value: string
  ) {
    const filterObject = objects.find((obj: { id: string; name: string }) => {
      return obj.id === value
    })
    return filterObject?.name
  }

  const appliedFilters = watchedFilterParams.reduce(
    (
      result: { param: string; value: string; prepend?: boolean }[],
      filter: { param: string; prepend?: boolean }
    ) => {
      return [
        ...result,
        ...searchParams
          .getAll(filter.param)
          .filter(
            (value) =>
              ["min", "max"].includes(filter.param) ||
              isInFilters(filter.param, value)
          )
          .map((value) => {
            return {
              param: filter.param,
              value: filter.prepend
                ? capitalise(filter.param) + ": " + value
                : value,
              prepend: filter.prepend,
            }
          }),
      ]
    },
    []
  )
  const removeExistingFilter = (
    paramsToRemove?: {
      param: string
      value: string
      prepend?: boolean | undefined
    }[]
  ) => {
    const result = new URLSearchParams(searchParams?.toString())

    paramsToRemove?.forEach((item) => {
      result.delete(item.param)
    })
    return result
  }

  const handleClear = () => {
    setSearchParams(removeExistingFilter(appliedFilters))
  }

  const handleDelete = (filter: {
    param: string
    value: string
    prepend?: boolean
  }) => {
    setSearchParams(
      updatedSearchParams(searchParams, [
        {
          paramName: filter.param,
          value: searchParams
            .getAll(filter.param)
            .filter(
              (item) =>
                item !==
                (filter.prepend
                  ? filter.value.substr(filter.value.search(": ") + 2)
                  : filter.value)
            ),
        },
      ])
    )
  }

  return (
    <>
      {appliedFilters.length > 0 && (
        <div
          className={classNames("pill-container", {
            "pill-container--wide": !isDesktop,
          })}
        >
          {showPills ? (
            <div className="pill-container__pills">
              {appliedFilters.map((filter, index) => (
                <Chip
                  key={index}
                  label={renderFilterLabel(filter.param, filter.value)}
                  onDelete={() => handleDelete(filter)}
                  sx={{ margin: "0.1875rem 0.125rem" }}
                />
              ))}
            </div>
          ) : (
            ""
          )}
          <Button
            sx={{
              fontSize: "0.875rem",
              textDecoration: "underline",
              alignSelf: "center",
              ":hover": {
                backgroundColor: "inherit",
                textDecoration: "underline",
              },
            }}
            onClick={handleClear}
          >
            {clearButtonLabel ?? getDefaultClearText()}
          </Button>
        </div>
      )}
    </>
  )
}
