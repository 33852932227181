import { IconsSection } from "./IconsSection"
import { Chip } from "./Chip"
import { ExploreCarBenefitButton } from "./ExploreCarBenefitButton"
import { Header } from "./Header"
import styled from "@emotion/styled"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

export function Content({ onlyCarBenefit }: { onlyCarBenefit: boolean }) {
  const isMobile = useIsMobileView()
  const { t, i18n, ready } = useTranslation("portalHomepage")
  const blueCar = useBuildAwsImageUrl(i18n.language, "blueCar.png")

  if (!ready) {
    return null
  }
  return (
    <>
      <Chip onlyCarBenefit={onlyCarBenefit} />
      <Header onlyCarBenefit={onlyCarBenefit} />

      <IconsSection onlyCarBenefit={onlyCarBenefit} />

      {!isMobile && (
        <Image src={blueCar} alt={t("carBenefit.mainImageAltText")} />
      )}

      <ExploreCarBenefitButton />
    </>
  )
}
const Image = styled("img")`
  height: 297px;
  display: block;
  margin: 0 auto;
  margin-top: 23px;
  margin-bottom: -23px;
`
