export const TranslatedPaths = [
  "/employee/dashboard",
  "/employee/benefits/home_and_electronics/landing",
  "/employee/benefits/cycle_to_work/landing",
  "/employee/benefits/bike_shop/landing",
  "/employee/benefits/family_pay",
  "/employee/benefits/family_care",
]

export default function isTranslatedPath(path: string) {
  const regex = new RegExp(".*" + TranslatedPaths.join("|.*"))
  return regex.test(path)
}
