type CurrentUserIdType = number | undefined
type BreadcrumbsLabelType = string

export function getBreadcrumbs(
  currentUserId: CurrentUserIdType,
  breadcrumbsLabel: BreadcrumbsLabelType
) {
  if (currentUserId) {
    return [
      {
        forceLink: null,
        label: "Home",
        link: "/",
        isExternalLink: true,
      },
      {
        forceLink: null,
        label: breadcrumbsLabel,
        link: "#",
      },
    ]
  }
  return [
    {
      forceLink: null,
      label: "Home",
      link: "/users/sign_in",
      isExternalLink: true,
    },
    {
      forceLink: null,
      label: breadcrumbsLabel,
      link: "#",
    },
  ]
}
