import OuterContainer from "../../../shared/card/OuterContainer"
import {
  InnerContainer,
  ImageWrapper,
  TextContainer,
  BottomVoucherRibbon,
} from "../../product-card/SmallCard"
import { VoucherText } from "../../product-card/SharedStyles"
import { Typography, styled, css } from "@mui/material"
import { BenefitCardDataType, TextRow } from "./BenefitCard"
import CardPopUp from "../../product-card/CardPopUp"
import { useState } from "react"
import Shiitake from "shiitake"
import { gtmLifestyleSavingTileClick } from "../../../shared/gtm-events/CustomGtmEvents"

const SmallBenefitCard = ({
  benefitCardData,
  index,
}: {
  benefitCardData: BenefitCardDataType
  index: number
}) => {
  const {
    id,
    name,
    link,
    image,
    savingText,
    value,
    voucher = "Local Benefit",
  } = benefitCardData
  const [popUpOpen, setPopUpOpen] = useState(false)
  const HandleCardOpen = () => {
    setPopUpOpen(true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    gtmLifestyleSavingTileClick(id, voucher, name, index + 1, value)
  }
  return (
    <OuterContainer>
      <InnerContainer onClick={HandleCardOpen} isMobileStoreSmallCard={true}>
        <ImageWrapper>
          <img src={image} style={{ width: "80%" }} />
        </ImageWrapper>
        <TextContainer>
          <TextRow title={name}>
            <Title>
              <Shiitake lines={1} tagName="span">
                {name}
              </Shiitake>
            </Title>
          </TextRow>

          <TextRow title={savingText}>
            <Text>
              <Shiitake lines={2} tagName="span">
                {savingText}
              </Shiitake>
            </Text>
          </TextRow>
        </TextContainer>
        <BottomVoucherRibbon>
          <VoucherText variant="body2">{voucher}</VoucherText>
        </BottomVoucherRibbon>
      </InnerContainer>
      <CardPopUp
        savingType={voucher}
        index={index}
        name={name}
        id={id}
        title={name}
        description={savingText}
        link={link}
        open={popUpOpen}
        setOpen={setPopUpOpen}
        disableClickCount
        value={""}
        indexCarousel={0}
        parseAmount={0}
      />
    </OuterContainer>
  )
}

export default SmallBenefitCard

const Text = styled(Typography)`
  ${({ theme }) => css`
    &&& {
      font-family: ${theme.typography.fontFamily};
      font-size: 0.75rem;
      color: ${theme.palette.primary.main};
      line-height: 1rem;
    }
  `}
`

const Title = styled(Text)`
  ${({ theme }) => css`
    &&& {
      font-size: ${theme.typography.body2.fontSize};
      font-weight: 700;
    }
  `}
`
