import React, { useRef } from "react"
import { styled, TextField, Autocomplete } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { DeliveryFieldsType } from "./defaultFormContent"
import { useState, useEffect } from "react"
import { getAddressSuggestions, getFullAddress } from "./CheckoutContext"
import { useTranslation } from "react-i18next"

export type DeliveryProps = {
  content: DeliveryFieldsType
  setContent: (conten: DeliveryFieldsType) => void
  focus: {
    counter: number
    key: string
  }
}
export type DeliveryAddressType = {
  id: string | undefined
}

export type AddressSuggestionType = {
  label: string | undefined
  id: string | undefined
}

const StyledTextField = styled(TextField)({
  marginBottom: "1.875rem",
})

export default function Delivery({
  content,
  setContent,
  focus,
}: DeliveryProps) {
  const [searchTerm, setSearchTerm] = useState("")
  const [apiResponseAddresses, setApiResponseAddresses] = useState<
    AddressSuggestionType[]
  >([])
  const { refetch } = getAddressSuggestions("")
  const { t, ready } = useTranslation("checkoutPage")
  const triggerAddressSuggestionsCall = () => {
    refetch({
      searchTerm: searchTerm,
    }).then((data) => {
      const result = data?.data?.employeeOrganisation?.addressSuggestions?.map(
        (addressData) => {
          return {
            label: addressData.address,
            id: addressData.id,
          }
        }
      )
      setApiResponseAddresses(result || [])
    })
  }

  const { refetch: refetchFullAddress } = getFullAddress("")
  const triggerGetFullAddressCall = (addressId: string) => {
    refetchFullAddress({
      id: addressId,
    }).then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fullAddressData: any =
        data?.data?.employeeOrganisation?.getFullAddress
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fullAddressPopulatedFields: any = {}
      Object.entries(content).map(([key, field]) => {
        const validation = field.validate(fullAddressData[key] || "")
        fullAddressPopulatedFields[key] = {
          ...field,
          value: fullAddressData[key] || "",
          state: validation.isValid ? undefined : "error",
          errorMessage: validation.isValid ? "" : validation.message,
        }
      })
      setContent({ ...content, ...fullAddressPopulatedFields })
    })
  }

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    if (searchTerm.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        triggerAddressSuggestionsCall()
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    }
    return () => setApiResponseAddresses([])
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO - fix eslint warnings/errors
  const refs = Object.keys(content).reduce(
    (a, v) => ({ ...a, [v]: useRef() }), // eslint-disable-line react-hooks/rules-of-hooks
    {}
  )

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    refs[focus.key].current.focus()
  }, [focus.counter]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO - fix eslint warnings/errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finishedTextField = (key: string, field: any) => (
    <StyledTextField
      label={field.label}
      variant="outlined"
      value={field.value}
      inputRef={refs[key]}
      error={field.state === "error"}
      helperText={field.errorMessage}
      onChange={(e) =>
        setContent({
          ...content,
          [key]: { ...field, value: e.target.value },
        })
      }
      onBlur={(e) => {
        const validation = field.validate(e.target.value)
        setContent({
          ...content,
          [key]: {
            ...field,
            state: validation.isValid ? undefined : "error",
            errorMessage: validation.isValid ? "" : validation.message || "",
          },
        })
      }}
    />
  )

  const handleChangeTemplate = (
    event: React.SyntheticEvent,
    value: DeliveryAddressType
  ) => {
    triggerGetFullAddressCall(value.id || "")
  }

  if (!ready) {
    return null
  }
  return (
    <div className="fields">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        filterOptions={(x) => x}
        options={apiResponseAddresses}
        sx={{ marginBottom: "1.875rem" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SearchIcon sx={{ paddingRight: "0.5rem" }} fontSize="small" />
                {t("checkoutPage.form.typePostCode")}
              </div>
            }
          />
        )}
        onKeyUp={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
        onChange={(event, value) =>
          value ? handleChangeTemplate(event, value) : ""
        }
      />
      {Object.entries(content).map(([key, field]) => (
        <React.Fragment key={key}>
          {finishedTextField(key, field)}
        </React.Fragment>
      ))}
      <div className="fields__footnote">
        {t("checkoutPage.form.requiredFields")}
      </div>
    </div>
  )
}
