import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { Typography } from "@mui/material"
import commonPasswords from "../../../utils/commonPasswords"
import { useTheme } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

type PasswordProgressBarProps = {
  password: string
}

export default function PasswordProgressBar({
  password,
}: PasswordProgressBarProps) {
  const theme = useTheme()
  const { t, ready } = useTranslation("regForm")
  const invalidPassword =
    password === "" || password in commonPasswords || password.length <= 7
  const weakPassword = password.length >= 8 && password.length <= 9
  const mediumPassword = password.length >= 10 && password.length <= 11

  const strongPassword = password.length >= 12

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "0.625rem",
    borderRadius: "0.25rem",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "0.25rem",
      backgroundColor:
        theme.palette.mode === "light"
          ? invalidPassword
            ? theme.palette.grey[500]
            : strongPassword
            ? theme.palette.success.main
            : mediumPassword
            ? theme.palette.warning.main
            : theme.palette.error.main
          : strongPassword
          ? theme.palette.success.main
          : mediumPassword
          ? theme.palette.warning.main
          : theme.palette.error.main,
    },
  }))

  let strengthText = ""
  if (invalidPassword) {
    strengthText = t("regForm:passwordStrengthBar.invalidPassword")
  } else if (strongPassword) {
    strengthText = t("regForm:passwordStrengthBar.strong")
  } else if (mediumPassword) {
    strengthText = t("regForm:passwordStrengthBar.medium")
  } else if (weakPassword) {
    strengthText = t("regForm:passwordStrengthBar.weak")
  }
  if (!ready) {
    return null
  }
  return (
    <Box
      sx={{ flexGrow: 1, marginBottom: "1.5rem" }}
      className="ProgressBarContainer"
    >
      <Box>
        <BorderLinearProgress
          variant="determinate"
          value={calculateProgress(password)}
        />
        <Typography
          sx={{
            color: invalidPassword
              ? theme.palette.primary.main
              : strengthColor(strengthText, theme),
            fontSize: "0.75rem",
            marginTop: "0.5rem",
          }}
        >
          {strengthText}
        </Typography>
      </Box>
    </Box>
  )
}

function calculateProgress(password: string): number {
  if (password === "") {
    return 0
  } else if (password.length >= 12) {
    return 100
  } else {
    const progress = password.length >= 8 ? (password.length - 7) * 20 : 0
    return progress
  }
}

function strengthColor(strength: string, theme: Theme): string {
  switch (strength) {
    case "Weak":
      return theme.palette.error.main
    case "Medium":
      return theme.palette.warning.main
    case "Strong":
      return theme.palette.success.main
    default:
      return ""
  }
}
