import { string } from "yup"
import i18n from "../../i18n"

const validationRegex = /^[A-Z]{2}\d{6}[A-Z]$/

export const niNumberValidation = string()
  .matches(validationRegex, () =>
    i18n.t("bikeQuote:validations.niNumberInvalidWarning")
  )
  .required(() => i18n.t("bikeQuote:validations.niNumberEmptyWarning"))
  .uppercase()
