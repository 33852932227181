import * as Yup from "yup"
import {
  mobileNumberValidation,
  mobileNumberValidationRequired,
} from "../../../utils/yup-validation-schema/mobileNumberValidation"
import { regConfirmPersonalEmailValidation } from "../../../utils/yup-validation-schema/emailValidation"
import {
  passwordValidation,
  reEnterPasswordValidation,
} from "../../../utils/yup-validation-schema/passwordValidation"
import { salaryBracketValidation } from "../../../utils/yup-validation-schema/salaryBracketValidation"

const defaultSalaryBracketState = "optional"
const defaultMobileNumberState = "optional"

export type RegConfirmationValue = {
  mobileNumberState: "required" | "optional" | "hidden"
  mobileNumber: string
  salaryBracketState: "required" | "optional" | "hidden"
  salaryBracket: string
  checkedTC: boolean
  confirmationPersonalEmail: string
  nonEditableWorkEmail: string
  password: string
  reEnterPassword: string
}
type RegConfirmationFieldsWithValidationKeys<
  T extends keyof RegConfirmationValue
> = T[]

export const RegConfirmationFieldsWithValidation: RegConfirmationFieldsWithValidationKeys<
  "mobileNumber" | "password" | "reEnterPassword" | "confirmationPersonalEmail"
> = ["mobileNumber", "password", "reEnterPassword", "confirmationPersonalEmail"]

export const regConfirmationInitialValues: RegConfirmationValue = {
  mobileNumberState: defaultMobileNumberState,
  mobileNumber: "",
  password: "",
  reEnterPassword: "",
  salaryBracketState: defaultSalaryBracketState,
  salaryBracket: "",
  checkedTC: false,
  confirmationPersonalEmail: "",
  nonEditableWorkEmail: "",
}

export const regConfirmationValidationSchema = Yup.object().shape({
  mobileNumber: Yup.string().when("mobileNumberState", (mobileNumberState) => {
    if (mobileNumberState === "required") {
      return mobileNumberValidationRequired
    }
    if (mobileNumberState === "optional") {
      return mobileNumberValidation
    }
    return Yup.string()
  }),
  password: passwordValidation,
  reEnterPassword: reEnterPasswordValidation,
  salaryBracket: Yup.string().when("salaryBracketState", {
    is: "required",
    then: salaryBracketValidation,
  }),
  confirmationPersonalEmail: regConfirmPersonalEmailValidation,
})
