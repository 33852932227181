import { styled } from "@mui/material"
import { RoundelProps } from "../../../shared/Roundel"
import C2WRoundel from "./C2WRoundel"
import { PaddingContainer } from "../../shared/SharedStyles"
import { Chip } from "./Chip"
import Footer from "./Footer"
import { Header } from "./Header"
import Partners from "./Partners"
import { ExploreC2WButton } from "./ExploreC2WButton"
import { TwoPropositionsRoundel } from "./TwoPropositionsRoundel"
import { MOBILE_ROUNDEL_PER_PAGE } from "./C2W"
import useIsMobileView from "../../../../utils/useIsMobileView"

export const Content = ({
  roundels,
  onlyC2W,
  isCycleToWork,
  cycleToWorkStore,
}: {
  roundels: RoundelProps[]
  onlyC2W: boolean
  isCycleToWork?: boolean
  cycleToWorkStore?: boolean
}) => {
  const isMobile = useIsMobileView()

  return (
    <>
      <Chip onlyC2W={onlyC2W} />
      <PaddingContainer>
        <Header onlyC2W={onlyC2W} />

        <RoundelContainer>
          {isMobile ? (
            <C2WRoundel
              roundelArray={roundels}
              roundelBackground="gray"
              roundelPerPage={MOBILE_ROUNDEL_PER_PAGE}
              isCycleToWork={isCycleToWork}
              cycleToWorkStore={cycleToWorkStore}
            />
          ) : (
            <TwoPropositionsRoundel
              roundelArray={roundels}
              roundelBackground="gray"
              cycleToWorkStore={cycleToWorkStore}
            />
          )}
        </RoundelContainer>
        {!isMobile && <Partners onlyC2W={onlyC2W} />}
        <ExploreC2WButton />
        <Footer />
      </PaddingContainer>
    </>
  )
}
const RoundelContainer = styled("section")`
  min-height: 241px;
  display: grid;
  align-items: center;
`
