import { styled, IconButton, Button } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import popupText from "./popupText"
import PopupRow from "./PopupRow"
import { CategoriesHeaders } from "./CategoriesHeaders"
import FirstPopupRow from "./FirstPopupRow"
import { CookieType, currentConsent } from "./Cookie"
import { useState } from "react"

const CookiePopup = ({
  setShouldShowCookieModal,
  setShouldShowPopup,
  saveSettingsToStorage,
  consent,
}: CookieType) => {
  const [cookieOptions, setCookieOptions] = useState<
    Record<"Functional", boolean>
  >({
    Functional: consent?.functional || false,
  })

  const handleCheckboxChange = (header: "Functional") => {
    setCookieOptions((prevState) => ({
      ...prevState,
      [header]: !prevState[header],
    }))
  }
  const handleSavePreference = () => {
    saveSettingsToStorage({
      functional: cookieOptions.Functional,
    })
    setShouldShowCookieModal && setShouldShowCookieModal(false)
    setShouldShowPopup && setShouldShowPopup(false)
  }

  const handleCloseWindow = () => {
    setShouldShowCookieModal &&
      !currentConsent() &&
      setShouldShowCookieModal(true)
    setShouldShowPopup && setShouldShowPopup(false)
  }

  return (
    <div className="cookie__popup-container">
      <StyledButtonContainer
        aria-label="close cookie popup icon"
        onClick={handleCloseWindow}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault()
            handleCloseWindow()
          }
        }}
      >
        <StyledCloseIcon fontSize="small" />
        <div className="screen-reader-only">Close</div>
      </StyledButtonContainer>
      <div className="cookie__popup-inner-container">
        <FirstPopupRow
          setShouldShowCookieModal={setShouldShowCookieModal}
          setShouldShowPopup={setShouldShowPopup}
          saveSettingsToStorage={saveSettingsToStorage}
        />
        <CategoriesHeaders />

        {popupText.map((rowData) => {
          const isSwitchableRow = rowData.header === "Functional"

          return (
            <PopupRow
              rowData={rowData}
              key={`cookie-header-${rowData.header}`}
              isChecked={
                isSwitchableRow
                  ? cookieOptions[rowData.header as "Functional"]
                  : false
              }
              onCheckboxChange={
                isSwitchableRow ? handleCheckboxChange : undefined
              }
            />
          )
        })}

        {/* use ".cookie__popup-save-button" in plain html */}
        <SaveButton variant="gradient" onClick={handleSavePreference}>
          Save Preference
        </SaveButton>
        {/* use ".cookie__popup-close-window-button" in plain html */}
        <CloseWindowButton variant="invisible" onClick={handleCloseWindow}>
          Close window
        </CloseWindowButton>
      </div>
    </div>
  )
}

export default CookiePopup

const StyledButtonContainer = styled(IconButton)`
  position: fixed;
  right: 4px;
  top: 4px;

  & svg {
    height: 24.24px;
    width: 24.24px;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`

const SaveButton = styled(Button)`
  font-size: 1rem;
  padding: 0;
  width: 190px;
  height: 49px;
  margin: 0.75rem auto;

  @media screen and (min-width: 900px) {
    margin: 0.25rem auto;
    margin-right: 0;
  }
`

const CloseWindowButton = styled(Button)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-decoration: underline;
  align-self: center;
  padding: 0;
  color: ${({ theme }) => theme.palette.grey[700]};

  &:hover {
    text-decoration: underline;
  }
`
