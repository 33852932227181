import Button from "@mui/material/Button"
import { styled, css } from "@mui/material"

export const ExploreButton = styled(Button)`
  ${({ theme }) => css`
    display: block;
    width: 17.375rem;
    height: 3.125rem;
    margin: 2rem auto;
    margin-top: 1rem;
    margin-bottom: 2.188rem;
    color: ${theme.palette.white.main};
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: center;
    border-radius: 2.625rem;
    box-shadow: ${theme.boxShadow};
    opacity: 1;
  `}
`
