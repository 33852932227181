import React from "react"
import { Box } from "@mui/system"
import { styled, css } from "@mui/material"
import { OverlayButton } from "./OverlayButton"
import { TextContent } from "./TextContent"
import { VideoContent } from "./VideoContent"

export const Overlay: React.FC<
  React.PropsWithChildren<{
    closeOverlay: React.MouseEventHandler<HTMLElement>
    isOverlayOpen: boolean
  }>
> = ({ closeOverlay, isOverlayOpen }) => {
  return (
    <WaveContainer>
      <Background />

      <GridContainer>
        <OverlayButton
          closeOverlay={closeOverlay}
          isOverlayOpen={isOverlayOpen}
        />
        <ContentGridContainer>
          <VideoContent />
          <TextContent
            closeOverlay={closeOverlay}
            isOverlayOpen={isOverlayOpen}
          />
        </ContentGridContainer>
      </GridContainer>
    </WaveContainer>
  )
}

const Background = () => {
  return (
    <WaveContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 1926 794.922"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            y1="0.455"
            x2="1.006"
            y2="0.444"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#539dd2" />
            <stop offset="1" stopColor="#302557" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="1"
            y1="0.5"
            x2="0"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#302557" />
            <stop offset="1" stopColor="#539dd2" />
          </linearGradient>
        </defs>
        <rect
          id="Rectangle_23"
          data-name="Rectangle 23"
          width="1926"
          height="613"
          transform="translate(0 181.922)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M7718.481,338.106c516.107,65.282,934.271,0,934.271,0V552.014h-1926V338.106S7202.375,272.825,7718.481,338.106Z"
          transform="translate(-6726.752 -309.092)"
          fill="url(#linear-gradient-2)"
        />
      </svg>
    </WaveContainer>
  )
}

const WaveContainer = styled(Box)`
  /* temporary, wait until there's a SVG file */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: 800px;
  z-index: 1; // so carousel arrows and fading edge won't show
`
const GridContainer = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: 11.6875rem auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      min-width: 50rem; // 800px, account for padding on left and right
      max-width: 69.125rem; //1106px
      margin: 0 auto;
      padding: 3rem 2rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      width: 100%;
    }
  `}
`
const ContentGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 55% 45%;
  width: 100%;
  height: 100%;
  z-index: 2; // so that the container is in front of the SVG background
`
