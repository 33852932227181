import { ItemHierarchyType } from "../shared/step-menu/StepMenu"
import { PortalMegaMenuItem } from "./formatMegaMenuData"
import {
  HealthAndWellbeing as HealthAndWellbeingType,
  FamilyCare as FamilyCareType,
  FamilyPay as FamilyPayType,
} from "../../graphqGenaretedTypes"
import { Child } from "../shared/step-menu/GenerateSlide"

const defaultBurgerMenuData = [
  {
    name: "homeAndElectronics",
    link: "#",
    hasChild: true,
    children: [],
    imgUrl: "",
  },
  {
    name: "cycleToWork",
    link: "#",
    hasChild: true,
    external: true,
    children: [],
    imgUrl: "",
  },
  { name: "carBenefit", link: "#", external: true },
  {
    name: "lifestyleSavings",
    link: "#",
    hasChild: true,
    children: [],
  },
  { name: "vivupHighfive", link: "#", external: true },
  { name: "healthAndWellbeing", link: "#", external: true },
  { name: "familyCare", link: "#", external: true },
  { name: "familyPay", link: "#", external: true },
  { name: "exploreYourBenefits", link: "#", external: true },
]

export default function formatBurgerMenuData(
  t: (key: string) => string,
  megaMenuItems: PortalMegaMenuItem[],
  healthAndWellbeingData?: HealthAndWellbeingType,
  familycareData?: FamilyCareType,
  familyPayData?: FamilyPayType
) {
  const updatedArray = healthAndWellbeingData?.usefulLinks?.map(
    (acc) => ({
      ...acc,
      name: acc.label,
    }),
    {}
  ) as Child[]

  const updatedArrayFamilyCare = familycareData?.usefulLinks?.map(
    (acc) => ({
      ...acc,
      name: acc.label,
    }),
    {}
  ) as Child[]

  const updatedArrayFamilyPay = familyPayData?.usefulLinks?.map(
    (acc) => ({
      ...acc,
      name: acc.label,
    }),
    {}
  ) as Child[]

  return defaultBurgerMenuData
    .map((burgerMenuField) => {
      const result = megaMenuItems.find(
        (item) => item.label === burgerMenuField.name
      )
      if (result) {
        const { departments, extraOptions, image: imgUrl, link } = result
        let customLink = link
        let children: ItemHierarchyType["children"]

        if (departments) {
          children = departments.map(({ label, link, categories }) => ({
            name: label,
            link,
            hasChild: true,
            children: categories
              ? categories.map((category) => ({
                  name: category.label,
                  link: category.link,
                }))
              : undefined,
          }))
        }

        let optionsToAppend: ItemHierarchyType["children"]
        if (extraOptions && extraOptions.length > 0) {
          optionsToAppend = extraOptions.map((extraOption) => {
            return {
              name: extraOption.label,
              link: extraOption.link,
              isExtraOption: true,
              hasChild: false,
            }
          })
        }

        if (optionsToAppend && children) {
          children = children.concat(optionsToAppend)
        }
        if (burgerMenuField.name === "healthAndWellbeing") {
          children = updatedArray
          burgerMenuField.hasChild = true
          burgerMenuField.level = healthAndWellbeingData?.level
          customLink = healthAndWellbeingData?.link || " "
          burgerMenuField.eapNumber = healthAndWellbeingData?.eapNumber
        }
        if (burgerMenuField.name === "familyCare") {
          children = updatedArrayFamilyCare
          burgerMenuField.hasChild = true
        }
        if (burgerMenuField.name === "familyPay") {
          children = updatedArrayFamilyPay
          burgerMenuField.hasChild = true
        }
        return {
          ...burgerMenuField,
          name: t("menu." + burgerMenuField.name),
          link,
          imgUrl,
          children,
          extraOptions,
          customLink,
        }
      }
      return {} // which is compatible with ItemHierarchyType, null/ undefined makes TS complains
    })
    .filter((value) => Object.keys(value).length !== 0)
}
