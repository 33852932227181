import { Box, styled } from "@mui/material"
import {
  PropositionSpaceOneSubHeader,
  HeaderSectionPadding1,
} from "../../shared/SharedStyles"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const Partners = ({ onlyC2W = true }: { onlyC2W: boolean }) => {
  if (onlyC2W) {
    return (
      <OnePropositionContainer>
        <Content onlyC2W={onlyC2W} />
      </OnePropositionContainer>
    )
  }
  return (
    <Container>
      <Content onlyC2W={onlyC2W} />
    </Container>
  )
}

function Content({ onlyC2W }: { onlyC2W: boolean }) {
  const { t, ready } = useTranslation("portalHomepage")
  const { i18n } = useTranslation()
  const partnersImage = useBuildAwsImageUrl(i18n.language, "partners.png")

  if (!ready) {
    return null
  }
  return (
    <>
      <HeaderSectionPadding1>
        <SubHeader variant="h4" align="left">
          {t("cycleToWork.partnerHeader")}
        </SubHeader>
      </HeaderSectionPadding1>
      <PartnersImageContainer onlyC2W={onlyC2W}>
        <ShadowContainer>
          <PartnersImage
            src={partnersImage}
            alt={t("cycleToWork.partnerImageAltText")}
          />
        </ShadowContainer>
      </PartnersImageContainer>
    </>
  )
}

export default Partners

const SubHeader = styled(PropositionSpaceOneSubHeader)`
  font-weight: bold;
  width: unset;
`

const PartnersImage = styled("img")`
  width: 92%;
  margin: auto;
`

const ShadowContainer = styled(Box)`
  display: flex;
  border-radius: ${({ theme }) => theme.boxBorderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: 80%;
`
const PartnersImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "onlyC2W",
})<{ onlyC2W: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  height: ${({ onlyC2W }) => (onlyC2W ? "168px" : "100px")};
`

const Container = styled(Box)`
  margin: 36px 0;
`

const OnePropositionContainer = styled(Box)`
  margin: 14px 0;
`
