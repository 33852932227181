import { Box, css, ThemeProvider } from "@mui/material"
import { styled } from "@mui/material/styles"
import DisableTabbing from "../shared/DisableTabbing"
import DefaultTheme from "../../theme/mui-theme"
import {
  BannerText,
  BreadcrumbsContainer,
  Content,
  ContentContainerBox,
  StyledBanner,
} from "./PreviewPopUp"
export interface benefitCardProps {
  title: string
  subHeader: string
  content: string
}

const PreviewPage = ({ title, subHeader, content }: benefitCardProps) => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <MainContainer
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div>
          <StyledBanner>
            <BannerText>{title}</BannerText>
          </StyledBanner>
          <BreadcrumbsContainer>
            {subHeader && <h4>{subHeader}</h4>}
          </BreadcrumbsContainer>
          <ContentEditorStyle>
            <DisableTabbing>
              <ContentContainerBox>
                <Content>
                  {content && (
                    <div
                      className="ck-content"
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                    />
                  )}
                </Content>
              </ContentContainerBox>
            </DisableTabbing>
          </ContentEditorStyle>
        </div>
      </MainContainer>
    </ThemeProvider>
  )
}

export default PreviewPage

const ContentEditorStyle = styled(Box)`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-top: 0rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      margin: 0.4rem 1rem;
    }
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        width: 100%;
      }
    }
    &.mobile {
      max-width: 37.8125rem;
      width: 100%;
    }
  `}
`
