import { Box } from "@mui/material"
import { RoundelProps } from "../../../shared/Roundel"
import { DesktopRoundel } from "../../home-and-electronics-section/DesktopRoundel"
import {
  OnePropositionBox,
  OnePropositionPadding,
} from "../../shared/SharedStyles"
import { Chip } from "./Chip"
import Footer from "./Footer"
import { Header } from "./Header"
import Partners from "./Partners"
import { ExploreC2WButton } from "./ExploreC2WButton"
import { ROUNDEL_BACKGROUND } from "./C2W"

export function OnePropositionContent({
  onlyC2W,
  roundels,
  cycleToWorkStore,
}: {
  onlyC2W: boolean
  roundels: RoundelProps[]
  cycleToWorkStore?: boolean
}) {
  return (
    <OnePropositionBox data-cy="portal-c2w-desktop-full-width">
      <Chip onlyC2W={onlyC2W} />
      <OnePropositionPadding>
        <Header onlyC2W={onlyC2W} />

        <Box sx={{ margin: "31px 23px" }}>
          <DesktopRoundel
            roundelArray={roundels}
            roundelBackground={ROUNDEL_BACKGROUND}
            isHomeAndElectronicSection={false}
            cycleToWorkStore={cycleToWorkStore}
          />
        </Box>

        <Partners onlyC2W={onlyC2W} />
        <ExploreC2WButton />
        <Footer />
      </OnePropositionPadding>
    </OnePropositionBox>
  )
}
