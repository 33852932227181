import { Typography, css, styled } from "@mui/material"
import Layout from "./Layout"
import { TitleHeading } from "./shared/headingStyles"
import { FormContainer } from "./shared/mainPageStyles"

const SubmitDataRequest = () => {
  return (
    <Layout>
      <div style={{ margin: "0 1rem" }}>
        <FormContainer>
          <TitleHeading variant="h1">
            Delete Account Request Process
          </TitleHeading>
          <Body sx={{ textAlign: "left", maxWidth: "auto" }}>
            To make a request for your data to be deleted under the GDPR
            guidelines, please log into your mobile app and navigate to the
            profile screen. Once you scroll to the bottom of the screen, you
            will see the option to Delete Account. Please tap through, and an
            email template will open in the mail client on your device to send
            us the details to identify your account and delete your data.
          </Body>
        </FormContainer>
      </div>
    </Layout>
  )
}

export default SubmitDataRequest

export const Body = styled(Typography)`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: medium;
    margin-bottom: 18px;
    color: ${theme.palette.primary.main};

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 2.375rem;
      font-size: 1rem;
    }
  `}
`
