import { object } from "yup"
import {
  lineOneValidation,
  lineTwoValidation,
  townValidation,
  countyValidation,
} from "../../../../utils/yup-validation-schema/addressValidation"
import { postCodeValidation } from "../../../../utils/yup-validation-schema/postcodeValidation"

export const submitOrderValidationSchema = object().shape({
  lineOne: lineOneValidation,
  lineTwo: lineTwoValidation,
  county: countyValidation,
  town: townValidation,
  postCode: postCodeValidation,
})
