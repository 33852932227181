import * as React from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { InputLabel, styled, css } from "@mui/material"
import { BrochureGroup as BrochureGroupType } from "../../../graphqGenaretedTypes"
import { useTranslation } from "react-i18next"

const ITEM_HEIGHT = 118
const ITEM_PADDING_TOP = 0

type DropDwonProps = {
  dropDownValue?: string
  dropDownHandler?: (e: React.MouseEvent<HTMLDivElement>) => void

  data?: BrochureGroupType[]
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      sx: { padding: "0px" },
    },
  },
  MenuListProps: {
    sx: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}

export default function DropDownField({
  dropDownValue,
  dropDownHandler,
  data,
}: DropDwonProps) {
  const { t, ready } = useTranslation("cycleToWork")

  if (!ready) {
    return null
  }

  return (
    <DropDownFormControl
      sx={{
        m: 1,
        mt: 3,
        margin: "0px",
      }}
    >
      <InputLabel htmlFor="my-select" style={{ display: "none" }}>
        {t("calculator.designText.dropdownLabel")}
      </InputLabel>
      <Select
        displayEmpty
        value={dropDownValue}
        inputProps={{
          name: "my-select",
          id: "my-select",
        }}
        onChange={dropDownHandler}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <PlaceHolderText>
                {t("calculator.designText.dropdownPlaceholder")}
              </PlaceHolderText>
            )
          }

          const selectedItem = data.find(
            (item: BrochureGroupType) => item.id === selected.toString()
          )
          return selectedItem?.term + " months"
        }}
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          padding: "0px !important",
        }}
      >
        {data.map(
          (
            item: {
              id: string | number | readonly string[] | undefined
              term:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
            },
            index: React.Key | null | undefined
          ) => (
            <MainItem key={index} value={item.id}>
              {item.term} {t("calculator.designText.months")}
            </MainItem>
          )
        )}
      </Select>
    </DropDownFormControl>
  )
}
const PlaceHolderText = styled("span")`
  && {
    color: #656565;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    opacity: 1;
  }
`
export const MainItem = styled(MenuItem)`
  color: #656565;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  height: 50px;
  font-size: 17px;
  &&:not(:last-child) {
    // border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #eae9ee;
  }
`
export const DropDownFormControl = styled(FormControl)`
  ${({ theme }) => css`
    & {
      width: 482px;
    }
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      width: 286.03px;
    }
  `}
`
