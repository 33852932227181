import { FormikValue, initialValues } from "./initialValues"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import {
  useGetCheckoutDataQuery,
  useCreatePayrollGivingQuoteMutation,
  useGetQuotePageDataQuery,
} from "../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../shared/CustomBreadcrumbs"

export const CreatePayrollGivingQuote = (formikData: FormikValue) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams()

  const termsAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "termsAcknowledged"
    )[0]?.checked || null

  const salarySacrificeArrangementAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "salarySacrificeArrangementAcknowledged"
    )[0]?.checked || null

  const hireAgreementAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "hireAgreementAcknowledged"
    )[0]?.checked || null

  const niNumber = formikData.niNumber?.toUpperCase() ?? ""
  const postCode = formikData.postCode?.toUpperCase() ?? ""

  return useCreatePayrollGivingQuoteMutation({
    variables: {
      relativeUrl: "payroll_giving",
      organisationId: params.organisationId ?? "",
      donationValue: parseFloat(formikData.totalValue),
      recurringDonation: formikData.picked,
      dateOfBirth: new Date(formikData.dateOfBirth).toDateString(),
      deliveryAddress1: formikData.lineOne,
      deliveryAddress2: formikData.lineTwo,
      deliveryCounty: formikData.county,
      deliveryPostcode: postCode,
      deliveryTown: formikData.town,
      email: formikData.email,
      employeeNumber: formikData.employeeNumber,
      firstName: formikData.firstName,
      hireAgreementAcknowledged: hireAgreementAcknowledged,
      lastName: formikData.lastName,
      mobileNumber: formikData.mobileNumber,
      niNumber: niNumber,
      placeOfWork: formikData.placeOfWork,
      salarySacrificeArrangementAcknowledged:
        salarySacrificeArrangementAcknowledged,
      telephoneNumber: formikData.phoneNumber,
      termsAcknowledged: termsAcknowledged,
      title: formikData.title,
    },
  })
}

let state = JSON.parse(JSON.stringify(initialValues))

export const useDefaultUserData: () => {
  userData: FormikValue
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()

  const userData = useGetCheckoutDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: "payroll_giving",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })
  if (!userData.loading) {
    const placeOfWork = userData.data?.currentUser?.placeOfWork ?? ""
    const employeeNumber =
      userData.data?.employeeOrganisation?.employee?.employeeNumber ?? ""
    const niNumber = userData.data?.currentUser?.niNumber ?? ""
    const title = userData.data?.currentUser?.title ?? ""
    // shouldn't fill a default value?
    const dateOfBirth = userData.data?.currentUser?.dateOfBirth ?? ""
    const firstName = userData.data?.currentUser?.firstName ?? ""
    const lastName = userData.data?.currentUser?.lastName ?? ""
    const mobileNumber = userData.data?.currentUser?.mobileNumber ?? ""
    const phoneNumber = userData.data?.currentUser?.homePhone ?? ""
    const email = userData.data?.currentUser?.email ?? ""
    const loading = userData.loading ?? false
    const lineOne = userData.data?.currentUser?.address1 ?? ""
    const lineTwo = userData.data?.currentUser?.address2 ?? ""
    const town = userData.data?.currentUser?.town ?? ""
    const county = userData.data?.currentUser?.county ?? ""
    const postCode = userData.data?.currentUser?.postcode ?? ""
    let checkboxes = userData.data?.employeeOrganisation?.scheme?.checkboxes

    checkboxes = checkboxes?.map((checkbox) => {
      return { ...checkbox, checked: false }
    })
    state = {
      ...initialValues,
      placeOfWork,
      employeeNumber,
      niNumber,
      title,
      dateOfBirth,
      firstName,
      lastName,
      mobileNumber,
      phoneNumber,
      email,
      loading,
      lineOne,
      lineTwo,
      town,
      county,
      postCode,
      checkboxes,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    userData: state,
  }
}

type FormDataStateType = {
  loading: boolean
  breadcrumbs: Array<BreadcrumbsType>
}

// TODO: define some proper init state
let formDataState: FormDataStateType = {
  loading: true,
  breadcrumbs: [],
}

export const useFormData = () => {
  const params = useParams()
  const { i18n } = useTranslation()
  const formData = useGetQuotePageDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: "payroll_giving",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!formData.loading) {
    const loading = formData.loading ?? false
    const breadcrumbs =
      formData?.data?.employeeOrganisation?.scheme?.quotePageBreadcrumbs

    formDataState = {
      ...formDataState,
      loading,
      // TODO: deal with type error
      breadcrumbs,
    }
  } else {
    formDataState = {
      ...formDataState,
      loading: true,
    }
  }
  return {
    formData: formDataState,
  }
}
