import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, Link, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import React from "react"
import { useTranslation } from "react-i18next"

const FooterLinksSection = ({
  privacyNoticeUrl,
  organisationPrivacyNotice,
  setShouldShowCookiePopup,
}: {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const params = useParams()

  const handleManageCookieClick = () => {
    setShouldShowCookiePopup((prev) => !prev)
  }

  const { t, ready } = useTranslation("layout")

  if (!ready) {
    return null
  }

  const FOOTER_LINKS = [
    { label: t("footer.cookiePolicy"), link: "/cookie_policy" },
    {
      label: t("footer.manageCookies"),
      link: "#",
      onClick: handleManageCookieClick,
    },
    {
      label: t("footer.vivupPrivacyNotice"),
      link: "https://vivupbenefits.co.uk/privacy-policy",
    },
    { label: t("footer.termsAndConditions"), link: "/terms_conditions" },
    {
      label: t("footer.promotionalTermsAndConditions"),
      link: "/promotional_terms_conditions",
    },
    { label: t("footer.contactUs"), link: "/access-benefits/helpdesk" },
    { label: t("footer.accessibility"), link: "/accessibility" },
  ]

  if (privacyNoticeUrl) {
    FOOTER_LINKS.splice(2, 0, {
      label: organisationPrivacyNotice
        ? t("footer.organisationPrivacyNotice")
        : t("footer.privacyPolicy"),
      link: privacyNoticeUrl,
    })
  }

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      data-cy="footer-links"
    >
      {isMobile ? (
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            sx={{
              width: "108%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {FOOTER_LINKS.map((item, index) => (
              <React.Fragment key={index}>
                {item.onClick ? (
                  <Link
                    underline="none"
                    color="inherit"
                    className="footerLinks_Links"
                    component={Button}
                    disableRipple
                    sx={{
                      fontSize: "0.875rem",
                    }}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    underline="none"
                    color="inherit"
                    href={item.link}
                    className="footerLinks_Links"
                    target="_blank"
                    rel="noopener"
                    component={"a"}
                    sx={{
                      fontSize: "0.875rem",
                    }}
                    onClick={item.onClick}
                  >
                    {item.label}
                    <span className="screen-reader-only">
                      (opens in a new tab)
                    </span>
                  </Link>
                )}

                {index !== FOOTER_LINKS.length - 1 && (
                  <Typography
                    sx={{
                      margin: "0.2rem 0.9rem",
                      border: `0.0625rem solid ${theme.palette.white.main}`,
                      borderLeftWidth: "0rem",
                      borderRightWidth: "0rem",
                      width: "0.0625rem",
                      background: theme.palette.white.main,
                      height: "1.5rem",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {FOOTER_LINKS.map((item, index) => (
            <React.Fragment key={index}>
              {item.onClick ? (
                <Link
                  underline="none"
                  color="inherit"
                  className="footerLinks_Links"
                  component={Button}
                  disableRipple
                  sx={{
                    fontSize: "0.875rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                  onClick={item.onClick}
                >
                  {item.label}
                </Link>
              ) : (
                <Link
                  underline="none"
                  color="inherit"
                  href={item.link}
                  className="footerLinks_Links"
                  component={"a"}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    fontSize: "0.875rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                  onClick={item.onClick}
                >
                  {item.label}
                  <span className="screen-reader-only">
                    (opens in a new tab)
                  </span>
                </Link>
              )}
              {index !== FOOTER_LINKS.length - 1 && (
                <Typography
                  sx={{
                    margin: {
                      sm: "1rem 1rem",
                      md: "1rem 1rem",
                      lg: "1.125rem 0.1rem",
                    },
                    border: `0.0625rem solid ${theme.palette.white.main}`,
                    borderLeftWidth: "0rem",
                    borderRightWidth: "0rem",
                    width: "0.0625rem",
                    background: theme.palette.white.main,
                    height: "1.5rem",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}

      <Typography
        variant="inherit"
        align="center"
        component={"span"}
        sx={{
          fontSize: "0.75rem",
          paddingBottom: "13px",
          paddingTop: "13px",
        }}
      >
        {params.schemeType === "cycle_to_work" && (
          <div>
            {t("footer.c2wCompanyInfoOne")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoTwo")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoThree")}
            {isMobile ? "" : <br />}
            {t("footer.c2wCompanyInfoFour")}
            {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoFive")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoSix")}
          </div>
        )}
        {params.schemeType === "cycle_to_work" ? (
          <div style={{ paddingTop: "14px" }}>
            {t("footer.c2wCompanyInfoSeven")} {isMobile ? <br /> : ""}
            {t("footer.c2wCompanyInfoEight")}
          </div>
        ) : (
          <div style={{ paddingTop: "14px" }}>
            {t("footer.companyInfoOne")} {isMobile ? <br /> : ""}
            {t("footer.companyInfoTwo")}
          </div>
        )}
      </Typography>
    </Grid>
  )
}

export default FooterLinksSection
