import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { FormikContextType } from "formik"

export type CheckboxType = {
  name: string
  content: string
  required: boolean
  checked: boolean
}

export type CheckboxesProps<T> = {
  checkboxes: CheckboxType[]
  setCheckboxes?: (checkboxesState: CheckboxType[]) => void
  formik?: FormikContextType<T>
}

export default function Checkboxes<T>({
  checkboxes,
  setCheckboxes,
  formik,
}: CheckboxesProps<T>) {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    checkbox: CheckboxType
  ) => {
    const updatedCheckboxes = [
      ...checkboxes.slice(0, index),
      { ...checkbox, checked: event.target.checked },
      ...checkboxes.slice(index + 1),
    ]

    if (setCheckboxes) {
      setCheckboxes(updatedCheckboxes)
      return
    }
    if (formik) {
      formik.setFieldValue("checkboxes", updatedCheckboxes)
    }
  }

  return (
    <div className="checkboxes">
      {checkboxes.map((checkbox, index) => (
        <FormGroup sx={{ marginBottom: "1rem" }} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox.checked}
                onChange={(event) => handleChange(event, index, checkbox)}
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            }
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            label={
              <div dangerouslySetInnerHTML={{ __html: checkbox.content }} />
            }
          />
        </FormGroup>
      ))}
    </div>
  )
}
