import { useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

export type BannerType = {
  desktopBannerUrl?: string
  mobileBannerUrl?: string
}

function BottomBanner({ desktopBannerUrl, mobileBannerUrl }: BannerType) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("productDetail")

  const imageUrl = () => {
    if (isDesktop) {
      if (desktopBannerUrl) {
        return desktopBannerUrl
      } else {
        return mobileBannerUrl
      }
    } else {
      if (mobileBannerUrl) {
        return mobileBannerUrl
      } else {
        return desktopBannerUrl
      }
    }
  }

  if (!ready) {
    return null
  }
  return (
    <div className="bottom-banner">
      <img
        src={imageUrl()}
        alt={t("productDetail.BottomBanner.alt")}
        className="bottom-banner__image"
      />
    </div>
  )
}

export default BottomBanner
