import Grid from "@mui/material/Grid"
import FooterIcons from "./FooterIcons"
import useIsMobileView from "../../../../utils/useIsMobileView"

const FooterLowerSection = () => {
  const isMobile = useIsMobileView()

  return (
    <>
      {isMobile ? (
        ""
      ) : (
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "row", lg: "column" },
            padding: 2,
          }}
        >
          <FooterIcons />
        </Grid>
      )}
    </>
  )
}

export default FooterLowerSection
