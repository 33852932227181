import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  styled,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"

type Data = {
  name: string
  description: string
}[]

type allCookiesType = { title: string; data: Data }[]

export const CookieAccordions = () => {
  const { t, ready } = useTranslation("cookieList")

  if (!ready) {
    return null
  }

  const allCookies = t("allCookies", {
    returnObjects: true,
    useSuspense: false,
  }) as allCookiesType

  return (
    <Container>
      {Object.values(allCookies).map(({ title, data }) => {
        return (
          <StyledAccordion>
            <StyledAccordionSummary
              expandIcon={<Arrow />}
              aria-controls="cookie-content"
              id="cookie-header"
            >
              <Category variant="h3">{title}</Category>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <TableRow style={{ paddingTop: "0" }}>
                <ColumnHeaderName variant="h5">
                  {t("columnHeader.name")}
                </ColumnHeaderName>
                <ColumnHeaderDescription variant="h5">
                  {t("columnHeader.description")}
                </ColumnHeaderDescription>
              </TableRow>
              <StyledDivider />
              {Object.values(data).map(({ name, description }, index) => {
                return (
                  <TableRow
                    sx={
                      index % 2 == 1
                        ? {
                            backgroundColor: (theme) => theme.palette.grey[200],
                          }
                        : undefined
                    }
                  >
                    <CategoryText>{name}</CategoryText>
                    <DescriptionText>{description}</DescriptionText>
                  </TableRow>
                )
              })}
            </StyledAccordionDetails>
          </StyledAccordion>
        )
      })}
    </Container>
  )
}

const Container = styled(Box)`
  width: 93.3%;
  margin: 0 auto 2rem auto;

  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
  }`}
`

const Category = styled(Typography)`
  ${({ theme }) => `
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      line-height: 1.5rem;
    }`}
`

const StyledAccordion = styled(Accordion)`
  ${({ theme }) => `
    box-shadow: ${theme.boxShadow};
  `}
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 1.125rem;

  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0.25rem 1rem;
  }`}
`
const Arrow = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`
const StyledDivider = styled(Divider)`
  border: ${({ theme }) => `0.0625rem solid ${theme.palette.primary.main}`};
  border-top: none;
`
const NAME_COLUMN_FLEX = "0 0 30%"
const DESCRIPTION_COLUMN_FLEX = "0 1 70%"
const TableRow = styled(Box)`
  display: flex;
  padding: 0.875rem 0.375rem;

  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0.5rem 0.625rem;
  }`}
`
const ColumnHeaderBase = styled(Typography)`
  ${({ theme }) => `
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      line-height: 1.5rem;
  }`}
`
const ColumnHeaderName = styled(ColumnHeaderBase)`
  flex: ${NAME_COLUMN_FLEX};
`
const ColumnHeaderDescription = styled(ColumnHeaderBase)`
  flex: ${DESCRIPTION_COLUMN_FLEX};
  padding: 0 0.875rem;
`
const CategoryText = styled(ColumnHeaderName)`
  font-weight: 500;
  overflow-wrap: anywhere;
`
const DescriptionText = styled(ColumnHeaderDescription)`
  font-weight: 500;
  overflow-wrap: anywhere;
`
const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin: 0 auto 1.25rem auto;
  width: 91.3%;

  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: unset;
      margin: 0.25rem 1.125rem 1.5rem 1.125rem;
  }`}
`
