import { Button, Box, styled } from "@mui/material"
import AddSharpIcon from "@mui/icons-material/AddSharp"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const AddCustomTile = () => {
  const { t, ready } = useTranslation("clientAdminPage")
  const navigate = useNavigate()
  const handleButton = () => {
    //to-replace once new page is implemented
    navigate("add_a_new_benefit_tile")
  }
  if (!ready) {
    return null
  }
  return (
    <StyledButton aria-label="Add custom tile" onClick={handleButton}>
      <div>
        <AddSharpIconStyled aria-hidden="true" />
        <CardLabel>
          {t("clientAdminPage:clientAdminReordering.addCustomTile")}
        </CardLabel>
      </div>
    </StyledButton>
  )
}

const CardLabel = styled(Box)`
  & {
    text-align: center;
    color: #35185f;
    position: absolute;
    line-height: normal;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    font-size: 1.0625rem;
    font-weight: bold;
  }
`

const StyledButton = styled(Button)`
  & {
    margin: 3px !important;
    cursor: pointer;
    position: relative;
    max-width: 10rem;
    width: 95%;
    height: 9.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    border-radius: 1rem;
    overflow: hidden;
    clip-path: view-box;
    box-shadow: 0px 3px 6px #00000029;
    background-color: rgb(234, 233, 238);
    border: 2.1px solid #35185f;
    text-align: center;
  }
`
const AddSharpIconStyled = styled(AddSharpIcon)`
  color: #35185f;
  font-size: 4.3rem;
  margin-top: 0.8rem;
  padding-bottom: 3rem;
`
