import { useMediaQuery, useTheme } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import classNames from "classnames"
import {
  gtmCycleToWorkBenefitDepartmentNavigationClick,
  gtmNavigationRoundleClick,
  gtmPortalHomepageCycleToWorkImageClick,
  gtmPortalHomepageHomeandElectronicImageClick,
} from "./gtm-events/CustomGtmEvents"

export type RoundelProps = {
  label?: string | null
  imageUrl: string
  link: string
  background: "gray" | "white"
  gtmActionName?: string
  isHomeAndElectronicSection?: boolean
  isCycleToWork?: boolean
  cycleToWorkStore?: boolean
}

export default function Roundel({
  label,
  imageUrl,
  link,
  background,
  gtmActionName,
  isHomeAndElectronicSection,
  isCycleToWork,
  cycleToWorkStore,
}: RoundelProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()
  const store = params.schemeType
  const navigationClick = () => {
    gtmActionName &&
      gtmNavigationRoundleClick({
        event_label: label,
        event_action: gtmActionName,
        store: store,
      })
    isCycleToWork &&
      label &&
      gtmCycleToWorkBenefitDepartmentNavigationClick(label)
    {
      isHomeAndElectronicSection &&
        gtmPortalHomepageHomeandElectronicImageClick(label)
    }
    {
      cycleToWorkStore && gtmPortalHomepageCycleToWorkImageClick(label)
    }
  }
  return (
    <div className={classNames("roundel", { "roundel--mobile": !isDesktop })}>
      <Link to={link} onClick={navigationClick}>
        <div
          className={classNames("roundel__image-container content-center", {
            "roundel__image-container--mobile": !isDesktop,
          })}
        >
          <img src={imageUrl} alt={label} className="roundel__image" />
          <div
            className={"roundel__bg-circle " + `roundel--bg-${background}`}
          />
        </div>
      </Link>
      {label && (
        <Link to={link} onClick={navigationClick}>
          <div className="roundel__label">{label}</div>
        </Link>
      )}
    </div>
  )
}
