import { Box, styled, css } from "@mui/material"
import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import snakeToCamel from "../../../../utils/snakeToCamel"

type Props = {
  children: ReactNode
  store?: string
}

const ButtonSection = ({ children }: Props) => {
  const params = useParams()
  const { t, ready } = useTranslation(
    snakeToCamel(params.schemeType || "bike_shop")
  )

  if (!ready) {
    return null
  }
  return (
    <MainBox store={params.schemeType}>
      <TextContainer>
        <Buttons style={{ marginTop: "0px !important" }}>{children}</Buttons>
        <Text>
          {params.schemeType !== "bike_shop" && t("landing.footerSection")}
        </Text>
      </TextContainer>
    </MainBox>
  )
}
export default ButtonSection

const Text = styled("div")`
  ${({ theme }) => css`
    padding-top: 60px;
    text-align: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 39px;
    }
    @media (min-width: 900px) and (max-width: 1120px) {
      padding-top: 15px;
    }
  `}
`
const Buttons = styled("div")`
  ${({ theme }) => css`
    && {
      padding-top: 28px;
      margin-top: 0rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 0px;
      }
      @media (min-width: 900px) and (max-width: 1120px) {
        padding-top: 15px;
        padding-top: 0px;
      }
    }
  `}
`

export const MainBox = styled(Box)<Props>(
  ({ theme, ...props }) => css`
    width: 100%;
    background-color: white;
    height: ${props.store !== "bike_shop" ? "243px" : "212px"};
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: ${props.store !== "bike_shop" ? "520px" : "311px"};
    }
  `
)

export const TextContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 1184px;
    width: 100%;
    height: 30px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.palette.primary.main};
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 94px;
      max-width: 275px;
    }
  `}
`
