import { Link } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DummyMegaMenu as MenuType } from "../layout/GlobalLayoutContext"

export type DummyMegaMenuType = {
  dummyMegaMenu: MenuType
  isTranslationKey?: boolean
}

const DummyMegaMenu = ({
  dummyMegaMenu,
  isTranslationKey = true,
}: DummyMegaMenuType) => {
  const { t, ready } = useTranslation("layout")

  if (!ready) return null

  if (dummyMegaMenu.length > 0) {
    return (
      <div className="mega-menu">
        {dummyMegaMenu.map(({ label, link }, index) => (
          <Link
            component={label === "carBenefit" ? "a" : Link}
            className="mega-menu-item"
            href={link}
            key={`mega-menu-item-${index}`}
          >
            {isTranslationKey ? t("menu." + label) : label}
          </Link>
        ))}
      </div>
    )
  }
  return null
}

export default DummyMegaMenu
