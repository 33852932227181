import getAddressFields from "./submitOrderFields"
import FindYourAddress from "./FindYourAddress"
import { useFormikContext } from "formik"
import { FormikValue } from "./initialValues"
import GenericField from "../shared/form/GenericField"
import Checkboxes from "../store/checkout-page/Checkboxes"
import { useTranslation } from "react-i18next"
import { css, styled, Typography } from "@mui/material"
import DonationConfirmationCheckBox from "./DonationConfirmationCheckBox"

const SubmitOrder = () => {
  const formik = useFormikContext<FormikValue>()
  const checkboxes = formik.values.checkboxes
  const addressFields = getAddressFields()
  const { ready } = useTranslation()
  if (!ready) {
    return null
  }
  if (checkboxes.length > 0) {
    return (
      <div>
        <FindYourAddress />

        {addressFields.map((field) => {
          return (
            <GenericField
              textFieldProps={field}
              key={`${field.id}-input-field`}
            />
          )
        })}
        <Checkboxes checkboxes={checkboxes} formik={formik} />
        <DonationConfirmationCheckBox />
      </div>
    )
  }
  return <></>
}

export default SubmitOrder
export const TcWarning = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    color: ${theme.palette.error.main};
  `}
`
