import { SxProps, TextField, TextFieldProps } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useFormikContext } from "formik"
import FormikValueType from "./formikValueType"
import { CSSProperties } from "@mui/styles"

type DateOfBirthProps = {
  props: TextFieldProps
  style?: CSSProperties // inline style props passed to TextField
  sx?: SxProps
  inlineTextFieldProps?: TextFieldProps
}

const DateOfBirth = <T extends FormikValueType>({
  props,
  style,
  sx,
  inlineTextFieldProps,
}: DateOfBirthProps) => {
  const formik = useFormikContext<T>()
  const touched = formik.touched?.dateOfBirth
  const error = formik.errors?.dateOfBirth
  const invalid = touched && error ? true : false
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          PopperProps={{ "data-cy": "date-picker" }}
          inputFormat="dd/MM/yyyy"
          label={props.label}
          value={formik.values.dateOfBirth ?? null}
          onChange={(value) =>
            formik.setFieldValue(props.id ?? "dateOfBirth", value, true)
          }
          maxDate={new Date()}
          renderInput={(params) => (
            <TextField
              {...params}
              id={props.id}
              required={props.required}
              style={style && style}
              sx={sx && sx}
              error={invalid}
              onBlur={formik.handleBlur}
              helperText={formik.errors.dateOfBirth}
              // inlineTextFieldProps is to override everything similar to inline styling manner
              {...inlineTextFieldProps}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default DateOfBirth
