import { createContext } from "react"
import { useParams } from "react-router"
import { useGetBenefitManagementDataQuery } from "../../graphqGenaretedTypes"

export interface Item {
  id: string
  text: string
  lock: boolean
  status: string
  position: number
  image: string
  link: string
  relativeUrl?: string
}
export interface NoticeBoardItem {
  id: string
  text: string
  position: number
  enabled: boolean
}
export interface ItemList {
  label: string
  id: number
  status: string
  position: string
}
export type BenefitManagementContextData = {
  requestsToOpen: {
    label: string
    number: number
  }[]
  payrollPayBenefits: Item[]
  noticeboards: NoticeBoardItem[]
  exploreYourBenefitsArea: boolean
  loading: boolean
  pendingOrdersCount: number
}

const defaultValue: BenefitManagementContextData = {
  requestsToOpen: [],
  payrollPayBenefits: [],
  noticeboards: [],
  exploreYourBenefitsArea: true,
  loading: false,
  pendingOrdersCount: 0,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const BenefitManagementContext = createContext(defaultValue)

export const useBenefitManagementData: () => {
  data: BenefitManagementContextData
} = function () {
  const { organisationId } = useParams()

  const data = useGetBenefitManagementDataQuery({
    variables: {
      organisationId: organisationId || "",
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      pendingOrdersCount: data.data?.employerOrganisation?.pendingOrdersCount,
      requestsToOpen: data.data?.employerOrganisation?.schemeRequestsCount?.map(
        (item) => ({
          label: item.name,
          number: item.count,
        })
      ),
      exploreYourBenefitsArea:
        data.data?.employerOrganisation?.exploreYourBenefitsArea,
      payrollPayBenefits:
        data.data?.employerOrganisation?.payrollPayBenefits?.map(
          (item, index) => ({
            id: item.id,
            text: item.label,
            lock: item.locked,
            status:
              item.status === "Open" || item.status?.includes("Open ")
                ? "Open"
                : item.status,
            position: index + 1,
            image: item.image,
            link: item.link,
            relativeUrl: item.relativeUrl ?? undefined,
          })
        ),
      noticeboards: data.data?.employerOrganisation?.noticeboards?.map(
        (item, index) => ({
          id: item.id,
          text: item.label,
          position: index + 1,
          enabled: item.enabled,
        })
      ),
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
