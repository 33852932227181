import { MobileContainer, OnePropositionContainer } from "./SharedStyles"
import useIsMobileView from "../../../utils/useIsMobileView"

export function WidgetWrapper({ children }: { children: JSX.Element }) {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return <MobileContainer>{children}</MobileContainer>
  }

  return <OnePropositionContainer>{children}</OnePropositionContainer>
}
