import { TextFieldProps } from "@mui/material"
import i18n from "../../../i18n"

export const getTitleField = (translation: string): TextFieldProps => {
  return {
    id: "name",
    name: "name",
    label: i18n.t(`${translation}:name`),
    "aria-label": i18n.t(`${translation}:name`),
    fullWidth: true,
  }
}

export const getOptionField = (translation: string): TextFieldProps => {
  return {
    id: "typeOfLink",
    name: "typeOfLink",
    select: true,
    "aria-label": i18n.t(`${translation}:typeOfLink`),
    fullWidth: true,
  }
}

export const getExternalLinkField = (translation: string): TextFieldProps => {
  return {
    id: "externalLink",
    name: "externalLink",
    label: i18n.t(`${translation}:externalLink`),
    "aria-label": i18n.t(`${translation}:externalLink`),
    fullWidth: true,
  }
}

export const getSavingTextField = (translation: string): TextFieldProps => {
  return {
    id: "savingText",
    name: "savingText",
    label: i18n.t(`${translation}:savingText`),
    "aria-label": i18n.t(`${translation}:savingText`),
    fullWidth: true,
  }
}

export const getField = (
  fieldName: string,
  translation: string
): TextFieldProps => {
  switch (fieldName) {
    case "name":
      return getTitleField(translation)
    case "savingText":
      return getSavingTextField(translation)
    default:
      return getExternalLinkField(translation)
  }
}
