import { useEffect } from "react"
import Layout from "./Layout"
import {
  Container,
  Header,
  InlineLinks,
  InlineLinksText,
  ListContainer,
  OrderListText,
  Paragraph,
  StyledOrderedList,
  StyledUnorderedList,
} from "./StyledComponents"

const CookiePolicy = () => {
  useEffect(() => {
    document.title = `Cookie Policy`
  })

  return (
    <Layout bannerText="Cookie Policy">
      <Container data-cy="cookie-policy-page">
        <Paragraph>
          Our website uses cookies to distinguish you from other users of our
          websites <InlineLinks href="https://www.vivupbenefits.co.uk/" /> and{" "}
          <InlineLinks href="https://www.vivup.co.uk/" /> (“Our Sites”). This
          helps us to provide you with a good experience when you browse our
          website and also allows us to improve our site.{" "}
        </Paragraph>
        <Paragraph>
          We will ask for your permission (“Consent”) to place cookies or other
          similar technologies on your device, except where they are essential
          for us to provide you with a service that you have requested.
        </Paragraph>
        <Paragraph>
          The operator of the Sites is SME HCI Limited trading as Vivup.
        </Paragraph>
        <Paragraph>
          Cookies perform various different functions, and the purpose of this
          policy is to give you a clearer understanding of:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>What cookies are?</li>
            <li>Why do we use cookies? </li>
            <li>How we use cookies? </li>
            <li>How you can control the use of cookies? </li>
          </StyledUnorderedList>
        </ListContainer>

        <Header variant="h3">What are cookies?</Header>
        <Paragraph>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your computer if you agree. Your browser
          makes cookies available each time you visit one of our Sites. [See the
          section below on How You Can Control The Use of Cookies].
        </Paragraph>

        <Header variant="h3">Why do we use cookies?</Header>
        <Paragraph>
          Cookies are used by our Sites to improve your online experience and to
          ensure that relevant content and functions are delivered and used more
          effectively.
        </Paragraph>
        <Paragraph>
          We use both session cookies and persistent cookies to perform various
          functions across our Sites.
        </Paragraph>
        <Paragraph>
          Session cookies are downloaded to your device temporarily for the
          period that you browse a particular website; these cookies might allow
          you to navigate between pages more efficiently or enable websites to
          remember the preferences you select.
        </Paragraph>
        <Paragraph>
          Persistent cookies can be used to help websites remember you as a
          returning visitor or ensure the online adverts you receive are more
          relevant to your interests.
        </Paragraph>

        <Header variant="h3">How we use cookies?</Header>
        <Paragraph>
          When you visit our Sites, cookies will be placed on your device in two
          ways.
        </Paragraph>
        <ListContainer>
          <StyledOrderedList>
            <li>
              <OrderListText>
                Cookies placed by Vivup, as the operator of the Sites, are
                called “first party” cookies.
              </OrderListText>
            </li>
            <li>
              <OrderListText>
                Cookies may also be placed on your device by a party other than
                Vivup, such cookies are known as “third party” cookies. Examples
                of the uses of third-party cookies include collecting
                information about browser habits and providing tailored content
                to you.
              </OrderListText>
            </li>
          </StyledOrderedList>
        </ListContainer>
        <Paragraph>
          While there are many different uses for first and third party cookies,
          the cookies used by us all fall into one of the following four main
          categories of cookies:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>Strictly Necessary Cookies </li>
            <li>Functionality Cookies </li>
            <li>Performance and Analytical Cookies </li>
            <li>Third Party Marketing / Targeting Cookies </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Set out below is more information on these categories of cookies,
          including their purpose and whether these cookies collect personal
          data:
        </Paragraph>

        <Header variant="h3">Cookie Type - Strictly Necessary Cookies </Header>
        <Paragraph>
          Purpose - These cookies are strictly necessary to enable you to
          navigate our Sites and to use the features you have requested during
          the course of your experience. They are used to provide content to
          you, as a user, and to provide products and services that you have
          requested. Such cookies are essential to help your device download or
          stream information so that you can navigate around our Sites, use the
          features on the Sites and allow you to return to pages you have
          previously visited.
        </Paragraph>
        <Paragraph>
          Here are some examples of strictly necessary cookies:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              To position information on your device or screen so that you can
              see and use our Sites;
            </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          These cookies do not remember your preferences or username beyond your
          current visit.
        </Paragraph>
        <Paragraph>
          Data Collection - Some of these cookies do collect your personal data
          in order to function. They may collect the following types of personal
          data:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>IP address; </li>
            <li>Location data; </li>
            <li>Identify you as being logged into our Sites. </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Cookie Validity - These cookies are deleted when you close your web
          browser (“browser session”). You can control the use of cookies
          depending on the type of browser you are using. [See the section below
          on How You Can Control The Use of Cookies].
        </Paragraph>

        <Header variant="h3">Cookie Type - Functionality Cookies</Header>
        <Paragraph>
          Purpose - These cookies are used to recognise you when you return to
          our Sites and allow us to remember the choices you make and to enable
          us to provide improved features. They also enhance the functionality
          of our Sites by storing your preferences. Functional cookies record
          information about choices you’ve made (such as language preference)
          and allow us to provide more personal features.
        </Paragraph>
        <Paragraph>
          Here are some examples of the uses of functionality cookies:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              To remember your preferences and settings such as language,
              location and marketing preferences, since your last visit;
            </li>
            <li>To remember selections in your basket; </li>
            <li>
              To remember if you have used a specific product on the Site; and
            </li>
            <li>To show you relevant 'related article' links. </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Data Collection - Some of these cookies do collect your personal data,
          to enable us to provide the services as outlined above.
        </Paragraph>
        <Paragraph>
          Cookie Validity - Functional cookies expire after a maximum 2 years.
          Functional cookies can be deleted from your browser history at any
          point before their expiry time. [See the section below on How You Can
          Control The Use of Cookies].
        </Paragraph>

        <Header>Cookie Type – Performance and Analytical Cookies</Header>
        <Paragraph>
          Purpose - These cookies are used to collect information on website
          usage. Such cookies are used to provide aggregated statistics on
          visitors to our Sites. These statistics are used for internal purposes
          to help us to test and improve the performance of our Sites, providing
          a better user experience.
        </Paragraph>
        <Paragraph>
          Here are some examples of the uses of performance cookies:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>To provide statistics on how our Sites are used;</li>
            <li>To see how effective our adverts are; and </li>
            <li>To assess errors within the user experience.</li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Additionally, we use a number of tools to assess cookie information at
          an aggregate level to carry out analytical functions on our site
          including Google Analytics.
        </Paragraph>
        <Paragraph>
          Data Collection - While these cookies do not identify you personally
          while you are not logged in, they will collect your browser
          information and anonymised data, for the purposes outlined above.
        </Paragraph>
        <Paragraph>
          In certain cases, some of these cookies are managed by third parties
          such as Google Analytics. These third parties are not capable of using
          these cookies for any purpose other than those outlined above and they
          are unable to identify you with these cookies.
        </Paragraph>
        <Paragraph>
          Cookie Validity - Performance cookies are valid for varying periods.
          Some performance cookies are deleted once you close your browser,
          while others have an indefinite validity period. Such cookies can be
          deleted at any stage using your browser settings. [See the section
          below on How You Can Control The Use of Cookies].
        </Paragraph>

        <Header variant="h3">
          Cookie Type - Third Party Marketing / Targeting Cookies
        </Header>
        <Paragraph>
          Purpose - These cookies are used to deliver Vivup advertisements
          relevant to you, based upon your interests. They are also used to
          limit the number of times you see an advertisement as well as help
          measure the effectiveness of an advertising campaign. These cookies
          record your visit to our Sites, the pages you have visited and the
          products you have selected. We will use this information to make our
          Sites and the advertising displayed more relevant to your interests.
          We may also share this information with third parties for this purpose
          and to measure the effectiveness of an advertising campaign.
        </Paragraph>
        <Paragraph>Targeting cookies are also used to:</Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              Collect information about browser habits to target website content
              and to target advertising;
            </li>
            <li>
              Link to social media networks who may use this information about
              your visit to target advertising to you;
            </li>
            <li>
              To provide advertisers with information on your visit so that they
              can present you with adverts that you may be interested in;
            </li>
            <li>
              Deliver content and marketing communications tailored to your
              interests based on information from your visit;
            </li>
            <li>
              To perform site retargeting. A retargeting pixel (a tiny unit of
              code) on our webpage leaves a cookie in the user’s browser, which
              causes our adverts to be displayed or to not be displayed to you
              when you visit certain websites within the retargeting network
              (e.g. Google Display Network); and
            </li>
            <li>
              To perform search retargeting, which allows our adverts to be
              displayed on third party webpages, based upon your previous web
              search activities.
            </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Data Collection - Third parties (including, for example, advertising
          networks and providers of external services like web traffic analysis
          services) may also use cookies on our websites. These cookies are
          likely to be analytical/performance cookies, or targeting cookies.
          Please note that no personal data is collected, an advertiser is
          unable to associate the cookie with a particular individual's name,
          address or other personal information.
        </Paragraph>
        <Paragraph>
          These cookies are placed by third party advertising networks with our
          permission.
        </Paragraph>
        <Paragraph>
          Cookie Validity - Third party cookies are valid for varying periods.
          Some cookies expire up to 13 months after they are last updated. [See
          the section below on How You Can Control The Use of Cookies].
        </Paragraph>

        <Header variant="h3">How You Can Control The Use of Cookies</Header>
        <Paragraph>
          You can amend your browser settings to block some or all cookies. To
          do this, follow the instructions provided by your preferred web
          browser. For your ease of reference we have provided the links below
          which provide instructions on how to remove and delete cookies for
          some of the more common web browsers. Please be aware that if you
          block cookies from the websites, some or all the website's functions
          may not perform as intended:
        </Paragraph>
        <Paragraph>
          Firefox
          <InlineLinks href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies" />
        </Paragraph>
        <Paragraph>
          Internet Explorer
          <InlineLinks href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" />
        </Paragraph>
        <Paragraph>
          Google Chrome
          <InlineLinks href="https://support.google.com/chrome/answer/95647?hl=en" />
        </Paragraph>
        <Paragraph>
          Safari <InlineLinks href="https://support.apple.com/en-gb/HT201265" />
          (iOS)
          <InlineLinks href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" />
          (macOS)
        </Paragraph>
        <Paragraph>
          Mobile phone users may have to refer to their handset manual for
          details on how to block cookies using their mobile browser. For more
          information about online behavioural advertising cookies and opt-out
          controls, please go to
          <InlineLinks href="http://www.youronlinechoices.eu/" />.
        </Paragraph>

        <Header variant="h3">Changes to this policy </Header>
        <Paragraph>
          This policy was published on 29/07/2020 and last updated on
          29/07/2020. We may change this policy from time to time, when we do we
          will inform you a notice on our Sites
        </Paragraph>

        <Header variant="h3">Contact Us</Header>
        <Paragraph>
          If you have any questions about how we use Cookies, you can contact us
          by emailing{" "}
          <InlineLinksText href="mailto: info@vivup.co.uk">
            info@vivup.co.uk
          </InlineLinksText>
        </Paragraph>
      </Container>
    </Layout>
  )
}

export default CookiePolicy
