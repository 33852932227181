import { Button, Container, Grid, css, styled, useTheme } from "@mui/material"
import {
  LocalDiscountsContext,
  useLocalDiscountsData,
} from "./LocalDiscountsContext"
import {
  LocalDiscount as DiscountType,
  useToggleLocalDiscountsMutation,
  useUpdatePositionsMutation,
} from "../../../graphqGenaretedTypes"
import Discount from "./Discount"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ModeEditOutlineOutlined, Add, SaveOutlined } from "@mui/icons-material"
import { StyledPara, StyledSwitch } from "../ShowOnPortalPageButton"
import { useParams } from "react-router-dom"
import Alert from "../../shared/Alert"
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs"

export default function ManageLocalDiscounts() {
  const { data } = useLocalDiscountsData()
  const params = useParams()
  const [discounts, setDiscounts] = useState<DiscountType[]>(data.discounts)
  const [isEditing, setIsEditing] = useState(false)
  const { t, ready } = useTranslation([
    "manageLocalDiscounts",
    "clientAdminBreadcrumb",
  ])
  const theme = useTheme()
  const [checked, setChecked] = useState(data.enableLocalDiscounts)
  const [toggleLocalDiscountsMutation] = useToggleLocalDiscountsMutation()
  const [updatePositionsMutation] = useUpdatePositionsMutation()
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)

  const checkboxLabel = {
    inputProps: { "aria-label": t("popUp") },
  }

  useEffect(() => {
    setDiscounts(data.discounts)
  }, [data.discounts])

  useEffect(() => {
    setChecked(data.enableLocalDiscounts)
  }, [data.enableLocalDiscounts])

  const handleToggle = async () => {
    const { data } = await toggleLocalDiscountsMutation({
      variables: {
        enableLocalDiscounts: !checked,
        organisationId: params.organisationId || "",
      },
    })
    const errors = data?.toggleLocalDiscounts?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    } else {
      setAlertMessage(
        `Local discounts have been turned ${
          data?.toggleLocalDiscounts?.enableLocalDiscounts ? "on" : "off"
        }`
      )
      setAlertSeverity("success")
      setErrorCount(errorCount + 1)
      setChecked(!checked)
    }
  }

  const handleReordering = async () => {
    if (isEditing) {
      const { data } = await updatePositionsMutation({
        variables: {
          organisationId: params.organisationId ?? "",
          entityName: "LocalDiscountSupplier",
          items: discounts.map((discount) => ({
            id: discount.id,
            position: discount.position,
          })),
        },
      })

      const errors = data?.updatePositions?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      }
    }

    setIsEditing(!isEditing)
  }

  const moveDiscount = useCallback((dragIndex: number, hoverIndex: number) => {
    setDiscounts((prevDiscounts) => {
      const updatedDiscounts = [...prevDiscounts]

      const dragId = updatedDiscounts[dragIndex].position
      const hoverId = updatedDiscounts[hoverIndex].position

      const draggedDiscount = updatedDiscounts[dragIndex]
      updatedDiscounts[dragIndex] = updatedDiscounts[hoverIndex]
      updatedDiscounts[hoverIndex] = draggedDiscount

      updatedDiscounts[dragIndex] = {
        ...updatedDiscounts[dragIndex],
        position: dragId,
      }
      updatedDiscounts[hoverIndex] = {
        ...updatedDiscounts[hoverIndex],
        position: hoverId,
      }

      return updatedDiscounts
    })
  }, [])

  const renderDiscount = useCallback(
    (discount: DiscountType, index: number) => (
      <Discount
        discount={discount}
        key={discount.id}
        index={index}
        moveDiscount={moveDiscount}
        isEditing={isEditing}
        hidden={!discount.enabled}
      />
    ),
    [moveDiscount, isEditing]
  )
  const breadcrumbsItems = [
    {
      label: t("clientAdminBreadcrumb:benefitManagement"),
      link: `/organisations/${params.organisationId}/employer/benefit_management`,
    },
    {
      label: t("clientAdminBreadcrumb:manageLocalDiscounts"),
      link: `/organisations/${params.organisationId}/employer/benefit_management/local_discounts`,
    },
  ]
  if (!ready) {
    return null
  }

  return (
    <LocalDiscountsContext.Provider value={data}>
      {alertMessage && (
        <Alert
          severity={alertSeverity}
          message={alertMessage}
          key={errorCount}
        />
      )}
      <Container sx={{ marginBottom: "1.5rem", paddingTop: "1.5rem" }}>
        <CustomBreadcrumbs breadcrumbsItems={breadcrumbsItems} />
        <br />
        <h3>{t("header")}</h3>
      </Container>
      <div style={{ margin: "0.75rem" }}>
        <DiscountBox>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleReordering} disabled={discounts.length <= 1}>
              {!isEditing ? (
                <>
                  <ModeEditOutlineOutlined sx={{ paddingRight: "0.5rem" }} />
                  {t("editLayout")}
                </>
              ) : (
                <>
                  <SaveOutlined sx={{ paddingRight: "0.5rem" }} />
                  {t("saveLayout")}
                </>
              )}
            </Button>
          </div>
          <DndProvider backend={HTML5Backend}>
            <Grid container sx={{ marginTop: "1rem" }} spacing={4}>
              {discounts.map((discount, index) => (
                <Grid item xs={12} md={4} lg={3} key={discount.id}>
                  {renderDiscount(discount, index)}
                </Grid>
              ))}
              <Grid item xs={12} md={4} lg={3}>
                <Button
                  variant="outlined"
                  href="new"
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    flexDirection: "column",
                    width: "100%",
                    minWidth: "15.3rem",
                    height: "17.3rem",
                    borderRadius: theme.boxBorderRadius,
                    border: `2px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      border: `2px solid ${theme.palette.primary.main}`,
                    },
                    textAlign: "center",
                  }}
                >
                  <Add sx={{ width: "4.5rem", height: "4.5rem" }} />
                  <div style={{ width: "7.2rem", fontWeight: "bold" }}>
                    {t("newItem")}
                  </div>
                </Button>
              </Grid>
            </Grid>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <StyledSwitch
                {...checkboxLabel}
                checked={checked}
                onClick={handleToggle}
              />
              <StyledPara sx={{ margin: 0 }}>
                {t(checked ? "enableLocalDiscounts" : "disableLocalDiscounts")}
              </StyledPara>
            </div>
          </DndProvider>
        </DiscountBox>
      </div>
    </LocalDiscountsContext.Provider>
  )
}

const DiscountBox = styled("div")`
  ${({ theme }) => css`
    margin: auto;
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    padding: 1rem;
    background-color: ${theme.palette.white.main};
    max-width: 1256px;
  `}
`
