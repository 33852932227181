export const CategoriesHeaders = () => {
  return (
    <div className="cookie__categories-container">
      <div className="cookie__popup-row">
        <div className="cookie__categories-category">Category</div>
        <div className="cookie__categories-description">Description</div>
        <div className="cookie__categories-status">Status</div>
      </div>
      <div className="cookie__divider" />
    </div>
  )
}
