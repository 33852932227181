import { styled, Button, Link, css } from "@mui/material"

const InnerContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    // all data attributes are not coded here yet
    // maybe ProductCard and LS-ProductCard have different data attributes
    <StyledInnerContainer component={Link} href="#" underline="none">
      {children}
    </StyledInnerContainer>
  )
}

export default InnerContainer

export const StyledInnerContainer = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isMobileStoreSmallCard",
})<{
  isMobileStoreSmallCard?: boolean
}>(
  ({ theme, isMobileStoreSmallCard }) => css`
    cursor: pointer;

    position: relative;
    border-radius: ${theme.boxBorderRadius};
    background-color: ${theme.palette.white.main};
    box-shadow: ${theme.boxShadow};
    display: flex;
    flex-direction: column;

    height: 18.75rem;
    align-items: center;
    width: 100%;
    text-align: start;
    padding: 0;
    justify-content: start;

    :hover {
      background-color: ${theme.palette.white.main};
    }

    /* adjustment made for PromoTagContainer. Mobile design is based on 375px */
    ${isMobileStoreSmallCard &&
    `
  @media screen and (min-width: 375px) {
    width: 10.4375rem;
  }
  `}

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 15.875rem;
      height: 22.125rem;
      align-items: flex-end;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      /* seems flex-start and flex-end make no difference */
      align-items: flex-start;
    }

    /* those conditionals from className are not added yet */
    /* gtm-product-box classes.Card,
  showAddToOrder ? classes.AddbtnCard : "",
  isTabPressed ? classes.TabPressed : ""  */
  `
) as typeof Link
