import ExpandableBox from "../ExpandableBox"
import DepartmentFilter from "../../store/filters/department-hierarchy-filter/DepartmentHierarchyFilter"

import AffordabilityCalculatorBox from "../affordability-calculator/AffordabilityCalculatorBox"
import CheckboxList from "../../store/filters/CheckboxList"
import LSCheckboxList from "../../lifestyle-savings/filters/CheckboxList"
import { StoreContext } from "../../store/StoreContext"
import { LSStoreContext } from "../../lifestyle-savings/LSStoreContext"
import { useContext } from "react"
import PricePerMonth from "../../store/filters/PricePerMonth"
import { Box } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

export type FiltersProps = {
  isMobile: boolean
  isLifestyleFilters?: boolean
  showArrow?: boolean
}

const showArrow = true

function Filters({ isMobile, isLifestyleFilters }: FiltersProps) {
  const { t, ready } = useTranslation("lifestyleSavings")

  const contextToUse = isLifestyleFilters ? LSStoreContext : StoreContext
  const {
    filters: {
      brands,
      promotions,
      trendings,
      categories,
      savingTypes,
      brochureGroups,
    },
    breadcrumbs,
  } = useContext(contextToUse)

  const checkboxList = isLifestyleFilters ? LSCheckboxList : CheckboxList

  // Ugly hack to append the 'landing' for the cycle to work scheme.
  const storeLink = /^.*\/landing$/.test(breadcrumbs[0]?.link || "")
    ? breadcrumbs[0]?.link
    : breadcrumbs[0]?.link + "/landing"
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  if (!ready) {
    return null
  }
  return (
    <>
      {isLifestyleFilters ? (
        <ExpandableBox
          showArrow={!isDesktop ? showArrow : !showArrow}
          title={t("store.filters.lsTitle")}
          expandable={false}
          content={
            <Box
              component={checkboxList}
              items={categories}
              paramName="categories"
              initialExpandedState={showArrow}
            />
          }
          className={classNames(
            "gtm-categories-filters",
            isMobile ? "mobile-filter" : "filter-box"
          )}
          expand
        />
      ) : (
        <DepartmentFilter
          className={isMobile ? "mobile-filter" : "filter-box"}
          storeLink={storeLink}
        />
      )}
      {promotions.length > 0 && (
        <ExpandableBox
          showArrow={showArrow}
          title={
            isLifestyleFilters ||
            promotions.includes("Cost Of Living Favourites")
              ? t("store.filters.trendingTitle")
              : t("store.filters.promotionsTitle")
          }
          content={
            <Box
              component={checkboxList}
              items={promotions}
              paramName={isLifestyleFilters ? "trend" : "promo"}
              initialExpandedState={isMobile}
            />
          }
          className={classNames(
            isLifestyleFilters ? "gtm-trending-filters" : "",
            isMobile ? "mobile-filter" : "filter-box"
          )}
          expand={!isMobile}
        />
      )}
      {trendings.length > 0 && (
        <ExpandableBox
          showArrow={showArrow}
          title={t("store.filters.trendingTitle")}
          content={
            <Box
              component={checkboxList}
              items={trendings}
              paramName={"promo"}
              initialExpandedState={isMobile}
            />
          }
          className={classNames(
            "gtm-trending-filters",
            isMobile ? "mobile-filter" : "filter-box"
          )}
          expand={!isMobile}
        />
      )}
      {isLifestyleFilters && savingTypes.length > 0 && (
        <ExpandableBox
          title={t("store.filters.savingsType")}
          showArrow={showArrow}
          content={
            <Box
              component={checkboxList}
              items={savingTypes}
              paramName="savingTypes"
              initialExpandedState={isMobile}
              showCounts={true}
            />
          }
          className={classNames(
            "gtm-saving-types-filters",
            isMobile ? "mobile-filter" : "filter-box"
          )}
          expand={false}
        />
      )}
      {!isLifestyleFilters && (
        <ExpandableBox
          title={t("store.filters.pricePerMonth")}
          showArrow={showArrow}
          content={<PricePerMonth brochureGroups={brochureGroups} />}
          className={isMobile ? "mobile-filter" : "filter-box"}
          expand={true}
        />
      )}
      {!isMobile && !isLifestyleFilters && (
        <AffordabilityCalculatorBox title={t("store.filters.affordability")} />
      )}
      {!isLifestyleFilters && brands.length > 0 ? (
        <ExpandableBox
          showArrow={showArrow}
          title={t("store.filters.brands")}
          content={
            <Box
              component={checkboxList}
              items={brands}
              paramName={"brands"}
              initialExpandedState={isMobile}
              showCounts={isLifestyleFilters}
            />
          }
          className={isMobile ? "mobile-filter" : "filter-box"}
        />
      ) : null}
    </>
  )
}

export default Filters
