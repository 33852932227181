import HowFamilyCareWorks from "./HowFamilyCareWorks"
import FamilyCareFaq from "./FamilyCareFaq"
import FamilyCareThreeStep from "./FamilyCareThreeStep"
import { TopBanner } from "../lifestyle-savings/homepage/TopBanner"
import StartExploringFamilyCare from "./StartExploringFamilyCare"
import { useGetBenefitDataQuery } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

const FamilyCareLanding = () => {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetBenefitDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      relativeUrl: "family_care",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })
  const link = data.data?.employeeOrganisation?.benefit?.ssoLink
  const faqLink = data.data?.employeeOrganisation?.benefit?.faqsUrl
  const familyCareBanner = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/FamilyCareBanner.png"
  )

  return (
    <div>
      <TopBanner
        desktopImageUrl={familyCareBanner}
        mobileImageUrl={familyCareBanner}
        link={null}
      />
      <StartExploringFamilyCare link={link} />
      <FamilyCareThreeStep />
      <HowFamilyCareWorks link={link} />
      <FamilyCareFaq link={faqLink} />
    </div>
  )
}
export default FamilyCareLanding
