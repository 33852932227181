import { Container, Grid, css, styled, useTheme, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../utils/useIsMobileView"
import InfoTooltip from "../shared/InfoTooltip"

interface CalculatedResult {
  costOfQuote: number
  salarySacrifice: number
  monthlyCostToYou: number
  totalCost: number
  youCouldSave: number
}

export type SavingCalculatorCardType = {
  calculatedResult: CalculatedResult | undefined
  picked: boolean
}

const SavingCalculatorCard = ({
  calculatedResult,
  picked,
}: SavingCalculatorCardType) => {
  const isDesktop = !useIsMobileView()
  const theme = useTheme()
  const { t, ready } = useTranslation("quotePage")
  const pickedFirstOption = picked === false
  const pickedSecondOption = picked === true

  if (!ready) {
    return null
  }
  return (
    <PayrollGivingQuoteContainer>
      <SavingCalculatorCardContainer>
        <MainGrid
          sx={{
            paddingRight: "0px !important",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          container
        >
          <GridContent
            item
            xs={6}
            sx={{
              fontSize: "17px",
              fontWeight: "bold",
              color: "#302557",
              transform: isDesktop ? "translate(0, 15%)" : "",
            }}
          >
            {t("savingCalculator.costOfQuote")}
          </GridContent>
          <CalculateValue
            item
            xs={6}
            sx={{
              paddingRight: isDesktop ? "52px" : "40px",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#92237F",
            }}
          >
            <>
              £
              {calculatedResult &&
                Number(calculatedResult.costOfQuote).toFixed(2)}
            </>
          </CalculateValue>
          <Grid item xs={12} sx={{ border: "1px solid #eae9ee" }}></Grid>
          <>
            <GridContent
              item
              sx={{
                paddingBottom: "0px !important",
                paddingTop: "0px !important",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
              xs={8}
            >
              {t("savingCalculator.salarySacrifice")}
              <Superscript>1</Superscript>:
              <Grid item xs={12} sx={{ fontSize: "10px" }}>
                {t("savingCalculator.fromGrossPay")}
              </Grid>
            </GridContent>
            <CalculateValue
              item
              xs={4}
              sx={{
                paddingRight: isDesktop ? "52px" : "40px",
                fontSize: isDesktop ? "16px" : "20px",
                color: "#92237F",
                paddingBottom: "10px",
              }}
            >
              {typeof calculatedResult?.salarySacrifice === "number" &&
                `£${calculatedResult.salarySacrifice.toFixed(2)}`}
            </CalculateValue>
          </>

          <>
            <GridContent
              item
              xs={12}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                fontWeight: "bold",
                fontSize: "16px",
                color: `${theme.palette.primary.main}`,
                paddingTop: "8px",
              }}
            >
              <div>{t("savingCalculator.meansToYou")}</div>
            </GridContent>

            {pickedSecondOption && (
              <>
                <GridContent
                  item
                  xs={7}
                  sx={{
                    paddingLeft: isDesktop ? "41px" : "12px",
                    fontSize: isDesktop ? "1rem" : "0.875rem !important",
                    paddingTop: "8px",
                  }}
                >
                  <Grid
                    style={{
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    {t("savingCalculator.monthlyCost")}
                    <Superscript>2</Superscript>:
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontSize: "10px",
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    {t("savingCalculator.fromYourPocket")}
                  </Grid>
                </GridContent>
                <CalculateValue
                  item
                  xs={5}
                  sx={{
                    fontSize: isDesktop ? "16px" : "20px",
                    color: "#92237F",
                    paddingBottom: "13px",
                  }}
                >
                  <div>
                    <span>
                      {typeof calculatedResult?.monthlyCostToYou === "number" &&
                        `£${parseFloat(
                          String(calculatedResult.monthlyCostToYou)
                        ).toFixed(2)}`}
                    </span>

                    <span style={{ paddingRight: !isDesktop ? "1px" : "10px" }}>
                      <InfoTooltip
                        content={t("savingCalculator.cardToolTip")}
                      />
                    </span>
                  </div>
                </CalculateValue>
              </>
            )}

            {pickedFirstOption && (
              <>
                <GridContent
                  item
                  xs={6}
                  sx={{
                    paddingLeft: isDesktop ? "41px" : "12px",
                    paddingBottom: "14px",
                    fontSize: isDesktop ? "1rem" : "0.875rem !important",
                  }}
                >
                  <Grid
                    style={{
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    {t("savingCalculator.totalCost")}
                    <Superscript>2</Superscript>:
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontSize: "10px",
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    {t("savingCalculator.fromYourPocket")}
                  </Grid>
                </GridContent>
                <Grid
                  item
                  xs={6}
                  sx={{
                    textAlign: "right",
                    fontSize: isDesktop ? "1rem" : "20px",
                    color: "#92237F",
                    paddingBottom: "12px",
                  }}
                >
                  <div>
                    <span>
                      {typeof calculatedResult?.totalCost === "number" &&
                        `£${calculatedResult.totalCost.toFixed(2)}`}
                    </span>
                    <span style={{ paddingRight: !isDesktop ? "1px" : "10px" }}>
                      <InfoTooltip
                        content={t("savingCalculator.cardToolTip")}
                      />
                    </span>
                  </div>
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                height: "47px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#488200",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                color: "#FFFFFF",
              }}
            >
              <div style={{ paddingRight: "6px" }}>
                {t("savingCalculator.youCouldSave")}
              </div>
              <div
                style={{
                  fontSize: isDesktop ? "32px" : "20px",
                  marginLeft: "1px !important",
                }}
              >
                £
                {calculatedResult?.youCouldSave &&
                  calculatedResult.youCouldSave.toFixed(2)}
              </div>
            </Grid>
          </>
        </MainGrid>
      </SavingCalculatorCardContainer>
    </PayrollGivingQuoteContainer>
  )
}

export default SavingCalculatorCard

const PayrollGivingQuoteContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 495px;
    height: 271px;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 24px;
    border: 1px solid ${theme.palette.grey[200]};
    box-shadow: ${theme.boxShadow};
    border-radius: ${theme.boxBorderRadius};

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 495px;
      height: 271px;
    }
  `}
`

const SavingCalculatorCardContainer = styled(Container)`
  &&&& {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 104%;
    background-color: white;
    border-radius: 16px;
  }
`

const MainGrid = styled(Grid)`
  &&&& {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`

const GridContent = styled(Grid)`
  ${({ theme }) => css`
    &&& {
      padding-left: 41px;
      font-size: 16px;
      color: ${theme.palette.primary.main};
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding-left: 12px;
      }
    }
  `}
`

const CalculateValue = styled(Grid)`
  && {
    text-align: end;
  }
`

const Superscript = styled("sup")`
  font-size: 0.625rem;
`
