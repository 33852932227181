import ColorDropdown from "../shared/ColorDropdown"
import { useSearchParams } from "react-router-dom"

export type VarientDropdownProps = {
  availableSizes: string
  selectedSizeFnc?: (size: string | undefined) => void
}
const VarientDropdownGrid = ({
  availableSizes,
  selectedSizeFnc,
}: VarientDropdownProps) => {
  const [searchParams] = useSearchParams()
  const value = searchParams.get("color_value") || "Variation 1"

  const parsedAvailableSizes = JSON.parse(availableSizes).map(
    (size: string) => {
      return {
        title: size,
        value: size,
      }
    }
  )

  return (
    <>
      <ColorDropdown
        paramName="Variation"
        selected={value}
        dropdownOptions={parsedAvailableSizes}
        selectedSizeFnc={(size) => {
          if (selectedSizeFnc) {
            selectedSizeFnc(size)
          }
        }}
      />
    </>
  )
}

export default VarientDropdownGrid
