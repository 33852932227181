import { Box, Typography, css, styled } from "@mui/material"
import { Form } from "formik"
import { Button } from "@mui/material"

export const StyledForm = styled(Form)`
  width: 92%;
  margin: 0 auto;
`

export const FormContainer = styled(Box)`
  ${({ theme }) => css`
    margin: 3rem auto;
    background-color: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    padding: 2.5rem 0.875rem 1.75rem 0.875rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 33.5rem;
      padding: 3.75rem;
      padding-bottom: 1rem;
    }
  `}
`
export const FormContainerBox = styled(FormContainer)`
  ${({ theme }) => css`
    margin: 0.625rem auto;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
      padding-bottom: 1.75rem;
    }
  `}
`
export const MainTextHeading = styled(Box)`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: 2.5rem 0.875rem 1.75rem 0.875rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
      padding: 3.75rem;
      padding-bottom: 2rem;
    }
  `}
`
export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: 1.5rem 0.875rem 1.75rem 0.875rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
      padding: 3.75rem;
      padding-top: 1.55rem;
      padding-bottom: 2rem;
    }
  `}
`
export const StyledFormContent = styled(Form)`
  margin: 0 auto;
`

export const SubmitButton = styled(Button)`
  ${({ theme }) => css`
    display: block;
    margin: 1.6875rem auto 0 auto;
    width: 100%;
    border-radius: 2.625rem;
    box-shadow: ${theme.boxShadow};
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 17.5rem;
    }
  `}
`

export const RedOuterBox = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.error.main};
    color: ${theme.palette.white.main};
    width: 100%;
    border-radius: 0.25rem;
    margin-bottom: 1.375rem;
  `}
`

export const RedInnerBox = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin: 0.875rem 0.375rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin: 0.875rem auto;
      justify-content: space-evenly;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 1.0625rem auto;
      justify-content: center;
    }
  `}
`

export const WarningParagraph = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  `}
`
