import { Button, styled } from "@mui/material"
import { CookieType } from "./Cookie"

const TITLE = "We value your privacy"

const MANAGE_PREFERENCE = "Manage Preferences"

const COOKIES = "Accept all cookies"

function CookieBanner({
  setShouldShowCookieModal,
  setShouldShowPopup,
  saveSettingsToStorage,
}: CookieType) {
  const handleAcceptCookie = () => {
    saveSettingsToStorage({ functional: true })
    setShouldShowCookieModal && setShouldShowCookieModal(false)
  }

  const handlePreferenceClick = () => {
    setShouldShowPopup && setShouldShowPopup(true)
  }

  return (
    <div className="cookie__banner-container">
      <div className="mobile-view">
        <div className="cookie__mobile-content-container">
          <h3 className="cookie__header">{TITLE}</h3>
          <p className="cookie__paragraph">
            We use cookies to help give you the best experience on our site. For
            more information see our{" "}
            <span>
              <a
                className="cookie__links"
                href="/cookie_policy"
                target="_blank"
                rel="noopener"
              >
                cookie policy
              </a>
            </span>
            .
          </p>
          <div className="cookie__buttons-flexbox">
            <ManagePreferenceButton
              onClick={handlePreferenceClick}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  event.preventDefault()
                  handlePreferenceClick()
                }
              }}
            >
              <p className="cookie__links">{MANAGE_PREFERENCE}</p>
            </ManagePreferenceButton>
            {/* use .cookie-accept-button in plain html version, use React component because of the ripple effect */}
            <AcceptButton variant="gradient" onClick={handleAcceptCookie}>
              {COOKIES}
            </AcceptButton>
          </div>
        </div>
      </div>

      <div className="desktop-view">
        <div className="cookie__desktop-content-container">
          <div className="cookie__left-content">
            <h3 className="cookie__header">{TITLE}</h3>
            <p className="cookie__paragraph">
              We use cookies to help give you the best experience on our site.
              For more information see our{" "}
              <span>
                <a
                  className="cookie__links"
                  href="/cookie_policy"
                  target="_blank"
                  rel="noopener"
                >
                  cookie policy
                </a>
              </span>
              .
            </p>
            <ManagePreferenceButton
              onClick={handlePreferenceClick}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  event.preventDefault()
                  handlePreferenceClick()
                }
              }}
            >
              <p className="cookie__links">{MANAGE_PREFERENCE}</p>
            </ManagePreferenceButton>
          </div>

          <AcceptButton variant="gradient" onClick={handleAcceptCookie}>
            {COOKIES}
          </AcceptButton>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner

const AcceptButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin: 0;
  padding: 14.5px 0;
  max-width: 12.25rem;
  flex: 0 0 65%;
`

const ManagePreferenceButton = styled(Button)`
  width: fit-content;
  text-align: left;
  padding: 0;
`
