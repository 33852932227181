import { styled } from "@mui/material"
import { StyledOuterContainer } from "../../shared/card/OuterContainer"
import PromoTag from "../../shared/card/PromoTag"

const CarouselWrapper = ({
  /**
   * OuterContainer for NormalCardContent in carousel because it needs handleTabFocus()
   */
  children,
  handleTabFocus,
  featuredTagImage,
  featuredTagAltText,
}: {
  children?: React.ReactNode
  handleTabFocus: (e: React.KeyboardEvent<HTMLDivElement>) => void
  featuredTagImage?: string
  featuredTagAltText?: string
}) => {
  return (
    <CarouselOuterContainer
      className="carousel-outer-container"
      onKeyUp={(e) => {
        if (e.key === "Tab") return handleTabFocus(e)
      }}
    >
      {featuredTagImage && (
        <PromoTag
          featuredTagImage={featuredTagImage}
          featuredTagAltText={featuredTagAltText}
        />
      )}
      {children}
    </CarouselOuterContainer>
  )
}

export default CarouselWrapper

const CarouselOuterContainer = styled(StyledOuterContainer)`
  margin-right: 1rem;
  justify-content: flex-start;
  position: relative; // the anchor container for PromoTagContainer with absolute position
`
