import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useMediaQuery, useTheme } from "@mui/material"
import MobileBreadCrumbs from "./MobileBreadCrumbs"

export type BreadcrumbsType = {
  label: string
  link: string
  forceLink?: boolean | undefined | null
  isExternalLink?: boolean
}

export default function CustomBreadcrumbs({
  breadcrumbsItems,
}: {
  breadcrumbsItems: BreadcrumbsType[]
}) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  if (!isDesktop) {
    return <MobileBreadCrumbs breadcrumbsItems={breadcrumbsItems} />
  }

  return (
    <>
      <Breadcrumbs
        separator={
          <ChevronRightIcon
            sx={{
              color: theme.palette.primary.main,
              width: "1rem",
              height: "1rem",
            }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbsItems.map((item, index) => {
          return breadcrumbsItems.length - 1 == index && !item?.forceLink ? (
            <Typography
              key={index}
              color={theme.palette.primary.main}
              fontWeight="bold"
              fontSize="0.75rem"
            >
              {item.label}
            </Typography>
          ) : (
            <Link
              component={
                /^.*cycle_to_work$/.test(item?.link) || item.isExternalLink
                  ? "a"
                  : Link
              } //TODO remove this hack once we have a react cycle_to_work landing page
              underline="hover"
              key={index}
              color={theme.palette.primary.main}
              href={item?.link}
              fontSize="0.75rem"
            >
              {item.label}
            </Link>
          )
        })}
      </Breadcrumbs>
    </>
  )
}
