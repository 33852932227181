import { Modal, Box, Button, css } from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import ErrorIcon from "@mui/icons-material/Error"
import { SubmitButton } from "../registration/shared/mainPageStyles"
import {
  CloseButton,
  CloseButtonContainer,
  IconContainer,
  MainText,
  TextContainerBox,
} from "../explore-your-benefits/BenefitCardModal"
import { useTranslation } from "react-i18next"

export type ConfirmationModalProps = {
  openModal: boolean
  handleClose(): void
  handleAction?: () => Promise<void> | void
  label?: string | null
  firstLine?: string
  secondLine?: string
  buttonLabel?: string
}

const ConfirmationModal = ({
  openModal,
  handleClose,
  handleAction,
  label,
  secondLine,
  firstLine,
  buttonLabel,
}: ConfirmationModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("newBenefitTile")
  const handleModalClose = () => {
    handleClose()
  }
  if (!ready) {
    return null
  }
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainer
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div>
          <TextContainerBox>
            <StyleConfirmationIcon />
            <MainText id="dialog_label">
              <span className="screen-reader-only">Opened</span>
              {label}
              <span className="screen-reader-only">pop up</span>
            </MainText>
          </TextContainerBox>
          <ParagraphStyle>
            <ConfirmationText>{firstLine} </ConfirmationText>
            <ConfirmationText>{secondLine} </ConfirmationText>
          </ParagraphStyle>
          <SubmitButton
            variant="gradient"
            onClick={() => {
              if (handleAction !== undefined) {
                handleAction()
              }
            }}
          >
            {buttonLabel}
          </SubmitButton>
          <CancelButton onClick={handleClose}>
            {t("popUpModal.cancel")}
          </CancelButton>
          <CloseButtonContainer>
            <CloseButton variant="invisible" onClick={handleClose}>
              {t("popUpModal.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </div>
        <IconContainer>
          <Button
            onClick={handleModalClose}
            aria-label={t("popUpModal.closeWindow")}
          >
            <CrossButton
              sx={{
                paddingRight: isDesktop ? "1.121rem" : "1rem",
              }}
            />
          </Button>
        </IconContainer>
      </MainContainer>
    </Modal>
  )
}
export default ConfirmationModal
export const CancelButton = styled(SubmitButton)`
  border: 0.0625rem solid black;
`
export const CrossButton = styled(Close)`
  ${({ theme }) => css`
    color: ${theme.palette.grey[900]};
    font-size: 1.515rem;
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      border-radius: 0.938rem;
      background-color: white;
      padding-top: 1rem;
      width: 33.813rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 21.313rem;
        padding: 0.5625rem;
      }
    }
  `}
`

const ParagraphStyle = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    padding: 1.3rem;
    padding-top:0.8rem;
    text-align:center;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 25rem;
      height:100%
      padding-top: 0rem;
      padding-bottom: 1rem;
    }
  `}
`
const ConfirmationText = styled("p")`
  ${({ theme }) => css`
    && {
      color:
      font-size: 1rem;
      margin: 0.5rem 0rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        font-size: 0.875rem;
      }
    }
  `}
`

const StyleConfirmationIcon = styled(ErrorIcon)`
  ${({ theme }) => css`
    font-size: 1.25rem;
    width: 100%;
    height: 100%;
    max-width: 5.4375rem;
    max-height: 4.75rem;
    padding: 0rem;
    color:${theme.palette.secondary.main};
    && {
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        font-size: large;
        max-width: 3.9169rem;
        max-height: 5.9169rem;
        width:100%;
        height:100%
        padding: 0rem;
    }
  `}
`
