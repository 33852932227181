import { Chip, Link, styled, Box, css } from "@mui/material"
import { useStoreHomeData } from "./StoreHomeContext"
import { gtmLifestylePillClick } from "../shared/gtm-events/CustomGtmEvents"
import { useEffect } from "react"
import { gtmLifestylePillsOnLanding } from "../shared/gtm-events/CustomGtmEvents"
import hexToRgba from "../../utils/hexToRgba"

const FeatureChips = () => {
  const { data } = useStoreHomeData()

  const dataHandler = (name: string, index: number) => {
    return gtmLifestylePillClick(name, index)
  }
  useEffect(() => {
    if (!data.loading) {
      data.categoryPills.map((item, index) =>
        gtmLifestylePillsOnLanding(item.name, index + 1)
      )
    }
  }, [data.loading]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <OuterContainer>
      <InnerContainer>
        {data?.categoryPills &&
          data.categoryPills.map(({ name, url }, index) => (
            <StyledChip
              onClick={() => dataHandler(name, index + 1)}
              clickable
              label={name}
              key={`ls-chip-${name}`}
              component={Link}
              href={url}
            />
          ))}
      </InnerContainer>
    </OuterContainer>
  )
}

export default FeatureChips

const OuterContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 2rem 1rem 0 1rem;
    overflow: hidden;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: 2rem;
    }

    @media (hover: none) {
      // draggable on touchscreen device
      touch-action: pan-x;
      overflow-x: scroll;

      // hide the scrollbar
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  `}
`

const InnerContainer = styled(Box)`
  padding: 0.0625rem;
  margin: 0 auto;
  width: max-content;
  height: 100%;
  display: flex;
  align-items: flex-end; // chips sit at bottom of container so on hover and expanded only one chip has the animation instead of the whole row
`

const StyledChip = styled(Chip)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    letter-spacing: 0.015625rem; //0.25px;
    height: 1.8125rem; // 29px;
    border: 0.0625rem solid ${theme.palette.primary.main};
    background-color: ${theme.palette.grey[200]};
    border-radius: ${theme.boxBorderRadius};
    margin-right: 1rem;

    &:hover {
      /* to override the MUI default hover color */
      background-color: ${hexToRgba(theme.palette.grey[200], "0.3")};
    }

    &:focus {
      color: #000000de;
      border: 0.0625rem solid #000000de;
      background-color: ${hexToRgba(theme.palette.grey[200], "0.3")};
    }
  `}
` as typeof Chip
