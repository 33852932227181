import { useState } from "react"
import { RichTextEditor } from "../shared/RichTextEditor"
import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

export function PopUpContentField({ content }: { content: string }) {
  const [characterCount, setCharacterCount] = useState(0)
  const maxCharacters = 1000
  const theme = useTheme()
  const { t, ready } = useTranslation("newBenefitTile")

  if (!ready) {
    return null
  }

  return (
    <>
      <RichTextEditor
        variant="light"
        fieldName="popUpContent"
        data={content}
        setCharacterCount={setCharacterCount}
      />
      {characterCount > maxCharacters && (
        <div
          style={{
            float: "left",
            color: theme.palette.error.main,
            margin: "3px 14px 0",
          }}
        >
          {t("popUpContentLengthError")}
        </div>
      )}
      <div
        style={{
          color: theme.palette.grey[700],
          float: "right",
          paddingTop: "0.5rem",
        }}
      >
        {characterCount} / {maxCharacters}
      </div>
    </>
  )
}
