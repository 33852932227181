import { TextFieldProps } from "@mui/material"
import i18n from "../../i18n"
export const getBenefitOptionField = (translation: string): TextFieldProps => {
  return {
    id: "SelectAnOption",
    name: "SelectAnOption",
    select: true,
    "aria-label": i18n.t(`${translation}:benefitOptionFieldLabel`),
    fullWidth: true,
  }
}
export const getBenefitTitleField = (translation: string): TextFieldProps => {
  return {
    id: "BenefitTitle",
    name: "BenefitTitle",
    label: i18n.t(`${translation}:benefitTitleFieldLabel`),
    "aria-label": i18n.t(`${translation}:benefitTitleFieldLabel`),
    fullWidth: true,
  }
}
export const getBenefitSubTitleField = (
  translation: string
): TextFieldProps => {
  return {
    id: "BenefitSubTitle",
    name: "BenefitSubTitle",
    label: i18n.t(`${translation}:benefitSubTitleField`),
    "aria-label": i18n.t(`${translation}:benefitSubTitleField`),
    fullWidth: true,
  }
}
export const getBenefitUrlField = (translation: string): TextFieldProps => {
  return {
    id: "BenefitUrl",
    name: "BenefitUrl",
    label: i18n.t(`${translation}:benefitUrlField`),
    "aria-label": i18n.t(`${translation}:benefitUrlField`),
    fullWidth: true,
  }
}
export const getPositionField = (translation: string): TextFieldProps => {
  return {
    id: "position",
    name: "position",
    label: i18n.t(`${translation}:positionInGrid`),
    select: true,
    "aria-label": i18n.t(`${translation}:positionInGrid`),
    fullWidth: true,
  }
}
export const getEditorField = (translation: string): TextFieldProps => {
  return {
    id: "position",
    name: "position",
    label: i18n.t(`${translation}:positionInGrid`),
    select: true,
    "aria-label": i18n.t(`${translation}:positionInGrid`),
    fullWidth: true,
  }
}
