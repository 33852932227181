import * as Yup from "yup"
import { FileUploadValidation } from "../../../utils/yup-validation-schema/ImageUploadValidation"
import { titleValidation } from "../../../utils/yup-validation-schema/titleValidation"
import i18n from "../../../i18n"

const NAME_MAX_LENGTH = 100
const DESCRIPTION_MAX_LENGTH = 100

export type NewLocalDiscountValue = {
  externalLink: string
  isVisible: boolean
  name: string
  position: string
  file: File | string
  logo: File | string
  savingText: string
  typeOfLink: string
}

export const NewLocalDiscountInitialValues: NewLocalDiscountValue = {
  externalLink: "",
  isVisible: true,
  name: "",
  file: "",
  logo: "",
  position: "",
  savingText: "",
  typeOfLink: "external_link",
}

type NewLocalDiscountValueFieldsWithValidationKeys<
  T extends keyof NewLocalDiscountValue
> = T[]

export const NewLocalDiscountFieldsWithValidation: NewLocalDiscountValueFieldsWithValidationKeys<
  "name" | "file"
> = ["name", "file"]

export const NewLocalDiscountValidationSchema = (translation: string) =>
  Yup.object().shape({
    name: titleValidation(translation).max(
      NAME_MAX_LENGTH,
      i18n.t(`${translation}:nameMaxLength`, { max: NAME_MAX_LENGTH })
    ),
    externalLink: Yup.string().when(["typeOfLink"], (typeOfLink) => {
      if (typeOfLink === "external_link") {
        return Yup.string()
          .test(
            "https-check",
            () => {
              const errorMessage = i18n.t(`${translation}:httpCheckWarning`)
              return errorMessage
            },
            (value) => {
              return value ? value.startsWith("https://") : true
            }
          )
          .url(() => i18n.t(`${translation}:invalidUrlWarning`))
          .required(() => i18n.t(`${translation}:emptyExternalLinkWarning`))
      }
      return Yup.string()
    }),
    logo: FileUploadValidation(translation, "logo"),
    file: Yup.string().when(["typeOfLink"], (typeOfLink) => {
      if (typeOfLink === "file_upload") {
        return FileUploadValidation(translation, "file")
      }
      return Yup.string()
    }),
    savingText: Yup.string()
      .required(() => i18n.t(`${translation}:emptySavingTextWarning`))
      .max(
        DESCRIPTION_MAX_LENGTH,
        i18n.t(`${translation}:savingTextMaxLength`, {
          max: DESCRIPTION_MAX_LENGTH,
        })
      ),
  })
