import { memo, RefObject, useEffect, useRef } from "react"

const DisableTabbing: React.FC<React.PropsWithChildren> = memo(
  ({ children }) => {
    const wrapperRef: RefObject<HTMLDivElement> = useRef(null)

    useEffect(() => {
      if (wrapperRef.current) {
        const interactiveElements =
          wrapperRef.current.querySelectorAll<HTMLElement>(
            "a, button, input, textarea, select, [tabindex]"
          )

        interactiveElements.forEach((element) => {
          element.setAttribute("tabindex", "-1")
        })
      }
    })

    return <div ref={wrapperRef}>{children}</div>
  }
)
export default DisableTabbing
