import { Box, styled } from "@mui/material"
import { GreenBoxContent } from "./GreenBoxContent"
import { Header } from "./Header"
import { colourBox } from "../../shared/SharedStyles"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"

function Content({ data }: { data: HealthAndWellbeing }) {
  const { usefulLinks } = data
  const isMobileView = useIsMobileView()

  return (
    <>
      <Header data={data} />
      <OuterGrid>
        <ContentGrid>
          <GreenBoxContent data={data} />
        </ContentGrid>
      </OuterGrid>
      {isMobileView && usefulLinks && (
        <ExpandableList
          links={usefulLinks}
          moreButtonColor="#488200"
          isExternalLink
        />
      )}
    </>
  )
}

export default Content

const OuterGrid = styled(colourBox)`
  background-color: #ecf2e5;
  display: grid;
  place-items: center;
`
export const ContentGrid = styled(Box)`
  display: grid;
  grid-auto-rows: min-content;
  width: 100%;
  padding: 1rem 0;
`
