import { Box, styled } from "@mui/material"
import {
  MainHeader,
  PropositionSpaceTwoSubHeader,
  Space2HeaderMobilePadding,
  Space2HeaderDesktopPadding,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"

export function Header() {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobilePadding>
        <Content />
      </MobilePadding>
    )
  }
  return (
    <Space2HeaderDesktopPadding>
      <Content />
    </Space2HeaderDesktopPadding>
  )
}

const MobilePadding = styled(Space2HeaderMobilePadding)`
  padding-bottom: 16px;
`

function Content() {
  const isMobile = useIsMobileView()
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <>
      <MainHeader variant="h3" align="left">
        {t("lifestyleSavings.header")}
      </MainHeader>
      <PropositionSpaceTwoSubHeader variant="h4" align="left">
        {t("lifestyleSavings.subHeader")}
      </PropositionSpaceTwoSubHeader>
      {!isMobile && (
        <ButtonContainer>
          <Button />
        </ButtonContainer>
      )}
    </>
  )
}

const ButtonContainer = styled(Box)`
  display: block;
  margin: 0 auto;
  padding-top: 20px;
`
