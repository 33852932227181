import { useState } from "react"
import {
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import scrollToElement from "../../cycle-to-work/quote-page/scrollToElement"
import {
  FormContainerBox,
  MainContainer,
  StyledFormContent,
  SubmitButton,
} from "../../registration/shared/mainPageStyles"
import { RequiredText } from "../../registration/sign-up/ColleagueField"
import Header from "../Header"
import {
  NewLocalDiscountFieldsWithValidation,
  NewLocalDiscountInitialValues,
  NewLocalDiscountValidationSchema,
  NewLocalDiscountValue,
} from "./formikUtils"
import ConfirmationModal from "../ConfirmationModal"
import { PositionDropDown } from "../PositionDropDown"
import {
  useGetLocalDiscountCountDataQuery,
  useManageLocalDiscountMutation,
} from "../../../graphqGenaretedTypes"
import { useNavigate, useParams } from "react-router-dom"
import Alert from "../../shared/Alert"
import { TypeDropdownField } from "./TypeDropDownField"
import Field from "./Field"
import FileUpload from "./FileUpload"
import Toggle from "./Toggle"
import { useLocalDiscountData } from "./getLocalDiscountData"
import Loader from "../../shared/Loader"
import { DeleteButtonContainer } from "../EditACustomTile"
import DeleteLocalBenefitButton from "../DeleteLocalBenefitButton"

const TRANSLATION = "newLocalDiscount"

const EditLocalDiscount = () => {
  const { id, organisationId } = useParams()
  const localDiscountData = useLocalDiscountData(organisationId, id)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const { t, ready } = useTranslation(TRANSLATION)
  const [manageLocalDiscountMutation] = useManageLocalDiscountMutation()
  const params = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetLocalDiscountCountDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
    },
    fetchPolicy: "no-cache",
  })
  const totalCards = data?.employerOrganisation?.localDiscountCount ?? 0

  const initialValues = { ...NewLocalDiscountInitialValues }
  initialValues.externalLink = localDiscountData.externalLink
  initialValues.isVisible = localDiscountData.isVisible
  initialValues.name = localDiscountData.name
  initialValues.position = localDiscountData.position?.toString() ?? ""
  initialValues.file = localDiscountData.fileName
  initialValues.logo = localDiscountData.logoName
  initialValues.savingText = localDiscountData.savingText
  initialValues.typeOfLink = localDiscountData.typeOfLink
  initialValues.isVisible = localDiscountData.enabled

  const handlePublishConfirm = async (
    formik: FormikProps<NewLocalDiscountValue>
  ) => {
    formik.isValidating = true

    const validationErrors = await formik.validateForm()

    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true))

      const errorFieldId = NewLocalDiscountFieldsWithValidation.find((field) =>
        Object.keys(validationErrors).find((errorField) => errorField == field)
      )

      scrollToElement(`#${errorFieldId}`)
      formik.isValidating = false
      return
    }
    setIsPublishModalOpen(true)
  }

  const handlePublish = async (
    formik: FormikProps<NewLocalDiscountValue>
  ): Promise<void> => {
    formik.handleSubmit()
    setIsPublishModalOpen(false)
  }

  const handlePublishModalClose = () => {
    setIsPublishModalOpen(false)
  }

  const handleFormSubmit = async (
    values: NewLocalDiscountValue,
    formikHelper: FormikHelpers<NewLocalDiscountValue>
  ) => {
    const { data } = await manageLocalDiscountMutation({
      variables: {
        externalLink: values.externalLink,
        isVisible: values.isVisible,
        name: values.name,
        id,
        organisationId: params.organisationId ?? "",
        savingText: values.savingText,
        file: typeof values.file !== "string" ? values.file : null,
        logo: typeof values.logo !== "string" ? values.logo : null,
        typeOfLink: values.typeOfLink,
        position: parseInt(values.position),
      },
    })

    const errors = data?.manageLocalDiscount?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    } else {
      navigate(
        `/organisations/${params.organisationId}/employer/benefit_management/local_discounts`
      )
    }
    formikHelper.setSubmitting(false)
  }

  if (localDiscountData.loading) {
    return <Loader />
  }
  if (!ready) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NewLocalDiscountValidationSchema(TRANSLATION)}
      onSubmit={(values, formikHelper) => {
        handleFormSubmit(values, formikHelper)
      }}
    >
      {(formik) => {
        return (
          <MainContainer>
            {alertMessage && (
              <Alert
                severity={alertSeverity}
                message={alertMessage}
                key={errorCount}
              />
            )}
            <Header content={t("editPage.title")} />
            <StyledFormContent>
              <FormContainerBox>
                <Field fieldName="name" />
                <TypeDropdownField isEditScreen />
                {formik.values.typeOfLink == "external_link" ? (
                  <Field fieldName="externalLink" />
                ) : (
                  <FileUpload formikValue={formik} fieldName="file" />
                )}
                <Field fieldName="savingText" />
                <FileUpload formikValue={formik} fieldName="logo" />
                <Toggle />
                {!loading && (
                  <PositionDropDown
                    formikValue={formik}
                    currentPosition={parseInt(formik.values.position)}
                    totalCards={totalCards}
                    infoToolTip={t("positionTooltip")}
                    translation={TRANSLATION}
                  />
                )}
                <div className="content-center">
                  <PublishButton
                    variant="gradient"
                    onClick={() => handlePublishConfirm(formik)}
                    disabled={formik.isSubmitting || formik.isValidating}
                  >
                    {t("publish")}
                  </PublishButton>
                </div>
                <DeleteButtonContainer>
                  <DeleteLocalBenefitButton
                    id={id || ""}
                    translation={TRANSLATION}
                    redirectUrl={`/organisations/${params.organisationId}/employer/benefit_management/local_discounts`}
                  />
                </DeleteButtonContainer>
                <ConfirmationModal
                  openModal={isPublishModalOpen}
                  handleClose={handlePublishModalClose}
                  handleAction={() => handlePublish(formik)}
                  label={t("popUpModal.publishModalHeading")}
                  firstLine={t(
                    formik.values.isVisible
                      ? "popUpModal.publishModalFirstLine"
                      : "popUpModal.hiddenContentMessage"
                  )}
                  secondLine={t("popUpModal.publishModalSecondLine")}
                  buttonLabel={t("popUpModal.publishButtonLabel")}
                />
              </FormContainerBox>
            </StyledFormContent>
          </MainContainer>
        )
      }}
    </Formik>
  )
}

export default EditLocalDiscount

export const StyledRequiredText = styled(RequiredText)`
  &&& {
    margin-top: 0rem;
  }
`

export const PublishButton = styled(SubmitButton)`
  ${({ theme }) => css`
    &&& {
      margin-top: 0;
      margin-left: 0px;
      margin-right: 0px;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `}
`
export const PreviewButton = styled(SubmitButton)`
  ${({ theme }) => css`
    &&& {
      border: 1px solid gray;
      margin-left: 0px;
      margin-right: 0px;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `}
`
export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    display: block;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      display: flex;
      justify-content: center;
    }
  `}
`
