import React from "react"
import { CustomArrowProps } from "react-slick"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { getStyledArrow } from "./getStyledArrow"

export const RightArrow = ({
  // eslint-disable-next-line
  currentSlide,
  // eslint-disable-next-line
  slideCount,
  ...props
}: CustomArrowProps) => {
  return <StyledRightArrow {...props} />
}
const StyledRightArrow = getStyledArrow(ChevronRightIcon)
