import { Box, Button, Typography, css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

type BenefitCard = {
  image: string
  heading: string
  content: string
  link: string
}

const BenefitCard = ({ image, heading, content, link }: BenefitCard) => {
  const { t, ready } = useTranslation("mentorPartnership")

  if (!ready) {
    return null
  }
  return (
    <CardOuterContainer>
      <InnerContainer>
        <Image src={image} alt={heading} />
        <ContentContainer>
          <Heading>{heading}</Heading>
          <Content>{content}</Content>
          <ExploreNowButton onClick={() => (window.location.href = link)}>
            {t("exploreNow")} {`>`}
          </ExploreNowButton>
        </ContentContainer>
      </InnerContainer>
    </CardOuterContainer>
  )
}

export default BenefitCard

const ExploreNowButton = styled(Button)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1rem;
    color: ${theme.palette.secondary.main};
    padding: 0;
    justify-content: flex-start;
    width: fit-content;
  `}
`

const Content = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${theme.palette.primary.main};
    min-height: 3.9375rem;
    margin-bottom: 1rem;
  `}
`

const Heading = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    margin-bottom: 1rem;
  `}
`

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: center;
  margin-left: 1rem;
`

const Image = styled("img")`
  ${({ theme }) => css`
    width: 132px;
    height: 168px;
    align-self: center;
    object-fit: cover;
    object-position: center;
    border-radius: 0.25rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 150px;
    }
  `}
`

const InnerContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const CardOuterContainer = styled(Box)`
  ${({ theme }) => css`
    min-height: 12.5rem;
    width: 92.3%;
    max-width: 33.3125rem;
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    margin: 1rem auto;
    background-color: ${theme.palette.white.main};
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
  `}
`
