import { string } from "yup"
import i18n from "../../i18n"

const maxCharactersAllowed = 35

export const brandOfBikeValidation = string()
  .required(() => i18n.t("bikeQuote:validations.brandOfBikeEmptyWarning"))
  .max(maxCharactersAllowed, () =>
    i18n.t("bikeQuote:validations.brandOfBikeMaxCharactersWarning")
  )
