import { Box, css, styled, useMediaQuery, useTheme } from "@mui/material"
import React from "react"

export const WavePayRollPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox={isDesktop ? `0 0 1920.696 400` : `0 0 1920.696 1621.533`}
        preserveAspectRatio="none"
      >
        <path
          d="M7715.75,672.88c514.685-82.726,931.7,0,931.7,0l-.006-1584.766H6726.751V672.88S7201.064,755.606,7715.75,672.88Z"
          transform="translate(8647.447 709.647) rotate(180)"
          fill={"#EAE9EE"}
        />
      </svg>
    </SvgContainer>
  )
}
const SvgContainer = styled(Box)`
  ${({ theme }) => css`
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    height: 68%;
    z-index: -1;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      top: 41px;
    }
  `}
`
