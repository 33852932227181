import React from "react"
import { styled, Button } from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { useTranslation } from "react-i18next"

const PopUpButton: React.FC<
  React.PropsWithChildren<{
    openOverlay: React.MouseEventHandler<HTMLElement>
    isOverlayOpen: boolean
  }>
> = ({ openOverlay, isOverlayOpen }) => {
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }

  return (
    <StyledButton
      id="whatIsLsButton"
      aria-expanded={isOverlayOpen}
      variant="gradient"
      onClick={openOverlay}
    >
      <Arrow /> {t("landingPage.overlay.button")}
    </StyledButton>
  )
}

export default PopUpButton

const StyledButton = styled(Button)`
  margin: 0;
  width: 18.1875rem; // 291px
  height: 2.625rem; // 42px;
  justify-self: center;
  align-self: center; // account for the padding-bottom and the size of grid container
  border-bottom-left-radius: 0; // to overwrite css from mui-theme gradient
  border-bottom-right-radius: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  justify-content: left;
  column-gap: 0.8rem;

  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
`

const Arrow = styled(ArrowDropUpIcon)`
  padding-left: 0.25rem;
`
