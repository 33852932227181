import { Link } from "../../../graphqGenaretedTypes"
import {
  ListItem,
  ListItemButton,
  ListItemText,
  css,
  styled,
  Divider,
} from "@mui/material"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { gtmPortalHomepageHealthAndWellbeingLinkClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

export function SingleLink({
  linkData,
  isExternalLink,
}: {
  linkData: Link
  isExternalLink?: boolean
}) {
  const { label, link, external } = linkData
  const clickLocation = "Page Body Menu"
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <div key={`${label} link`}>
      <StyledListItem
        disableGutters
        disablePadding
        secondaryAction={<RightArrow />}
        component="div"
      >
        <ListItemButton
          disableGutters
          sx={{ padding: 0 }}
          component={isExternalLink ? "a" : "div"}
          href={link}
          target={isExternalLink || external ? "_blank" : undefined}
        >
          <Text
            primary={label}
            onClick={() => {
              gtmPortalHomepageHealthAndWellbeingLinkClick(
                label,
                link,
                clickLocation
              )
            }}
          />
          {isExternalLink && (
            <span className="screen-reader-only">
              {`(${t("healthAndWellbeing.screenReader")})`}
            </span>
          )}
        </ListItemButton>
      </StyledListItem>
      <StyledDivider />
    </div>
  )
}
const Text = styled(ListItemText)`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 16px;
    color: ${theme.palette.primary.main};
  `}
`
const StyledListItem = styled(ListItem)`
  padding: 6px;
`
const StyledDivider = styled(Divider)`
  ${({ theme }) => css`
    color: ${theme.palette.grey[200]};
  `}
`
const RightArrow = styled(KeyboardArrowRightIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`
