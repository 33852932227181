const nonStandAloneElements = [
  "employee_noticeboard",
  "banner_space",
  "trustpilot",
  "highfive_banner",
]

const blueElements = ["cycle_to_work", "car_benefit"]

const pairs: { [key: string]: string } = {
  cycle_to_work: "car_benefit",
  car_benefit: "cycle_to_work",
  health_and_wellbeing: "lifestyle_savings",
  lifestyle_savings: "health_and_wellbeing",
}

export type Group = {
  categories: string[]
  color: string
  isComplete?: boolean
}

// Splits the items into groups taking into account the nonStandAloneElements and pairs
const processItem = (item: string, output: Group[]) => {
  const newGroup: Group = { categories: [], color: "gray", isComplete: false }
  const currentGroup: Group = output[output.length - 1] || newGroup
  if (nonStandAloneElements.includes(item)) {
    currentGroup.categories.push(item)
  } else if (
    currentGroup.isComplete === true &&
    pairs[currentGroup.categories.slice(-1)[0]] !== item
  ) {
    newGroup.categories.push(item)
    newGroup.isComplete = true
  } else {
    currentGroup.categories.push(item)
    currentGroup.isComplete = true
  }
  if (newGroup.categories.length > 0) {
    output.push(newGroup)
  }
}

// Applies special rules for the elements with blue background
const processBlue = (index: number, output: Group[]): boolean => {
  const categories = output[index].categories
  let blue = false
  for (let i = 0; i < categories.length; i++) {
    if (blueElements.includes(categories[i])) {
      blue = true
      output[index].color = "blue"
      // moves nonStandAloneElements to the previous group
      if (i !== 0) {
        output[index - 1].categories = output[index - 1].categories.concat(
          categories.slice(0, i)
        )
      }
      if (i != categories.length - 1) {
        // moves nonStandAloneElements to the next group if the paired items are together
        if (
          blueElements.includes(categories[i + 1]) &&
          categories.length - i > 2
        ) {
          if (output[index + 1]) {
            output[index + 1].categories = categories
              .slice(i + 2)
              .concat(output[index + 1].categories)
            output[index].categories = categories.slice(0, i + 2)
          } else {
            // creates a new group of nonStandAloneElements if it's the last one
            const newGroup: Group = {
              categories: categories.slice(i + 2),
              color: "gray",
              isComplete: true,
            }
            output[index].categories = categories.slice(0, i + 2)
            output.splice(index + 1, 0, newGroup)
          }

          return blue
        }
        // splits into 2 groups
        if (
          categories
            .slice(i + 1)
            .filter((value) => blueElements.includes(value)).length > 0
        ) {
          if (categories.length > 2) {
            const newGroup: Group = {
              categories: categories.slice(i + 1),
              color: "gray",
              isComplete: true,
            }
            output[index].categories = categories.slice(0, i + 1)
            output.splice(index + 1, 0, newGroup)
          }
        } else {
          // moves nonStandAloneElements to the next group if it's not a pair
          if (output[index + 1]) {
            output[index + 1].categories = categories
              .slice(i + 1)
              .concat(output[index + 1].categories)
            output[index].categories = categories.slice(0, i + 1)
          } else {
            // creates a new group of nonStandAloneElements if it's the last one
            const newGroup: Group = {
              categories: categories.slice(i + 1),
              color: "gray",
              isComplete: true,
            }
            output[index].categories = categories.slice(0, i + 1)
            output.splice(index + 1, 0, newGroup)
          }
        }
      }

      return blue
    }
  }

  return blue
}

export function groupInput(
  input: string[]
): { categories: string[]; color: string }[] {
  const output: { categories: string[]; color: string }[] = []

  for (let i = 0; i < input.length; i++) {
    processItem(input[i], output)
  }

  for (let j = 1; j < output.length; j++) {
    if (processBlue(j, output)) {
      break
    }
  }

  let lastColor = "white"

  for (let i = 0; i < output.length; i++) {
    if (output[i].color !== "blue") {
      output[i].color = lastColor === "white" ? "gray" : "white"
      lastColor = output[i].color
    }

    if (output[i].categories.length > 3) {
      const newGroup: Group = {
        categories: output[i].categories.slice(
          Math.ceil(output[i].categories.length / 2)
        ),
        color: "gray",
        isComplete: true,
      }
      output[i].categories = output[i].categories.slice(
        0,
        Math.ceil(output[i].categories.length / 2)
      )
      output.splice(i + 1, 0, newGroup)
    }
  }

  return output
}
