const onEnter = (
  event: React.KeyboardEvent<HTMLDivElement>,
  action: () => void
) => {
  if (event.code === "Enter") {
    action()
  }
}

export default onEnter
