import { styled } from "@mui/material"

// allow "style" prop from HTML Image Element for inline styling
export interface CardImageProp
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src?: string
  alt?: string
}

const ProductCardImage: React.FC<React.PropsWithChildren<CardImageProp>> = ({
  src,
  alt,
}) => {
  return <CardImage src={src} alt={alt} />
}

export default ProductCardImage

export const CardImage = styled("img")`
  max-height: 85%;
  max-width: 85%;
  object-fit: fill;
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 13.14rem;
    max-height: 10.465rem;
  }
`
