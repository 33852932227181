import React, { useState } from "react"
import { FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import useIsMobileView from "../../utils/useIsMobileView"
import { FormikValue } from "./initialValues"

const DonationConfirmationCheckBox = () => {
  const theme = useTheme()
  const isMobile = useIsMobileView()
  const [checked, setChecked] = useState(false)
  const { ready, t } = useTranslation("quotePage")
  const formik = useFormikContext<FormikValue>()

  const checkBoxClick = () => {
    setChecked(!checked)
    formik.setFieldValue("checkedTC", !checked)
  }
  const pickedValue = formik.values.picked
  if (!ready) {
    return null
  }
  return (
    <div style={{ margin: isMobile ? "2rem 1.25rem" : "" }}>
      <FormGroup sx={{ marginBottom: "1rem" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={checkBoxClick}
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            />
          }
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          label={
            <div>
              <p
                style={{
                  color: theme.palette.primary.main,
                  margin: "0.625rem",
                  marginLeft: "0.125rem",
                  fontSize: !isMobile ? "0.875rem" : "0.75rem",
                  lineHeight: !isMobile ? "" : "1rem",
                }}
              >
                {!pickedValue
                  ? t("DonationConfirmationCheckBox.ifSelectedFirstButton")
                  : t("DonationConfirmationCheckBox.ifSelectedSecondButton")}
              </p>
            </div>
          }
        />
      </FormGroup>
    </div>
  )
}

export default DonationConfirmationCheckBox
