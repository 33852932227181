import { Modal, styled, Button, css, Link } from "@mui/material"
import * as Yup from "yup"
import { Close } from "@mui/icons-material"
import { Formik } from "formik"
import { SelectReasonField } from "./SelectReasonField"
import GenericField from "../../shared/form/GenericField"
import { emailValidation } from "../../../utils/yup-validation-schema/emailValidation"
import useIsMobileView from "../../../utils/useIsMobileView"
import {
  ExploreButton,
  MainText,
  TextContainerBox,
  MainContainer,
} from "../../explore-your-benefits/BenefitCardModal"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { StyledForm } from "../shared/mainPageStyles"
import { getSelectedReason, getTroubleEmailField } from "./troubleLoggingFields"
import { useHaveTroubleLoggingInMutation } from "../../../graphqGenaretedTypes"
import { Dispatch, SetStateAction } from "react"

export type CardModalProps = {
  openModal: boolean
  handleModalClose(): void
  troubleEmail: string
  setAlertMessage: Dispatch<SetStateAction<string>>
  setErrorCount: Dispatch<SetStateAction<number>>
  setAlertSeverity: Dispatch<
    SetStateAction<"error" | "success" | "warning" | "info">
  >
}
type TroubleSignInFormikForm = {
  troubleEmail: string
  selectReason: string
}

const TroubleLoggingPopup = ({
  openModal,
  handleModalClose,
  troubleEmail,
  setAlertMessage,
  setErrorCount,
  setAlertSeverity,
}: CardModalProps) => {
  const { t, ready } = useTranslation("registration")
  const isMobile = useIsMobileView()
  const navigate = useNavigate()
  const [haveTroubleLoggingInMutation] = useHaveTroubleLoggingInMutation()

  const initialValues: TroubleSignInFormikForm = {
    troubleEmail: troubleEmail,
    selectReason: "",
  }

  const validationSchema = Yup.object().shape({
    troubleEmail: emailValidation,
    selectReason: Yup.string().required(() =>
      t("registration.havingTroubleSignIn.notSelectedReasonWarning")
    ),
  })

  const handleFormSubmit = async (values: TroubleSignInFormikForm) => {
    const { data } = await haveTroubleLoggingInMutation({
      variables: {
        reason: values.selectReason,
        email: values.troubleEmail,
      },
    })

    const errors = data?.haveTroubleLoggingIn?.errors
    const info = data?.haveTroubleLoggingIn?.info

    // show error on snack bar, close the modal
    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount((prev) => prev + 1)
      handleModalClose()
      return
    }

    // show info on snack bar, close the modal
    if (info) {
      setAlertMessage(info)
      setAlertSeverity("info")
      setErrorCount((prev) => prev + 1)
      handleModalClose()
      return
    }
  }

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableScrollLock
    >
      <MainContainerBox
        id="trouble-login"
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {(formik) => {
            return (
              <StyledForm>
                <div>
                  <div style={{ alignItems: "center" }}>
                    <TextContainerBox sx={{ paddingTop: "2rem" }}>
                      <MainText id="dialog_label">
                        <span className="screen-reader-only">opened</span>
                        {t("registration.havingTroubleSignIn.havingTrouble")}
                        <span className="screen-reader-only">popUp</span>
                      </MainText>
                      <SubHeading sx={{ marginBottom: "2rem" }}>
                        {t("registration.havingTroubleSignIn.subHeader")}
                      </SubHeading>
                    </TextContainerBox>

                    <SelectReasonField
                      selectedReasonFieldProps={getSelectedReason()}
                    />
                    <GenericField textFieldProps={getTroubleEmailField()} />
                    <SubmitButton
                      tabIndex={0}
                      style={{ marginBottom: !isMobile ? "0.5rem" : "0.3rem" }}
                      onClick={formik.submitForm}
                    >
                      {t("registration.havingTroubleSignIn.Submit")}
                    </SubmitButton>
                    <TextContainerBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NotRegisteredText sx={{ textAlign: "left" }}>
                        <strong>
                          {t("registration.havingTroubleSignIn.notRegister")}
                        </strong>{" "}
                        {t("registration.havingTroubleSignIn.alreadyRegister")}{" "}
                        <TextWithLink
                          component={"button"}
                          type={"button"}
                          aria-label={t(
                            "registration.havingTroubleSignIn.createAccount"
                          )}
                          onClick={() => {
                            navigate("/users/sign_up")
                          }}
                          sx={{ fontSize: !isMobile ? "1.0625rem" : "0.75rem" }}
                        >
                          {t("registration.havingTroubleSignIn.createAccount")}
                        </TextWithLink>
                      </NotRegisteredText>
                    </TextContainerBox>
                  </div>
                </div>
                <IconContainer>
                  <Button
                    tabIndex={0}
                    sx={{ padding: "0rem" }}
                    aria-label="close"
                    onClick={() => {
                      handleModalClose()
                    }}
                  >
                    <Close
                      style={{
                        fontSize: "1.515rem",
                        color: "#33235A",
                        paddingTop: "0rem",
                      }}
                    />
                  </Button>
                </IconContainer>
              </StyledForm>
            )
          }}
        </Formik>
      </MainContainerBox>
    </Modal>
  )
}
export default TroubleLoggingPopup

export const SubmitButton = styled(ExploreButton)`
  && {
    background: linear-gradient(90deg, #0b7eb0, #35185f);
  }
`
const SubHeading = styled("p")`
  ${({ theme }) => css`
    &&& {
      font-size: 0.875rem;
      color: ${theme.palette.primary.main};
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        font-size: 1rem;
        color: ${theme.palette.primary.main};
      }
    }
  `}
`
const NotRegisteredText = styled(SubHeading)`
  ${({ theme }) => css`
    &&&& {
      font-size: 0.75rem;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        font-size: 1.0625rem;
      }
    }
  `}
`
const TextWithLink = styled(Link)`
  && {
    text-decoration: underline;
    vertical-align: top;
    font-family: Raleway;
  }
` as typeof Link

export const MainContainerBox = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      position: relative;
      width: 90%;
      margin: 0 auto;
      padding: 1.25rem 0.875rem 1.75rem 0.875rem;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        max-width: 33.5rem;
        padding: 3.75rem;
        padding-top: 1.875rem;
      }
    }
  `}
`

export const IconContainer = styled("div")`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`
