import { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import InfoTooltip from "../../shared/InfoTooltip"
import { getStorePriceTooltip } from "../../../data/constants"
import Item, { ProductType } from "../basket-page/Item"
import { useParams } from "react-router-dom"
import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"

export type OrderSummaryProps = {
  products: ProductType[]
  monthlyReduction: number
  term: number
  pricePerMonth: number
}

export default function OrderSummaryMobile({
  products,
  monthlyReduction,
  term,
  pricePerMonth,
}: OrderSummaryProps) {
  const [expanded, setExpanded] = useState(false)
  const basketItemCount = products.reduce(
    (result: number, current: ProductType) => current.quantity + result,
    0
  )
  const params = useParams()
  const theme = useTheme()
  const { quoteType } = useContext(StoreLayoutContext)
  const isNet = quoteType == "NET"
  const { t, ready } = useTranslation("checkoutPage")

  if (!ready) {
    return null
  }
  return (
    <div className="order-summary-mobile">
      <div
        className="order-summary-mobile__title-container"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="content-center">
          {t("checkoutPage.orderSummary.orderSummaryTitle")}
          <ArrowDropDownIcon
            className={classNames("expandable-box__arrow", {
              "expandable-box__arrow--expanded": expanded,
            })}
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              transitionDuration: "0.3s",
              transitionProperty: "transform",
            }}
          />
        </div>
        <div className="body-3 content-center">
          <div className="h4" style={{ color: theme.palette.secondary.main }}>
            £{pricePerMonth.toFixed(2)}
          </div>
          <div style={{ paddingLeft: "0.4rem", width: "4rem" }}>
            {t("checkoutPage.orderSummary.totalPerMonth")}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            className="expandable-box__content"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{
              type: "spring",
              bounce: 0,
              duration: 0.3,
            }}
          >
            <div className="order-summary-mobile__content border-bottom">
              {products.map((product) => (
                <Item key={product.id} product={product} editable={false} />
              ))}
              <h4>{t("checkoutPage.orderSummary.orderTotals")}</h4>
              <div className="order-summary-mobile__totals">
                <p className="body-3" style={{ width: "11rem" }}>
                  {t("checkoutPage.orderSummary.takeHomePay")} (
                  {basketItemCount + " "}
                  {basketItemCount === 1
                    ? t("checkoutPage.orderSummary.item")
                    : t("checkoutPage.orderSummary.items")}
                  ):
                </p>
                <div className="d-flex">
                  <div className="body-2" style={{ width: "6rem" }}>
                    <h3 style={{ color: theme.palette.secondary.main }}>
                      £{pricePerMonth.toFixed(2)}
                    </h3>
                    {t("checkoutPage.orderSummary.perMonthFor")} {term}{" "}
                    {t("checkoutPage.orderSummary.month")}
                  </div>
                  {params.schemeType !== "bike_shop" ? (
                    <InfoTooltip content={getStorePriceTooltip()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="body-2">
                {!isNet && params.schemeType !== "bike_shop"
                  ? t("checkoutPage.orderSummary.monthlyReduction")
                  : t("checkoutPage.orderSummary.monthlyDeduction")}
                £{monthlyReduction.toFixed(2)}{" "}
                {t("checkoutPage.orderSummary.asSeenPaySlip")}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
