import { css, Link, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
interface NotificationStripProps {
  orderCount: number
}
const NotificationStrip = ({ orderCount }: NotificationStripProps) => {
  const params = useParams()
  const { t, ready } = useTranslation("clientAdmin")
  if (!ready) {
    return null
  }
  return (
    <NotificationBar
      href={`/organisations/${params.organisationId}/orders_management`}
    >
      <div style={{ marginLeft: "0.3125rem", marginRight: "0.3125rem" }}>
        {t("clientAdmin:benefitManagement.notificationStrip.youHave")}{" "}
        <strong>
          &nbsp;
          {orderCount > 1
            ? `${orderCount} ${t(
                "clientAdmin:benefitManagement.notificationStrip.orders"
              )}`
            : `${orderCount} ${t(
                "clientAdmin:benefitManagement.notificationStrip.order"
              )}`}
        </strong>
        &nbsp;
        {t("clientAdmin:benefitManagement.notificationStrip.requireAttention")}
        <strong>
          &nbsp;
          {t(
            "clientAdmin:benefitManagement.notificationStrip.orderManagement"
          )}{" "}
          {">"}
        </strong>
      </div>
    </NotificationBar>
  )
}
export default NotificationStrip
export const NotificationBar = styled(Link)`
  ${({ theme }) => css`
    && {
      background-color: ${theme.palette.warning.main};
      text-decoration: none;
      color: black;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.125rem;
    }
  `}
`
