import SearchIcon from "@mui/icons-material/Search"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  styled,
  css,
} from "@mui/material"
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike"
import { useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"

export default function NoResults({
  searchTerm,
}: {
  searchTerm?: string | null
}) {
  const { schemeType } = useParams()
  const { t, ready } = useTranslation("noResults")

  if (!ready) {
    return null
  }

  const noResultsText = t("noResultsText", {
    returnObjects: true,
    useSuspense: false,
  }) as Array<string>

  return (
    <div>
      {searchTerm ? (
        <>
          <header>
            <Heading>{t("heading")}</Heading>
            <SearchTerm>{`'${searchTerm}'`}</SearchTerm>
          </header>

          <SearchTipsContainer sx={{ color: "primary.main" }}>
            <SearchIconContainer>
              <StyledSearchIcon />
            </SearchIconContainer>
            <h4>{t("searchTips")}</h4>
          </SearchTipsContainer>

          <List>
            {schemeType // LSS has no schemeType
              ? Object.values(noResultsText)
                  .filter((_, index) => ![2, 3, 4].includes(index))
                  .map((text, index) => {
                    return (
                      <SearchTipsListItems text={text} key={`tip-${index}`} />
                    )
                  })
              : Object.values(noResultsText).map((text, index) => {
                  return (
                    <SearchTipsListItems text={text} key={`tip-${index}`} />
                  )
                })}
          </List>

          {schemeType === "cycle_to_work" && <ContactCustomerServiceTeam />}
        </>
      ) : (
        <Heading>{t("noSearchTermHeader")}</Heading>
      )}
    </div>
  )
}

const ContactCustomerServiceTeam = () => {
  return (
    <Container>
      <BikeIcon />
      <CallToActionText>
        <Trans
          i18nKey="noResults:contactCustomerService"
          components={{
            email: (
              <CallToActionEmail href="mailto: customersupport@vivup.co.uk" />
            ),
          }}
        />
      </CallToActionText>
    </Container>
  )
}

const Container = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      margin-top: 2.5rem;
      max-width: 1082px;
    }
  `}
`

const BikeIcon = styled(DirectionsBikeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    width: 3rem;
    height: 3rem;
    padding-bottom: 1rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 4rem;
      height: 4rem;
      padding-right: 1rem;
      padding-bottom: unset;
    }
  `}
`

const CallToActionText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 1.25rem;
    line-height: 1.625rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  `}
`

const CallToActionEmail = styled("a")`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-weight: bold;
  `}
`

const SearchTipsListItems: React.FC<
  React.PropsWithChildren<{ text: string }>
> = ({ text }) => {
  return (
    <ListItem disableGutters disablePadding sx={{ alignItems: "start" }}>
      <Icon>
        <CheckIcon sx={{ color: "success.main" }} />
      </Icon>

      <Typography
        variant="body2"
        sx={{ color: "primary.main", lineHeight: "1.625rem" }}
      >
        {text}
      </Typography>
    </ListItem>
  )
}

const Heading = styled("h4")`
  margin-bottom: 1rem;
`

const SearchTerm = styled("h3")`
  margin-bottom: 2.25rem;
`
const SearchTipsContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 1.25rem;
`

const SearchIconContainer = styled("div")`
  min-width: 2rem;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 1.25rem;
`

const CheckIcon = styled(CheckCircleOutlineIcon)`
  font-size: 1.25rem;
`
const Icon = styled(ListItemIcon)`
  min-width: 2rem;
  padding-top: 0.1875rem;
`
