import { string } from "yup"
import i18n from "../../i18n"

const mobileNumberValidationRegexPattern = /^(07\d{9})$/

export const mobileNumberValidationRequired = string()
  .matches(mobileNumberValidationRegexPattern, () =>
    i18n.t("bikeQuote:validations.mobileNumber.invalidNumberWarning")
  )
  .required(() =>
    i18n.t("bikeQuote:validations.mobileNumber.emptyNumberWarning")
  )

export const mobileNumberValidation = string().matches(
  mobileNumberValidationRegexPattern,
  () => i18n.t("bikeQuote:validations.mobileNumber.invalidNumberWarning")
)
