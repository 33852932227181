import { string } from "yup"
import i18n from "../../i18n"

// const nameValidationRegex = /^([a-z]|[A-Z]|\s|'|-)$/
// Regex: https://stackoverflow.com/questions/68317971/regex-and-yup-allow-a-certain-special-character-but-it-cant-be-repeated
const nameValidationRegex = /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u

const minCharacters = 2
const maxCharacters = 64

const nameValidation = string()
  .min(minCharacters, () =>
    i18n.t("bikeQuote:validations.lastName.lessThanTwoCharactersWarning")
  )
  .max(maxCharacters, () =>
    i18n.t("bikeQuote:validations.lastName.maxCharsWarning")
  )
  .matches(nameValidationRegex, () =>
    i18n.t("bikeQuote:validations.lastName.invalidNameWarning")
  )
  .trim()

export const firstNameValidation = nameValidation.required(() =>
  i18n.t("bikeQuote:validations.lastName.emptyFirstNameWarning")
)

export const lastNameValidation = nameValidation.required(() =>
  i18n.t("bikeQuote:validations.lastName.emptyLastNameWarning")
)
