import { Box, styled, Link } from "@mui/material"
import { useParams } from "react-router-dom"
import { ButtonBase, ButtonText } from "../shared/SharedStyles"
import { gtmPortalHomepageHomeandElectronicButtonClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

export const ViewAllButton = () => {
  const params = useParams()
  const HELandingPageUrl = `/organisations/${params.organisationId}/employee/benefits/home_and_electronics/landing`

  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <ButtonContainer
      id="view-all-home-and-electronics-button-container"
      onClick={() => {
        return gtmPortalHomepageHomeandElectronicButtonClick()
      }}
    >
      <ButtonBase
        variant="gradient"
        component={Link}
        href={HELandingPageUrl}
        sx={{
          textAlign: "center",
        }}
      >
        <ButtonText variant="body2">
          {t("homeAndElectronics.viewAllButton")}
        </ButtonText>
      </ButtonBase>
    </ButtonContainer>
  )
}

const ButtonContainer = styled(Box)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`
