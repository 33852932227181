import { useEffect, useState } from "react"
import { SavingsCalculatorResult } from "../../../graphqGenaretedTypes"
import { GetSavingCalculations } from "../../store/StoreHomeContext"

export type TriggerSavingCalculationsCallArgs = {
  costOfQuote: string
  brochureGroupId: string
  employeeSalary?: string
  triggerCalculation: boolean
}

const useTriggerSavingCalculationsCall = ({
  costOfQuote,
  brochureGroupId,
  employeeSalary = "",
  triggerCalculation = false,
}: TriggerSavingCalculationsCallArgs) => {
  const [quoteType, setQuoteType] = useState("")
  const [calculatedResult, setCalculatedResult] = useState<
    SavingsCalculatorResult | undefined
  >(undefined)

  const { refetch } = GetSavingCalculations({
    costOfQuote: "",
    brochureGroupId: "",
    employeeSalary: "",
  })

  useEffect(() => {
    if (triggerCalculation && Number(costOfQuote) > 0 && brochureGroupId) {
      refetch({
        costOfQuote,
        brochureGroupId,
        employeeSalary,
      }).then((queryData) => {
        const queryQuoteType =
          queryData.data?.employeeOrganisation?.scheme?.quoteType
        if (queryQuoteType) {
          setQuoteType(queryQuoteType)
        }

        const data =
          queryData.data?.employeeOrganisation?.scheme?.savingsCalculator
            ?.calculatedResult

        if (data?.error === "false") {
          setCalculatedResult(data)
        }
      })
    }
  }, [
    costOfQuote,
    brochureGroupId,
    employeeSalary,
    refetch,
    triggerCalculation,
  ])

  return { quoteType, calculatedResult }
}

export default useTriggerSavingCalculationsCall
