export const CommentsImages = [
  {
    image: "trustPilot/Comments1.png",
  },
  {
    image: "trustPilot/Comments2.png",
  },
  {
    image: "trustPilot/Comments3.png",
  },
  {
    image: "trustPilot/Comments4.png",
  },
  {
    image: "trustPilot/Comments5.png",
  },
  {
    image: "trustPilot/Comments6.png",
  },
]
