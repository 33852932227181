import { createContext } from "react"
import { useGetRegValidationDataQuery } from "../../../graphqGenaretedTypes"

export type RegValidationContextData = {
  validationNumberType: string
  lastValidationNumber: string
}

const defaultValue: RegValidationContextData = {
  validationNumberType: "",
  lastValidationNumber: "",
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useRegValidationData: () => {
  data: RegValidationContextData
} = function () {
  const data = useGetRegValidationDataQuery({
    errorPolicy: "all",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      validationNumberType:
        data?.data?.currentUser?.employeeOrganisations &&
        data?.data?.currentUser?.employeeOrganisations[0]?.validationNumberType,
      lastValidationNumber: data.data?.currentUser?.lastValidationNumber,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const RegValidationContext = createContext(defaultValue)
