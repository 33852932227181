import { useState } from "react"
import { Link } from "../../../graphqGenaretedTypes"
import { css, styled, Box, Collapse } from "@mui/material"
import useIsMobileView from "../../../utils/useIsMobileView"
import { MoreButton } from "./MoreButton"
import { SingleLink } from "./SingleLink"
import { TransitionGroup } from "react-transition-group"

const MOBILE_DEFAULT_TO_SHOW = 3
const DESKTOP_DEFAULT_TO_SHOW = 4

type ExpandableListType = {
  links: Link[]
  moreButtonColor: string
  isExternalLink?: boolean
}

const ExpandableList = ({
  links,
  moreButtonColor,
  isExternalLink,
}: ExpandableListType) => {
  const isMobileView = useIsMobileView()
  const [isExpanded, setIsExpanded] = useState(false)

  const getLinksToShow = () => {
    return isExpanded
      ? links
      : links.slice(
          0,
          isMobileView ? MOBILE_DEFAULT_TO_SHOW : DESKTOP_DEFAULT_TO_SHOW
        )
  }

  const linksToShow = getLinksToShow()
  const shouldShowMoreButtonOnMobile = links.length > MOBILE_DEFAULT_TO_SHOW
  const shouldShowMoreButtonOnDesktop = links.length > DESKTOP_DEFAULT_TO_SHOW

  const handleClick = () => {
    setIsExpanded((prev) => !prev)
  }
  if (isMobileView) {
    return (
      <MobileExpandableList
        shouldShowMoreButtonOnMobile={shouldShowMoreButtonOnMobile}
      >
        <>
          <TransitionGroup>
            {linksToShow.map((linkData, index) => {
              return (
                <Collapse key={`link-${index}`}>
                  <SingleLink
                    linkData={linkData}
                    isExternalLink={isExternalLink}
                  />
                </Collapse>
              )
            })}
          </TransitionGroup>

          {shouldShowMoreButtonOnMobile && (
            <MoreButton
              isExpanded={isExpanded}
              moreButtonColor={moreButtonColor}
              handleClick={handleClick}
            />
          )}
        </>
      </MobileExpandableList>
    )
  }
  return (
    <DesktopExpandableList
      shouldShowMoreButtonOnDesktop={shouldShowMoreButtonOnDesktop}
    >
      <>
        <DesktopInnerContainer>
          {linksToShow.map((linkData, index) => {
            return (
              <Collapse key={`link-${index}`}>
                <SingleLink
                  linkData={linkData}
                  isExternalLink={isExternalLink}
                />
              </Collapse>
            )
          })}
        </DesktopInnerContainer>

        {shouldShowMoreButtonOnDesktop && (
          <MoreButton
            isExpanded={isExpanded}
            moreButtonColor={moreButtonColor}
            handleClick={handleClick}
          />
        )}
      </>
    </DesktopExpandableList>
  )
}

export default ExpandableList

const MobileContainer = styled(Box)`
  padding: 20px 38px;
`

const DesktopContainer = styled(Box)`
  ${({ theme }) => css`
    width: 93%;
    background-color: ${theme.palette.white.main};
    margin: 0 auto;
    border-bottom-left-radius: ${theme.boxBorderRadius};
    border-bottom-right-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    padding-bottom: 14px;
  `}
`

const DesktopInnerContainer = styled(TransitionGroup)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  padding-right: 2.15%;
  align-items: flex-end;

  & > div {
    margin-left: 2.7%;
    flex: 0 0 47%;
    max-width: 47%;
  }
`

function DesktopExpandableList({
  shouldShowMoreButtonOnDesktop,
  children,
}: {
  shouldShowMoreButtonOnDesktop: boolean
  children: JSX.Element
}) {
  return (
    <DesktopContainer
      sx={
        !shouldShowMoreButtonOnDesktop ? { paddingBottom: "28px" } : undefined
      }
      data-cy="expandable-list-desktop"
    >
      {children}
    </DesktopContainer>
  )
}

function MobileExpandableList({
  shouldShowMoreButtonOnMobile,
  children,
}: {
  shouldShowMoreButtonOnMobile: boolean
  children: JSX.Element
}) {
  return (
    <MobileContainer
      sx={!shouldShowMoreButtonOnMobile ? { paddingBottom: "30px" } : undefined}
      data-cy="expandable-list-mobile"
    >
      {children}
    </MobileContainer>
  )
}
