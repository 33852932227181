import { useGetBenefitTileDataQuery } from "../../graphqGenaretedTypes"
import { NewBenefitTileInitialValues } from "./formikUtils"

export type BenefitTileData = {
  editorContent: string
  enabled: boolean
  headline: string
  name: string
  popUpContent: string
  popUpRequired: boolean
  portalColumn: string
  portalTileImage: string
  position: number
  kind: string
  url: string
  loading: boolean
}

export const useBenefitTileData = (
  organisationId: string | undefined,
  id: string | undefined
): BenefitTileData => {
  let state = JSON.parse(JSON.stringify(NewBenefitTileInitialValues))

  const { data, loading } = useGetBenefitTileDataQuery({
    variables: {
      organisationId: organisationId ?? "",
      localBenefitId: id ?? "",
    },
    fetchPolicy: "no-cache",
  })

  if (!loading) {
    const editorContent =
      data?.employerOrganisation?.localBenefit?.content ?? ""
    const enabled = data?.employerOrganisation?.localBenefit?.enabled ?? false
    const headline = data?.employerOrganisation?.localBenefit?.headline ?? ""
    // id
    const name = data?.employerOrganisation?.localBenefit?.name ?? ""
    // organisationId
    const popUpContent =
      data?.employerOrganisation?.localBenefit?.popUpContent ?? ""
    const popUpRequired =
      data?.employerOrganisation?.localBenefit?.popUpRequired ?? false
    const portalColumn =
      data?.employerOrganisation?.localBenefit?.portalColumn ?? ""
    const portalTileImage =
      data?.employerOrganisation?.localBenefit?.portalTileImage ?? ""
    const position = data?.employerOrganisation?.localBenefit?.position ?? 1
    const kind = data?.employerOrganisation?.localBenefit?.type ?? ""
    const url = data?.employerOrganisation?.localBenefit?.url ?? ""

    state = {
      ...NewBenefitTileInitialValues,
      editorContent,
      enabled,
      headline,
      name,
      popUpContent,
      popUpRequired,
      portalColumn,
      portalTileImage,
      position,
      kind,
      url,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return state
}
