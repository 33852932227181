import { InputAdornment } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../utils/useIsMobileView"

import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../registration/sign-up/SignUp"
import GenericField from "../shared/form/GenericField"
import InfoTooltip from "../shared/InfoTooltip"
import { getBenefitUrlField } from "./fields"

export function UrlField({ translation }: { translation: string }) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation(translation)
  const textFieldProps = getBenefitUrlField(translation)

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip>
      <GenericField
        textFieldProps={textFieldProps}
        sx={
          isMobileView
            ? {
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
                marginBottom: 0,
              }
            : { marginBottom: 0 }
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                label: textFieldProps.label,
                required: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={t("benefitUrlTooltip")} />
                    </InputAdornment>
                  ),
                },
              }
            : {
                label: textFieldProps.label,
                required: true,
              }
        }
      />
      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t("benefitUrlTooltip")} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
