import { string } from "yup"
import i18n from "../../i18n"

export const lineOneValidation = string().required(() =>
  i18n.t("bikeQuote:validations.address.lineOneWarning")
)
export const lineTwoValidation = string()
export const townValidation = string().required(() =>
  i18n.t("bikeQuote:validations.address.townWarning")
)
export const countyValidation = string().required(() =>
  i18n.t("bikeQuote:validations.address.countyWarning")
)
