import { Container, Grid, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import ProductCarousel from "./ProductCarousel"
import { ProductType } from ".././product-card/ProductCard"

export type ProductCarouselProps = {
  frequentlyBrought?: string
  products: ProductType[]
  changeStateFromChild?: (totalItems: number, monthlyPrice: number) => void
  isFrequentlyBrought?: boolean
}

const productCarouselPopupGridId = "productCarouselPopupGrid"

function ProductCarouselPopupGrid({
  frequentlyBrought,
  products,
  changeStateFromChild,
  isFrequentlyBrought,
}: ProductCarouselProps) {
  const theme = useTheme()

  return (
    <>
      {products.length > 0 && (
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Container>
                <Grid
                  item
                  xs={12}
                  md={11}
                  lg={10}
                  sx={{ margin: "0 auto" }}
                  id={productCarouselPopupGridId}
                >
                  <ProductCarousel
                    isFrequentlyBrought={isFrequentlyBrought}
                    productCardCarousel={products}
                    slidesToShow={3}
                    showAddToOrder={true}
                    changeStateFromChild={(totalItems, monthlyPrice) => {
                      if (changeStateFromChild) {
                        changeStateFromChild(totalItems, monthlyPrice)
                      }
                    }}
                    productCarouselPopupGridId={productCarouselPopupGridId}
                    frequentlyBrought={frequentlyBrought}
                  />
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default ProductCarouselPopupGrid
