import { Link, css } from "@mui/material"
import { styled } from "@mui/system"
import { useParams } from "react-router-dom"
import { gtmPortalHomepageBannerSpaceClick } from "../shared/gtm-events/CustomGtmEvents"
import BannerCarousel from "../shared/banner-carousel/BannerCarousel"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

const BannerSpace = ({
  show = true,
  link,
}: {
  show?: boolean
  link?: string
}) => {
  const params = useParams()
  const { i18n } = useTranslation()
  const banner_space_desktop_image = useBuildAwsImageUrl(
    i18n.language,
    "banner_space_desktop_image.png"
  )
  const banner_space_mobile_image = useBuildAwsImageUrl(
    i18n.language,
    "banner_space_mobile_image.png"
  )
  const bannerLink =
    link ||
    `/organisations/${params.organisationId}/employee/lifestyle_savings/landing`
  if (show) {
    return (
      <div data-cy="portal-banner-space">
        <Container
          href={bannerLink}
          component="a"
          target={"_blank"}
          rel="noopener"
          onClick={() => {
            gtmPortalHomepageBannerSpaceClick()
          }}
        >
          <BannerCarousel
            desktopImageUrl={banner_space_desktop_image}
            mobileImageUrl={banner_space_mobile_image}
            showWelcomeText={false}
            link={null}
          />
          <span className="screen-reader-only">
            See our latest lifestyle savings offer (opens in a new tab)
          </span>
        </Container>
      </div>
    )
  }
}
export default BannerSpace

const Container = styled(Link)`
  ${({ theme }) => css`
    width: 93%;
    margin: 0 auto;
    display: block;
    height: 20.375rem;
    max-width: 21.0625rem;

    & > div {
      background-size: cover;
      border-radius: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 69.25rem;
      height: 17.3125rem;
    }
  `}
` as typeof Link
