import { useTranslation } from "react-i18next"
import {
  HeaderSectionPadding1,
  MainHeader,
  SubHeader,
} from "../shared/SharedStyles"

export const Header = () => {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <HeaderSectionPadding1>
      <MainHeader variant="h3" align="left">
        {t("homeAndElectronics.mainHeader")}
      </MainHeader>
      <SubHeader variant="h4" align="left">
        {t("homeAndElectronics.subHeader")}
      </SubHeader>
    </HeaderSectionPadding1>
  )
}
