import Footer from "../footer/Footer"
import Header from "../header/Header"
import { StoreLayoutContext, useStoreLayoutData } from "./StoreLayoutContext"
import {
  StoreDepartmentContext,
  useDepartmentLayoutData,
} from "./StoreDepartmentContext"
import MegaMenu from "../../shared/MegaMenu"
import { Outlet, useParams, useNavigate } from "react-router"
import { StepMenuProps } from "../../shared/step-menu/StepMenu"
import BurgerMenu from "../../shared/nav/BurgerMenu"
import { ThemeProvider } from "@mui/material"
import DefaultTheme from "../../../theme/mui-theme"
import C2WTheme from "../../../theme/mui-c2w-theme"
import { useEffect, useState } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { defaultValue as defaultStoreDepartmentValue } from "./StoreDepartmentContext"
import DummyMegaMenu from "../../shared/DummyMegaMenu"

export type globalHeaderData = {
  supplierBanner?: {
    mobile?: string
    desktop?: string
  }
  loading?: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const StoreLayout = function ({
  supplierBanner,
  setShouldShowCookiePopup,
}: globalHeaderData) {
  const contextValue = useStoreLayoutData(supplierBanner)
  const params = useParams()

  const {
    currentUserId,
    logo,
    condensedLogo,
    privacyNoticeUrl,
    organisationPrivacyNotice,
    supportLinks,
    algoliaFilters,
    loading: layoutLoading,
    dummyMegaMenu,
    footerLogo,
    invalidScheme,
    isSingleBenefit,
    backendUser,
    employeeOrganisations,
  } = contextValue

  const [shouldFetchDepartmentHierarchy, setShouldFetchDepartmentHierarchy] =
    useState(false)

  const contextDepartmentValue = useDepartmentLayoutData(
    shouldFetchDepartmentHierarchy
  )

  const {
    departments,
    departmentHierarchy,
    customNavigations,
    loading: departmentLoading,
  } = contextDepartmentValue

  const { t, ready } = useTranslation("layout")

  const dummyBurgerMenuHierarchy = dummyMegaMenu.map(({ label }) => ({
    key: label,
    name: label,
    link: "#",
  }))

  const stepMenuProps: StepMenuProps = {
    title: t("menu.browseByDepartment"),
    itemHierarchy:
      !shouldFetchDepartmentHierarchy || departmentLoading
        ? dummyBurgerMenuHierarchy
        : departmentHierarchy ?? [],
    levels: !shouldFetchDepartmentHierarchy || departmentLoading ? 1 : 3,
  }

  useEffect(() => {
    if (!layoutLoading) {
      setShouldFetchDepartmentHierarchy(true)
    }
  }, [layoutLoading])

  // state for algolia widget on H&E and C2W
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)

  useEffect(() => {
    const url = location.href
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    let sanitisedSchemeType = params.schemeType?.replaceAll("_", " ") || ""
    sanitisedSchemeType =
      sanitisedSchemeType.charAt(0).toUpperCase() + sanitisedSchemeType.slice(1)
    document.title = `${subdomain}: ${sanitisedSchemeType}`
  }, [params.schemeType])

  const isMobile = useIsMobileView()

  // disable scroll when desktop algolia search widget is shown, mobile view is scrollable
  useEffect(() => {
    const preventScroll = (e: Event) => {
      if (isWidgetOpen && !isMobile) {
        e.preventDefault()
      }
    }
    document.addEventListener("wheel", preventScroll, { passive: false })
    document.addEventListener("touchmove", preventScroll, { passive: false })

    return () => {
      document.removeEventListener("wheel", preventScroll)
      document.removeEventListener("touchmove", preventScroll)
    }
  }, [isWidgetOpen, isMobile])

  const navigate = useNavigate()

  // Route the user to HE landing page if access something other than HE
  // applies to every route within StoreLayout
  if (
    isSingleBenefit &&
    params.schemeType !== "home_and_electronics" // hardcode HE, match partnerJourney benefit when available
  ) {
    const url = `/organisations/${params.organisationId}/employee/benefits/home_and_electronics/landing`
    navigate(url)
  }

  if (!ready) {
    return null
  }
  return (
    <ThemeProvider
      theme={
        ["cycle_to_work", "bike_shop"].includes(params.schemeType || "")
          ? C2WTheme
          : DefaultTheme
      }
    >
      <StoreDepartmentContext.Provider
        value={
          departmentLoading
            ? defaultStoreDepartmentValue
            : contextDepartmentValue
        }
      >
        <StoreLayoutContext.Provider value={contextValue}>
          {currentUserId && (
            <>
              <Header
                logo={logo}
                condensedLogo={condensedLogo}
                currentUserId={currentUserId}
                showBasket={invalidScheme ? false : true}
                MegaMenu={
                  !invalidScheme && !departmentLoading && departments ? (
                    <MegaMenu
                      departments={departments}
                      customNavigations={customNavigations}
                      isWidgetOpen={isWidgetOpen}
                    />
                  ) : !invalidScheme ? (
                    <DummyMegaMenu
                      dummyMegaMenu={dummyMegaMenu}
                      isTranslationKey={false}
                    />
                  ) : undefined
                }
                BurgerMenu={
                  !invalidScheme ? (
                    <BurgerMenu
                      stepMenuProps={stepMenuProps}
                      supportLinks={supportLinks}
                    />
                  ) : undefined
                }
                showNoticeboard={[]}
                backendUser={backendUser}
                isOrganisationSettingUser={false}
                algoliaFilters={algoliaFilters}
                isWidgetOpen={isWidgetOpen}
                setIsWidgetOpen={setIsWidgetOpen}
                availableLanguages={contextValue.availableLanguages}
                defaultLanguage={contextValue.defaultLanguage}
                isSingleBenefit={isSingleBenefit}
                invalidScheme={invalidScheme}
                employeeOrganisations={employeeOrganisations}
              />
              <div
                className={
                  invalidScheme
                    ? "store-main-container-invalid-scheme"
                    : "store-main-container"
                }
                style={isWidgetOpen ? { overflow: "hidden" } : undefined}
                id="main-content"
              >
                <Outlet />
              </div>
              <Footer
                organisationPrivacyNotice={organisationPrivacyNotice}
                privacyNoticeUrl={privacyNoticeUrl}
                setShouldShowCookiePopup={setShouldShowCookiePopup}
                logo={footerLogo}
                singleBenefit={isSingleBenefit}
              />
            </>
          )}
        </StoreLayoutContext.Provider>
      </StoreDepartmentContext.Provider>
    </ThemeProvider>
  )
}
