import { date } from "yup"
import i18n from "../../i18n"

const invalidWarning = () =>
  i18n.t("bikeQuote:validations.dateOfBirth.emptyDateWarning")

export const dateOfBirthValidation = date()
  .required(invalidWarning)
  .typeError(invalidWarning)
  .min("1900-01-01", () =>
    i18n.t("bikeQuote:validations.dateOfBirth.invalidWarning")
  )
  .max(new Date(), () =>
    i18n.t("bikeQuote:validations.dateOfBirth.valueWarning")
  )
