import { string } from "yup"
import i18n from "../../i18n"

export const bikeTitleValidation = string().required(() =>
  i18n.t("bikeQuote:validations.titleEmptyWarning")
)

export const benefitTitleValidation = string().required(() =>
  i18n.t("newBenefitTile:emptyTitleWarning")
)

export const noticeboardTitleValidation = string().required(() =>
  i18n.t("newNoticeboardTile:emptyTitleWarning")
)
export const familyCareTitleValidation = string().required(() =>
  i18n.t("newFamilyCare:emptyTitleWarning")
)
export const wellbeingTitleValidation = string().required(() =>
  i18n.t("newWellbeingItem:emptyTitleWarning")
)

export const titleValidation = (translation: string) =>
  string().required(() => i18n.t(`${translation}:emptyTitleWarning`))
export const familyPayTitleValidation = string().required(() =>
  i18n.t("newFamilyPay:emptyTitleWarning")
)
