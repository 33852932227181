import { styled, Box, Grid, Typography, css } from "@mui/material"
import { ExploreButton } from "../../../lifestyle-savings/shared-styles"
import { useParams } from "react-router-dom"
import { gtmCycleToWorkBenefitWorksNavigationClick } from "../../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"
const DecathlonSection = ({
  bikeDepartmentId,
  isBikeShop,
}: {
  bikeDepartmentId?: number
  isBikeShop?: boolean
}) => {
  const params = useParams()
  const event_action = "benefits Navigation"

  const { t, i18n, ready } = useTranslation("cycleToWork")
  const decathlonLogo = useBuildAwsImageUrl(i18n.language, "decathlonLogo.png")
  const cycleSolutionsLogo = useBuildAwsImageUrl(
    i18n.language,
    "cycleSolutionsLogo.png"
  )

  if (!ready) {
    return null
  }
  return (
    <MainBox>
      <ContentContainer>
        <MainGrid container>
          <Grid item xs={12} md={7.5}>
            <ImageContainer
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "1.5rem",
                }}
              >
                <div>
                  <DecathlonLogo src={decathlonLogo} alt={"DecathlonLogo"} />
                </div>
                <div>
                  <CycleSolutionLogo
                    src={cycleSolutionsLogo}
                    alt={"cycleSolutionsLogo"}
                  />
                </div>
              </div>
              <OnlineOnlyLogo>
                {t("landing.decathlon.onlineOnly")}
              </OnlineOnlyLogo>
            </ImageContainer>
          </Grid>
          <ContentGrid item xs={12} md={4.5}>
            <TextContainer>
              <TextTitle>{t("landing.decathlon.title")}</TextTitle>
              <ParagraphText>{t("landing.decathlon.paragraph")}</ParagraphText>
              <ButtonContainer>
                <Button
                  href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${bikeDepartmentId}`}
                  onClick={() => {
                    !isBikeShop &&
                      gtmCycleToWorkBenefitWorksNavigationClick(event_action)
                  }}
                >
                  {t("landing.decathlon.browseBikes")}
                </Button>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
        </MainGrid>
      </ContentContainer>
    </MainBox>
  )
}
export default DecathlonSection
export const MainBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.palette.white.main};
    height: 400px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-bottom: 2rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 450px;
      margin-bottom: 1.9rem;
      text-align: center;
    }
  `}
`

export const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 1043px;
    width: 100%;
    height: 342px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 503px;
    }
  `}
`
export const MainGrid = styled(Grid)`
  max-width: 1043px;
  height: 342px;
  width: 100%;
`
export const ImageContainer = styled("div")`
  ${({ theme }) => css`
    max-width: 570px;
    width: 100%;
    height: 342px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 3px 6px 0px #00000029;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 326px;
      width: 100%;
      height: 196px;
      margin: auto; // Center horizontally
      display: flex;
      flex-direction: column;
      justify-content: center; // Center vertically
    }
  `}
`
export const TextContainer = styled(Grid)`
  ${({ theme }) => css`
    max-width: 439px;
    width: 100%;
    height: 342px;
    color: white;
    display: flex;
    justify-content: center;
    opacity: 1;
    flex-direction: column;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;

      height: 241px;
    }
  `}
`
export const TextTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.primary.main};
    font-weight: bold;
    margin-bottom: 15px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 32px;
    }
  `}
`
export const ParagraphText = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.palette.primary.main};
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
    }
  `}
`
export const Button = styled(ExploreButton)`
  ${({ theme }) => css`
    && {
      display: flex;
      background: transparent
        linear-gradient(90deg, var(--tertiary) 0%, var(--primary) 100%) 0% 0%
        no-repeat padding-box;
      background: transparent
        linear-gradient(90deg, #92237f 0%, ${theme.palette.primary.main} 100%)
        0% 0% no-repeat padding-box;
      color: ${theme.palette.white.main};
      margin: 0rem;
      max-width: 246px;
      width: 100%;
      height: 50px;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 220px;
        width: 100%;
        text-align: center;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  `}
`
export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 40px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 15px;
    }
  `}
`
export const ContentGrid = styled(Grid)`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
    }
  `}
`
export const DecathlonLogo = styled("img")`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 220px;
      height: 40px;
      object-fit: contain;
      margin-bottom: 1rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 342px;
      height: 85px;
      object-fit: contain;
      margin-top: 1.5rem;
    }
  `}
`
export const CycleSolutionLogo = styled("img")`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 250px;
      height: 50px;
      margin-bottom: 1.3rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 423px;
      height: 70px;
      margin-top: 1.5rem;
    }
  `}
`
export const OnlineOnlyLogo = styled("div")`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background-color: #35185f;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: ${theme.palette.white.main};
  `}
`
