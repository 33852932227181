import {
  Box,
  Typography,
  css,
  styled,
  Button as MuiButton,
} from "@mui/material"
import { ButtonBase, colourBox } from "../SharedStyles"

export const MORE_BUTTON_COLOR = "#6A6BA8"

export const PaddingOuterGrid = styled(colourBox)`
  ${({ theme }) => css`
    background: rgba(112, 113, 173, 0.1);
    display: grid;
    place-items: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      place-items: unset;
    }
  `}
`

export const FamilyPayOuterGrid = styled(PaddingOuterGrid)`
  background: rgba(241, 241, 247, 1);
`

export const PaddingContentGrid = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    grid-auto-rows: min-content;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: 54px; // to align with Heading on the left box
    }
  `}
`
export const FamilyCareLogo = styled("img")`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto;
    margin-bottom: 18px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 34px;
    }
  `}
`
export const FamilyCareLogos = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 26px;
`
export const SupportType = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 8px;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin: 0 16px;
    }
  `}
`
export const Icon = styled("img")`
  ${({ theme }) => css`
    height: 54.26px;
    align-self: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 67.46px;
    }
  `}
`
export const IconText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 12px;
    line-height: 12px; // different to figma account for small viewport
    text-align: center;
    margin-top: 8px;
    font-weight: bold;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
      line-height: 14px;
    }
  `}
`

export const PurpleButtonContainer = styled(ButtonBase)`
  ${({ theme }) => css`
    background: rgba(106, 107, 168, 1);
    display: inline-flex;

    &:hover {
      background: rgba(106, 107, 168, 1);
    }

    &:disabled {
      background-color: ${theme.palette.grey[200]};
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0;
    }
  `}
` as typeof MuiButton

export const FamilyPayButtonContainer = styled(PurpleButtonContainer)`
  background: rgba(112, 113, 173, 1);

  &:hover {
    background: rgba(112, 113, 173, 1);
  }
` as typeof MuiButton
