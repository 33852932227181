import { Button, styled } from "@mui/material"
import { useParams } from "react-router-dom"
import { gtmPortalHomepageExploreCycleToWorkButtonClick } from "../../../shared/gtm-events/CustomGtmEvents"
import {
  ButtonBase,
  ButtonText,
  SpaceOneButtonContainer,
} from "../../shared/SharedStyles"
import { useTranslation } from "react-i18next"

export function ExploreC2WButton() {
  const params = useParams()
  const C2WUrl = `/organisations/${params.organisationId}/employee/benefits/cycle_to_work/landing`
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <SpaceOneButtonContainer
      id="exploreC2WButton"
      onClick={() => {
        gtmPortalHomepageExploreCycleToWorkButtonClick()
      }}
    >
      <C2WButtonBase component={"a"} href={C2WUrl}>
        <WhiteText variant="body2">{t("cycleToWork.exploreButton")}</WhiteText>
      </C2WButtonBase>
    </SpaceOneButtonContainer>
  )
}
const C2WButtonBase = styled(ButtonBase)`
  background: linear-gradient(
    90deg,
    #763679 0%,
    ${({ theme }) => theme.palette.primary.main}
  );
  text-align: center;

  &:hover {
    /* not sure if this is the correct effect? */
    opacity: 75%;
  }
` as typeof Button

const WhiteText = styled(ButtonText)`
  color: ${({ theme }) => theme.palette.white.main};
`
