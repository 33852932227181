import { Box, css, styled } from "@mui/material"
import Slider from "react-slick"
import { SubTitleHeading } from "./shared/headingStyles"

function repeatArray<T>(array: T[], times: number): T[] {
  if (times < 1) {
    return []
  }
  let result: T[] = []
  for (let i = 0; i < times; i++) {
    result = result.concat(array)
  }
  return result
}

type LoggedOutBannerType = {
  bannerHeading: string
  images: Array<string>
}

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  // speed: 2000,
  autoplaySpeed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  variableWidth: true,
  centerMode: false,
  pauseOnHover: false,
  swipe: false,
}

const LoggedOutBanner = ({
  bannerHeading,
  images: inputImages,
}: LoggedOutBannerType) => {
  // make sure images array is long enough, so no blank slide problem
  // https://github.com/kenwheeler/slick/issues/3841
  const images = repeatArray(inputImages, 4)

  return (
    <BannerContainer>
      <BannerHeading>{bannerHeading}</BannerHeading>
      <StyledSlider {...settings}>
        {images.map((image, index) => (
          <ImageWrapper key={`LoggedOutBannerImg-${index}`}>
            <img src={image} alt={`LoggedOutBannerImg-${index}`} />
          </ImageWrapper>
        ))}
      </StyledSlider>
    </BannerContainer>
  )
}

export default LoggedOutBanner

const BannerContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 64.375rem;
    background-color: ${theme.palette.white.main};
    /* height: 10rem; */
    width: 86.4%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: ${theme.boxBorderRadius};
    padding: 1rem;
  `}
`

const ImageWrapper = styled(Box)`
  height: 10rem;
  display: flex !important;
  flex-wrap: wrap;
  align-content: center;
`

// add space in between: https://github.com/kenwheeler/slick/issues/582
const StyledSlider = styled(Slider)`
  .slick-slide {
    padding: 0 0.5rem;
    box-sizing: border-box;
  }
`

const BannerHeading = styled(SubTitleHeading)`
  ${({ theme }) => css`
    font-weight: 700;
    max-width: 90%;
    margin-top: 1.125rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 0.75rem;
      margin-bottom: 1.125rem;
    }
  `}
`
