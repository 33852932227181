import { createContext } from "react"
import { useGetRegistrationLayoutDataQuery } from "../../graphqGenaretedTypes"

export type RegistrationLayoutContextData = {
  leftLogo?: string
  rightLogo?: string
  logo?: string
  billboardImage?: string
  content?: string
  header?: string
  stopRegistration: boolean
  showBanner?: boolean
  banner?: {
    heading: string
    images: string[]
    show: boolean
  }
  loading: boolean
}

const defaultValue: RegistrationLayoutContextData = {
  leftLogo: undefined,
  rightLogo: undefined,
  logo: undefined,
  billboardImage: undefined,
  content: undefined,
  header: undefined,
  stopRegistration: false,
  showBanner: undefined,
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useRegistrationLayoutData: () => {
  data: RegistrationLayoutContextData
} = function () {
  const url = location.href
  const subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
  const data = useGetRegistrationLayoutDataQuery({
    variables: {
      subdomain: subdomain,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      leftLogo: data.data?.portalProvider?.whitelabelLeftLogo,
      rightLogo: data.data?.portalProvider?.whitelabelRightLogo,
      logo: data.data?.portalProvider?.whitelabelLogo,
      billboardImage: data.data?.portalProvider?.billboardImage,
      content: data.data?.portalProvider?.content,
      header: data.data?.portalProvider?.contentHeader,
      stopRegistration: data.data?.portalProvider?.stopRegistration || false,
      showBanner: data.data?.portalProvider?.showLoggedOutBanner,
      loading: false,
      banner:
        data.data?.loggedOutBanner?.images?.length || 0 > 0
          ? {
              heading: data.data?.loggedOutBanner?.heading,
              images: data.data?.loggedOutBanner?.images,
              show: data.data?.loggedOutBanner?.show || true,
            }
          : undefined,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const RegistrationLayoutContext = createContext(defaultValue)
