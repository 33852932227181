/* eslint-disable no-undef */
const domain =
  document.location.hostname.indexOf("vivup.co.uk") > -1
    ? "vivup.co.uk"
    : (document.location.hostname.match(/\./g) || []).length > 1
    ? document.location.hostname.substring(
        document.location.hostname.indexOf(".") + 1
      )
    : document.location.hostname

const cookieStorage = {
  getItem: (item) => {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {})
    return cookies[item]
  },
  setItem: (item, value) => {
    document.cookie = `${item}=${value};domain=${domain}`
  },
}
/* eslint-enable no-undef */

export default cookieStorage
