import { InputAdornment, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../utils/useIsMobileView"
import { FormikProps, useFormikContext } from "formik"
import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../registration/sign-up/SignUp"
import GenericSelect from "../shared/form/GenericSelect"
import InfoTooltip from "../shared/InfoTooltip"
import { getPositionField } from "./fields"
import { NewBenefitTileValue } from "./formikUtils"
import { useEffect, useMemo } from "react"
import { NewLocalDiscountValue } from "./local-discounts/formikUtils"

export function PositionDropDown({
  formikValue,
  currentPosition,
  totalCards,
  infoToolTip,
  translation,
}: {
  formikValue:
    | FormikProps<NewBenefitTileValue>
    | FormikProps<NewLocalDiscountValue>
  currentPosition: number
  totalCards: number
  infoToolTip: string
  translation: string
}) {
  const isMobileView = useIsMobileView()
  const { setFieldValue } = useFormikContext()
  const { ready } = useTranslation(translation)
  const formik = formikValue

  const selectBenefitOption = useMemo(() => currentPosition, [currentPosition])
  const TotalNumberofCards = Array.from(
    { length: totalCards },
    (_, index) => index + 1
  )

  const defaultSelect = formik.values as NewLocalDiscountValue

  useEffect(() => {
    if (!defaultSelect.position) {
      const defaultValue = selectBenefitOption

      setFieldValue("position", defaultValue)
    }
  }, [defaultSelect.position, setFieldValue, selectBenefitOption])
  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip sx={{ width: { xs: "100%", sm: "20%" } }}>
      <GenericSelect
        props={getPositionField(translation)}
        sx={
          isMobileView
            ? {
                "& .MuiSelect-icon": {
                  position: "relative",
                  right: 0,
                  cursor: "pointer",
                },
                "& .MuiInputBase-root": { paddingRight: 0 },
              }
            : undefined
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                required: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={infoToolTip} />
                    </InputAdornment>
                  ),
                },
              }
            : { required: true }
        }
      >
        {TotalNumberofCards.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </GenericSelect>
      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={infoToolTip} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
