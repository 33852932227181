const commonPasswords = {
  "123456": true,
  admin: true,
  "12345678": true,
  "123456789": true,
  "1234": true,
  "12345": true,
  password: true,
  "123": true,
  Aa123456: true,
  "1234567890": true,
  UNKNOWN: true,
  "1234567": true,
  "123123": true,
  "111111": true,
  "12345678910": true,
  "0": true,
  admin123: true,
  "********": true,
  user: true,
  "1111": true,
  "P@ssw0rd": true,
  root: true,
  "654321": true,
  qwerty: true,
  "Pass@123": true,
  "******": true,
  "112233": true,
  "102030": true,
  ubnt: true,
  abc123: true,
  "Aa@123456": true,
  abcd1234: true,
  "1q2w3e4r": true,
  "123321": true,
  err: true,
  qwertyuiop: true,
  "87654321": true,
  "987654321": true,
  Eliska81: true,
  "123123123": true,
  "11223344": true,
  demo: true,
  "12341234": true,
  qwerty123: true,
  "Admin@123": true,
  "1q2w3e4r5t": true,
  "11111111": true,
  pass: true,
  "Demo@123": true,
  "**********": true,
  azerty: true,
  admintelecom: true,
  "123meklozed": true,
  "666666": true,
  "121212": true,
  "1234qwer": true,
  "1qaz2wsx": true,
  "*************": true,
  "123456789a": true,
  Aa112233: true,
  asdfghjkl: true,
  Password1: true,
  "888888": true,
  admin1: true,
  test: true,
  "Aa123456@": true,
  asd123: true,
  qwer1234: true,
  "123qwe": true,
  "202020": true,
  asdf1234: true,
  "Abcd@1234": true,
  banned: true,
  "12344321": true,
  "1122334455": true,
  guest: true,
  "88888888": true,
  secret: true,
  "1122": true,
  admin1234: true,
  administrator: true,
  "Password@123": true,
  q1w2e3r4: true,
  "10203040": true,
  a123456: true,
  "12345678a": true,
  "555555": true,
  zxcvbnm: true,
  welcome: true,
  "Abcd@123": true,
  "Welcome@123": true,
  minecraft: true,
}

export default commonPasswords
