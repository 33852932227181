import { Box, styled, css } from "@mui/material"
import {
  SharedTopRightChip,
  OnePropositionTopRightChip,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

export function Chip({ onlyCarBenefit }: { onlyCarBenefit: boolean }) {
  const isMobile = useIsMobileView()

  if (onlyCarBenefit && !isMobile) {
    return (
      <OnePropositonChip>
        <Content />
      </OnePropositonChip>
    )
  }
  return (
    <TwoPropositionsChip>
      <Content />
    </TwoPropositionsChip>
  )
}

function Content() {
  const { t, ready } = useTranslation("portalHomepage")
  const { i18n } = useTranslation()

  const tuskerLogo = useBuildAwsImageUrl(i18n.language, "tusker_logo.png")

  if (!ready) {
    return null
  }
  return (
    <LogoContainer>
      <TuskerLogo
        src={tuskerLogo}
        alt={t("carBenefit.tuskerLogoAltText")}
      ></TuskerLogo>
    </LogoContainer>
  )
}

const TwoPropositionsChip = styled(SharedTopRightChip)`
  ${({ theme }) => css`
    background: linear-gradient(
      114deg,
      #32a7db 0%,
      ${theme.palette.primary.main}
    );
  `}
`

const OnePropositonChip = styled(OnePropositionTopRightChip)`
  ${({ theme }) => css`
    background: linear-gradient(
      114deg,
      #32a7db 0%,
      ${theme.palette.primary.main}
    );
  `}
`

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const TuskerLogo = styled("img")`
  height: 38%;
`
