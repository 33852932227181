import { Button, styled, css } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { useRegisterInterestMutation } from "../../graphqGenaretedTypes"
import Alert from "../shared/Alert"
import { gtmPortalHomepageExploreBenefitRequestButtonClick } from "../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

const RequestToOpenButton = ({
  relativeUrl,
  benefitName,
}: {
  relativeUrl: string
  benefitName: string
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const { t, ready } = useTranslation("portalHomepage")

  const { organisationId } = useParams()

  const [isClicked, setIsClicked] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [registerInterestMutation] = useRegisterInterestMutation({
    variables: {
      schemeTypeRelativeUrl: relativeUrl,
      organisationId: organisationId ? organisationId : "",
    },
  })

  const handleClick = () => {
    registerInterestMutation().then(({ data }) => {
      if (
        data?.registerInterest?.errors &&
        data?.registerInterest?.errors.length > 0
      ) {
        setMessage(t("exploreYourBenefit.requestToOpen.unableToProcess"))
        setIsSuccess(false)
      } else {
        setMessage(
          t("exploreYourBenefit.requestToOpen.thankYou", {
            condition: benefitName ? ` in ${benefitName}` : "",
          })
        )
        setIsSuccess(true)
      }
    })
    setIsClicked(true)
    gtmPortalHomepageExploreBenefitRequestButtonClick(benefitName)

    return
  }

  if (!ready) {
    return null
  }

  return (
    <>
      {message !== "" && (
        <Alert
          severity={!isSuccess ? "error" : "success"}
          message={message || ""}
        />
      )}
      <ExploreButton
        onClick={handleClick}
        disabled={isClicked}
        style={{ marginBottom: isDesktop ? "0.5rem" : "0.3rem" }}
      >
        {t("exploreYourBenefit.requestToOpen.buttonText")}
      </ExploreButton>
    </>
  )
}
export default RequestToOpenButton
export const ExploreButton = styled(Button)`
  ${({ theme }) => css`
    && {
      display: block;
      width: 17.375rem;
      height: 3.125rem;
      margin: 2rem auto;
      margin-top: 1rem;
      margin-bottom: 2.188rem;
      color: ${theme.palette.white.main};
      font-size: 1rem;
      line-height: 1.125rem;
      text-align: center;
      border-radius: 2.625rem;
      box-shadow: ${theme.boxShadow};
      opacity: 1;
      background: transparent linear-gradient(90deg, #772a73 0%, #35185f 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 15.625rem;
        height: 2.875rem;
        font-size: 0.875rem;
      }
      &:hover,
      &:disabled {
        opacity: 75%;
        color: #fff;
      }
    }
  `}
`
