import { useEffect, useState } from "react"
import { Supplier } from "../../../../graphqGenaretedTypes"
import { GetSchemeSuppliersData } from "../SchemSupplierContext"

const useGetSuppliersCall = (searchTerm: string) => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([])

  const { refetch } = GetSchemeSuppliersData({
    searchTerm: "",
  })

  useEffect(() => {
    if (searchTerm !== "") {
      refetch({
        searchTerm,
      }).then((queryData) => {
        const querySuppliers =
          queryData.data?.employeeOrganisation?.scheme?.schemeSuppliers

        if (querySuppliers) {
          setSuppliers(querySuppliers)
        }
      })
    }
  }, [searchTerm, refetch])

  if (searchTerm !== "") {
    return { suppliers }
  }

  return { suppliers: [] }
}

export default useGetSuppliersCall
