import { string } from "yup"
import i18n from "../../i18n"

const postCodeRegex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/

export const postCodeValidation = string()
  .required(() => i18n.t("bikeQuote:validations.postCode.invalidWarning"))
  .matches(postCodeRegex, () =>
    i18n.t("bikeQuote:validations.postCode.emptyWarning")
  )
  .uppercase()
