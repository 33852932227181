import { useState } from "react"
import DrawerItem from "../drawer/DrawerItem"
import AffordabilityModal from "../affordability-calculator/Modal"
import onEnter from "../../../utils/onEnter"
import { useTranslation } from "react-i18next"

export type AffordabilityCalculatorLinkProps = {
  setDrawerOpen?: (state: boolean) => void
}

export default function AffordabilityCalculatorLink({
  setDrawerOpen,
}: AffordabilityCalculatorLinkProps) {
  const { t, ready } = useTranslation("layout")
  const [open, setOpen] = useState(false)

  if (!ready) {
    return null
  }
  return (
    <div>
      <DrawerItem
        text={t("menu.affordabilityCalculator")}
        divider
        onClick={() => setOpen(true)}
        onKeyDown={(event) => onEnter(event, () => setOpen(true))}
      />

      <AffordabilityModal
        open={open}
        setOpen={setOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </div>
  )
}
