import { styled, css } from "@mui/material"
import { WaveSvgCtw } from "../shared/wave-svg/WaveSvgCtw"
import {
  MainBox,
  WaveBackgroundContainer,
  ContentContainer,
  ImageGrid,
  MainGrid,
  ImageContainer,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  ButtonContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import { ButtonStyle } from "./Howbenifitworks"
import { FamilyCareProps } from "./FamilyCare"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"
const FamilyCareFaq: React.FC<FamilyCareProps> = ({ link }) => {
  const childrenPlaying = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenPlaying.png"
  )
  return (
    <MainBox>
      <WaveBackgroundContainer>
        <WaveSvgCtw />
        <ContentContainer>
          <MainGrid container>
            <ContentGrid item xs={12} md={5}>
              <TextContainer>
                <TextTitle>FAQs</TextTitle>
                <ParagraphText>
                  Find the answers to all your Family Care questions via our
                  handy FAQ page
                </ParagraphText>

                <ButtonContainer>
                  <Button
                    variant="gradient"
                    href={link}
                    component={"a"}
                    target="_blank"
                    aria-label="View Family Care FAQs"
                    rel="noopener"
                  >
                    View our FAQs
                  </Button>
                </ButtonContainer>
              </TextContainer>
            </ContentGrid>
            <ImageGrid item xs={12} md={7}>
              <ImageContainer
                src={childrenPlaying}
                alt="ChildrenPlayingImage"
              />
            </ImageGrid>
          </MainGrid>
        </ContentContainer>
      </WaveBackgroundContainer>
      <span className="screen-reader-only">
        View Family Care FAQs (opens in a new tab)
      </span>
    </MainBox>
  )
}
export default FamilyCareFaq

export const Button = styled(ButtonStyle)`
  ${({ theme }) => css`
    && {
      color: ${theme.palette.white.main};
    }
  `}
`
