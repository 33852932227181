import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import {
  SIGN_IN_PATH,
  FOUR_OH_FOR_PATH,
  BENEFIT_LANDING_PAGE_WITH_SALARY_BRACKET,
  EXPIRED_PASSWORD_PATH,
} from "../data/constants"
import fetch from "cross-fetch"

type EnsureSalaryBracketPresent =
  | "storeHome"
  | "store"
  | "product"
  | "department"
  | "basket"

interface SalaryBracketState {
  page: EnsureSalaryBracketPresent | null
  isSalaryBracketModalOpen: boolean | null
}

let salaryBracketState: SalaryBracketState = {
  page: null,
  isSalaryBracketModalOpen: null,
}

export const updateSalaryBracketModalState = (
  newPage: EnsureSalaryBracketPresent,
  newIsSalaryBracketModalOpen: boolean | null = null
) => {
  salaryBracketState = {
    page: newPage,
    isSalaryBracketModalOpen: newIsSalaryBracketModalOpen,
  }
}

const getSalaryBracketModalState = (): SalaryBracketState => ({
  ...salaryBracketState,
})
const CSRFToken = function (): string {
  const tag =
    document.head &&
    document.head.querySelector("meta[name=csrf-token][content]")

  return (tag && tag.getAttribute("content")) || ""
}

export function initApolloClient(
  handleError: (errorMessage: string | null) => void
) {
  const batchHttpLink = new BatchHttpLink({
    uri: "/graphql",
    batchMax: 5,
    batchInterval: 20,
    headers: {
      "X-CSRF-Token": CSRFToken(),
    },
    fetch,
  })

  const uploadLink = createUploadLink({
    uri: "/graphql",
    headers: {
      "X-CSRF-Token": CSRFToken(),
    },
    fetch,
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (message.startsWith("UNAUTHENTICATED:")) {
          window.location.assign(SIGN_IN_PATH)
        } else if (message.startsWith("NO_SALARY_BRACKET")) {
          const params = JSON.parse(message.slice(message.indexOf("{")))
          const { page, isSalaryBracketModalOpen } =
            getSalaryBracketModalState()

          // if page is not null, meaning updateSalaryBracketModalState() is called
          if (page && !isSalaryBracketModalOpen) {
            window.location.assign(
              BENEFIT_LANDING_PAGE_WITH_SALARY_BRACKET(
                params.organisationId,
                params.schemeType
              )
            )
          }
        } else if (message.startsWith("RECORD_NOT_FOUND")) {
          window.location.assign(FOUR_OH_FOR_PATH)
        } else if (message.indexOf("PASSWORD_CHANGED_REQUIRED") !== -1) {
          window.location.assign(EXPIRED_PASSWORD_PATH)
        } else {
          handleError(
            "An error has occurred - if the problem persists please contact our\nsupport team on customersupport@vivup.co.uk for further assistance"
          )
        }
      })
    } else if (networkError) {
      if (
        networkError.statusCode === 403 &&
        networkError.result.indexOf("Cloudflare Ray ID") !== -1
      ) {
        handleError(
          "The action you made was blocked by Cloudflare - please contact our support team at customersupport@vivup.co.uk for further assistance. In order for us to help you, please make a note of the full website URL currently showing at the top of the page & the last action you performed before the error."
        )
      } else {
        const message = networkError.result ?? ""
        if (message.startsWith("https://") || message.startsWith("http://")) {
          window.location = message
        } else if (message.startsWith("TIMEOUT")) {
          window.location.assign(SIGN_IN_PATH)
        } else {
          handleError("Network error.")
        }
      }
    }
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, uploadLink, batchHttpLink]),
  })
}
