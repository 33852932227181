import { string } from "yup"
import i18n from "../../i18n"

const phoneNumberValidationRegexPattern = /^0\d{9}\d?$/

export const phoneNumberValidation = string()
  .matches(phoneNumberValidationRegexPattern, () =>
    i18n.t("bikeQuote:validations.phoneNumber.invalidNumberWarning")
  )
  .required(() =>
    i18n.t("bikeQuote:validations.phoneNumber.emptyNumberWarning")
  )
