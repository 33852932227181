import { createContext } from "react"
import { useParams } from "react-router"
import { RoundelProps } from "../../shared/Roundel"
import { BreadcrumbsType } from "../../shared/CustomBreadcrumbs"
import {
  GetDepartmentDataQueryHookResult,
  useGetDepartmentDataQuery,
} from "../../../graphqGenaretedTypes"
import { useTranslation } from "react-i18next"

export type DepartmentContextData = {
  title: string
  leftColour?: string
  rightColour?: string
  categories: RoundelProps[]
  breadcrumbs: Array<BreadcrumbsType>
  loading: boolean
}

const defaultValue: DepartmentContextData = {
  title: "",
  leftColour: undefined,
  rightColour: undefined,
  breadcrumbs: [],
  categories: [],
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToBreadcrumbs: (
  queryData: GetDepartmentDataQueryHookResult
) => BreadcrumbsType[] = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.department?.breadcrumbs || []
}

const queryDataToCategories: (
  queryData: GetDepartmentDataQueryHookResult
) => RoundelProps[] = function (queryData) {
  let result: RoundelProps[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.department?.categories) {
    result = data.employeeOrganisation.scheme.department.categories.map(
      (category) => {
        return {
          label: category.name,
          imageUrl: category.roundel.imageUrl,
          link: category.roundel.link,
          background: "gray",
        }
      }
    )

    result.push({
      label: "All " + data.employeeOrganisation.scheme.department.name,
      imageUrl:
        data.employeeOrganisation.scheme.department.landingRoundel.imageUrl,
      link: data.employeeOrganisation.scheme.department.landingRoundel.link,
      background: "gray",
    })
  }

  return result
}

export const DepartmentContext = createContext(defaultValue)

export const useDepartmentData: () => {
  data: DepartmentContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetDepartmentDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      departmentId: params.id ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const categories = queryDataToCategories(data)
    const title =
      data.data?.employeeOrganisation?.scheme?.department?.name || ""
    const leftColour =
      data.data?.employeeOrganisation?.scheme?.department?.leftColour
    const rightColour =
      data.data?.employeeOrganisation?.scheme?.department?.rightColour
    const breadcrumbs = queryDataToBreadcrumbs(data)
    const loading = data.loading

    state = {
      ...defaultValue,
      title,
      leftColour,
      rightColour,
      categories,
      breadcrumbs,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
