import { useContext, useState } from "react"
import { object, string } from "yup"
import { Formik } from "formik"
import Layout from "../Layout"
import {
  FormContainer,
  RedOuterBox,
  StyledForm,
  WarningParagraph,
  SubmitButton,
} from "../shared/mainPageStyles"
import { InnerBox, ProceedButton, InfoIcon, Subheader } from "./sharedStyles"
import { TitleHeading } from "../shared/headingStyles"
import GenericField from "../../shared/form/GenericField"
import { useTranslation } from "react-i18next"
import { toCamelCase } from "../toCamelCase"
import { useSetValidationNumberMutation } from "../../../graphqGenaretedTypes"
import { useNavigate, useParams } from "react-router-dom"
import {
  RegValidationContext,
  useRegValidationData,
} from "./RegValidationContext"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import LoggedOutBanner from "../LoggedOutBanner"
import { getShouldShowBanner } from "../registrationUtils"

const RegValidation = () => {
  const navigate = useNavigate()
  const { data } = useRegValidationData()
  const { t, ready } = useTranslation(["regForm"])
  const [isValidationSuccess, setIsValidationSuccess] = useState<
    undefined | boolean
  >()
  const { showBanner: portalProviderShowBanner, banner } = useContext(
    RegistrationLayoutContext
  )
  const { user_id } = useParams()

  const globalShowBanner = banner?.show
  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  const label = data.validationNumberType
  const lastValidationNumber = data.lastValidationNumber

  let id = ""

  if (label) {
    id = toCamelCase(label)
  }

  const validationFieldProps = {
    id: id,
    name: id,
    label: label,
    fullWidth: true,
    type: "text",
  }

  const initialValues = {
    [id]: lastValidationNumber ?? "",
  }

  const [setValidationNumberMutation] = useSetValidationNumberMutation()

  const setValidationNumber = async (validationNumber: string) => {
    const { data } = await setValidationNumberMutation({
      variables: {
        validationNumberValue: validationNumber,
      },
    })

    const errors = data?.setValidationNumber?.errors

    if (errors && errors.length > 0) {
      if (errors[0].includes("Invalid validation number")) {
        setIsValidationSuccess(false)
      } else {
        navigate("/500")
      }
    } else {
      setIsValidationSuccess(true)
    }
  }

  const validationSchema = object().shape({
    [id]: string().required(`Please enter ${label}`),
  })

  if (!ready || !id) {
    return null
  }

  if (isValidationSuccess) {
    navigate("/users/confirmation_required")
  }
  return (
    <RegValidationContext.Provider value={data}>
      <Layout>
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => setValidationNumber(values[id])}
          >
            {(formik) => {
              return (
                <StyledForm>
                  <FormContainer>
                    <TitleHeading sx={{ marginBottom: "1.125rem" }}>
                      {t("regValidation.header")}
                    </TitleHeading>
                    <Subheader>{t("regValidation.subheader")}</Subheader>

                    <RedOuterBox sx={{ marginBottom: "2.3125rem" }}>
                      <InnerBox>
                        <InfoIcon />
                        <WarningParagraph sx={{ paddingRight: "0.5rem" }}>
                          {t("regValidation.warning")}
                        </WarningParagraph>
                      </InnerBox>
                    </RedOuterBox>

                    <GenericField
                      textFieldProps={validationFieldProps}
                      inlineTextFieldProps={
                        isValidationSuccess === false
                          ? {
                              error: true,
                              helperText: "Invalid validation number",
                            }
                          : undefined
                      }
                    />

                    <ProceedButton
                      variant="gradient"
                      type="submit"
                      disabled={formik.isSubmitting || formik.isValidating}
                    >
                      {t("regValidation.proceed")}
                    </ProceedButton>

                    <SubmitButton
                      variant="gray"
                      onClick={() =>
                        navigate(
                          `/users/${user_id}/validation_numbers/id/request_review`
                        )
                      }
                      disabled={formik.isSubmitting || formik.isValidating}
                    >
                      {t("regValidation.forgotDetails")}
                    </SubmitButton>
                  </FormContainer>
                </StyledForm>
              )
            }}
          </Formik>
          {shouldShowBanner && banner?.heading && banner?.images && (
            <LoggedOutBanner
              bannerHeading={banner.heading}
              images={banner.images}
            />
          )}
        </>
      </Layout>
    </RegValidationContext.Provider>
  )
}

export default RegValidation
