import {
  MainHeader,
  PropositionSpaceTwoSubHeader,
  Space2HeaderMobilePadding,
  Space2HeaderDesktopPadding,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { Box, css, styled } from "@mui/material"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"

export const Header = ({ link }: { link: string }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <Space2HeaderMobilePadding>
        <Content link={link} />
      </Space2HeaderMobilePadding>
    )
  }
  return (
    <DesktopPadding>
      <Content link={link} />
    </DesktopPadding>
  )
}

function Content({ link }: { link: string }) {
  const isMobile = useIsMobileView()
  const { t } = useTranslation("portalHomepage")

  return (
    <>
      <H3Header variant="h3" align="left">
        {t("familyPay.header")}
      </H3Header>
      <H4Para variant="h4" align="left">
        {t("familyPay.subHeader")}
      </H4Para>
      {!isMobile && (
        <Box sx={{ marginTop: "1.875rem" }}>
          <Button link={link} />
        </Box>
      )}
    </>
  )
}

const DesktopPadding = styled(Space2HeaderDesktopPadding)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      padding-right: 0.625rem;
    }
  `}
`

const H3Header = styled(MainHeader)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 0.625rem;
    }
  `}
`

const H4Para = styled(PropositionSpaceTwoSubHeader)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-top: 0.625rem;
    }
  `}
`
