import { useMediaQuery, useTheme } from "@mui/material"

const BottomWave = ({ backgroundColor }: { backgroundColor: string }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const resizeWave = useMediaQuery("@media (min-width:1920px)")

  return (
    <div
      className="content-center"
      style={{
        width: "100%",
        overflow: "hidden",
        marginBottom: "-1px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 110"
        style={{
          minWidth: resizeWave ? "100%" : "1920px",
          height: isDesktop ? "auto" : "3.25rem",
        }}
      >
        <path
          fill={backgroundColor}
          fillOpacity="1"
          transform="translate(1920.248 112.037) rotate(180)"
          d="M1920.6,0v28.3l-1.8,0.6L1918,29c0,0-179.4,47.5-358.6,41.6s-439.3-54.4-638-61.1
		c-198.7-6.7-556.1,92.9-705.5,101C66.6,118.8,0,95.8,0,95.8V0H1920.6z"
        ></path>
      </svg>
    </div>
  )
}

export default BottomWave
