import Editor from "ckeditor5-custom-build/build/ckeditor"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import {
  UploadAdapter,
  FileLoader,
} from "@ckeditor/ckeditor5-upload/src/filerepository"
import axios from "axios"
import { useFormikContext } from "formik"

/* eslint-disable no-async-promise-executor */
function uploadAdapter(loader: FileLoader): UploadAdapter {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file
          const response = await axios.request({
            method: "POST",
            url: `/ckeditor/pictures`,
            data: {
              upload: file,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          resolve({
            default: response.data.asset.url_content,
          })
        } catch (error) {
          reject("There has been an error uploading your image.")
        }
      })
    },
  }
}
/* eslint-enable no-async-promise-executor */

function uploadPlugin(editor: Editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader)
  }
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
export function RichTextEditor<T>({
  data = "",
  fieldName,
  variant,
  setCharacterCount,
}: {
  data?: string
  fieldName: string
  variant?: "light"
  setCharacterCount?: (count: number) => void
}) {
  const formik = useFormikContext<T>()

  let extraConfig = {}

  if (variant === "light") {
    extraConfig = {
      toolbar: [
        "undo",
        "redo",
        "bold",
        "underline",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
      ],
    }
  }

  return (
    <div className="App">
      <CKEditor
        id={fieldName}
        name={fieldName}
        editor={Editor}
        data={data}
        config={{
          // @ts-ignore
          extraPlugins: [uploadPlugin],
          mediaEmbed: { previewsInData: true },
          ...extraConfig,
          link: {
            defaultProtocol: "https://",
          },
          wordCount: {
            onUpdate: (stats) => {
              if (setCharacterCount) {
                setCharacterCount(stats.characters)
              }
            },
          },
        }}
        onChange={(_, editor) => {
          const newData = editor.getData()
          formik.setFieldValue(fieldName, newData) // Use setFieldValue instead of handleChange
        }}
      />
    </div>
  )
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
