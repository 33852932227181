import { useState } from "react"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import Drawer from "../../shared/drawer/Drawer"
import Filters from "../../shared/store/Filters"
import { Button } from "@mui/material"
import { useContext } from "react"
import { StoreContext } from "../StoreContext"
import { LSStoreContext } from "../../lifestyle-savings/LSStoreContext"
import PillContainer from "../../shared/store/PillContainer"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

export type FilterDrawerProps = {
  isLifestyleFilters?: boolean
}

function FilterDrawer({ isLifestyleFilters }: FilterDrawerProps) {
  const [open, setOpen] = useState(false)
  const contextToUse = isLifestyleFilters ? LSStoreContext : StoreContext
  const { totalEntries } = useContext(contextToUse)
  const theme = useTheme()
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }
  return (
    <Drawer
      title="Filters"
      label={
        <>
          {t("store.drawer.label")}
          <ArrowRightIcon />
        </>
      }
      labelSx={{
        backgroundColor: theme.palette.white.main,
        width: "7rem",
        fontSize: "0.75rem",
        justifyContent: "space-between",
        paddingRight: 0,
      }}
      open={open}
      setOpen={setOpen}
      content={
        <div className="filter-drawer">
          <div className="filter-drawer__overflow">
            <Filters isMobile={true} isLifestyleFilters={isLifestyleFilters} />
          </div>
          <div className="filter-drawer__actions">
            <PillContainer
              showPills={false}
              clearButtonLabel={t("store.pillContainer.clearFilters")}
              isLifestyleFilters={isLifestyleFilters}
            />
            <Button
              variant="gradient"
              onClick={() => setOpen(false)}
              sx={{ fontWeight: 700 }}
            >
              {t("store.drawer.resultButton", { totalEntries })}
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default FilterDrawer
