import getAddressFields from "./fields/submitOrderFields"
import GenericField from "../../shared/form/GenericField"
import Checkboxes from "../../store/checkout-page/Checkboxes"
import FindYourAddress from "./FindYourAddress"
import { useFormikContext } from "formik"
import { FormikValue } from "./initialValues"

const SubmitOrder = () => {
  const formik = useFormikContext<FormikValue>()
  const checkboxes = formik.values.checkboxes
  const addressFields = getAddressFields()

  if (checkboxes.length > 0) {
    return (
      <div>
        <FindYourAddress />

        {addressFields.map((field) => {
          return (
            <GenericField
              textFieldProps={field}
              key={`${field.id}-input-field`}
            />
          )
        })}
        <Checkboxes checkboxes={checkboxes} formik={formik} />
      </div>
    )
  }
  return <></>
}

export default SubmitOrder
