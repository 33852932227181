import { useTranslation } from "react-i18next"
import {
  getEmailField,
  getFirstNameField,
  getLastNameField,
} from "../../cycle-to-work/quote-page/fields/yourDetailsFields"
import GenericField from "../../shared/form/GenericField"
import { useFormikContext } from "formik"
import { SignUpFormikValue } from "./formikUtils"
import useTriggerSignUpParametersQuery from "../useTriggerSignUpParametersQuery"
import { defaultMobileNumberState } from "./formikUtils"
import { useEffect } from "react"
import { TitleField } from "./TitleField"
import MobileNumberField from "../MobileNumberField"

const defaultRequiresDomainValidation = false

export function PersonalInfoFields({
  setShowFields,
}: {
  setShowFields: (showFields: boolean) => void
}) {
  const { t, ready } = useTranslation("regForm")
  const formik = useFormikContext<SignUpFormikValue>()
  const data = useTriggerSignUpParametersQuery(formik.values.organisation.id)
  const namesFields = [getFirstNameField(), getLastNameField()]

  const requiresDomainValidation =
    data?.organisation?.requiresDomainValidation ??
    defaultRequiresDomainValidation

  const mobileNumberState =
    data?.organisation?.mobileNumberState ?? defaultMobileNumberState

  useEffect(() => {
    if (formik.values.organisation.id !== "" && data?.organisation) {
      setShowFields(true)
    } else {
      setShowFields(false)
    }
  }, [data, formik.values.organisation.id, setShowFields])

  if (!ready) {
    return null
  }

  return (
    <>
      {formik.values.organisation.id !== "" && data?.organisation && (
        <>
          <TitleField />
          {namesFields.map((field) => (
            <GenericField
              textFieldProps={field}
              key={`${field.id}-input-field`}
            />
          ))}

          <MobileNumberField mobileNumberState={mobileNumberState} />

          <GenericField
            textFieldProps={getEmailField()}
            inlineTextFieldProps={{
              label: requiresDomainValidation
                ? t("regForm:email:workEmail")
                : t("regForm:email:personalEmail"),
            }}
          />
        </>
      )}
    </>
  )
}
