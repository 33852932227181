import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { Button } from "@mui/material"
import { useState } from "react"
import Modal from "./Modal"

export type AffordabilityCalculatorBoxProps = {
  title: string
}

function AffordabilityCalculatorBox({
  title,
}: AffordabilityCalculatorBoxProps) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <div
        className="filter-box btn-gradient cursor-pointer"
        onClick={() => setModalOpen(true)}
      >
        <div className="expandable-box__title">
          <Button sx={{ padding: 0 }}>
            <h4 className="color-white">{title}</h4>
          </Button>
          <ArrowRightIcon
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              marginRight: "-0.5rem",
            }}
          />
        </div>
      </div>
      <Modal open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}

export default AffordabilityCalculatorBox
