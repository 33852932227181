export function getTop5Category(obj: Record<string, number>): CategoriesType[] {
  return Object.entries(obj)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([key]) => {
      const [fullCategory, ids] = key.split("- (").map((item) => item.trim())
      const [department_id, category_id] = ids
        .slice(0, -1)
        .split(" - ")
        .map(Number)
      return {
        department_id,
        category_id,
        category: fullCategory,
      }
    })
}

export type CategoriesType = {
  department_id: number
  category_id: number
  category: string
}
