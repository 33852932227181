import { useEffect } from "react"
import Layout from "../Layout"
import {
  Container,
  Header,
  InlineLinks,
  ListContainer,
  Paragraph,
  StyledOrderedList,
  StyledUnorderedList,
  Table,
  TableOrderedList,
} from "../StyledComponents"
import { usePrivacyNoticeData } from "./PrivacyNoticeContext"

const RETENTION_SCHEDULE_LINK =
  "https://business.vivupbenefits.co.uk/hubfs/Privacy%20Notice/Vivup%20Platform%20Employer%20Retention%20Schedule%20v1.0.pdf"
const SUPPLIER_LIST_LINK =
  "https://business.vivupbenefits.co.uk/hubfs/Privacy%20Notice/Vivup%20Platform%20Third%20Party%20Suppliers%20v1.0.pdf"

const PrivacyNotice = () => {
  const { data } = usePrivacyNoticeData()

  useEffect(() => {
    document.title = `Privacy Notice`
  })

  return (
    <Layout bannerText="Organisation Privacy Notice">
      <Container>
        <Paragraph>
          Your employer (referred to in this privacy policy as “your employer”,
          “we”, “us” or “our”) has appointed SME HCI Limited, trading as Vivup
          (“Vivup”), and its sub-processors, to run and administer your employee
          benefits on our behalf. We respect your privacy and are committed to
          protecting your personal data. This privacy policy will inform you as
          to how we look after the personal data that you provide to us when you
          register for one of the employee benefit schemes (“Benefit”) and when
          you order any products or services provided as part of the Benefit.
          The privacy policy will tell you about your privacy rights and how the
          law protects you.
        </Paragraph>
        <Header variant="h3">1. Important information and who we are</Header>
        <Header variant="h4">Purpose of this privacy policy</Header>
        <Paragraph>
          This privacy policy aims to give you information on how your employer
          collects and processes your personal data when you register for a
          Benefit or when you order any products provided as part of a Benefit.
        </Paragraph>
        <Paragraph>
          This website is not intended for children. We do not knowingly collect
          data relating to children apart from in connection with the Work &
          Life Partners Platform where we will need to collect names for the
          provision of the benefits to care recipients (this may include
          children).
        </Paragraph>
        <Paragraph>
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy policy supplements other notices and privacy
          policies and is not intended to override them.
        </Paragraph>
        <Header variant="h4">Controller/Processor</Header>
        <Paragraph>
          We will be responsible for collecting/processing personal data about
          you and will oversee questions in relation to this privacy policy.
        </Paragraph>
        <Paragraph>
          With respect to personal data relating to you, processed by Vivup for
          the purpose of running and administering a Benefit on our behalf, such
          personal data is processed by us a Data Controller and by Vivup as a
          Data Processor. For instances where Vivup is a Data Controller, please
          see Vivup’s Privacy Notice{" "}
          <InlineLinks href="https://vivupbenefits.co.uk/privacy-policy">
            here
          </InlineLinks>
          .
        </Paragraph>
        <Paragraph>
          If you have any questions about this privacy policy, including any
          requests to exercise your legal rights, please contact us using the
          details set out below.
        </Paragraph>
        <Header variant="h4">Contact details</Header>
        <Paragraph>
          If you have any questions about this privacy policy, or our privacy
          practices in relation to the Vivup Platform, please contact us.
        </Paragraph>
        <Paragraph>
          Email address:{" "}
          <InlineLinks href={"mailto:" + data.contactEmail}>
            {data.contactEmail}
          </InlineLinks>
        </Paragraph>
        <Paragraph>
          You have the right to make a complaint at any time to the Information
          Commissioner's Office (“ICO”), the UK supervisory authority for data
          protection issues (www.ico.org.uk). We would, however, appreciate the
          chance to deal with your concerns before you approach the ICO so
          please contact us.
        </Paragraph>
        <Header variant="h4">
          Changes to the privacy policy and your duty to inform us of changes
        </Header>
        <Paragraph>We keep our privacy policy under regular review.</Paragraph>
        <Paragraph>This version was last updated on 18/09/2024.</Paragraph>
        <Paragraph>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          whilst you are a member of the Benefit.
        </Paragraph>
        <Header variant="h3">2. The data we collect about you</Header>
        <Paragraph>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </Paragraph>
        <Paragraph>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              <strong>Identity Data</strong> includes first name, last name,
              username or similar identifier, Employee Number, NI Number, date
              of birth and gender.
            </li>
            <li>
              <strong>Contact Data</strong> includes address, email address and
              telephone numbers.
            </li>
            <li>
              <strong>Salary Information</strong> includes salary bracket,
              benefit amount, and annual salary.{" "}
            </li>
            <li>
              <strong>Transaction Data</strong> includes details about products
              and services you have ordered in connection with the Benefit.
              Information about specific items ordered is not visible to us.
            </li>
            <li>
              <strong>Technical</strong> Data includes internet protocol (IP)
              address, your login data, browser type and version, time zone
              setting and location, operating system and platform, and other
              technology on the devices you use to access this website
              [https://www.vivup.co.uk/ (the “Site”)].
            </li>
            <li>
              <strong>Profile Data</strong> includes your username and password,
              orders made by you, your interests, preferences, feedback and
              survey responses.
            </li>
            <li>
              <strong>Usage Data</strong> includes information about how you use
              the Site, products and services.
            </li>
            <li>
              <strong>Communications Data</strong> includes your communication
              preferences.
            </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          We also collect, use and share with third parties{" "}
          <strong>Aggregated Data</strong> such as statistical or demographic.
          Aggregated Data could be derived from your personal data but is not
          considered personal data in law as this data will not directly or
          indirectly reveal your identity. For example, we may aggregate your
          Usage Data to calculate the percentage of users accessing a specific
          Site feature. However, if we combine or connect Aggregated Data with
          your personal data so that it can directly or indirectly identify you,
          we treat the combined data as personal data which will be used in
          accordance with this privacy policy.
        </Paragraph>
        <Paragraph>
          We do not collect any{" "}
          <strong>Special Categories of Personal Data</strong> about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </Paragraph>
        <Header variant="h4">If you fail to provide personal data</Header>
        <Paragraph>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel an order you have
          with us, but we will notify you if this is the case at the time.
        </Paragraph>
        <Header variant="h3">
          3. Personal data collected from you in relation to the Vivup Platform
        </Header>
        <Paragraph>
          We use different methods to collect data from and about you including
          through:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              <strong>Direct interactions.</strong> You may give us your
              Identity and Contact Data by filling in forms or by corresponding
              with us by post, phone, email or otherwise. This includes personal
              data you provide when you:
              <StyledUnorderedList>
                <li>make an order for products or services;</li>
                <li>create an account;</li>
                <li>subscribe to publications;</li>
                <li>enter a competition, promotion or survey; or</li>
                <li>give us feedback or contact us.</li>
              </StyledUnorderedList>
            </li>
            <li>
              <strong>Automated technologies or interactions.</strong> As you
              interact with the Site, we will automatically collect Technical
              Data about your equipment, browsing actions and patterns. We
              collect this personal data by using cookies and other similar
              technologies. Please see our cookie policy for further details.
            </li>
            <li>
              <strong>Third parties or publicly available sources.</strong> We
              will receive personal data about you from analytics providers such
              as Google.
            </li>
          </StyledUnorderedList>
        </ListContainer>
        <Header variant="h3">4. How we use your personal data</Header>
        <Paragraph>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              Where it is necessary for our legitimate interests to enrol you in
              the Vivup Platform to provide our staff benefits package and your
              interests and fundamental rights do not override those interests.
            </li>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          Below are further details of the types of lawful basis that we will
          rely on to process your personal data:
        </Paragraph>
        <Paragraph>
          Legitimate Interests means the interests of our organisation in
          conducting and managing our business to enable us to give you the best
          service/product and the best and most secure experience. We make sure
          we consider and balance any potential impact on you (both positive and
          negative) and your rights before we process your personal data for our
          legitimate interests. We do not use your personal data for activities
          where our legitimate interests are overridden by the impact on you
          (unless we have your consent or are otherwise required or permitted to
          by law). You can obtain further information about how we assess our
          legitimate interests against any potential impact on you in respect of
          specific activities by contacting us (
          <InlineLinks href={"mailto:" + data.contactEmail}>
            {data.contactEmail}
          </InlineLinks>
          ).
        </Paragraph>
        <Paragraph>
          Performance of Contract means processing your data where it is
          necessary for the performance of a contract to which you are a party
          or to take steps at your request before entering into such a contract.
          This also means the services we are providing to you through your
          Contract of Employment/Terms of Service.
        </Paragraph>
        <Paragraph>
          Comply with a legal obligation means processing your personal data
          where it is necessary for compliance with a legal obligation that we
          are subject to.
        </Paragraph>
        <Paragraph>
          We do not rely on consent as a legal basis for processing your
          personal data.
        </Paragraph>
        <Header>Purposes for which we will use your personal data</Header>
        <Paragraph>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data.
        </Paragraph>
        <ListContainer>
          <Table>
            <thead>
              <tr>
                <th>Purpose/Activity</th>
                <th>Type of data</th>
                <th>Lawful basis for processing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>To register you for a Benefit</td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Salary Information</li>
                  </TableOrderedList>
                </td>
                <td>Legitimate interests</td>
              </tr>
              <tr>
                <td>
                  To process and deliver your order including:
                  <ul>
                    <li>
                      To provide status updates, respond to your queries,
                      requests and complaints
                    </li>
                    <li>
                      Manage payments, fees and charges such as payroll
                      deductions
                    </li>
                    <li>Collect and recover money owed to us</li>
                    <li>
                      For compliance with a legal obligation that we are subject
                      to
                    </li>
                  </ul>
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Salary Information</li>
                    <li>Transaction</li>
                    <li>Communications</li>
                  </TableOrderedList>
                </td>
                <td>
                  Performance of a contract with you
                  <br />
                  Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you which will include:
                  <ul>
                    <li>
                      Notifying you about changes to our terms or privacy policy
                    </li>
                  </ul>
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Communications</li>
                  </TableOrderedList>
                </td>
                <td>
                  Performance of a contract with you
                  <br />
                  Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To enable you to leave a review, partake in a prize draw,
                  competition, or complete a survey
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Communications</li>
                  </TableOrderedList>
                </td>
                <td>
                  Legitimate interests (to keep our records updated and to study
                  how you use products/services to develop and make more
                  relevant to you)
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our organisation and this website
                  (including troubleshooting, data analysis, testing, system
                  maintenance, support, reporting and hosting of data)
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                  </TableOrderedList>
                </td>
                <td>
                  Necessary to comply with a legal obligation.
                  <br />
                  Legitimate interests (for running our business and the
                  provision of administrative and IT services)
                </td>
              </tr>
              <tr>
                <td>
                  To deliver relevant website content to you and measure or
                  understand the effectiveness of the communications
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Communications</li>
                    <li>Technical</li>
                  </TableOrderedList>
                </td>
                <td>
                  Legitimate interests (to keep our records updated and to study
                  how you use products/services to develop and make more
                  relevant to you)
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve our website,
                  products/services, communications, customer relationships and
                  experiences
                </td>
                <td>
                  <TableOrderedList>
                    <li>Technical</li>
                    <li>Usage</li>
                  </TableOrderedList>
                </td>
                <td>
                  Legitimate interests (to keep our records updated and to study
                  how you use products/services to develop and make more
                  relevant to you)
                </td>
              </tr>
              <tr>
                <td>
                  To make relevant suggestions and recommendations to you about
                  goods or services that may be of interest to you
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Usage</li>
                    <li>Profile</li>
                    <li>Communications</li>
                  </TableOrderedList>
                </td>
                <td>
                  Legitimate interests (to keep our records updated and to study
                  how you use products/services to develop and make more
                  relevant to you)
                </td>
              </tr>
              <tr>
                <td>
                  To enable access to benefits on third party web sites,
                  accessed via the Vivup Platform including:
                  <ul>
                    <li>Enable single sign on account creation</li>
                  </ul>
                </td>
                <td>
                  <TableOrderedList>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Usage</li>
                  </TableOrderedList>
                </td>
                <td>
                  Legitimate interests
                  <br />
                  Performance of a contract.
                </td>
              </tr>
            </tbody>
          </Table>
        </ListContainer>
        <Header variant="h4">Promotional offers from us</Header>
        <Paragraph>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which messages, products,
          services and offers may be relevant for you.
        </Paragraph>
        <Paragraph>
          You will receive communications from us and/or Vivup if you have
          registered or you make an order for any goods or services in
          connection with a Benefit from us and/or Vivup and you have not opted
          out/unsubscribed of/from receiving such communications.
        </Paragraph>
        <Header variant="h4">Third-party communications</Header>
        <Paragraph>
          You will not receive any communications from a third party unless this
          is part of the fulfilment process relating to a specific order you
          have made or if you have given your express opt-in consent directly to
          that third party to receive communications. As the provider of the
          platform, any communication sent by Vivup directly or on behalf of
          your employer are not treated as Third Party communications.
        </Paragraph>
        <Header variant="h4">
          Opting out or unsubscribing from communications
        </Header>
        <Paragraph>
          You can ask us to stop sending you communications at any time by
          contacting us or clicking the unsubscribe link in a message.
        </Paragraph>
        <Paragraph>
          Unsubscribing from communications will not apply to messages sent as a
          result of the fulfilment of an order or your registration into a
          Benefit or when we are required to notify you regarding other
          important developments or changes.
        </Paragraph>
        <Paragraph>
          If you no longer wish to take advantage of the Vivup Platform, you can
          choose to opt out. If you have registered post June 2023, please
          contact us directly.
        </Paragraph>
        <Header variant="h4">Cookies</Header>
        <Paragraph>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly. For more information about the
          cookies used on the Site, please see{" "}
          <InlineLinks href="https://www.vivup.co.uk/cookie_policy" />.
        </Paragraph>
        <Header variant="h4">Change of purpose</Header>
        <Paragraph>
          We will only use your personal data for the purposes for which it is
          collected, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact Vivup, who will respond on our behalf.
        </Paragraph>
        <Paragraph>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </Paragraph>
        <Paragraph>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </Paragraph>
        <Header variant="h3">5. Disclosures of your personal data</Header>
        <Paragraph>
          We may share your personal data with the parties set out below for the
          purposes set out in the table in Section 4 above.
        </Paragraph>
        <ListContainer>
          <StyledUnorderedList>
            <li>
              Internal Third Parties related to your Employer acting as joint
              controllers or processors.
            </li>
            <li>
              External Third Parties such as:{" "}
              <ul>
                <li>
                  Service and other benefits providers acting as data
                  controllers or processors, such as Vivup, and its suppliers.
                </li>
                <li>
                  Professional advisers acting as processors or joint
                  controllers including lawyers, tax advisors and auditors.
                </li>
                <li>
                  HM Revenue & Customs, regulators and other authorities acting
                  as processors or joint controllers who require reporting of
                  processing activities in certain circumstances.
                </li>
              </ul>
            </li>
            <li>
              Other parties to whom we may choose to sell, transfer or merge
              parts of our organisation or our assets. Alternatively, we may
              seek to acquire other organisations or merge with them. If a
              change happens to our organisation, then the new owners may use
              your personal data in the same way as set out in this privacy
              policy.
            </li>
          </StyledUnorderedList>
        </ListContainer>
        <Paragraph>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </Paragraph>
        <Header variant="h3">6. International transfers</Header>
        <Paragraph>
          The Vivup Platform is held in UK data centres. However, Vivup may
          transfer personal data to a third party that processes data outside
          the UK or European Economic Area (EEA). Vivup will only transfer
          personal data to countries that have deemed to provide an adequate
          level of protection for personal data and otherwise they may use
          specific contracts approved for use in the UK which give personal data
          the same protection as in the UK. A schedule of suppliers is provided{" "}
          <InlineLinks href={SUPPLIER_LIST_LINK}>here</InlineLinks>.
        </Paragraph>
        <Header variant="h3">7. Data security</Header>
        <Paragraph>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </Paragraph>
        <Paragraph>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </Paragraph>
        <Header variant="h3">8. Data retention</Header>
        <Header variant="h4">
          How long will you use my personal data for?
        </Header>
        <Paragraph>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
        </Paragraph>
        <Paragraph>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </Paragraph>
        <Paragraph>
          The data retention table can be found{" "}
          <InlineLinks href={RETENTION_SCHEDULE_LINK}>here</InlineLinks>.
        </Paragraph>
        <Paragraph>
          In some circumstances you can ask us to delete your data: see your
          legal rights below for further information.
        </Paragraph>
        <Paragraph>
          In some circumstances we will anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes, in which case we may use this information indefinitely
          without further notice to you.
        </Paragraph>
        <Header variant="h3">9. Your legal rights</Header>
        <Paragraph>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data.
        </Paragraph>
        <Paragraph>You have the right to:</Paragraph>
        <ListContainer>
          <StyledOrderedList sx={{ listStyleType: "lower-alpha" }}>
            <li>
              Request access to your personal data (commonly known as a "data
              subject access request"). This enables you to receive a copy of
              the personal data we hold about you and to check that we are
              lawfully processing it.
            </li>
            <li>
              Request correction of the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide to us.
            </li>
            <li>
              Request erasure of your personal data. This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us continuing to process it. You also have the right to ask us
              to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on
              legitimate interests (or those of a third party) and there is
              something about your particular situation which makes you want to
              object to processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. In some cases, we may demonstrate
              that we have compelling legitimate grounds to process your
              information which override your rights and freedoms.
            </li>
            <li>
              Request restriction of processing of your personal data. This
              enables you to ask us to suspend the processing of your personal
              data in the following scenarios:
              <ol style={{ listStyleType: "lower-roman" }}>
                <li>If you want us to establish the data's accuracy.</li>
                <li>
                  Where our use of the data is unlawful but you do not want us
                  to erase it.
                </li>
                <li>
                  Where you need us to hold the data even if we no longer
                  require it as you need it to establish, exercise or defend
                  legal claims.
                </li>
                <li>
                  You have objected to our use of your data but we need to
                  verify whether we have overriding legitimate grounds to use
                  it.
                </li>
              </ol>
            </li>
            <li>
              Request the transfer of your personal data to you or to a third
              party. We will provide to you, or a third party you have chosen,
              your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you.
            </li>
            <li>
              Withdraw consent at any time where we are relying on consent to
              process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </li>
          </StyledOrderedList>
        </ListContainer>
        <Paragraph>
          If you wish to exercise any of the rights set out above, please
          contact us.
        </Paragraph>
        <Header variant="h4">No fee usually required</Header>
        <Paragraph>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </Paragraph>
        <Header variant="h4">What we may need from you</Header>
        <Paragraph>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </Paragraph>
        <Header variant="h4">Time limit to respond</Header>
        <Paragraph>
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </Paragraph>
      </Container>
    </Layout>
  )
}

export default PrivacyNotice
