import { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material"
import { FormikProps } from "formik"
import { FormikValue } from "./initialValues"
import InfoTooltip from "../shared/InfoTooltip"
import { useTranslation } from "react-i18next"
import { useGetQuotePageDataQuery } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import i18n from "../../i18n"

const Container = styled("div")({
  width: "100%",
})

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "checked",
})<{ checked: boolean }>(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}))

const PayrollGivingRadioButton = ({
  formikValue,
}: {
  formikValue: FormikProps<FormikValue>
}) => {
  const { setFieldValue, values } = useFormikContext<FormikValue>()
  const { t, ready } = useTranslation("quotePage")
  const params = useParams()
  const defaultSelect = formikValue.values as FormikValue
  const [, forceUpdate] = useState({}) // State to force re-render

  useEffect(() => {
    if (defaultSelect.picked === undefined) {
      setFieldValue("picked", false)
    }
  }, [setFieldValue, defaultSelect.picked])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === "true"

    setFieldValue("picked", newValue)
    forceUpdate({}) // Force re-render
  }

  const formData = useGetQuotePageDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      schemeType: "payroll_giving",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  const hasRecurringDonationOrder =
    formData.data?.employeeOrganisation?.scheme?.recurringDonationExists

  if (!ready) {
    return null
  }

  return (
    <Container style={{ marginBottom: "1.875rem" }}>
      <RadioGroup
        name="use-radio-group"
        value={values.picked.toString()}
        onChange={handleChange}
      >
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            gap: "34px",
          }}
        >
          <StyledFormControlLabel
            value="false"
            label={t("radioButton.firstButton")}
            control={<Radio />}
            checked={values.picked === false}
          />
          <InputAdornment position="end">
            <InfoTooltip content={t("radioButton.firstButtonTooltip")} />
          </InputAdornment>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            gap: "18px",
          }}
        >
          <StyledFormControlLabel
            value="true"
            label={t("radioButton.secondButton")}
            control={<Radio />}
            checked={values.picked === true}
            disabled={hasRecurringDonationOrder}
          />
          <InputAdornment position="end">
            <InfoTooltip
              content={
                hasRecurringDonationOrder
                  ? t("radioButton.existRecurringTooltip")
                  : t("radioButton.secondButtonTooltip")
              }
            />
          </InputAdornment>
        </div>
      </RadioGroup>
    </Container>
  )
}

export default PayrollGivingRadioButton
