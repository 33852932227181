import React, { useState } from "react"
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  styled,
  Link,
} from "@mui/material"
import TermAndConditionPopUp from "./TermAndConditionPopUp"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../../utils/useIsMobileView"
import { useFormikContext } from "formik"
import useTriggerSignUpParametersQuery from "../useTriggerSignUpParametersQuery"
import { SignUpFormikValue } from "./formikUtils"

const TermAndConditionCheckBox = ({
  showCheckboxError,
}: {
  showCheckboxError: boolean
}) => {
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const isMobile = useIsMobileView()
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation("registration")
  const formik = useFormikContext<SignUpFormikValue>()
  const data = useTriggerSignUpParametersQuery(
    formik.values.organisation?.id ?? ""
  )

  const handleModalOpen = () => {
    setOpenModal(true)
  }
  const checkBoxClick = () => {
    setChecked(!checked)
    formik.setFieldValue("checkedTC", !checked)
  }
  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <div>
      <div>
        <h4 style={{ fontSize: !isMobile ? "1rem" : "17px" }}>
          {t("registration.termsAndConditions")}
        </h4>
      </div>
      <FormGroup sx={{ marginBottom: "1rem" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={checkBoxClick}
              sx={{
                color: (theme) =>
                  showCheckboxError
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
              }}
            />
          }
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          label={
            <div>
              <p
                style={{
                  color: theme.palette.primary.main,
                  margin: "10px",
                  marginLeft: "2px",
                  fontSize: !isMobile ? "14px" : "12px",
                  lineHeight: !isMobile ? "" : "1rem",
                }}
              >
                {t("registration.termsAndConditionsPara")}{" "}
                <TextWithLink
                  component={"button"}
                  type={"button"}
                  aria-label={t("registration.termsAndConditionsLinkLabel")}
                  onClick={handleModalOpen}
                  sx={{ fontSize: !isMobile ? "14px" : "12px" }}
                >
                  {t("registration.termsAndConditionsLink")}
                </TextWithLink>
                {data?.organisation?.privacyNoticeUrl && (
                  <>
                    {" "}
                    {t("registration.prePrivacyNotice")}{" "}
                    <a
                      href={data?.organisation?.privacyNoticeUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: !isMobile ? "14px" : "12px",
                        lineHeight: !isMobile ? "" : "1rem",
                        textDecoration: "underline",
                      }}
                    >
                      {t("registration.privacyNoticeLink")}
                    </a>
                  </>
                )}
              </p>
            </div>
          }
        />
      </FormGroup>

      <TermAndConditionPopUp
        openModal={openModal}
        handleModalClose={handleModalClose}
      />
    </div>
  )
}

export default TermAndConditionCheckBox
const TextWithLink = styled(Link)`
  && {
    text-decoration: underline;
    vertical-align: top;
    font-family: Raleway;
  }
` as typeof Link
