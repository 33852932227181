import {
  Modal,
  Box,
  Button,
  css,
  Card,
  CardMedia,
  CircularProgress,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { SubmitButton } from "../../registration/shared/mainPageStyles"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { Container } from "./FileSelector"
import i18n from "../../../i18n"
import {
  CloseButton,
  CloseButtonContainer,
  IconContainer,
  MainText,
  TextContainerBox,
} from "../../explore-your-benefits/BenefitCardModal"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { BuildAwsImageUrl } from "../../../utils/BuildAwsImageUrl"
import { useEffect, useState, useRef } from "react"
export type SelectedCardProps = {
  index: number
  image: string
}

type ImageObjectPromise = {
  imageUrl: string
  size: number | null
}

export type ConfirmationModalProps = {
  openModal: boolean
  handleClose(): void
  handleStoredFile(selectedCard: SelectedCardProps | null): void
}

const StoredImagePopUp = ({
  openModal,
  handleClose,
  handleStoredFile,
}: ConfirmationModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const isMobile = useMediaQuery(theme.breakpoints.down(601))
  const { t, ready } = useTranslation("newBenefitTile")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedCard, setSelectedCard] = useState<SelectedCardProps | null>(
    null
  )
  const { setFieldValue } = useFormikContext()
  const [cardImageObjs, setCardImageObjs] = useState<
    { imageUrl: string; size: number | null }[]
  >([])
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([])

  useEffect(() => {
    const fetchCardImageUrls = async () => {
      const objs: ImageObjectPromise[] = []
      for (let i = 1; i <= 34; i++) {
        const imageName = `benefit_tile_${i}.png`
        const imageObjPromise = BuildAwsImageUrl(i18n.language, imageName)
        const imageObj = await imageObjPromise
        if (imageObj !== "") {
          objs.push(imageObj)
        }
      }
      setCardImageObjs(objs)
    }

    fetchCardImageUrls()
  }, [])

  useEffect(() => {
    if (selectedCard && buttonRefs.current[selectedCard.index]) {
      buttonRefs.current[selectedCard.index]?.focus()
    }
  }, [selectedCard])

  const urlToFile = async (
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> => {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-store",
    })

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`)
    }

    const buffer = await response.arrayBuffer()
    return new File([buffer], filename, { type: mimeType })
  }

  const handleCardClick = async (index: number) => {
    const imageObj = cardImageObjs[index]
    const file = await urlToFile(
      imageObj.imageUrl,
      `benefit_tile+${index}.png`,
      "image/png"
    )
    setSelectedCard({
      index: index,
      image: imageObj.imageUrl,
    })
    setSelectedFile(file)
  }

  const handleUseImage = () => {
    handleClose()
    setFieldValue("document", selectedFile)

    if (selectedCard) {
      handleStoredFile(selectedCard)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainer
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div>
          <TextContainerBox>
            <MainText id="dialog_label" sx={{ marginBottom: "1rem" }}>
              <span className="screen-reader-only">Opened</span>
              {t("imagePopUpModal.chooseFromLibrary")}
              <span className="screen-reader-only">pop up</span>
            </MainText>
          </TextContainerBox>

          {isMobile ? (
            <MobileContainer>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {cardImageObjs.length > 0 ? (
                  <>
                    {cardImageObjs.slice(0, 34).map((image, index) => (
                      <Button
                        key={index}
                        ref={(el) => (buttonRefs.current[index] = el)}
                        style={{
                          flex: "0 0 calc(39.33% - 16px)",
                          outline:
                            selectedCard && selectedCard.index === index
                              ? "2px solid blue"
                              : "none",
                        }}
                        onClick={() => handleCardClick(index)}
                      >
                        <StyledCard>
                          <StyledCardImage
                            component="img"
                            src={image.imageUrl}
                            alt={`Image ${index + 1}`}
                          />
                        </StyledCard>
                      </Button>
                    ))}
                  </>
                ) : (
                  <div
                    style={{ margin: "auto", height: "10rem" }}
                    className="content-center"
                  >
                    <CircularProgress size="3rem" />
                  </div>
                )}
              </div>
            </MobileContainer>
          ) : (
            <Container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid black",
                overflow: "scroll",
              }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {cardImageObjs.length > 0 ? (
                  <>
                    {cardImageObjs.slice(0, 34).map((image, index) => (
                      <Button
                        key={index}
                        ref={(el) => (buttonRefs.current[index] = el)}
                        style={{
                          flex: "0 0 calc(25.5% - 24px)",
                          outline:
                            selectedCard && selectedCard.index === index
                              ? "2px solid blue"
                              : "none",
                        }}
                        onClick={() => handleCardClick(index)}
                      >
                        <StyledCard>
                          <StyledCardImage
                            component="img"
                            src={image.imageUrl}
                            alt={`Image ${index + 1}`}
                          />
                        </StyledCard>
                      </Button>
                    ))}
                  </>
                ) : (
                  <div
                    style={{ margin: "auto", height: "10rem" }}
                    className="content-center"
                  >
                    <CircularProgress size="3rem" />
                  </div>
                )}
              </div>
            </Container>
          )}

          <SubmitButton
            variant="gradient"
            onClick={handleUseImage}
            disabled={!selectedFile || !selectedCard}
            aria-label={t("imagePopUpModal.useImage")}
          >
            {t("imagePopUpModal.useImage")}
          </SubmitButton>

          <CloseButtonContainer>
            <CloseButton
              variant="invisible"
              onClick={handleClose}
              aria-label={t("popUpModal.closeWindow")}
            >
              {t("popUpModal.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </div>
        <IconContainerArea>
          <Button
            onClick={handleClose}
            aria-label={t("popUpModal.closeWindow")}
          >
            <ArrowBackIosIcon />
            {t("imagePopUpModal.back")}
          </Button>
          <Button
            onClick={handleClose}
            aria-label={t("popUpModal.closeWindow")}
          >
            <CrossButton
              sx={{
                paddingRight: isDesktop ? "1.121rem" : "1rem",
              }}
            />
          </Button>
        </IconContainerArea>
      </MainContainer>
    </Modal>
  )
}

export default StoredImagePopUp

export const IconContainerArea = styled(IconContainer)`
  ${({ theme }) => css`
    &&& {
      padding: 0rem 1.75rem;
      justify-content: space-between;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding: 0.375rem 0rem;
      }
    }
  `}
`

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    && {
      border-radius: ${theme.shape.borderRadius}px;
      box-shadow: ${theme.shadows[4]};
      transition: ${theme.transitions.create(["box-shadow"])};

      &:hover {
        box-shadow: ${theme.shadows[8]};
      }
    }
  `}
`

export const MobileContainer = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    position: relative;
    padding: 0.3rem;
    padding-top: 0.8rem;
    text-align: center;
    border: 1px solid black;
    margin-right: 2rem;
    margin-left: 2rem;
    height: 200px;
    border-radius: 0.4rem;
    height: 323px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 25rem;
    height: 100%;
    padding-top: 0rem;
    padding-bottom: 1rem;
  `}
`

const StyledCardImage = styled(CardMedia)`
  && {
    height: 4.5625rem;
  }
` as typeof CardMedia

export const CancelButton = styled(SubmitButton)`
  border: 0.0625rem solid black;
`

export const CrossButton = styled(Close)`
  ${({ theme }) => css`
    color: ${theme.palette.grey[900]};
    font-size: 1.515rem;
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      border-radius: 0.938rem;
      background-color: white;
      padding-top: 1rem;
      width: 33.813rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 21.313rem;
        padding: 0.5625rem;
      }
    }
  `}
`
