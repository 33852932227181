import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { SmallerCard } from "./SmallCard"
import NormalSizeCard from "./NormalCard"
import { DiscountType } from "../LSStoreContext"

type CardProps = {
  isMobileStore?: boolean
  data: DiscountType
  indexCarousel: number
}

const LifestyleProductCard = ({
  isMobileStore,
  data,
  indexCarousel,
}: CardProps) => {
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"))

  return isMobileStore && isNarrow ? (
    <SmallerCard data={data} indexCarousel={indexCarousel} />
  ) : (
    <NormalSizeCard data={data} indexCarousel={indexCarousel} />
  )
}

export default LifestyleProductCard
