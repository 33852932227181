import { styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <FooterText variant="body2">
      <Asterisk>*</Asterisk>
      {t("cycleToWork.disclaimerText")}
    </FooterText>
  )
}

export default Footer

const FooterText = styled(Typography)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: left;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 13px;
  margin-bottom: -14px; // disclaimer text closer to the bottom of box
`

const Asterisk = styled("span")`
  vertical-align: top;
`
