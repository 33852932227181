import { useContext, useEffect, useState } from "react"
import {
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { Box, Typography, css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

import {
  initialValues,
  SignUpFormikValue,
  fieldsWithValidation,
  validationSchema,
  defaultSalaryBracketState,
} from "./formikUtils"
import OrganisationField from "./OrgansiationField"
import ColleagueField from "./ColleagueField"
import { SalaryBracketField } from "./SalaryBracketField"
import { ValidationNumberField } from "./ValidationNumberField"
import { PersonalInfoFields } from "./PersonalInfoFields"
// import { DisplayFormikState } from "../../cycle-to-work/formik-helper"
import Alert from "../../shared/Alert"
import scrollToElement from "../../cycle-to-work/quote-page/scrollToElement"
import { Headers } from "./Headers"
import { PasswordFields } from "./PasswordFields"
import { StyledForm, FormContainer } from "../shared/mainPageStyles"
import { SubmitButton } from "../shared/mainPageStyles"
import Layout from "../Layout"
import TermAndConditionCheckBox from "./TermAndConditionCheckBox"
import { useNavigate } from "react-router-dom"
import {
  SignUpContext,
  useSignUpData,
  GetSignUpParameters,
} from "./SignUpContext"
import { LogoSection } from "../LogoSection"
import { useUserRegisterMutation } from "../../../graphqGenaretedTypes"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import LoggedOutBanner from "../LoggedOutBanner"

export default function SignUp() {
  const [alertMessage, setAlertMessage] = useState("")
  const [showFields, setShowFields] = useState(false) // for re-rendering fields
  const [errorCount, setErrorCount] = useState(0) // for re-rendering alert snackbar
  const navigate = useNavigate()
  const { data } = useSignUpData()
  const {
    stopRegistration,
    billboardImage,
    logo,
    rightLogo,
    leftLogo,
    showBanner: portalProviderShowBanner,
    banner,
  } = useContext(RegistrationLayoutContext)
  const { t, ready } = useTranslation(["bikeQuote", "regForm"])
  const [userRegisterMutation] = useUserRegisterMutation()
  const [orgId, setOrgId] = useState<undefined | string>(undefined)
  const [showCheckboxError, setShowCheckBoxError] = useState(false)

  useEffect(() => {
    if (stopRegistration) {
      navigate("/users/sign_in")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleRegisterClick = async (
    formik: FormikProps<SignUpFormikValue>
  ) => {
    formik.isValidating = true

    // if the TC box is checked?
    if (!formik.values.checkedTC) {
      setShowCheckBoxError(true)
      formik.isValidating = false
    } else {
      setShowCheckBoxError(false)
    }

    const validationErrors = await formik.validateForm() // exclude checkbox

    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true))

      const errorFieldId = fieldsWithValidation.find((field) =>
        Object.keys(validationErrors).find((errorField) => errorField == field)
      )

      scrollToElement(`#${errorFieldId}`)
      formik.isValidating = false
      return
    }

    // call default formik.handleSubmit(), then will call handleFormSubmit()
    formik.handleSubmit()
  }

  const handleFormSubmit = async (
    values: SignUpFormikValue,
    formikHelper: FormikHelpers<SignUpFormikValue>
  ) => {
    const { data } = await userRegisterMutation({
      variables: {
        organisationId: values.organisation.id,
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        passwordConfirmation: values.reEnterPassword,
        salaryBracketId: values.salaryBracket,
        validationNumber: values.validationNumber,
        referralEmail: values.colleagueEmail,
        mobileNumber: values.mobileNumber,
      },
    })

    const errors = data?.userRegister?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setErrorCount(errorCount + 1)
    } else {
      const authToken = data?.userRegister?.authToken

      if (authToken) {
        const queryString = new URLSearchParams({
          auth_token: authToken,
        }).toString()

        window.location.href = `/users/authorize_user?${queryString}`
      }
    }

    formikHelper.setSubmitting(false)
  }

  const result = GetSignUpParameters(orgId ?? "")
  const salaryBracketState = result.data?.organisation?.salaryBracketState
  const orgShowBanner = result.data?.organisation?.showLoggedOutBanner
  const globalShowBanner = banner?.show

  function getShouldShowBannerOnSignUp() {
    if (portalProviderShowBanner === false) return false
    if (portalProviderShowBanner && globalShowBanner) return true // portal provider case
    if (orgId && orgShowBanner && globalShowBanner) return true // non portal provider case
    return false
  }

  if (!ready) {
    return null
  }
  return (
    <SignUpContext.Provider value={data}>
      <Layout billboardImage={billboardImage}>
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelper) =>
              handleFormSubmit(values, formikHelper)
            }
          >
            {(formik) => {
              return (
                <StyledForm>
                  {alertMessage && (
                    <Alert
                      severity={"error"}
                      message={alertMessage}
                      key={errorCount}
                    />
                  )}
                  <FormContainer>
                    <LogoSection logos={[leftLogo, logo, rightLogo]} />
                    <Headers />
                    <OrganisationField setOrgId={setOrgId} />
                    <PersonalInfoFields
                      setShowFields={(state) => setShowFields(state)}
                    />
                    {showFields && (
                      <>
                        <PasswordFields />
                        <SalaryBracketField
                          salaryBrackets={data.salaryBrackets}
                          salaryBracketState={
                            salaryBracketState ?? defaultSalaryBracketState
                          }
                        />
                        <ValidationNumberField />
                        <ColleagueField />
                        <TermAndConditionCheckBox
                          showCheckboxError={showCheckboxError}
                        />
                        {showCheckboxError && (
                          <TcWarning>{t("bikeQuote:alertMessage")}</TcWarning>
                        )}

                        <SubmitButton
                          variant="gradient"
                          onClick={() => handleRegisterClick(formik)}
                          disabled={formik.isSubmitting || formik.isValidating}
                        >
                          {t("regForm:register")}
                        </SubmitButton>
                      </>
                    )}
                    {/* <DisplayFormikState /> */}
                  </FormContainer>
                </StyledForm>
              )
            }}
          </Formik>
          {getShouldShowBannerOnSignUp() &&
            banner?.heading &&
            banner?.images && (
              <LoggedOutBanner
                bannerHeading={banner.heading}
                images={banner.images}
              />
            )}
        </>
      </Layout>
    </SignUpContext.Provider>
  )
}

export const FieldWithTooltip = styled(Box)`
  // decide where to place these shared styles
  position: relative;
  margin-bottom: 1.875rem;
`

export const ToolTipContainer = styled(Box)`
  // decide where to place these shared styles
  position: absolute;
  right: -2.625rem;
  bottom: calc(50% - 1.125rem);
`

export const TcWarning = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    color: ${theme.palette.error.main};
  `}
`
