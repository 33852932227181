import { Box, styled } from "@mui/material"
import {
  SharedTopRightChip,
  OnePropositionTopRightChip,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

export function Chip({ onlyC2W }: { onlyC2W: boolean }) {
  const isMobile = useIsMobileView()

  if (onlyC2W && !isMobile) {
    return (
      <OnePropositionChip>
        <Content />
      </OnePropositionChip>
    )
  }
  return (
    <TwoPropositionsChip>
      <Content />
    </TwoPropositionsChip>
  )
}

function Content() {
  const { t, ready } = useTranslation("portalHomepage")
  const { i18n } = useTranslation()

  const tuskerLogo = useBuildAwsImageUrl(i18n.language, "ctw_save_text.png")

  if (!ready) {
    return null
  }
  return (
    <LogoContainer>
      <TuskerLogo
        src={tuskerLogo}
        alt={t("cycleToWork.chipAltText")}
      ></TuskerLogo>
    </LogoContainer>
  )
}

const TwoPropositionsChip = styled(SharedTopRightChip)`
  background: linear-gradient(
    115deg,
    #76377a 0%,
    ${({ theme }) => theme.palette.primary.main}
  );
`
const OnePropositionChip = styled(OnePropositionTopRightChip)`
  background: linear-gradient(
    115deg,
    #76377a 0%,
    ${({ theme }) => theme.palette.primary.main}
  );
`

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const TuskerLogo = styled("img")`
  height: 80%;
`
