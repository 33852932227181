import { Modal, Box, Button, css } from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { SubmitButton } from "../../registration/shared/mainPageStyles"
import {
  CloseButton,
  CloseButtonContainer,
  IconContainer,
  MainText,
  TextContainerBox,
} from "../../explore-your-benefits/BenefitCardModal"
import { useTranslation } from "react-i18next"
import FileSelector from "./FileSelector"
import { useState } from "react"
import { SelectedCardProps } from "./StoredImagePopUp"

export type ConfirmationModalProps = {
  openModal: boolean
  handleClose(): void
  onFileSelected: (selectedFileUrl: string, selectedFile: File | null) => void
  onStoredFileSelected: (selectedCard: SelectedCardProps) => void
}
const ImageUploadModal = ({
  openModal,
  handleClose,
  onFileSelected,
  onStoredFileSelected,
}: ConfirmationModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("newBenefitTile")
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const handleModalClose = () => {
    handleClose()
  }
  const handleFileSelected = (fileUrl: string, file: File) => {
    setSelectedFileUrl(fileUrl)
    setSelectedFile(file)
  }
  const handleUploadImageClick = () => {
    onFileSelected(selectedFileUrl, selectedFile)
    setSelectedFile(null)
    handleClose()
  }
  const handleStoredFile = (selectedCard: SelectedCardProps) => {
    const ifStoredFile = selectedCard
    ifStoredFile && handleModalClose()
    onStoredFileSelected(selectedCard)
  }

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainer
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div>
          <TextContainerBox>
            <MainText id="dialog_label" sx={{ marginBottom: "1rem" }}>
              <span className="screen-reader-only">Opened</span>
              {t("imagePopUpModal.benefitTileUpload")}
              <span className="screen-reader-only">pop up</span>
            </MainText>
          </TextContainerBox>
          <FileSelector
            onFileSelected={handleFileSelected}
            onStoredValueChange={handleStoredFile}
          />
          <SubmitButton
            variant="gradient"
            onClick={handleUploadImageClick}
            disabled={!selectedFile}
            aria-label={t("imagePopUpModal.uploadImage")}
          >
            {t("imagePopUpModal.uploadImage")}
          </SubmitButton>
          <CloseButtonContainer>
            <CloseButton
              variant="invisible"
              onClick={handleClose}
              aria-label={t("popUpModal.closeWindow")}
            >
              {t("popUpModal.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </div>
        <IconContainer>
          <Button
            onClick={handleModalClose}
            aria-label={t("popUpModal.closeWindow")}
          >
            <CrossButton
              sx={{
                paddingRight: isDesktop ? "1.121rem" : "1rem",
              }}
            />
          </Button>
        </IconContainer>
      </MainContainer>
    </Modal>
  )
}
export default ImageUploadModal
export const CancelButton = styled(SubmitButton)`
  border: 0.0625rem solid black;
`
export const CrossButton = styled(Close)`
  ${({ theme }) => css`
    color: ${theme.palette.grey[900]};
    font-size: 1.515rem;
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      border-radius: 0.938rem;
      background-color: white;
      padding-top: 1rem;
      width: 33.813rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 21.313rem;
        padding: 0.5625rem;
      }
    }
  `}
`
