import { Formik, FormikHelpers } from "formik"
import {
  passwordValidation,
  reEnterPasswordValidation,
} from "../../utils/yup-validation-schema/passwordValidation"
import Layout from "./Layout"
import * as Yup from "yup"
import Alert from "../shared/Alert"
import { useState } from "react"
import {
  FormContainer,
  StyledForm,
  SubmitButton,
} from "./shared/mainPageStyles"
import { useTranslation } from "react-i18next"
import { Headers } from "./sign-in/Headers"
import PasswordField from "./sign-up/PasswordField"
import {
  getConfirmNewPasswordField,
  getNewPasswordField,
} from "./sign-up/fields"
import { useSearchParams } from "react-router-dom"
import { useForgotPasswordMutation } from "../../graphqGenaretedTypes"
import PasswordProgressBar from "./sign-up/PasswordProgressBar"

type ChangeYourPasswordForm = {
  password: string
  reEnterPassword: string
}

const initialValues: ChangeYourPasswordForm = {
  password: "",
  reEnterPassword: "",
}

const validationSchema = Yup.object().shape({
  password: passwordValidation,
  reEnterPassword: reEnterPasswordValidation,
})

const ChangeYourPassword = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)

  const { t, ready } = useTranslation("regForm")
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const [forgotPasswordMutation] = useForgotPasswordMutation()

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => {
      return !prev
    })
  }

  const toggleShowConfirmEnterPassword = () => {
    setShowConfirmNewPassword((prev) => {
      return !prev
    })
  }

  const handleSubmit = async (
    values: ChangeYourPasswordForm,
    formikHelpers: FormikHelpers<ChangeYourPasswordForm>
  ) => {
    const token = searchParams.get("reset_password_token")

    if (token) {
      const { data } = await forgotPasswordMutation({
        variables: {
          newPassword: values.password,
          confirmNewPassword: values.reEnterPassword,
          resetToken: token,
        },
      })

      const errors = data?.forgotPassword?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      } else {
        const authToken = data?.forgotPassword?.authToken

        if (authToken) {
          const queryString = new URLSearchParams({
            auth_token: authToken,
          }).toString()

          window.location.href = `/users/authorize_user?${queryString}`
        }
      }
    }
    formikHelpers.setSubmitting(false)
  }

  if (!ready) {
    return null
  }
  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
      >
        {(formik) => {
          return (
            <StyledForm>
              {alertMessage && (
                <Alert
                  severity={alertSeverity}
                  message={alertMessage}
                  key={errorCount}
                />
              )}
              <FormContainer>
                <Headers
                  header={t("changeYourPassword.header")}
                  content={t("changeYourPassword.content")}
                />
                <PasswordField
                  passwordFieldProps={getNewPasswordField()}
                  showPassword={showNewPassword}
                  toggleShowPassword={toggleShowNewPassword}
                  inlineTextFieldProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    },
                  }}
                />

                {formik.values.password !== "" && (
                  <PasswordProgressBar password={formik.values.password} />
                )}

                <PasswordField
                  passwordFieldProps={getConfirmNewPasswordField()}
                  showPassword={showConfirmNewPassword}
                  toggleShowPassword={toggleShowConfirmEnterPassword}
                  inlineTextFieldProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    },
                  }}
                />
                <SubmitButton
                  variant="gradient"
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting || formik.isValidating}
                >
                  {t("changeYourPassword.buttonLabel")}
                </SubmitButton>
              </FormContainer>
            </StyledForm>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default ChangeYourPassword
