import { Box, CardContent, Typography, styled, css } from "@mui/material"
import { StyledTitle } from "../../shared/card/card-text/CardTitle"
import { Price } from "../../shared/card/card-text/CardPrice"
import { ImageContainer } from "../../shared/card/card-image/CardImageContainer"
import {
  CardImage,
  CardImageProp,
} from "../../shared/card/card-image/ProductCardImage"

export const SharedVoucherContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
    position: absolute;
    bottom: 0;
    right: 0;
    display: grid;
    align-items: center;
    justify-content: center;
  `}
`
export const VoucherText = styled(Typography)`
  ${({ theme }) => css`
    &&& {
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-family: ${theme.typography.fontFamily};
    }
  `}
`

export const Text = styled(StyledTitle)`
  ${({ theme }) => css`
    && {
      font-weight: 500;
      font-size: ${theme.typography.body2.fontSize};
      font-family: ${theme.typography.fontFamily};
    }
  `}
`

export const BrandName = styled(Text)`
  && {
    font-weight: 600;
  }
`

export const SharedSavePercentage = styled(Price)`
  ${({ theme }) => css`
    && {
      font-size: ${theme.typography.h3.fontSize};
      font-weight: 600;
      font-family: ${theme.typography.fontFamily};
    }
  `}
`

export type LSCardDataProps = {
  imageUrl: string
  brand: string
  savePercentage?: number
  savingInPound?: number
  href: string
  featuredTagImage?: string
  promoCode?: string
}

export const SharedTextContainer = styled(CardContent)`
  width: 80%;
  align-self: center;
  padding: 0.5rem 0;
`

export const StyledImageContainer = styled(ImageContainer)`
  width: 100%;
`

export const ShadowedCardImage = styled(CardImage)`
  ${({ theme }) => css`
    box-shadow: ${theme.boxShadow};
    border-radius: 0.5rem;
  `}
`

export const LSProductCardImage: React.FC<
  React.PropsWithChildren<CardImageProp>
> = ({ src, alt }) => {
  return <ShadowedCardImage src={src} alt={alt} />
}
