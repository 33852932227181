import React from "react"
import { Button, styled } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useTranslation } from "react-i18next"

export const OverlayButton: React.FC<
  React.PropsWithChildren<{
    closeOverlay: React.MouseEventHandler<HTMLElement>
    isOverlayOpen: boolean
  }>
> = ({ closeOverlay, isOverlayOpen }) => {
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }
  return (
    <StyledButton onClick={closeOverlay} aria-expanded={isOverlayOpen}>
      <ArrowDropDownIcon /> {t("landingPage.overlay.button")}
    </StyledButton>
  )
}
const StyledButton = styled(Button)`
  align-self: start;
  color: ${({ theme }) => theme.palette.white.main};
  padding-top: 2rem; // so that it's not on top of the wave container
  z-index: 1;

  &:hover {
    background-color: transparent;
  }
`
