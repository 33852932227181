export default function updatedSearchParams(
  searchParams: URLSearchParams,
  params: {
    paramName: string
    value: string | string[]
  }[],
  paramsToRemove?: string[],
  paramsToKeep?: string[]
) {
  let originalParams = undefined

  if (paramsToKeep) {
    originalParams = paramsToKeep.reduce(
      (acc, param) => ({
        ...acc,
        [param]: searchParams.get(param) || "",
      }),
      {}
    )
  } else {
    originalParams = searchParams.toString()
  }
  const result = new URLSearchParams(originalParams || "")

  params.forEach((param) => {
    if (typeof param.value === "string") {
      result.set(param.paramName, param.value)
    } else if (param.value.length > 0) {
      result.set(param.paramName, param.value[0])
      param.value
        .slice(1)
        .forEach((element) => result.append(param.paramName, element))
    } else {
      result.delete(param.paramName)
    }
  })

  if (paramsToRemove) {
    paramsToRemove.forEach((param) => {
      result.delete(param)
    })
  }

  return result.toString()
}
