import type { Identifier } from "dnd-core"
import { FC, useEffect } from "react"
import { useDrag, useDrop } from "react-dnd"
import React, { useRef } from "react"

import { Box, Button, Grid, styled, Divider, css, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import OpenWithIcon from "@mui/icons-material/OpenWith"
import { useTranslation } from "react-i18next"

export const ItemTypes = {
  CARD: "card",
}

export interface CardProps {
  id: string | number
  index: number
  locked: boolean
  hidden: boolean
  isEditing: boolean
  text: string
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const ListComponent: FC<CardProps> = ({
  id,
  index,
  moveCard,
  locked,
  hidden,
  isEditing,
  text,
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const { ready } = useTranslation("clientAdminPage")

  const [, drop] = useDrop<
    DragItem,
    { hoverIndex: number },
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!cardRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
    },
    drop() {
      return { hoverIndex: index }
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        return
      }

      const dropResult = monitor.getDropResult<{ hoverIndex: number }>()
      if (dropResult) {
        const { hoverIndex } = dropResult
        moveCard(item.index, hoverIndex)
      }
    },
    options: {
      dropEffect: "copy",
    },
  })

  useEffect(() => {
    if (iconRef.current) {
      iconRef.current.setAttribute("title", "Edit item")
    }
  }, [])

  if (!ready) {
    return null
  }
  return (
    <>
      <ListContainer
        item
        xs={12}
        sm={12}
        md={6}
        ref={(node) => {
          cardRef.current = node
          preview(drop(node))
        }}
        style={{
          pointerEvents: locked ? "none" : "auto",
          opacity: locked ? "0.4" : opacity,
          paddingLeft: "15px",
          paddingRight: "25px",
        }}
        isEditing={isEditing}
      >
        <ItemBox>
          <Box
            sx={{ padding: 0 }}
            aria-label={`Label: ${text}`}
            aria-disabled={hidden || locked}
          >
            <Label isHidden={hidden}>{text}</Label>
          </Box>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {hidden && (
                <StatusBadge role="status" aria-label={`Status: hidden`}>
                  Hidden
                </StatusBadge>
              )}
            </div>
            <div>
              {!locked && (
                <>
                  {isEditing ? (
                    <div
                      ref={iconRef}
                      {...drag(iconRef)}
                      aria-label="Drag item"
                      role="button"
                      tabIndex={0}
                      aria-pressed={isEditing}
                    >
                      <OpenWithIcon />
                    </div>
                  ) : (
                    <LabelButton
                      aria-label="Edit item"
                      role="button"
                      tabIndex={0}
                      aria-pressed={isEditing}
                      LinkComponent={Link}
                      href={`${id}/edit`}
                    >
                      <EditOutlinedIcon
                        aria-label="Edit item"
                        aria-hidden="false"
                      />
                    </LabelButton>
                  )}
                </>
              )}
            </div>
          </div>
        </ItemBox>
        <Divider />
      </ListContainer>
    </>
  )
}
const ListContainer = styled(Grid)`
  ${({ isEditing }) =>
    isEditing &&
    `
      &:hover {
        border: 3px solid #1ea3eb;
        cursor: pointer;
      }
    `}
`
const LabelButton = styled(Button)`
  padding: 0rem;
`

const ItemBox = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const StatusBadge = styled(Box)`
  ${({ theme }) => css`
    && {
      background-color: ${theme.palette.grey[600]};
      font-size: 0.75rem;
      color: ${theme.palette.white.main};
      padding: 0.25rem 0.5rem;
      border-radius: 1.25rem;
      margin-right: 0.5rem;
    }
  `}
`

const Label = styled("p")(({ isHidden }) => ({
  color: isHidden ? "rgba(0, 0, 0, 0.38)" : "inherit",
  margin: "0.9rem",
  marginTop: "0.4rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "1rem",
}))
