import {
  getTitleProps,
  getDateOfBirthProps,
  getWorkInfoFields,
  getPersonalInfoFields,
} from "./yourDetailsFields"

import { Box, MenuItem, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import GenericField from "../shared/form/GenericField"
import GenericSelect from "../shared/form/GenericSelect"
import DateOfBirth from "../shared/form/DateOfBirth"
export function YourDetails() {
  const { t, ready } = useTranslation(["constants", "bikeQuote"])
  const workInfoFields = getWorkInfoFields()
  const personalInfoFields = getPersonalInfoFields()
  const titleProps = getTitleProps()
  const dateOfBirthProps = getDateOfBirthProps()
  const TITLES = t("constants.TITLES", {
    returnObjects: true,
    useSuspense: false,
  })

  if (!ready) {
    return null
  }

  return (
    <>
      {workInfoFields.map((field) => {
        return (
          <GenericField
            textFieldProps={field}
            key={`${field.id}-input-field`}
          />
        )
      })}

      <SelectRow>
        <GenericSelect props={titleProps} style={{ width: "7rem" }}>
          {Object.values(TITLES).map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </GenericSelect>

        <DateOfBirth props={dateOfBirthProps} />
      </SelectRow>

      {personalInfoFields.map((field) => {
        return (
          <GenericField
            textFieldProps={field}
            key={`${field.id}-input-field`}
          />
        )
      })}
    </>
  )
}

const SelectRow = styled(Box)`
  display: flex;
  column-gap: 2.375rem;
  margin-bottom: 1.875rem;
`
