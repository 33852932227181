import { Link } from "@mui/material"
import classNames from "classnames"

export type SubCategoryItemProps = {
  name: string
  isBold?: boolean | undefined
  link: string
}

type ColumnProps = {
  rows: SubCategoryItemProps[]
  setActive: (state: boolean) => void
  handleSubCategory: (name: string) => void
}

const Column = ({ rows, setActive, handleSubCategory }: ColumnProps) => {
  const handleClick = (link: string, name: string) => {
    handleSubCategory(name)
    setActive(false)
  }
  return (
    <div className="mega-menu-tab__categories">
      {rows.map(({ name, isBold, link }) => {
        return (
          <Link
            key={`ls-tab-${name}-btn`}
            className={classNames("mega-menu-tab__subcategory", {
              "mega-menu-tab__title": isBold,
            })}
            onClick={() => handleClick(link, name)}
            href={link}
            sx={{ display: "block" }}
          >
            {name}
          </Link>
        )
      })}
    </div>
  )
}

export const AllCategoriesColumns = ({
  subcategories,
  setActive,
  handleSubCategory,
}: {
  subcategories: SubCategoryItemProps[]
  setActive: (state: boolean) => void
  handleSubCategory: (name: string) => void
}) => {
  // if length of subcategories is not divisible by 2, first column has one more row
  const numOfRowsFirstColumn = Math.ceil(subcategories.length / 2)

  return (
    <>
      <Column
        setActive={setActive}
        rows={subcategories.slice(0, numOfRowsFirstColumn)}
        handleSubCategory={handleSubCategory}
      />
      <Column
        setActive={setActive}
        rows={subcategories.slice(numOfRowsFirstColumn)}
        handleSubCategory={handleSubCategory}
      />
    </>
  )
}

export const SavingTypeColumns = ({
  subcategories,
  setActive,
  handleSubCategory,
}: {
  subcategories: SubCategoryItemProps[]
  setActive: (state: boolean) => void
  handleSubCategory: (name: string) => void
}) => {
  // there are only six categories, no need to divide into two columns
  return (
    <Column
      setActive={setActive}
      rows={subcategories}
      handleSubCategory={handleSubCategory}
    />
  )
}
