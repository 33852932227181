import { Box, css, styled } from "@mui/material"
import { Button } from "./Ofsted"
export interface FamilyCareProps {
  link: string
}

const FamilyCare: React.FC<FamilyCareProps> = ({ link }) => {
  return (
    <MainContainer>
      <SecondaryContainer>
        <div>
          <div style={{ paddingBottom: "20px" }}>
            Ready to manage your child care costs with Family Pay?
          </div>
          <div style={{ paddingBottom: "20px" }}>
            Our Family Pay employee benefit enables you to salary sacrifice up
            to 80% of your childcare costs over a 12 month period. This reduces
            your National Insurance and pension contributions, helping you
            control your expenditure. Family Pay can be used in conjunction with
            other government provided schemes such as the tax-free childcare
            account and funded hours*.
          </div>
          <div>*see Gov.uk for full details.</div>
          <ButtonContainer>
            <Button
              variant="gradient"
              href={link}
              data-cy="start-exploring-btn"
            >
              Start Exploring
            </Button>
          </ButtonContainer>
        </div>
      </SecondaryContainer>
    </MainContainer>
  )
}
export default FamilyCare
const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 256px;
    background-color: ${theme.palette.white.main};
    display: flex;
    justify-content: flex-start;
    color: #35185f;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;

    /* Tablets (max-width: 959px) */
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-height: 500px;
      width: unset;
      justify-content: flex-start;
    }
    /* Mobile devices (max-width: 599px) */
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 500px;
      height: 100%;
      width: unset;
      justify-content: flex-start;
    }
  `}
`

export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 25px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 15px;
    }
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-bottom: 40px;
    }
  `}
`

export const SecondaryContainer = styled(Box)`
  ${({ theme }) => css`
    width: 96%;
    max-height: 950px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 764px;
      height: 190px;
      padding-right: unset;
      padding-left: 10px;
      text-align: unset;
    }
  `}
`
