import { styled, css, Box, Grid } from "@mui/material"
import {
  ButtonContainer,
  ContentGrid,
  ImageContainer,
  ImageGrid,
  MainGrid,
  ParagraphText,
} from "../cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import { ButtonStyle } from "../cycle-to-work/BikeShopHardCodeComponent"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import i18n from "../../../i18n"

export interface PayRollGivingProps {
  link: string
}

const StartExploringPayRollGiving: React.FC<PayRollGivingProps> = ({
  link,
}) => {
  const { t, ready } = useTranslation("payrollGiving")
  const taxFree_image = useBuildAwsImageUrl(i18n.language, "MainImage.png")

  if (!ready) {
    return null
  }
  return (
    <MainBox>
      <ContentContainer>
        <MainGrid container>
          <ContentGrid item xs={12} md={5}>
            <TextContainer>
              <ParagraphText>
                {t("payrollGiving.startExploringPayRollGiving.firstPara")}
              </ParagraphText>
              <br />
              <ParagraphText>
                {t("payrollGiving.startExploringPayRollGiving.secondPara")}
              </ParagraphText>
              <br />
              <ParagraphText>
                {t("payrollGiving.startExploringPayRollGiving.thirdPara")}
              </ParagraphText>

              <ButtonContainer>
                <ButtonStyle
                  href={link}
                  component={"a"}
                  aria-label="Get started button"
                  rel="noopener"
                >
                  {t(
                    "payrollGiving.startExploringPayRollGiving.getStartedButton"
                  )}
                </ButtonStyle>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
          <ImageGrid item xs={12} md={7}>
            <ImageContainer
              src={taxFree_image}
              alt="Start exploring payroll giving"
            />
          </ImageGrid>
        </MainGrid>
      </ContentContainer>
    </MainBox>
  )
}
export default StartExploringPayRollGiving

export const MainBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 3rem;
    color: ${theme.palette.white.main};
    height: 24.125rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    @media screen and (max-width: 899px) {
      height: 39.8125rem;
      margin-top: 0rem;
    }
  `}
`
export const TextContainer = styled(Grid)`
  max-width: 25.8125rem;
  width: 100%;
  height: 21.375rem;
  color: white;
  display: flex;
  justify-content: center;
  opacity: 1;
  flex-direction: column;
  @media screen and (max-width: 899px) {
    display: flex;
    justify-content: center;
    padding: 0.6rem;
    height: 21.8125rem;
  }
`
export const ContentContainer = styled(Box)`
  max-width: 65.1875rem;
  width: 100%;
  height: 21.375rem;
  @media screen and (max-width: 899px) {
    height: 39.8125rem;
  }
`
