/**
 * Below responsive breakpoints and number of slidesToShow are set after testing what looks good at that particular width with lifestyle saving cards.
 * Because H&E/ C2W cards have different dimensions to LSS, so two different variables are defined
 * Even the variable names seem to suggest they are only applicable to carousel cards in LSS or C2W, but any carousel with LSS card dimension can use LSS breakpoints, and carousel with H&E/C2W cards can use C2W breakpoints
 */
export const LSS_CARDS_RESPONSIVE_BREAKPOINTS = [
  {
    breakpoint: 1099,
    settings: {
      slidesToShow: 3.8,
    },
  },
  // From 899px and below there are no more arrows
  {
    breakpoint: 899,
    settings: {
      slidesToShow: 3.5,
      arrows: false,
    },
  },
  {
    breakpoint: 735,
    settings: {
      slidesToShow: 2.5,
      arrows: false,
    },
  },
  {
    breakpoint: 520,
    settings: {
      slidesToShow: 1.6,
      arrows: false,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1.4,
      arrows: false,
    },
  },
]

export const C2W_CARDS_RESPONSIVE_BREAKPOINTS = [
  {
    breakpoint: 1360, //1099
    settings: {
      slidesToShow: 3.8,
    },
  },
  {
    breakpoint: 1099,
    settings: {
      slidesToShow: 3.2,
    },
  },
  {
    breakpoint: 899,
    settings: {
      slidesToShow: 3.2,
      arrows: false,
    },
  },
  {
    breakpoint: 819,
    settings: {
      slidesToShow: 2.3,
      arrows: false,
    },
  },
  {
    breakpoint: 599,
    settings: {
      slidesToShow: 1.8,
      arrows: false,
    },
  },
  {
    breakpoint: 499,
    settings: {
      slidesToShow: 1.5,
      arrows: false,
    },
  },
  {
    breakpoint: 409,
    settings: {
      slidesToShow: 1.3,
      arrows: false,
    },
  },
  {
    breakpoint: 359,
    settings: {
      slidesToShow: 1.1,
      arrows: false,
    },
  },
]
