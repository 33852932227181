import { css, styled, Typography } from "@mui/material"

export const TitleHeading = styled(Typography)`
  ${({ theme }) => css`
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    margin-bottom: 20px;
    line-height: 28px;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 26px;
    }
  `}
`
export const SubTitleHeading = styled(Typography)`
  ${({ theme }) => css`
    font-size: 14px;
    text-align: center;
    font-weight: medium;
    margin-bottom: 18px;
    color: ${theme.palette.primary.main};
    margin-left: auto;
    margin-right: auto;
    max-width: 282px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 2.375rem;
      font-size: 1rem;
    }
  `}
`
