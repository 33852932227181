import { createContext } from "react"
import { useGetRegQuestionsDataQuery } from "../../../graphqGenaretedTypes"

export type RegQuestionsContextData = {
  securityQuestions: {
    placeholder: string | null
    question: string
    id: string
  }[]
}

const defaultValue: RegQuestionsContextData = {
  securityQuestions: [],
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useRegQuestionsData: () => {
  data: RegQuestionsContextData
} = function () {
  const data = useGetRegQuestionsDataQuery({
    errorPolicy: "all",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      securityQuestions:
        data?.data?.currentUser?.employeeOrganisations &&
        data?.data?.currentUser?.employeeOrganisations[0]?.securityQuestions,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const RegQuestionsContext = createContext(defaultValue)
