import { useState } from "react"
import { useFormikContext } from "formik"
import { NewLocalDiscountValue } from "./formikUtils"
import {
  ContainerStyle,
  StyledPara,
  StyledSwitch,
} from "../ShowOnPortalPageButton"
import { useTranslation } from "react-i18next"

const Toggle = () => {
  const formik = useFormikContext<NewLocalDiscountValue>()
  const [checked, setChecked] = useState(formik.values.isVisible)
  const { t, ready } = useTranslation("newLocalDiscount")

  const labelValue = () => {
    if (checked) {
      return t("isVisible")
    } else {
      return t("isVisibleNot")
    }
  }

  const checkBoxClick = () => {
    setChecked(!checked)
    formik.setFieldValue("isVisible", !checked)
  }

  const label = {
    inputProps: { "aria-label": labelValue() },
  }

  if (!ready) {
    return null
  }
  return (
    <>
      <ContainerStyle>
        <StyledSwitch {...label} checked={checked} onClick={checkBoxClick} />
        <StyledPara>{labelValue()}</StyledPara>
      </ContainerStyle>
    </>
  )
}
export default Toggle
