import React from "react"
import { Box } from "@mui/system"
import { styled, Typography, Button, css } from "@mui/material"
import { useTranslation } from "react-i18next"

type Paragraph = {
  subHeading?: string
  content: string
}

export const TextContent: React.FC<
  React.PropsWithChildren<{
    closeOverlay: React.MouseEventHandler<HTMLElement>
    isOverlayOpen: boolean
    isMobile?: boolean
  }>
> = ({ closeOverlay, isOverlayOpen }) => {
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }

  const paragraphs: Paragraph[] = t("landingPage.overlay.paragraphs", {
    returnObjects: true,
    useSuspense: false,
  })

  return (
    <TextContainer>
      <Heading variant="h3">{t("landingPage.overlay.heading")}</Heading>
      {Object.values(paragraphs).map(({ content, subHeading }, index) => {
        if (subHeading) {
          return (
            <Paragraph key={`ls-paragraph-${index}`}>
              <Subheading>{subHeading}</Subheading>
              {" - "}
              {content}
            </Paragraph>
          )
        }
        return <Paragraph key={`ls-paragraph-${index}`}>{content}</Paragraph>
      })}

      <CloseButton onClick={closeOverlay} aria-expanded={isOverlayOpen}>
        {t("landingPage.overlay.close")}{" "}
      </CloseButton>
    </TextContainer>
  )
}
const TextContainer = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    display: grid;
    row-gap: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    grid-template-rows: repeat(6, auto);

    max-width: 26rem;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: unset;
      margin: unset;
      padding-left: 1rem;
      /* padding-top: 1rem; */
      padding-right: 0;
      row-gap: 0.7rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding-left: 4rem;
      /* padding-top: 2rem; */
      row-gap: 0.7rem;
    }
  `}
`

const Heading = styled(Typography)`
  ${({ theme }) => css`
    && {
      color: ${theme.palette.white.main};
      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.h3.fontSize};
      font-weight: 700;
      line-height: ${theme.typography.h3.lineHeight};
    }
  `}
`
const Paragraph = styled(Typography)`
  ${({ theme }) => css`
    && {
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body1.lineHeight};
      font-family: ${theme.typography.fontFamily};
    }
  `}
`

const Subheading = styled("span")`
  font-weight: 700;
`

const CloseButton = styled(Button)`
  ${({ theme }) => css`
    width: fit-content;
    color: ${theme.palette.white.main};
    padding: 0;
    text-decoration: underline;
  `}
`
