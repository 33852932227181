import { styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export function Disclaimer() {
  const { t, ready } = useTranslation("bikeQuote")

  if (!ready) {
    return null
  }
  return <StyledDisclaimer>{t("disclaimer")}</StyledDisclaimer>
}

const StyledDisclaimer = styled(Typography)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-top: 29px;
`
