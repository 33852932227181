import { TextFieldProps } from "@mui/material"
import i18n from "../../../i18n"

export const getConfirmationPersonalEmailField = (): TextFieldProps => ({
  id: "confirmationPersonalEmail",
  name: "confirmationPersonalEmail",
  label: i18n.t("regForm:regConfirm.enterPersonalEmail"),
  fullWidth: true,
  required: false,
  type: "text",
})
export const getNonEditableWorkEmailField = (
  label: string
): TextFieldProps => ({
  id: "nonEditableWorkEmail",
  name: "nonEditableWorkEmail",
  label: label,
  fullWidth: true,
  required: false,
  type: "text",
})
