import { styled, Box, Grid, Typography, css } from "@mui/material"
import { ExploreButton } from "../../../lifestyle-savings/shared-styles"
import { useParams } from "react-router-dom"
import { gtmCycleToWorkBenefitWorksNavigationClick } from "../../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import snakeToCamel from "../../../../utils/snakeToCamel"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const HowBenefitWorks = ({
  bikeDepartmentId,
  isBikeShop,
}: {
  bikeDepartmentId?: number
  isBikeShop?: boolean
}) => {
  const params = useParams()
  const event_action = "benefits Navigation"
  const { t, i18n, ready } = useTranslation(
    snakeToCamel(params.schemeType || "bike_shop")
  )

  const manCycling = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/ManCycling.png"
  )

  if (!ready) {
    return null
  }

  return (
    <MainBox data-cy="how-the-benefit-works">
      <ContentContainer>
        <MainGrid container>
          <ImageGrid item xs={12} md={7.5}>
            <ImageContainer src={manCycling} alt="" />
          </ImageGrid>
          <ContentGrid item xs={12} md={4.5}>
            <TextContainer>
              <TextTitle>{t("landing.howBenefitWorks.mainHeader")}</TextTitle>
              <ParagraphText>{t("landing.howBenefitWorks.para")}</ParagraphText>

              <ButtonContainer>
                <Button
                  href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${bikeDepartmentId}`}
                  onClick={() => {
                    !isBikeShop &&
                      gtmCycleToWorkBenefitWorksNavigationClick(event_action)
                  }}
                >
                  {t("landing.howBenefitWorks.button")}
                </Button>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
        </MainGrid>
      </ContentContainer>
    </MainBox>
  )
}
export default HowBenefitWorks
export const MainBox = styled(Box)`
  ${({ theme }) => css`
    background: linear-gradient(180deg, #2fa7db 0%, #351960 100%);
    width: 100%;
    background: transparent linear-gradient(89deg, #35195f 0%, #92237f 100%) 0%
      0% no-repeat padding-box;
    height: 533px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-bottom: 2rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 503px;
      margin-bottom: 1.9rem;
    }
  `}
`
export const ImageGrid = styled(Grid)`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      text-align: center;
    }
  `}
`
export const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 1043px;
    width: 100%;
    height: 342px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 503px;
    }
  `}
`
export const MainGrid = styled(Grid)`
  max-width: 1043px;
  height: 342px;
  width: 100%;
`
export const ImageContainer = styled("img")`
  ${({ theme }) => css`
    max-width: 570px;
    width: 100%;
    height: 342px;
    color: white;
    border-radius: 16px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 326px;
      width: 100%;
      height: 196px;
      padding-top: 33px;
    }
  `}
`
export const TextContainer = styled(Grid)`
  ${({ theme }) => css`
    max-width: 439px;
    width: 100%;
    height: 342px;
    color: white;
    display: flex;
    justify-content: center;
    opacity: 1;
    flex-direction: column;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;

      height: 241px;
    }
  `}
`
export const TextTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.white.main};
    font-weight: bold;
    margin-bottom: 15px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 32px;
    }
  `}
`
export const ParagraphText = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
    }
  `}
`
export const Button = styled(ExploreButton)`
  ${({ theme }) => css`
    && {
      background-color: #ffffff;
      color: ${theme.palette.primary.main};
      display: flex;
      margin: 0rem;
      max-width: 246px;
      width: 100%;
      height: 50px;

      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 220px;
        width: 100%;
        text-align: center;
      }

      /* add hover effect */
      &:hover {
        opacity: 0.75;
      }
    }
  `}
`
export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 40px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 15px;
    }
  `}
`
export const ContentGrid = styled(Grid)`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
    }
  `}
`
