import { createContext } from "react"
import { useParams } from "react-router"
import { useGetManageBenefitGroupsDataQuery } from "../../graphqGenaretedTypes"

export interface Item {
  id: string
  label: string
  locked: boolean
  position: number
  enabled: boolean
}

export type ManageBenefitGroupsContextData = {
  benefits: Item[]
  loading: boolean
}

const defaultValue: ManageBenefitGroupsContextData = {
  benefits: [],
  loading: false,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const ManageBenefitGroupsContext = createContext(defaultValue)

export const useManageBenefitGroupsData: (group: string) => {
  data: ManageBenefitGroupsContextData
} = function (group) {
  const { organisationId } = useParams()

  const data = useGetManageBenefitGroupsDataQuery({
    variables: {
      organisationId: organisationId || "",
      portalColumn: group,
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      benefits:
        data.data?.employerOrganisation?.localGroupedBenefits?.map(
          (item, index) => ({
            id: item.id,
            label: item.label,
            locked: item.locked,
            position: index + 1,
            enabled: item.enabled,
          })
        ) || [],
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
