type rowDataType = {
  rowData: {
    header: string
    content: string
    isAlwaysOn: boolean
  }
  isChecked: boolean
  onCheckboxChange?: (header: "Functional") => void
}

const PopupRow = ({ rowData, isChecked, onCheckboxChange }: rowDataType) => {
  const { header, content, isAlwaysOn } = rowData
  return (
    <>
      <div className="cookie__popup-row">
        <h3 className="cookie__popup-header">{header}</h3>
        <p className="cookie__popup-content">{content}</p>
        {isAlwaysOn ? (
          <div className="cookie__popup-switch-container">Always On</div>
        ) : (
          <div className="cookie__popup-switch-container">
            <label
              className="cookie__popup-switch-button"
              aria-label={`${header} cookie switch`}
            >
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() =>
                  onCheckboxChange && onCheckboxChange(header as "Functional")
                }
              />
              <span className="cookie__popup-switch-slider"></span>
            </label>
          </div>
        )}
      </div>
      <hr className="cookie__divider" />
    </>
  )
}

export default PopupRow
