import { Box, Container, Grid, styled, Typography, css } from "@mui/material"
import iconsData from "./hardCodedData"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

export function IconsSection({ onlyCarBenefit }: { onlyCarBenefit: boolean }) {
  const isMobile = useIsMobileView()
  const blueCar = useBuildAwsImageUrl("en-GB", "blueCar.png")

  if (onlyCarBenefit && !isMobile) {
    return (
      <OnePropositionGreyBox>
        <Content />
        <OnePropositionImageContainer>
          <OnePropositionImage src={blueCar} alt="tusker-go-green" />
        </OnePropositionImageContainer>
      </OnePropositionGreyBox>
    )
  }
  return (
    <GreyBox>
      <Content />
    </GreyBox>
  )
}

function Content() {
  const { t, i18n, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <Container>
      <FlexContainer container justifyContent="center">
        {iconsData.map((data) => {
          return (
            <ItemsContainer item key={`car-benefit-icon-${data.label}`}>
              <Item>
                <Icon
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  src={useBuildAwsImageUrl(i18n.language, data.imageName)}
                  alt={data.label}
                />
                <IconText>{t(`carBenefit.images.${data.label}`)}</IconText>
              </Item>
            </ItemsContainer>
          )
        })}
      </FlexContainer>
    </Container>
  )
}

// TODO: all the px to rem

const OnePropositionImageContainer = styled(Box)`
  height: 345px;
  justify-self: baseline;
  display: flex;
  align-items: end;
`

const OnePropositionImage = styled("img")`
  height: 90%;
`

const GreyBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 204px;
    background: #f8f9fc;
    border-radius: ${theme.boxBorderRadius};
    display: grid;
    place-items: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      min-height: 308px;
    }
  `}
`
const OnePropositionGreyBox = styled(GreyBox)`
  height: 345px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-bottom: 14px;
`

const Item = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
const Icon = styled("img")`
  ${({ theme }) => css`
    height: 2rem;
    align-self: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 2.5rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      height: 50px;
    }
  `}
`
const IconText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin-top: 8px;
    font-weight: bold;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 16px;
      line-height: 20px;
    }
  `}
`
const ItemsContainer = styled(Grid)`
  ${({ theme }) => css`
    width: 19%;
    height: 60px;
    margin: 14px 4px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 101px;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      width: 90px;
    }
  `}
`

const FlexContainer = styled(Grid)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      row-gap: 4px;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      gap: 24px;
    }
  `}
`
