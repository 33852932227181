import {
  MainHeader,
  PropositionSpaceOneSubHeader as C2WSubHeader,
  HeaderSectionPadding1,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"

export function Header({ onlyC2W }: { onlyC2W: boolean }) {
  const isMobile = useIsMobileView()

  if (onlyC2W && !isMobile) {
    return <Content />
  }
  return (
    <HeaderSectionPadding1>
      <Content />
    </HeaderSectionPadding1>
  )
}

function Content() {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <>
      <MainHeader variant="h3" align="left">
        {t("cycleToWork.mainHeader")}
      </MainHeader>
      <C2WSubHeader variant="h4" align="left">
        {t("cycleToWork.subHeader")}
      </C2WSubHeader>
    </>
  )
}
