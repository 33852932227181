export function genericTypePredicate<T>(input: unknown): input is T {
  /**
   * To narrow union to target Type T
   * T: the type one wanted to narrowed down to
   * input: JS variable, not type layer variable
   *
   * e.g.:
   * input has a union type of `DiscountCategory | ProductType`
   * and I want to narrow it down to DiscountCategory
   * call this function like so: genericTypePredicate<DiscountCategory>(input) and use it as a type guard
   */
  return input !== undefined
}
