import { Button } from "@mui/material"
import affordabilityCalculatorIcon from "./images/affordability-calculator.svg"
import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import Modal from "./Modal"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function AffordabilityCalculatorButton() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [modalOpen, setModalOpen] = useState(false)
  const { t, ready } = useTranslation("productDetail")

  if (!ready) {
    return null
  }
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        sx={{
          color: (theme) => theme.palette.primary.main,
          ":hover": {
            backgroundColor: "inherit",
          },
        }}
        className="affordability-calculator-button"
      >
        <img
          src={affordabilityCalculatorIcon}
          alt={t("productDetail.StickyACButton.Imgalt")}
          className={classNames("affordability-calculator-button__icon", {
            "affordability-calculator-button__icon--mobile": !isDesktop,
          })}
        />
        <div
          className={classNames("affordability-calculator-button__text", {
            "affordability-calculator-button__text--mobile": !isDesktop,
          })}
        >
          {t("productDetail.StickyACButton.label")}
        </div>
      </Button>
      <Modal open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}
