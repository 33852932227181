import { Autocomplete, styled, TextField, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { FormikValue } from "./initialValues"
import { useFormData } from "./QuotePageContext"
import ClearIcon from "@mui/icons-material/Clear"
import { useTranslation } from "react-i18next"

const id = "bikeShop"

const BikeShop = () => {
  const {
    formData: { suppliers },
  } = useFormData()
  const { t, ready } = useTranslation("bikeQuote")

  const formik = useFormikContext<FormikValue>()

  const touched = Object.keys(formik.touched).includes(id)
  const error = Object.keys(formik.errors).includes(id)
  const invalid = touched && error ? true : false
  if (suppliers && ready) {
    return (
      <div>
        <Autocomplete
          sx={{ marginBottom: "1.875rem" }}
          disablePortal // for iOS voice over support
          fullWidth
          id="bikeShop"
          options={suppliers}
          isOptionEqualToValue={(option, value) => {
            return option.label === String(value)
          }}
          clearIcon={
            formik.values.bikeShop.label ? <ClearIcon fontSize="small" /> : null
          }
          // TODO: deal with type error
          value={formik.values[id].label}
          onChange={(e, value) => {
            if (!value) {
              formik.setFieldValue(id, formik.initialValues.bikeShop)
            } else {
              formik.setFieldValue(id, value)
            }
          }}
          onBlur={formik.handleBlur}
          // TODO: ask Sharon for this part of text
          noOptionsText={t("bikeShop.noOptions")}
          ListboxProps={{ role: "listbox" }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={t("bikeShop.label")}
              error={invalid}
              helperText={invalid && formik.errors[id]?.label}
            />
          )}
        />
        {formik.values[id].label &&
          formik.values.bikeShop.bikeShopQuoteInformation && (
            <BikeShopQuoteInfo variant="body1">
              {t("bikeShop.pleaseNotes", {
                quoteInformation:
                  formik.values.bikeShop.bikeShopQuoteInformation,
              })}
            </BikeShopQuoteInfo>
          )}
      </div>
    )
  }
  return <></>
}

export default BikeShop

const BikeShopQuoteInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; // not in design but needed to create gap to the next line
  overflow-wrap: break-word;
`
