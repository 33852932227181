import { string, ref } from "yup"
import i18n from "../../i18n"
import commonPasswords from "../commonPasswords"

// export const passwordRegex =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,1000}$/
// export const passwordMediumRegex =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,1000}$/

export const passwordValidation = string()
  .min(8, () => i18n.t("regForm:password.lengthValidationError"))
  .required(() => i18n.t("regForm:password.requiredValidationError"))
  .test(
    "is password in the blocked list",
    () => i18n.t("regForm:password.blockPassword"),
    (password) => password !== undefined && !(password in commonPasswords)
  )

export const reEnterPasswordValidation = string()
  .required(() => i18n.t("regForm:reEnterPassword.required"))
  .min(8, () => i18n.t("regForm:password.lengthValidationError"))
  .when("password", {
    is: (password: string) => !!password && password.length >= 8,
    then: string().oneOf([ref("password")], () =>
      i18n.t("regForm:reEnterPassword.notMatchValidationError")
    ),
  })
