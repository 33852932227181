import { number } from "yup"
import i18n from "../../i18n"

export const bikeValueValidation = number()
  .positive(() => i18n.t("bikeQuote:validations.bikeValueEmptyWarning"))
  .when("brandOfBike", {
    is: (brandOfBike: string) => brandOfBike && brandOfBike.length > 0,
    then: number().required(() =>
      i18n.t("bikeQuote:validations.bikeValueEmptyWarning")
    ),
  })
  .when("typeOfBike", {
    is: (typeOfBike: string) => typeOfBike && typeOfBike.length > 0,
    then: number().required(() =>
      i18n.t("bikeQuote:validations.bikeValueEmptyWarning")
    ),
  })
  .required(() => i18n.t("bikeQuote:validations.bikeValueEmptyWarning"))
