import { FamilyCareProps } from "./FamilyCare"
import {
  ImageGrid,
  ContentContainer,
  MainGrid,
  TextContainer,
  TextTitle,
  ParagraphText,
  ContentGrid,
  ButtonContainer,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import {
  MainBoxContainer,
  ImageContainerStyle,
  ButtonStyle,
} from "./Howbenifitworks"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
const HowFamilyCareWorks: React.FC<FamilyCareProps> = ({ link }) => {
  const { i18n } = useTranslation()
  const elderCare = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ElderCare.png"
  )

  return (
    <MainBoxContainer>
      <ContentContainer>
        <MainGrid container>
          <ImageGrid item xs={12} md={7.5}>
            <ImageContainerStyle src={elderCare} alt="ElderCare" />
          </ImageGrid>
          <ContentGrid item xs={12} md={4.5}>
            <TextContainer>
              <TextTitle>How it works</TextTitle>
              <ParagraphText>
                Should your employer provide a care budget, you can expense care
                or support to help solve family challenges. This may even
                include funding activities, allowing your trusted network of
                friends and family to help facilitate the care need
              </ParagraphText>

              <ButtonContainer>
                <ButtonStyle
                  href={link}
                  component={"a"}
                  aria-label="Explore Family Care"
                >
                  Explore Family Care
                </ButtonStyle>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
        </MainGrid>
      </ContentContainer>
    </MainBoxContainer>
  )
}
export default HowFamilyCareWorks
