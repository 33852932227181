import { styled, css, Button as MuiButton } from "@mui/material"
import { HealthAndWellbeing } from "../../../../graphqGenaretedTypes"
import Content from "./Content"
import { Button } from "./Button"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

export const HEALTH_ICONS = [
  {
    name: "resources",
    img: "resources_1.png",
  },
  {
    name: "support",
    img: "support.png",
  },
  {
    name: "advice",
    img: "advice.png",
  },
]

export const YOUR_CARE_ICONS = [
  {
    name: "resources",
    img: "resources_2.png",
  },
  {
    name: "goals",
    img: "health_goals.png",
  },
  {
    name: "support",
    img: "support.png",
  },
  {
    name: "advice",
    img: "advice.png",
  },
]

export const LEVEL_5 = [
  {
    name: "resources",
    img: "resources_2.png",
  },
  {
    name: "goals",
    img: "health_goals.png",
  },
  {
    name: "advice",
    img: "advice.png",
  },
]

export function GreenBoxContent({ data }: { data: HealthAndWellbeing }) {
  const { level } = data
  const isMobileView = useIsMobileView()
  const { t, ready, i18n } = useTranslation("portalHomepage")
  let images: string[] = []
  let icons: { name: string; img: string }[] = []

  if (level === "level_1" || level === "level_2") {
    images = HEALTH_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = HEALTH_ICONS
  } else if (level === "level_3" || level === "level_4") {
    images = YOUR_CARE_ICONS.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = YOUR_CARE_ICONS
  } else if (level === "level_5") {
    images = LEVEL_5.map((icon) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useBuildAwsImageUrl(i18n.language, icon.img)
    )
    icons = LEVEL_5
  }

  const exclamationMark = useBuildAwsImageUrl(
    i18n.language,
    "exclaimation-mark.png"
  )

  if (!ready) {
    return null
  }
  if (level === "hidden") {
    return (
      <ExclamationMark
        src={exclamationMark}
        alt={t("healthAndWellbeing.images.exclamation")}
      />
    )
  }

  if (level === "disabled") {
    return (
      <>
        <ExclamationMark
          src={exclamationMark}
          alt={t("healthAndWellbeing.images.exclamation")}
          sx={isMobileView ? { height: "132px", marginBottom: "25px" } : null}
        />
        {isMobileView && <Button data={data} />}
      </>
    )
  }

  if (level === "level_1" || level === "level_2" || level === "level_5") {
    return (
      <>
        <ButtonContainer>
          {icons.map((icon, index) => (
            <IconContainer
              href={data.link || ""}
              component="a"
              target="_blank"
              rel="noopener"
            >
              <div style={{ height: "3.5rem" }} className="content-center">
                <img
                  src={images[index]}
                  alt={t(`healthAndWellbeing.buttons.${icon.name}`)}
                />
              </div>
              <div>{t(`healthAndWellbeing.buttons.${icon.name}`)}</div>
            </IconContainer>
          ))}
        </ButtonContainer>
        {isMobileView && <Button data={data} />}
      </>
    )
  }
  // level_3 & level_4
  return (
    <>
      <ButtonContainer>
        {icons.map((icon, index) => (
          <IconContainer
            sx={{ width: "8rem" }}
            href={data.link || ""}
            component="a"
            target="_blank"
            rel="noopener"
          >
            <div style={{ height: "3.5rem" }} className="content-center">
              <img
                src={images[index]}
                alt={t(`healthAndWellbeing.buttons.${icon.name}`)}
              />
            </div>
            <div>{t(`healthAndWellbeing.buttons.${icon.name}`)}</div>
          </IconContainer>
        ))}
      </ButtonContainer>
      {isMobileView && <Button data={data} />}
    </>
  )
}

const ExclamationMark = styled("img")`
  display: block;
  margin: 0 auto;
`

export default Content

const ButtonContainer = styled("div")`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

const IconContainer = styled(MuiButton)`
  ${({ theme }) => css`
    display: flex;
    width: 7.8rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.75rem;
    padding: 0;
    line-height: 1.2;
    padding-bottom: 0.25rem;
    color: ${theme.palette.primary.main};
  `}
`
