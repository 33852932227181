import { MenuItem, TextFieldProps } from "@mui/material"
import GenericSelect from "../../shared/form/GenericSelect"
const REASONS = [
  "Forgotten password",
  "Didn't receive confirmation instructions",
  "Didn't receive unlock instructions",
]
type SelectReasonFieldType = {
  selectedReasonFieldProps: TextFieldProps
}
export function SelectReasonField({
  selectedReasonFieldProps,
}: SelectReasonFieldType) {
  return (
    <GenericSelect
      props={selectedReasonFieldProps}
      sx={{ marginBottom: "1.5rem" }}
    >
      {REASONS.map((selectReason) => (
        <MenuItem key={selectReason} value={selectReason}>
          {selectReason}
        </MenuItem>
      ))}
    </GenericSelect>
  )
}
