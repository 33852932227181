import { Button, styled } from "@mui/material"
import { useState } from "react"
import SecondaryPopUp from "./cycle-to-work/SecondaryPopUp"
import useTriggerSavingCalculationsCall from "../cycle-to-work/quote-page/useTriggerSavingCalculationsCall"
import { useTranslation } from "react-i18next"

export type BannerType = {
  savings: number
  label: string
  orderValue: string
  brochureGroup: string
}

function SavingsBanner({
  savings,
  label,
  orderValue,
  brochureGroup,
}: BannerType) {
  const [openModal, setOpenModal] = useState(false)
  const { t, ready } = useTranslation("productDetail")
  const { calculatedResult, quoteType } = useTriggerSavingCalculationsCall({
    costOfQuote: orderValue,
    brochureGroupId: brochureGroup,
    triggerCalculation: true,
  })

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  if (!ready) {
    return null
  }
  return (
    <>
      <SavingsButton onClick={handleOpenModal}>
        {t("productDetail.SavingsBanner.youCouldSave")}&nbsp;
        <strong>£{savings.toFixed(2)}*</strong>
        &nbsp;{t("productDetail.SavingsBanner.on")} {label}
      </SavingsButton>
      <SecondaryPopUp
        handleModalClose={handleModalClose}
        open={openModal}
        handleCloseModal={handleModalClose}
        showButtons={false}
        data={calculatedResult}
        quoteType={quoteType}
      />
    </>
  )
}

const SavingsButton = styled(Button)(
  ({ theme }) => `
  background-color: #3F7700;
  text-align: center;
  color: ${theme.palette.white.main};
  font-size: 1rem;
  position: fixed;
  width: 100%;
  height: 3.125rem;
  bottom: 0%;
  z-index: 10;
  border-radius: 0;
  &:hover {
    background-color: #3F7700;
  }
  @media screen and (min-width: ${theme.breakpoints.values.md}px) {
    font-size: 1.5rem;
  }
`
)

export default SavingsBanner
