import { Box, Container, Grid, styled, css } from "@mui/material"
import Button from "@mui/material/Button"
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike"
import StorefrontIcon from "@mui/icons-material/Storefront"
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import React from "react"
import CalculateSavingsModal from "./CalculateSavingsModal"
import { useParams } from "react-router-dom"
import { gtmCycleToWorkBenefitNavigationClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import snakeToCamel from "../../../utils/snakeToCamel"

export type CycleToWorkButtonGridProps = {
  data: { label: string; link: string }[]
  store?: string
  isCycleToWork?: boolean
}

const CycleToWorkButtonGrid = ({
  data,
  isCycleToWork,
}: CycleToWorkButtonGridProps) => {
  const icons = [
    <DirectionsBikeIcon />,
    <StorefrontIcon />,
    <DescriptionOutlinedIcon />,
  ]

  const [openModal, setOpenModal] = React.useState(false)

  const params = useParams()
  const { t, ready } = useTranslation(
    snakeToCamel(params.schemeType || "bike_shop")
  )
  const handleOpenModal = (label: string) => {
    setOpenModal(true)
    {
      isCycleToWork && gtmCycleToWorkBenefitNavigationClick(label)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const links = data.map((item) => {
    const newlink = item.link.replace(
      ":organisationId",
      `${params.organisationId}`
    )
    return newlink
  })

  const lastItem = data[data.length - 1]
  const items = data.slice(0, data.length - 1)

  if (!ready) {
    return null
  }
  return (
    <MainContainer store={params.schemeType}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid item container xs={12} sm={12} md={12}>
          {items.map((item, index) => (
            <CycleToWorkExploreButton
              key={item.label}
              onClick={() => {
                const label = item.label
                isCycleToWork && gtmCycleToWorkBenefitNavigationClick(label)
              }}
              href={links[index]}
              aria-label={`${item.label} button`}
            >
              <div style={{ color: "#92237F", paddingRight: "10px" }}>
                {icons[index]}
              </div>

              {item.label}
            </CycleToWorkExploreButton>
          ))}

          {lastItem && params.schemeType !== "bike_shop" && (
            <CycleToWorkExploreButton
              onClick={() => {
                const label = lastItem.label
                handleOpenModal(label)
              }}
              aria-label={`${lastItem.label} button`}
            >
              <div style={{ color: "#92237F", paddingRight: "10px" }}>
                <SavingsOutlinedIcon />
              </div>
              {lastItem.label}
            </CycleToWorkExploreButton>
          )}
          {params.schemeType === "bike_shop" ? (
            <CycleToWorkExploreButton
              aria-label={t("landing.topButtons.cycleToWorkBenefit")}
              component="a"
              href={`/organisations/${params.organisationId}/employee/benefits/cycle_to_work/landing`}
            >
              <div style={{ color: "#92237F", paddingRight: "10px" }}>
                <DirectionsBikeIcon />
              </div>
              {t("landing.topButtons.cycleToWorkBenefit")}
            </CycleToWorkExploreButton>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      <CalculateSavingsModal
        openModal={openModal}
        handleClose={handleCloseModal}
      />
    </MainContainer>
  )
}

export default CycleToWorkButtonGrid

const MainContainer = styled(Container)<CycleToWorkButtonGridProps>`
  &&&& {
    max-width: 1184px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${(props) => (props.store !== "bike_shop" ? "2rem" : "")};
  }
`

export const CycleToWorkExploreButton = styled(Button)`
  ${({ theme }) => css`
    && {
      background-color: ${theme.palette.white.main};
      display: block;
      max-width: 16.875rem;
      width: 100%;
      height: 2.6875rem;
      margin: 2rem auto;
      margin-top: 1rem;
      margin-bottom: 2.188rem;
      color: ${theme.palette.primary.main};
      font-size: 17px;
      font-weight: bold;
      font-family: ${theme.typography.fontFamily};
      line-height: ${theme.typography.body2.lineHeight};
      text-align: center;
      border-radius: 6px;
      box-shadow: ${theme.boxShadow};
      opacity: 1;
      &:hover {
        background-color: ${theme.palette.grey[200]};
      }

      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        max-width: 16.875rem;
        width: 100%;
        height: 2.6875rem;
      }
    }
  `}
` as typeof Button
