import { StepType } from "./Form"
import { DeliveryFieldsType, DetailsFieldsType } from "./defaultFormContent"

export default function parseErrors(
  responseErrors: { fieldName: string; message: string }[]
): {
  detailsErrors: { fieldName: keyof DetailsFieldsType; message: string }[]
  deliveryErrors: { fieldName: keyof DeliveryFieldsType; message: string }[]
  globalErrors: { message: string }[]
  errorStep: StepType
} {
  const deliveryErrors: {
    fieldName: keyof DeliveryFieldsType
    message: string
  }[] = []
  let errorStep: StepType = "delivery"
  if (responseErrors.length > 0) {
    responseErrors.forEach((error) => {
      let fieldNameEquivalent: keyof DeliveryFieldsType | undefined = undefined
      switch (error.fieldName) {
        case "postcode":
          fieldNameEquivalent = "postCode"
          break
        case "town":
          fieldNameEquivalent = "town"
          break
        case "county":
          fieldNameEquivalent = "county"
          break
        case "address_1":
          fieldNameEquivalent = "lineOne"
          break
        case "address_2":
          fieldNameEquivalent = "lineTwo"
          break
      }
      if (fieldNameEquivalent) {
        deliveryErrors.push({
          fieldName: fieldNameEquivalent,
          message: error.message,
        })
      }
    })
  }

  const detailsErrors: {
    fieldName: keyof DetailsFieldsType
    message: string
  }[] = []
  if (responseErrors.length > 0) {
    responseErrors.forEach((error) => {
      let fieldNameEquivalent: keyof DetailsFieldsType | undefined = undefined
      switch (error.fieldName) {
        case "first_name":
          fieldNameEquivalent = "firstName"
          break
        case "last_name":
          fieldNameEquivalent = "lastName"
          break
        case "email":
          fieldNameEquivalent = "emailAddress"
          break
        case "place_of_work":
          fieldNameEquivalent = "placeOfWork"
          break
        case "employee_number":
          fieldNameEquivalent = "employeeNumber"
          break
        case "title":
          fieldNameEquivalent = "title"
          break
        case "date_of_birth":
          fieldNameEquivalent = "dateOfBirth"
          break
        case "mobile_number":
          fieldNameEquivalent = "mobileNumber"
          break
        case "home_phone":
          fieldNameEquivalent = "phoneNumber"
          break
        case "ni_number":
          fieldNameEquivalent = "niNumber"
          break
      }
      if (fieldNameEquivalent) {
        detailsErrors.push({
          fieldName: fieldNameEquivalent,
          message: error.message,
        })
        errorStep = "details"
      }
    })
  }

  const globalErrors = responseErrors
    .filter(({ fieldName }) => fieldName == "global")
    .map(({ message }) => {
      return { message }
    })

  return {
    detailsErrors: detailsErrors,
    deliveryErrors: deliveryErrors,
    globalErrors: globalErrors,
    errorStep: errorStep,
  }
}
