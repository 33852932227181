import { createContext } from "react"
import { useGetPrivacyNoticeDataQuery } from "../../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export type PrivacyNoticeContextData = {
  contactEmail: string
  loading: boolean
}

const defaultValue: PrivacyNoticeContextData = {
  contactEmail: "",
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const PrivacyNoticeContext = createContext(defaultValue)

export const usePrivacyNoticeData: () => {
  data: PrivacyNoticeContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetPrivacyNoticeDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const contactEmail =
      data.data?.employeeOrganisation?.privacyNoticeContactEmail
    const loading = data.loading

    state = {
      ...defaultValue,
      contactEmail,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
