import { string } from "yup"
import i18n from "../../i18n"

const maxCharactersAllowed = 200

export const descriptionOfBikeValidation = string()
  .required(() => i18n.t("bikeQuote:validations.descriptionOfBikeEmptyWarning"))
  .max(maxCharactersAllowed, () =>
    i18n.t("bikeQuote:validations.descriptionOfBikeMaxCharactersWarning")
  )
