import { InputAdornment } from "@mui/material"
import { useTranslation } from "react-i18next"
import GenericField from "../../shared/form/GenericField"
import { getValidationNumberField } from "./fields"
import InfoTooltip from "../../shared/InfoTooltip"
import useIsMobileView from "../../../utils/useIsMobileView"
import { FieldWithTooltip, ToolTipContainer } from "./SignUp"
import { useFormikContext } from "formik"
import {
  SignUpFormikValue,
  defaultValidationNumberRequired,
} from "./formikUtils"
import useTriggerSignUpParametersQuery from "../useTriggerSignUpParametersQuery"
import { useEffect } from "react"

export function ValidationNumberField() {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("regForm")
  const textFieldProps = getValidationNumberField()
  const formik = useFormikContext<SignUpFormikValue>()
  const data = useTriggerSignUpParametersQuery(formik.values.organisation.id)
  const validationType =
    data?.organisation?.validationNumberType || "Employee number"

  const validationNumberRequired =
    data?.organisation?.validationNumberRequired ??
    defaultValidationNumberRequired

  useEffect(() => {
    // update the salaryBracketState in formik.values for validationSchema
    // formik.setFieldValue triggers re-render, need to be inside useEffect
    formik.setFieldValue("validationNumberRequired", validationNumberRequired)
    formik.setFieldValue("validationNumberType", validationType)
    // eslint-disable-next-line
  }, [validationNumberRequired])

  const camelValidationType = validationType
    ? validationType[0].toLowerCase() +
      validationType
        .toLowerCase()
        .split(" ")
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join("")
        .slice(1)
    : ""

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip>
      <GenericField
        textFieldProps={textFieldProps}
        sx={
          isMobileView
            ? {
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
                marginBottom: 0,
              }
            : { marginBottom: 0 }
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                label: validationType ? validationType : textFieldProps.label,
                required: validationNumberRequired,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip
                        content={t(
                          `regForm:validationNumber.tooltip.${camelValidationType}`
                        )}
                      />
                    </InputAdornment>
                  ),
                },
              }
            : {
                label: validationType ? validationType : textFieldProps.label,
                required: validationNumberRequired,
              }
        }
      />
      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip
            content={t(
              `regForm:validationNumber.tooltip.${camelValidationType}`
            )}
          />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
