import classNames from "classnames"

import MegaMenuTab, { MegaMenuTabProps } from "./Tab"
import { promotionalItem } from "../../../utils/specialPromoHelper"
import { Link } from "@mui/material"
import { KeyboardEvent } from "react"

export type MegaMenuItemProps = {
  active: boolean
  setActive: (state: boolean) => void
  name: string
  featured?: boolean
  link: string
  storeLink: string
  categories: MegaMenuTabProps["categories"]
  imageUrl: string
}

function MegaMenuItem({
  active,
  setActive,
  name,
  link,
  storeLink,
  featured = false,
  categories,
  imageUrl,
}: MegaMenuItemProps) {
  const actionKey = (e: KeyboardEvent) => {
    if (e.key == "ArrowDown") {
      e.preventDefault()
      setActive(true)
    } else if (e.key == "ArrowUp") {
      e.preventDefault()
      setActive(false)
    }
  }
  return (
    <>
      <Link
        className={classNames("mega-menu-item", {
          "mega-menu-item--active": active,
          "mega-menu-item--featured": featured,
          "mega-menu-item--promo": promotionalItem(name),
        })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        href={link}
        onKeyDown={actionKey}
        onClick={() => setActive(false)}
      >
        {name}
      </Link>
      {!promotionalItem(name) && (
        <MegaMenuTab
          name={name}
          link={storeLink}
          categories={categories}
          imageUrl={imageUrl}
          active={active}
          setActive={setActive}
        />
      )}
    </>
  )
}

export default MegaMenuItem
