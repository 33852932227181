import { Box, css, styled } from "@mui/material"
import { Button } from "./Ofsted"
import { SecondaryContainer, ButtonContainer } from "./FamilyCare"
export interface FamilyCareProps {
  link: string
}

const StartExploringFamilyCare: React.FC<FamilyCareProps> = ({ link }) => {
  return (
    <MainContainer>
      <SecondaryContainer>
        <div>
          <div style={{ paddingBottom: "20px" }}>
            Our Family Care benefit enables you to fund care support from people
            you know and trust
          </div>
          <div style={{ paddingBottom: "20px" }}>
            If you're in need of care for a family member or pet, you can browse
            a network of care providers and support helplines through one
            comprehensive platform to find vetted care solutions via a
            geotargeted search engine or extensive resource library
          </div>
          <div style={{ paddingBottom: "20px" }}>
            Not only does this mean you won't have to miss work if the
            unexpected happens, but you'll also enjoy peace of mind knowing that
            your loved ones are in safe hands
          </div>
          <ButtonContainer>
            <Button
              variant="gradient"
              href={link}
              component={"a"}
              aria-label="Start Exploring"
              data-cy="start-exploring-btn"
            >
              Start Exploring
            </Button>
          </ButtonContainer>
        </div>
      </SecondaryContainer>
    </MainContainer>
  )
}
export default StartExploringFamilyCare
const MainContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 310px;
    background-color: ${theme.palette.white.main};
    display: flex;
    justify-content: flex-start;
    color: #35185f;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;

    /* Tablets (max-width: 959px) */
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-height: 550px;
      width: unset;
      justify-content: flex-start;
    }
    /* Mobile devices (max-width: 599px) */
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 500px;
      height: 100%;
      width: unset;
      justify-content: flex-start;
    }
  `}
`

export const ButtonContainerText = styled(ButtonContainer)`
   {
    margin-top: 10px;
  }
`
