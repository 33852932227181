import { Box, styled } from "@mui/material"

export const WaveSvg = () => {
  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1920.696 1621.533"
        preserveAspectRatio="none"
      >
        <path
          d="M7715.75,672.88c514.685-82.726,931.7,0,931.7,0l-.006-1584.766H6726.751V672.88S7201.064,755.606,7715.75,672.88Z"
          transform="translate(8647.447 709.647) rotate(180)"
          fill="#eae9ee"
        />
      </svg>
    </SvgContainer>
  )
}
const SvgContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
