import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled, css } from "@mui/material"
import { gtmLifestyleFeatureTileClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

export type ImgMediaCardProps = {
  index: number
  title?: string
  imageUrl: string
  description?: string | null
  url: string
}
export default function ImgMediaCard({
  index,
  title,
  imageUrl,
  description,
  url,
}: ImgMediaCardProps) {
  const dataHandler = (name: string | undefined, index: number) => {
    gtmLifestyleFeatureTileClick(name, index)
  }
  const theme = useTheme()
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }

  return (
    <Card
      sx={{
        marginTop: "2rem",
        width: "21.313rem",
        height: "28.625rem",
        borderRadius: theme.boxBorderRadius,
        boxShadow: "0rem 0.188rem 0.188rem #00000029",
      }}
    >
      <CardMedia
        component="img"
        alt={t("landingPage.cardGrid.alt")}
        height="140"
        image={imageUrl}
        sx={{ height: "14.813rem", borderRadius: theme.boxBorderRadius }}
      />
      <CardContent sx={{ padding: "0.625rem" }}>
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            textAlign: "center",
            fontWeight: "bold !important",
            color: theme.palette.primary.main,
            marginTop: "1rem",
            marginBottom: "1rem",
            fontSize: "1.5rem",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            marginTop: "1rem",
            color: theme.palette.primary.main,
            height: "2.438rem !important",
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <ExploreButton
        variant="gradient"
        onClick={() => {
          dataHandler(title, index + 1)
        }}
        href={url}
        aria-label={t("landingPage.cardGrid.button", { title })}
      >
        {t("landingPage.cardGrid.button")}
      </ExploreButton>
    </Card>
  )
}

const ExploreButton = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    width: 17.375rem;
    height: 3.125rem;
    margin: 2rem auto;
    margin-top: 1rem;
    margin-bottom: 2.188rem;
    color: ${theme.palette.white.main};
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: center;
    border-radius: 2.625rem;
    box-shadow: ${theme.boxShadow};
    opacity: 1;
    max-width: 90%;
  `}
`
