import { TextFieldProps } from "@mui/material"
import i18n from "../../i18n"

interface TextFieldsProps {
  totalValueProps: TextFieldProps
}
export const getTextFieldsProps = (): TextFieldsProps => ({
  totalValueProps: {
    id: i18n.t("quotePage:orderDetailsField.totalValue"),
    name: i18n.t("quotePage:orderDetailsField.totalValue"),
    label: i18n.t("quotePage:orderDetailsField.label"),
    fullWidth: true,
    type: "text",
  },
})

export default getTextFieldsProps
