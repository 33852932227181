import {
  SxProps,
  TextField,
  TextFieldProps,
  styled,
  InputAdornment,
} from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { useFormikContext } from "formik"
import FormikValueType from "../shared/form/formikValueType"

type GenericInputProps = {
  textFieldProps: TextFieldProps
  style?: CSSProperties // inline style props passed to TextField
  sx?: SxProps
  inlineTextFieldProps?: TextFieldProps
}

const DonationFieldValue = <T extends FormikValueType>({
  textFieldProps,
  style,
  sx,
  inlineTextFieldProps,
}: GenericInputProps) => {
  const { id } = textFieldProps
  const formik = useFormikContext<T>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      formik.handleChange(event)
    }
  }

  if (id) {
    const touched = formik.touched[id as keyof T]
    const error = formik.errors[id as keyof T]
    const invalid = touched && error ? true : false

    return (
      <div>
        <StyledTextField
          {...textFieldProps}
          style={style && style}
          sx={sx && sx}
          error={invalid}
          value={formik.values[id]}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          helperText={invalid && (formik.errors[id] as string)}
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
            inputProps: { inputMode: "decimal", pattern: "^d*.?d{0,2}$" },
          }}
          {...inlineTextFieldProps}
        />
      </div>
    )
  }
  return <></>
}

export default DonationFieldValue

const StyledTextField = styled(TextField)`
  margin-bottom: 1.875rem;
`
