export function arrayToSubArrays<T>(
  input: Array<T>,
  size: number
): Array<Array<T>> {
  /**
   * Slice an array into sub-arrays for pagination
   * so that each sub-array can be used for map() render element on different page
   */
  const subArrays = []
  let count = 0

  while (count < input.length) {
    subArrays.push(input.slice(count, count + size))
    count += size
  }

  return subArrays
}
