import { Box, styled } from "@mui/material"
import { DESKTOP_CONTAINER_WIDTH } from "../shared/SharedStyles"

export const MobileContainer = styled(Box)`
  padding: 1rem 0;
  & > div {
    margin-bottom: 3rem;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`
export const OnePropositionContainer = styled(Box)`
  width: 93%;
  margin: 0 auto;
  max-width: ${DESKTOP_CONTAINER_WIDTH}px;

  & > div:first-of-type {
    margin-top: 0;
  }
  & > div {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`
export const TwoPropositionsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;

  width: 93%;
  margin: 0 auto;
  max-width: ${DESKTOP_CONTAINER_WIDTH}px;
`
