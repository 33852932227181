import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Button from "@mui/material/Button"
import { ClickAwayListener, Tooltip } from "@mui/material"

export type DrawerProps = {
  title: string
  label: JSX.Element
  content: JSX.Element
  open: boolean
  setOpen: (open: boolean) => void
  labelSx?: React.CSSProperties
  disabled?: boolean
  tooltip?: string
}

export default function Drawer({
  title,
  label,
  content,
  open,
  setOpen,
  labelSx = {
    fontWeight: open ? 700 : 500,
  },
  disabled = false,
  tooltip = "",
}: DrawerProps) {
  const toggleDrawer =
    (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setOpen(state)
    }

  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <Button
            onClick={toggleDrawer(!open)}
            sx={labelSx}
            title={title}
            disabled={disabled}
          >
            {label}
          </Button>
        </span>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          top: "4.375rem",
          height: "calc(100% - 4.375rem)",
        }}
        PaperProps={{
          sx: {
            width: "90%",
            top: "4.375rem",
            height: "calc(100% - 4.375rem)",
          },
        }}
        BackdropProps={{
          sx: {
            top: "4.375rem",
          },
        }}
        swipeAreaWidth={10}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className="nav-item__drawer-content">{content}</div>
        </ClickAwayListener>
      </SwipeableDrawer>
    </>
  )
}
