const popupText = [
  {
    header: "Strictly Necessary",
    content:
      "These cookies are needed to enable our website to run and keep it secure",
    isAlwaysOn: true,
  },
  {
    header: "Statistics/Performance",
    content:
      "These cookies tell us how customers use our site and provide information to help us to improve the website and your browsing experience",
    isAlwaysOn: true,
  },
  {
    header: "Functional",
    content:
      "These cookies allow us to provide enhanced functionality, they personalise content for you. For example they're used to recognise you when you return to our website. If you do not allow these cookies then some or all of these services may not function properly",
    isAlwaysOn: false,
  },
]

export default popupText
