import { string } from "yup"
import i18n from "../../i18n"
const notValidMessage = () => i18n.t("quotePage:errorMessage.validValue")
const rangeValidMessage = () => i18n.t("quotePage:errorMessage.rangeValue")

export const totalValueValidation = string()
  .required(() => i18n.t("quotePage:errorMessage.emptyError"))
  .matches(/^\d*\.?\d{0,2}$/, {
    message: notValidMessage,
    excludeEmptyString: true,
  })
  .test({
    name: "range",
    message: rangeValidMessage,
    test: (value) => {
      const numericValue = Number(value)
      return !isNaN(numericValue) && numericValue >= 5 && numericValue <= 10000
    },
  })
