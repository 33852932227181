import { useContext } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { LSStoreLayoutContext } from "../../layout/lifestyle-savings/LSStoreLayoutContext"
import FilterSidebar from "./Filters"
import SupportLinks from "./SupportLinks"
import PillContainer from "./PillContainer"

export type SidebarProps = {
  isLifeStyleStore?: boolean
  isLifestyleFilters?: boolean
}
function Sidebar({ isLifestyleFilters, isLifeStyleStore }: SidebarProps) {
  const contextToUse = isLifeStyleStore
    ? LSStoreLayoutContext
    : StoreLayoutContext
  const { supportLinks } = useContext(contextToUse)

  return (
    <div className="sidebar">
      <PillContainer isLifestyleFilters={isLifestyleFilters} />
      <FilterSidebar isMobile={false} isLifestyleFilters={isLifestyleFilters} />
      <SupportLinks linkList={supportLinks} />
    </div>
  )
}

export default Sidebar
