import { GetSignUpParameters } from "./sign-up/SignUpContext"
import { useEffect, useState } from "react"
import { GetSignUpParametersQuery } from "../../graphqGenaretedTypes"

const emptyOrgId = ""

const useTriggerSignUpParametersQuery = (id: string) => {
  const [data, setData] = useState<GetSignUpParametersQuery>()
  const { refetch } = GetSignUpParameters(emptyOrgId)

  useEffect(() => {
    const refetchData = async () => {
      const { data } = await refetch({ id })

      setData(data)
    }

    refetchData()
  }, [id, refetch])

  return data
}

export default useTriggerSignUpParametersQuery
