import { styled, Box, css } from "@mui/material"

const CardImageContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <ImageContainer>{children}</ImageContainer>
}

export default CardImageContainer

export const ImageContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55%;
    width: 40%;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 100%;
    }
  `}
`
