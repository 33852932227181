import { Link } from "@mui/material"
import classNames from "classnames"

export type CustomItemProps = {
  name: string
  link: string
}

function CustomItem({ name, link }: CustomItemProps) {
  return (
    <>
      <Link
        className={classNames("mega-menu-item", {
          "mega-menu-item--active": false,
          "mega-menu-item--bold": true,
        })}
        href={link}
      >
        {name}
      </Link>
    </>
  )
}

export default CustomItem
