import { Box, styled, css } from "@mui/material"
import React from "react"
import CustomBreadcrumbs, { BreadcrumbsType } from "../shared/CustomBreadcrumbs"

type PayrollGivingLayoutType = {
  breadcrumbsItems?: BreadcrumbsType[]
}

const Layout: React.FC<React.PropsWithChildren<PayrollGivingLayoutType>> = ({
  breadcrumbsItems,

  children,
}) => {
  return (
    <React.Fragment>
      <BreadcrumbsContainer>
        <CustomBreadcrumbs breadcrumbsItems={breadcrumbsItems} />
      </BreadcrumbsContainer>

      {children}
    </React.Fragment>
  )
}

export default Layout

const BreadcrumbsContainer = styled(Box)`
  ${({ theme }) => css`
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 5rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 85%;
      max-width: 1212px;
      margin-top: 8rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 8rem;
    }
  `}
`
