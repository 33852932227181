import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import StickyButton from "./StickyButton"

export type ScrollToTopProps = {
  showBelow?: number
}

function ScrollToTop({ showBelow = 300 }: ScrollToTopProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    document.documentElement.focus()
  }

  return (
    <StickyButton
      showBelow={showBelow}
      onClick={scrollToTop}
      buttonLabel="Scroll to top"
    >
      <ArrowUpwardIcon
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontSize: "1.5rem",
        }}
      />
    </StickyButton>
  )
}

export default ScrollToTop
