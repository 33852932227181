import { useEffect, useState } from "react"
import CookieBanner from "./CookieBanner"
import CookiePopup from "./CookiePopup"
import cookieStorage from "../../utils/cookieStorage"
import axios from "axios"
import { useBasePath } from "../../utils/useBasePath"

const storageType = cookieStorage
const consentPropertyName = "cookie_consent"

export const currentConsent = () => {
  let consent = storageType.getItem(consentPropertyName)
  consent = consent ? JSON.parse(consent) : undefined
  return consent
}

const saveSettingsToStorage = function (settings: CookieContentType) {
  document.cookie = "name=value;path=/"
  let cookieContent = currentConsent()
  if (cookieContent) {
    cookieContent.functional = settings.functional.toString()
  } else {
    cookieContent = {
      functional: settings.functional.toString(),
    }
  }

  storageType.setItem(
    consentPropertyName,
    JSON.stringify(cookieContent) + ";path=/"
  )
  sync(settings)
}

const saveGuidToStorage = function (guid: string) {
  const cookieContent = currentConsent()
  cookieContent.guid = guid

  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + 90 * 24 * 60 * 60 * 1000)
  const expires = "; expires=" + expirationDate.toUTCString()

  storageType.setItem(
    consentPropertyName,
    JSON.stringify(cookieContent) + expires + ";path=/"
  )
}

const sync = (settings: CookieContentType) => {
  axios
    .post("/cookie_management/sync", {
      cookie_data: {
        functional: settings.functional.toString(),
      },
      guid: currentConsent()?.guid,
    })
    .then(function (response) {
      if (currentConsent()?.guid !== response.data.guid) {
        saveGuidToStorage(response.data.guid)
      }
    })
}

export type CookieType = {
  setShouldShowCookieModal?: React.Dispatch<React.SetStateAction<boolean>>
  setShouldShowPopup?: React.Dispatch<React.SetStateAction<boolean>>
  saveSettingsToStorage: (settings: CookieContentType) => void
  consent?: CookieContentType
}

export type CookieContentType = {
  functional: boolean
}

type CookiePopupType = {
  shouldShowCookiePopup: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}

const Cookie = ({
  shouldShowCookiePopup,
  setShouldShowCookiePopup,
}: CookiePopupType) => {
  const location = useBasePath()
  const [shouldShowBanner, setShouldShowBanner] = useState(
    currentConsent() ? false : true
  )

  useEffect(() => {
    setShouldShowBanner(currentConsent() ? false : true)
  }, [location])

  return (
    <>
      {shouldShowCookiePopup && <div className="cookie__modal" />}

      {shouldShowCookiePopup ? (
        <CookiePopup
          setShouldShowCookieModal={setShouldShowBanner}
          setShouldShowPopup={setShouldShowCookiePopup}
          saveSettingsToStorage={saveSettingsToStorage}
          consent={currentConsent()}
        />
      ) : (
        <>
          {shouldShowBanner && (
            <CookieBanner
              setShouldShowCookieModal={setShouldShowBanner}
              setShouldShowPopup={setShouldShowCookiePopup}
              saveSettingsToStorage={saveSettingsToStorage}
            />
          )}
        </>
      )}
    </>
  )
}

export default Cookie
