const disableCarouselAriaHidden = (id: string) => {
  const targetElement = document.getElementById(id)

  if (targetElement) {
    const slickSlideElements = targetElement.querySelectorAll(".slick-slide")

    for (let i = 0; i < slickSlideElements.length; i++) {
      const slickSlideElement = slickSlideElements[i]
      if (slickSlideElement.getAttribute("aria-hidden") === "true") {
        slickSlideElement.setAttribute("aria-hidden", "false")
      }
    }
  }
}

export default disableCarouselAriaHidden
