import { useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const useClientAdminBreadcrumb = () => {
  const params = useParams()
  const location = useLocation()
  const { t } = useTranslation("clientAdminBreadcrumb")
  const currentUrlSegments = location.pathname.split("/")
  const lastSegment = currentUrlSegments[currentUrlSegments.length - 1]

  const breadcrumbsMap: { [key: string]: { label: string; link: string }[] } = {
    [`benefit_management/local_benefit/${params.id}/edit`]: [
      { label: t("editCustomTile"), link: "#" },
    ],
    "benefit_management/add_a_new_benefit_tile": [
      { label: t("addCustomTile"), link: "#" },
    ],
    [`benefit_management/noticeboard/${params.id}/edit`]: [
      { label: t("editNoticeboard"), link: "#" },
    ],
    "benefit_management/add_a_new_noticeboard_tile": [
      { label: t("addNewNoticeboard"), link: "#" },
    ],
    [`benefit_management/health_and_wellbeing/${params.id}/edit`]: [
      {
        label: t("yourCareWellbeing"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/health_and_wellbeing`,
      },
      { label: t("editWellbeingItem"), link: "#" },
    ],
    "benefit_management/health_and_wellbeing": [
      { label: t("yourCareWellbeing"), link: "#" },
    ],
    "benefit_management/add_a_new_wellbeing": [
      {
        label: t("yourCareWellbeing"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/health_and_wellbeing`,
      },
      { label: t("addWellbeingItem"), link: "#" },
    ],
    [`benefit_management/family_pay/${params.id}/edit`]: [
      {
        label: t("manageFamilyPay"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/family_pay`,
      },
      { label: t("editFamilyPayItem"), link: "#" },
    ],
    "benefit_management/family_pay": [
      { label: t("manageFamilyPay"), link: "#" },
    ],
    "benefit_management/add_a_new_family_pay": [
      {
        label: t("manageFamilyPay"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/family_pay`,
      },
      { label: t("addFamilyPayItem"), link: "#" },
    ],
    [`benefit_management/family_care/${params.id}/edit`]: [
      {
        label: t("manageFamilyCare"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/family_care`,
      },
      { label: t("editFamilyCareItem"), link: "#" },
    ],
    "benefit_management/family_care": [
      { label: t("manageFamilyCare"), link: "#" },
    ],
    "benefit_management/add_a_new_family_care": [
      {
        label: t("manageFamilyCare"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/family_care`,
      },
      { label: t("addFamilyCareItem"), link: "#" },
    ],
    [`benefit_management/local_discounts/${params.id}/edit`]: [
      {
        label: t("manageLocalDiscounts"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/local_discounts`,
      },
      { label: t("editLocalDiscount"), link: "#" },
    ],
    "benefit_management/local_discounts/new": [
      {
        label: t("manageLocalDiscounts"),
        link: `/organisations/${params.organisationId}/employer/benefit_management/local_discounts`,
      },
      { label: t("newLocalDiscount"), link: "#" },
    ],
    "benefit_management/local_discounts": [{ label: "", link: "#" }],
  }

  const matchedKey = Object.keys(breadcrumbsMap).find((key) =>
    location.pathname.includes(key)
  )
  const shouldShowDefaultBreadcrumb =
    matchedKey !== "benefit_management/local_discounts"

  const defaultBreadcrumb =
    shouldShowDefaultBreadcrumb && lastSegment !== "benefit_management"
      ? [
          {
            label: t("benefitManagement"),
            link: `/organisations/${params.organisationId}/employer/benefit_management`,
          },
        ]
      : []

  const breadcrumbsItems = matchedKey
    ? [...defaultBreadcrumb, ...breadcrumbsMap[matchedKey]]
    : defaultBreadcrumb

  return breadcrumbsItems
}
