import { Breadcrumbs, css, Link, styled, Typography } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { setNestedObjectValues, useFormikContext } from "formik"
import { FormikValue } from "./initialValues"
import scrollToElement from "./scrollToElement"
import { useTranslation } from "react-i18next"

type TabProps = {
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  isOverQuoteLimit: boolean
}

function Tabs({ activeTab, setActiveTab, isOverQuoteLimit }: TabProps) {
  const { t, ready } = useTranslation("bikeQuote")
  const tabText = [
    t("tab.orderDetails"),
    t("tab.yourDetails"),
    t("tab.submitOrder"),
  ]

  const formik = useFormikContext<FormikValue>()

  // TODO: refactor this bit with handleStepValidation() under QuotePage.tsx
  const handleClick = async (index: number) => {
    // validate the current page
    formik.isValidating = true
    const validationErrors = await formik.validateForm()

    if (isOverQuoteLimit) {
      scrollToElement("#totalOrderValue")
    } else if (Object.keys(validationErrors).length > 0) {
      // set each error field as touch=true, so error message shows
      formik.setTouched(setNestedObjectValues(validationErrors, true))
      formik.isValidating = false
      // scroll to the error field
      scrollToElement(".Mui-error, [data-error]")
    } else {
      formik.setTouched({})
      setActiveTab(index)
      formik.isValidating = false
      window.scrollTo(0, 0) // or scroll to top of the form?
    }
  }

  if (!ready) {
    return null
  }
  return (
    <FormTabs
      separator={<TabSeparator color="primary" />}
      aria-label="breadcrumb"
    >
      {tabText.map((text, index) => {
        return (
          <Link
            underline="none"
            href="#"
            key={`tab-${index}`}
            onClick={() => handleClick(index)}
          >
            <FormTabText
              variant="h3"
              sx={index == activeTab ? { fontWeight: 700 } : undefined}
            >
              {text}
            </FormTabText>
          </Link>
        )
      })}
    </FormTabs>
  )
}

export default Tabs

const FormTabs = styled(Breadcrumbs)`
  ${({ theme }) => css`
    margin-bottom: 2rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 60px;
    }
  `}
`

export const TabSeparator = styled(NavigateNextIcon)`
  ${({ theme }) => css`
    font-size: 1rem;
    margin-left: 0.375rem;
    margin-right: 0.375rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  `}
`

export const FormTabText = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.875rem;
    line-height: 1rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  `}
`
