import { Box, css, styled } from "@mui/material"
import { MainContainer } from "../explore-your-benefits/BenefitCardModal"
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp"
import { useTranslation } from "react-i18next"
import Layout from "./Layout"
import { MainHeading, SecondPara, ThirdPara } from "./sign-up/RegThankYou"
import { useContext } from "react"
import { RegistrationLayoutContext } from "./RegistrationLayoutContext"
import LoggedOutBanner from "./LoggedOutBanner"
import { getShouldShowBanner } from "./registrationUtils"

const ThankYouForSubmission = () => {
  const { t } = useTranslation("registration")
  const { showBanner: portalProviderShowBanner, banner } = useContext(
    RegistrationLayoutContext
  )
  const globalShowBanner = banner?.show
  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  return (
    <Layout>
      <>
        <MainContainerBox>
          <ContentContainerBox>
            <div>
              <StyleMailIcon />

              <MainHeading>
                {t("registration:registration.submissionSuccessful.header")}
              </MainHeading>
              <SecondParaStyle>
                {t("registration:registration.submissionSuccessful.secondPara")}
              </SecondParaStyle>
              <ThirdParaStyle>
                {t("registration:registration.submissionSuccessful.thirdPara")}
              </ThirdParaStyle>
            </div>
          </ContentContainerBox>
        </MainContainerBox>
        {shouldShowBanner && banner?.heading && banner?.images && (
          <LoggedOutBanner
            bannerHeading={banner.heading}
            images={banner.images}
          />
        )}
      </>
    </Layout>
  )
}
export default ThankYouForSubmission
const MainContainerBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 4.81rem;
  /* min-height: 81vh; */
`
const StyleMailIcon = styled(CheckCircleSharpIcon)`
  ${({ theme }) => css`
    font-size: 1.25rem;
    margin-top: 2.2331rem;
    margin-bottom: 1.6706rem;
    width: 100%;
    height: 100%;
    max-width: 5.4375rem;
    max-height: 4.75rem;
    padding: 0px;
    color:green;
    && {
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        margin-top:1.9206rem;
        margin-bottom:1.8581rem;
        font-size: large;
        max-width: 6.9169rem;
        max-height: 5.9169rem;
        width:100%;
        height:100%
        padding: 0px;
    }
  `}
`

export const ContentContainerBox = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      width: 92%;
      height: 100%;
      display: flex;
      text-align: center;
      flex-direction: column-reverse;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        width: 40.89rem;
      }
    }
  `}
`

const SecondParaStyle = styled(SecondPara)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 34.1875rem;
    }
  `}
`
const ThirdParaStyle = styled(ThirdPara)`
  ${({ theme }) => css`
    margin-bottom: 2.5rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 33.8125rem;
    }
  `}
`
