import { TextFieldProps } from "@mui/material"
import i18n from "../../i18n"

const getAddressFields = (): TextFieldProps[] => [
  {
    id: "lineOne",
    name: "lineOne",
    label: i18n.t("bikeQuote:addressFields.line1"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "lineTwo",
    name: "lineTwo",
    label: i18n.t("bikeQuote:addressFields.line2"),
    fullWidth: true,
    required: false,
    type: "text",
  },
  {
    id: "town",
    name: "town",
    label: i18n.t("bikeQuote:addressFields.town"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "county",
    name: "county",
    label: i18n.t("bikeQuote:addressFields.county"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "postCode",
    name: "postCode",
    label: i18n.t("bikeQuote:addressFields.postCode"),
    fullWidth: true,
    required: true,
    type: "text",
    inputProps: { style: { textTransform: "uppercase" } },
  },
]

export default getAddressFields
