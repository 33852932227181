import { useState } from "react"
import { Container, Tabs, Tab, Box, Grid } from "@mui/material"
import ProductCarousel from "./product-carousels/ProductCarousel"
import { ProductType } from "./product-card/ProductCard"
import { useTranslation } from "react-i18next"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
export type TabcenterProps = {
  products: ProductType[]
  similarProducts: ProductType[]
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Module-tabpanel-${index}`}
      aria-labelledby={`Module-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-data">{children}</div>}
    </div>
  )
}

const tabbuttonProps = (index: number) => {
  return {
    id: `Module-tab-${index}`,
    "aria-controls": `Module-tabpanel-${index}`,
  }
}

function Tabcenter({ products, similarProducts }: TabcenterProps) {
  const [value, setValue] = useState(0)
  const { t, ready } = useTranslation("productDetail")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const carouselSliderId = "tabCenter"

  if (!ready) {
    return null
  }

  return (
    <>
      {(products.length > 0 || similarProducts.length > 0) && (
        <Box sx={{ width: "100%" }} className="tab-center">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label={t("productDetail.ModulasTab.ariaLabel")}
              centered
            >
              {products.length > 0 && (
                <Tab
                  tabIndex={0}
                  label={t("productDetail.ModulasTab.label")}
                  {...tabbuttonProps(0)}
                />
              )}
              {similarProducts.length > 0 && (
                <Tab
                  tabIndex={0}
                  // label not move to translation json because it will change to dynamic label later
                  label="Similar Products"
                  {...tabbuttonProps(products.length > 0 ? 1 : 0)}
                />
              )}
            </Tabs>
          </Box>
          {products.length > 0 && (
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Container>
                    <Grid
                      item
                      xs={12}
                      lg={9.665}
                      sx={{
                        marginX: "auto",
                      }}
                      id={carouselSliderId}
                    >
                      <ProductCarousel
                        productCardCarousel={products}
                        slidesToShow={4}
                        isRecommendedTab={true}
                        youMayAlsoLike={"You-may-also-like-product-clicked"}
                        productCarouselPopupGridId={carouselSliderId}
                      />
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </TabPanel>
          )}

          {similarProducts.length > 0 && (
            <TabPanel value={value} index={products.length > 0 ? 1 : 0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Container>
                    <Grid
                      item
                      xs={12}
                      lg={9.665}
                      sx={{
                        marginX: "auto",
                      }}
                      id={carouselSliderId}
                    >
                      <ProductCarousel
                        productCardCarousel={similarProducts}
                        slidesToShow={4}
                        isRecommendedTab={true}
                        isSimilarProduct={"Similar-product-clicked"}
                        productCarouselPopupGridId={carouselSliderId}
                      />
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </TabPanel>
          )}
        </Box>
      )}
    </>
  )
}

export default Tabcenter
