import Breadcrumbs from "../CustomBreadcrumbs"
import { Grid, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import Filters from "../../store/filters/FilterDrawer"
import { useContext } from "react"
import { StoreContext } from "../../store/StoreContext"
import { LSStoreContext } from "../../lifestyle-savings/LSStoreContext"
import { LocalBenefitStoreContext } from "../../lifestyle-savings/local-benefit-store/LocalBenefitStoreContext"

export type StoreBreadcrumbsProps = {
  showBreadcrumbs?: boolean
  isLifestyleFilters?: boolean
  showTopFilters: boolean
  isLocalBenefits?: boolean
}

function StoreBreadcrumbs({
  showBreadcrumbs = true,
  isLifestyleFilters,
  showTopFilters,
  isLocalBenefits = false,
}: StoreBreadcrumbsProps) {
  const contextToUse = isLifestyleFilters
    ? isLocalBenefits
      ? LocalBenefitStoreContext
      : LSStoreContext
    : StoreContext
  // contextToUse spits out correct result, resolve the error later
  const { breadcrumbs } = useContext(contextToUse)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const currentLabel =
    breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].label
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: { xs: "0.6rem", lg: "1rem" },
          marginLeft: { xs: "0rem", md: "1.5rem", lg: "8.4rem" },
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <div style={{ display: "none" }} id={"current-label"}>
          {currentLabel}
        </div>
        {showBreadcrumbs && <Breadcrumbs breadcrumbsItems={breadcrumbs} />}
      </Grid>
      <Grid item xs={5} md={4} lg={4}>
        {showTopFilters &&
          (isDesktop ? (
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: "1.5rem !important",
                fontWeight: "bold !important",

                marginLeft: {
                  xs: "0rem !important",
                  md: "1.5rem !important",
                  lg: "8.4rem !important",
                },
              }}
              id="current-label"
            >
              {currentLabel}
            </Typography>
          ) : (
            !isLocalBenefits && (
              <>
                <Filters isLifestyleFilters={isLifestyleFilters} />
              </>
            )
          ))}
      </Grid>
    </>
  )
}

export default StoreBreadcrumbs
