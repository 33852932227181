import { styled, Button } from "@mui/material"
import { CookieType } from "./Cookie"

const FirstPopupRow = ({
  setShouldShowCookieModal,
  setShouldShowPopup,
  saveSettingsToStorage,
}: CookieType) => {
  const handleAllowAllCookies = () => {
    saveSettingsToStorage({ functional: true })
    setShouldShowCookieModal && setShouldShowCookieModal(false)
    setShouldShowPopup && setShouldShowPopup(false)
  }

  return (
    <>
      <div className="cookie__popup-first-row">
        <h2 className="cookie__popup-header-h2">Manage cookies</h2>
        {/* mobile view */}
        <div className="cookie__mobile-flex-container">
          <p className="cookie__popup-manage-cookie-content">
            Learn more about what each cookie category does and choose your
            settings. {/* either use MUI Link or a, add href */}
            <a
              className="cookie__popup-inline-links"
              href="/cookie_policy"
              target="_blank"
              rel="noopener"
            >
              Cookie policy
            </a>{" "}
            and{" "}
            <a
              className="cookie__popup-inline-links"
              href="/cookie_list"
              target="_blank"
              rel="noopener"
            >
              List of cookies
            </a>
          </p>
          {/* use ".cookie__popup-allow-all-button" in html version */}
          <AllowAllButton variant="gradient" onClick={handleAllowAllCookies}>
            Allow all
          </AllowAllButton>
        </div>
      </div>
      {/* desktop view  */}
      <div className="cookie__desktop-flex-container">
        <p className="cookie__popup-manage-cookie-content">
          Learn more about what each cookie category does and choose your
          settings. {/* either use MUI Link or a, add href */}
          <a
            className="cookie__popup-inline-links"
            href="/cookie_policy"
            target="_blank"
            rel="noopener"
          >
            Cookie policy
          </a>{" "}
          and{" "}
          <a
            className="cookie__popup-inline-links"
            href="/cookie_list"
            target="_blank"
            rel="noopener"
          >
            List of cookies
          </a>
        </p>
        {/* use ".cookie__popup-allow-all-button" in html version */}
        <AllowAllButton variant="gradient" onClick={handleAllowAllCookies}>
          Allow all
        </AllowAllButton>
      </div>

      <div className="mobile-view cookie__divider" />
    </>
  )
}

export default FirstPopupRow

const AllowAllButton = styled(Button)`
  margin: 1.5rem auto 0.875rem auto;
  font-size: 1rem;
  padding: 0;
  width: 128px;
  height: 49px;

  @media screen and (min-width: 900px) {
    justify-self: end;
    margin: 0;
    flex: 0 0 124px;
  }
`
