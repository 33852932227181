import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { Box, Button, css, styled, Typography } from "@mui/material"
import { HealthAndWellbeingButton } from "../global-mega-menu/HealthAndWellbeingDesktopCard"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

export type SlideProps = {
  name?: string
  customLink: string
  handleLink: (customLink: string, external: boolean) => void
}
export type SlideCardProps = {
  name?: string
  customLink: string
  handleLink: (customLink: string, external: boolean) => void
  items: {
    label: string
    link?: string
  }[]
}
const FamilyPayNameHeading = ({ name, customLink, handleLink }: SlideProps) => {
  const { t } = useTranslation("portalHomepage")
  return (
    <div>
      {name === "familyPay" ? (
        <TittleLink
          className="mega-menu-tab__category mega-menu-tab__title"
          style={{
            paddingLeft: "0px",
            fontWeight: "bold",
          }}
          onClick={() => {
            handleLink(customLink, true)
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#302557",
              fontWeight: "bold",
            }}
          >
            {t("familyPay.familyPayHeading")}
          </div>
          <ArrowForwardIcon
            fontSize="inherit"
            className="mega-menu-tab__forward-arrow"
            sx={{
              color: "#35185f",
            }}
          />
        </TittleLink>
      ) : null}
    </div>
  )
}
const FamilyPayMobileCard = ({
  customLink,
  handleLink,
  items,
}: SlideCardProps) => {
  const { t, i18n } = useTranslation("portalHomepage")
  const childminder = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Childminder.png"
  )
  const nursery = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Nursery.png"
  )
  const holidayClubs = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/HolidayClubs.png"
  )
  const familyPayIconImage = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/FamilyPayIconImage.png"
  )
  return (
    <div style={{ paddingTop: "3rem" }}>
      <div style={{ textAlign: "center" }}>
        <img
          src={familyPayIconImage}
          alt="FamilyPayIconImage"
          style={{
            paddingBottom: "1rem",
            paddingTop: "22px",
            width: "152px",
            height: "55px",
          }}
        />
        <Cardlayout style={{ textAlign: "center" }}>
          {items.some((item) => item.label === "Nursery") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.2rem",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Nursery")?.link || "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyPayIcon src={nursery} alt="NurseryImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.nursery")}
              </TextContainer>
            </Button>
          )}

          {items.some((item) => item.label === "Childminder") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.2rem",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Childminder")?.link ||
                    "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyPayIcon src={childminder} alt="ChildminderImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.childminder")}
              </TextContainer>
            </Button>
          )}
          {items.some((item) => item.label === "Holiday Clubs") && (
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "6.2rem",
              }}
              onClick={() =>
                handleLink(
                  items.find((item) => item.label === "Holiday Clubs")?.link ||
                    "",
                  false
                )
              }
            >
              <ImageConatiner>
                <FamilyPayIcon src={holidayClubs} alt="HolidayClubsImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyPay.services.holidayClubs")}
              </TextContainer>
            </Button>
          )}
        </Cardlayout>
        <ExploreButton onClick={() => handleLink(customLink, true)}>
          {t("familyPay.buttonText")}
        </ExploreButton>
      </div>
    </div>
  )
}
export { FamilyPayNameHeading, FamilyPayMobileCard }
const TittleLink = styled("div")`
  &:hover {
    background-color: transparent;
  }
`

const ExploreButton = styled(HealthAndWellbeingButton)`
  &&&& {
    max-width: 283.15px;
    width: 100%;
    background-color: #6a6ba8;
    margin-top: 2rem;
  }
`
export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
  }
`
export const ContentContainerHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;

    font-size: 15px;
    line-height: 22px;
    margin-bottom: 4px;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 14 px;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 21px;
    }
  `}
`

export const ImageConatiner = styled(Box)`
  ${({ theme }) => css`
    max-width: 54.26px;
    width: 100%;
    height: 54.26px;
    background-color: white;
    opacity: 1;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 54.26px;
      max-width: 54.26px;
      width: 100%;
    }
  `}
`
export const FamilyPayIcon = styled("img")`
  width: 50px;
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      max-width: 100%;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 12px;
    margin-top: 8px; // Adjust the margin as needed
    font-weight: 700;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 4px; // Adjust the margin as needed
    }
  `}
`
