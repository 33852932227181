import PersonIcon from "@mui/icons-material/Person"
import HelpIcon from "@mui/icons-material/Help"
import { useTranslation } from "react-i18next"

import NavItem from "../../../shared/nav/Item"
import { useContext } from "react"
import { RegistrationLayoutContext } from "../../../registration/RegistrationLayoutContext"

export type RegistrationHeaderNavigationLinksProps = {
  BurgerMenu?: JSX.Element
  signInPage?: boolean
}

const RegistrationHeaderNavigationLinks = ({
  BurgerMenu,
  signInPage,
}: RegistrationHeaderNavigationLinksProps) => {
  const { t, ready } = useTranslation("registration")

  const { stopRegistration } = useContext(RegistrationLayoutContext)

  let navContent = t("registration.navItemContact")
  let link = "https://vivupbenefits.co.uk/contact-us"

  if (!stopRegistration) {
    if (signInPage) {
      navContent = t("registration.navItemRegister")
      link = "/users/sign_up"
    } else {
      navContent = t("registration.navItemSignIn")
      link = "/users/sign_in"
    }
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <NavItem
        name={navContent}
        title={navContent}
        icon={stopRegistration ? <HelpIcon /> : <PersonIcon />}
        size="small"
        link={link}
        external={stopRegistration}
        mobileLabel={true}
      />
      {BurgerMenu}
    </>
  )
}

export default RegistrationHeaderNavigationLinks
