import React, { useState } from "react"
import Modal from "../shared/Modal"
import {
  Box,
  Link,
  Button as MuiButton,
  Typography,
  css,
  styled,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../utils/useIsMobileView"
import { useParams } from "react-router-dom"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

export function getPromoLink(orgId: string | undefined, stage: string) {
  if (orgId === undefined) return ""

  const promoCode =
    stage === "fourthStage" ? "cyber_monday" : "black_friday_deals"

  return `/organisations/${orgId}/employee/benefits/home_and_electronics/products?promo=${promoCode}`
}

const PromoModal = ({ stage }: { stage: string }) => {
  const [isPromoPopupOpen, setIsPromoPopupOpen] = useState(true)
  const { t, ready, i18n } = useTranslation("blackFriday")
  const isMobile = useIsMobileView()
  const params = useParams()
  const promoLink = getPromoLink(params.organisationId, stage)

  const firstStage = useBuildAwsImageUrl(
    i18n.language,
    "blackFridayFirstStage.png"
  )
  const secondStage = useBuildAwsImageUrl(
    i18n.language,
    "blackFridaySecondStage.png"
  )
  const thirdStage = useBuildAwsImageUrl(
    i18n.language,
    "blackFridayThirdStage.png"
  )
  const fourthStage = useBuildAwsImageUrl(
    i18n.language,
    "blackFridayFourthStage.png"
  )

  const imageMapping: { [key: string]: string } = {
    firstStage,
    secondStage,
    thirdStage,
    fourthStage,
  }

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={isPromoPopupOpen}
      handleClose={() => setIsPromoPopupOpen(false)}
      sx={{
        "#modal-main-box": {
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "16px",
          width: { md: "521px" },
        },
      }}
    >
      <>
        <Header
          align="center"
          sx={stage === "fourthStage" ? { fontSize: 24 } : { fontSize: 30 }}
        >
          {t(`${stage}.modal.header`)
            .split("<br/>")
            .map((text, index) => (
              <React.Fragment key={index}>
                {text}
                {index === 0 && <br />}
              </React.Fragment>
            ))}
        </Header>
        <ImageContainer>
          <Image src={imageMapping[stage]} alt="promotion image" />
        </ImageContainer>
        <Text align="center">
          {t(`${stage}.modal.text`)
            .split("<br/>")
            .map((text, index) => (
              <React.Fragment key={index}>
                {text}
                {!isMobile && index === 0 && <br />}
              </React.Fragment>
            ))}
        </Text>
        <Button
          variant="gradient"
          sx={{ textAlign: "center" }}
          LinkComponent={Link}
          href={promoLink}
        >
          {t(`${stage}.modal.button`)}
        </Button>
      </>
    </Modal>
  )
}

export default PromoModal

const Header = styled(Typography)`
  ${({ theme }) => css`
    line-height: unset;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
    }
  `}
`

const ImageContainer = styled(Box)`
  padding: 2rem 0;
`

const Image = styled("img")`
  border-radius: 1rem;
  width: 100%;
  /* min-height: 117px; */
  object-fit: cover;
  max-width: 521px;
  display: block;
  margin: 0 auto;
`

const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.palette.primary.main};
  `}
`

const Button = styled(MuiButton)`
  ${({ theme }) => css`
    margin: 2rem auto 26px auto;
    display: block;
    min-height: 3rem;
    width: 96%;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 420px;
      height: 3rem;
    }
  `}
`
