import getTextFieldsProps from "./orderDetailsFields"
import ShowOnPortalPageButton from "./PayrollGivingRadioButton"
import { FormikProps } from "formik"
import { FormikValue } from "./initialValues"
import SavingCalculatorCard from "./SavingCalculatorCard"
import PayrollGivingFootnote from "./PayrollGivingFootnote"
import { useGetQuotePageDataQuery } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import i18n from "../../i18n"
import { useTranslation } from "react-i18next"
import Loader from "../shared/Loader"
import DonationFieldValue from "./DonationFieldValue"

const OrderDetails = ({
  formikValue,
}: {
  formikValue: FormikProps<FormikValue>
}) => {
  const { totalValueProps } = getTextFieldsProps()
  const params = useParams()
  const { ready } = useTranslation("constants")
  const formData = useGetQuotePageDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      schemeType: "payroll_giving",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  const salaryBracketCategory =
    formData.data?.employeeOrganisation?.scheme?.salaryBracketCategory
  const donationValue = parseFloat(formikValue.values.totalValue)
  const pickedValue = formikValue.values.picked

  const calculatedResult = (
    donationAmount: number,
    salaryBracketCategory?: number | null
  ) => {
    let costOfQuote = 0
    let salarySacrifice = 0
    let monthlyCostToYou = 0
    let totalCost = 0
    let youCouldSave = 0
    const valueA = (donationAmount / 100) * (salaryBracketCategory ?? 0)
    if (
      !isNaN(donationAmount) &&
      donationAmount >= 5 &&
      donationAmount < 10001
    ) {
      costOfQuote = donationAmount
      salarySacrifice = donationAmount
      monthlyCostToYou = donationAmount - valueA
      totalCost = donationAmount - valueA
      youCouldSave = valueA
    }

    return {
      costOfQuote,
      salarySacrifice,
      monthlyCostToYou,
      totalCost,
      youCouldSave,
    }
  }
  const inputError = formikValue.errors.totalValue
  if (formData.loading || !ready) {
    return <Loader />
  }
  return (
    <>
      <DonationFieldValue textFieldProps={totalValueProps} />
      <ShowOnPortalPageButton formikValue={formikValue} />
      {donationValue >= 5 &&
        donationValue < 10001 &&
        calculatedResult(donationValue, salaryBracketCategory) &&
        !inputError && (
          <>
            <SavingCalculatorCard
              calculatedResult={calculatedResult(
                donationValue,
                salaryBracketCategory
              )}
              picked={pickedValue}
            />
            <PayrollGivingFootnote />
          </>
        )}
    </>
  )
}

export default OrderDetails
