import { Container, Grid } from "@mui/material"
import ImgMediaCard, { ImgMediaCardProps } from "./Card"
import Carousel from "react-material-ui-carousel"
import { useMediaQuery, useTheme } from "@mui/material"
import { useStoreHomeData } from "../StoreHomeContext"
import { useCallback, useEffect, useState } from "react"

export type LsFeatureCardGridProps = {
  data: ImgMediaCardProps[]
}

export default function LsFeatureCardGrid() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const {
    data: { featureTiles },
  } = useStoreHomeData()

  return (
    // Between 900 to ≈1350px, the CardGrid doesn't align correctly
    // above lg breakpoint it should have max width of 1106px, currently larger than that
    <Container>
      <div>
        {!isDesktop ? (
          <Wrapper>
            {featureTiles.length > 0 &&
              featureTiles.map(({ description, image, name, url }, index) => {
                return (
                  <div
                    key={`feature-tiles-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item>
                      <ImgMediaCard
                        index={index}
                        title={name}
                        imageUrl={image}
                        description={description}
                        url={url}
                      />
                    </Grid>
                  </div>
                )
              })}
          </Wrapper>
        ) : (
          <Container
            sx={{
              paddingLeft: "0rem!important",
              margin: "0 auto",
              minWidth: "50rem",
              maxWidth: "69.125rem",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{
                paddingRight: "0rem!important",
                marginLeft: "0rem!important",
              }}
            >
              <Grid
                item
                md={11}
                lg={10}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.8rem",
                  justifyContent: "center",
                  paddingRight: "0rem!important",
                }}
              >
                {featureTiles.length > 0 &&
                  featureTiles.map(
                    ({ description, image, name, url }, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            Width: "21.313rem",
                            paddingRight: "0rem!important",
                            zIndex: 1,
                          }}
                          key={`feature-tiles-${index}`}
                        >
                          <ImgMediaCard
                            index={index}
                            title={name}
                            imageUrl={image}
                            description={description}
                            url={url}
                          />
                        </Grid>
                      )
                    }
                  )}
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Container>
  )
}

const Wrapper = ({ children }: { children: false | React.ReactNode }) => {
  const [activeChild, setActiveChild] = useState(0)
  const {
    data: { featureTiles },
  } = useStoreHomeData()

  const changeChild = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        setActiveChild((a) => (a - 1 < 0 ? featureTiles.length - 1 : a - 1))
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) => (a + 1 > featureTiles.length - 1 ? 0 : a + 1))
      }
    },
    [featureTiles]
  )

  useEffect(() => {
    document.addEventListener("keydown", changeChild)
    return function cleanup() {
      document.removeEventListener("keydown", changeChild)
    }
  })
  return (
    <Carousel
      index={activeChild}
      autoPlay={false}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={true}
      indicators={true}
      animation="slide"
      interval={5000}
      height="31rem"
      sx={{ zIndex: "10" }}
    >
      {children}
    </Carousel>
  )
}
