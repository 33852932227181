import { useFormikContext } from "formik"
import { getMobileNumberField } from "../cycle-to-work/quote-page/fields/yourDetailsFields"
import GenericField from "../shared/form/GenericField"
import { useEffect } from "react"

export type MobileNumberFieldType = {
  mobileNumberState: string
}

const MobileNumberField = ({ mobileNumberState }: MobileNumberFieldType) => {
  const formik = useFormikContext()

  useEffect(() => {
    // update the salaryBracketState in formik.values for validationSchema
    // formik.setFieldValue triggers re-render, need to be inside useEffect
    formik.setFieldValue("mobileNumberState", mobileNumberState)
    // eslint-disable-next-line
  }, [mobileNumberState])

  if (mobileNumberState === "hidden") {
    return null
  }
  return (
    <GenericField
      textFieldProps={getMobileNumberField()}
      inlineTextFieldProps={{
        required: mobileNumberState === "required" ? true : false,
        onChange: (e) => {
          const newValue = e.target.value
          if (/^\d*$/.test(newValue)) {
            formik.handleChange(e)
          }
        },
      }}
    />
  )
}

export default MobileNumberField
