import { Box, Button, css, styled } from "@mui/material"
import React, {
  useState,
  ChangeEvent,
  DragEvent,
  useRef,
  useEffect,
} from "react"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined"
import Alert from "../../shared/Alert"
import StoredImagePopUp, { SelectedCardProps } from "./StoredImagePopUp"
import { useTranslation } from "react-i18next"
interface FileSelectorProps {
  onFileSelected: (fileUrl: string, file: File) => void
  onStoredValueChange: (selectedCard: SelectedCardProps) => void
}
const FileSelector: React.FC<FileSelectorProps> = ({
  onFileSelected,
  onStoredValueChange,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState("")
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [errorCount, setErrorCount] = useState(0)
  const [fileUrl, setFileUrl] = useState<string>("")
  const [open, setOpen] = useState(false)
  const [stored, setStored] = useState<SelectedCardProps | null>()
  const { t, ready } = useTranslation("newBenefitTile")
  useEffect(() => {
    // Call the callback function passed down from the parent component
    if (stored) {
      onStoredValueChange(stored)
    }
  }, [stored, onStoredValueChange])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile)
      setFile(selectedFile)
      setFileUrl(URL.createObjectURL(selectedFile))
      onFileSelected(fileUrl, selectedFile)
    }
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const droppedFile = e.dataTransfer.files?.[0]
    if (droppedFile) {
      // Check if the dropped file type is allowed (PNG or JPEG)
      if (
        droppedFile.type === "image/png" ||
        droppedFile.type === "image/jpeg"
      ) {
        const fileUrl = URL.createObjectURL(droppedFile)
        setFile(droppedFile)
        setFileUrl(URL.createObjectURL(droppedFile))
        onFileSelected(fileUrl, droppedFile)
      } else {
        // Inform the user that only PNG and JPEG files are allowed
        setError(t("imagePopUpModal.errorMessage"))
        setErrorCount(errorCount + 1)
      }
    }
  }
  const handleOpenStoredImage = () => {
    setOpen(true)
  }
  const closeStoredImageModal = () => {
    setOpen(false)
  }
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleSelectFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const handleStoredFile = (selectedCard: SelectedCardProps) => {
    setStored(selectedCard)
  }
  if (!ready) {
    return null
  }
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-labelledby="file_selector_label"
    >
      {error && <Alert severity={"error"} message={error} key={errorCount} />}
      {!file?.name && (
        <div
          style={{ display: "flex", cursor: "pointer" }}
          className="file-selector"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          role="group"
          aria-labelledby="file_selector_label"
        >
          <input
            id="document"
            name="document"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <div style={{ display: "block" }}>
            {" "}
            <SelectFile
              onClick={handleSelectFileClick}
              aria-label={t("imagePopUpModal.selectYourFile")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <FileUploadIcon />
                </div>
                <div style={{ maxWidth: "56%" }}>
                  {t("imagePopUpModal.selectYourFile")}
                </div>
              </div>
            </SelectFile>
            <p style={{ fontSize: "1.2rem" }}>
              <strong>{t("imagePopUpModal.or")}</strong>
            </p>
            <SelectFile
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleOpenStoredImage}
              aria-label={t("imagePopUpModal.chooseFromLibraryOption")}
            >
              <div>
                <InsertPhotoOutlinedIcon />
              </div>
              <p style={{ maxWidth: "56%", margin: "0rem" }}>
                {t("imagePopUpModal.chooseFromLibraryOption")}
              </p>
            </SelectFile>
          </div>
        </div>
      )}
      {file?.name && (
        <div
          style={{ display: "flex" }}
          className="file-selector"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          role="group"
          aria-labelledby="file_selector_label"
        >
          {" "}
          <div style={{ display: "grid" }}>
            <CardImage src={fileUrl} alt="Uploaded File" />
            <label htmlFor="document">
              <UploadButton
                onClick={handleSelectFileClick}
                aria-label={t("imagePopUpModal.changeFile")}
              >
                {t("imagePopUpModal.changeFile")}
                <input
                  id="document"
                  name="document"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </UploadButton>
            </label>
          </div>
        </div>
      )}

      <StoredImagePopUp
        openModal={open}
        handleClose={closeStoredImageModal}
        handleStoredFile={handleStoredFile}
      />
    </Container>
  )
}

export default FileSelector
export const Container = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    position:relative;
    padding: 1.3rem;
    padding-top:0.8rem;
    text-align:center;
    border:1px dotted black;
    margin-right:2rem;
    margin-left:2rem;
    height:200px;
    border-radius:0.4rem;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 25rem;
      height:100%
      padding-top: 0rem;
      padding-bottom: 1rem;
    }
  `}
`
const SelectFile = styled(Button)`
  ${({ theme }) => css`
    && {
      color:
      font-size: 1rem;
      margin: 0.5rem 0rem;
      display:flex;
      position:relative;
      width: 100%;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        font-size: 0.875rem;
      }
    }
  `}
`
const CardImage = styled("img")`
  ${({ theme }) => css`
    width: 7.5rem;
    height: 6.6875rem;
    border-radius: 1rem;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-left: 0rem;
    }
  `}
`
const UploadButton = styled(Button)`
  font-size: 0.875rem;
  max-width: 8.625rem;
  width: 100%;
  line-height: 1.25rem;
  background-color: ${({ theme }) => theme.palette.secondary.main[300]};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  padding: 0 0.75rem;
  border-radius: 1rem;
  height: 1.875rem;
  margin-top: 1rem;
  background: aliceblue;
  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`
