import { createTheme, ThemeOptions } from "@mui/material/styles"
import theme from "./mui-theme"

const styleVars = {
  xlBreakpoint: "9000",
}

const breakpoints = {
  values: {
    xs: parseInt(styleVars.xlBreakpoint),
    sm: parseInt(styleVars.xlBreakpoint),
    md: parseInt(styleVars.xlBreakpoint),
    lg: parseInt(styleVars.xlBreakpoint),
    xl: parseInt(styleVars.xlBreakpoint),
  },
} as ThemeOptions["breakpoints"]

const Theme = createTheme({
  ...theme,
  breakpoints,
})

export default Theme
