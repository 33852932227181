import { createContext } from "react"
import { useParams } from "react-router"
import {
  // GetLsHomeDataQueryHookResult,
  useGetLsHomeDataQuery,
  DiscountCategory,
} from "../../graphqGenaretedTypes"
import { useTranslation } from "react-i18next"

export type StoreHomeContextData = {
  loading: boolean
  categoryPills: ReadonlyArray<CategoryPillsType>
  featureTiles: ReadonlyArray<FeatureTilesType>
  carouselCategories: ReadonlyArray<DiscountCategory>
}

type CategoryPillsType = {
  __typename?: "DiscountCategory" | undefined
  id: string
  name: string
  url: string
}

type FeatureTilesType = {
  id: string
  description?: string
  image: string
  name: string
  url: string
}

const defaultValue: StoreHomeContextData = {
  loading: true,
  categoryPills: [],
  featureTiles: [],
  carouselCategories: [],
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const StoreHomeContext = createContext(defaultValue)

export const useStoreHomeData: () => {
  data: StoreHomeContextData
} = function () {
  const { organisationId } = useParams()
  const { i18n } = useTranslation()
  const data = useGetLsHomeDataQuery({
    variables: {
      organisationId: organisationId || "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const loading = data.loading
    const categoryPills =
      data.data?.employeeOrganisation?.lifestyleSavings?.categoryPills
    const featureTiles =
      data.data?.employeeOrganisation?.lifestyleSavings?.featureTiles
    const carouselCategories =
      data.data?.employeeOrganisation?.lifestyleSavings?.carouselCategories

    state = {
      ...defaultValue,
      loading,
      categoryPills,
      featureTiles,
      carouselCategories,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
