import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import Modal from "../support-links/Modal"
import AffordabilityCalculatorLink from "../support-links/AffordabilityCalculatorLink"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useState } from "react"
import PensionCalculatorModal from "../support-links/PensionCalculator"
import { Mainlinkbutton } from "../support-links/Modal"
import { useContext } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import DrawerItem from "../drawer/DrawerItem"
import { useNavigate } from "react-router"
import getQuoteText from "../../../utils/quoteTypeDymanicTextCalculator"
import onEnter from "../../../utils/onEnter"
import React from "react"
import { useTranslation } from "react-i18next"

export type SupportLinksProps = {
  linkList: {
    label?: string
    url?: string
    content?: string
    isBurgerMenu?: boolean
    visibility?: string
    popup?: boolean
  }[]
  showAffordabilityCalculator?: boolean | true
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

function SupportLinks({
  linkList,
  setDrawerOpen,
  showAffordabilityCalculator = true,
}: SupportLinksProps) {
  const { t, ready } = useTranslation("lifestyleSavings")
  const { quoteType } = useContext(StoreLayoutContext)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const isBurgerMenu = linkList.length > 0 ? linkList[0]?.isBurgerMenu : null
  const [openPensionCalculator, setOpenPensionCalculator] = useState(false)
  const navigate = useNavigate()
  const isLifeStyleSaving = window.location.href.includes("lifestyle_savings")
  const handleAction = (url: string | undefined, external: boolean) => {
    if (external && url) {
      window.location.href = url
    } else if (url) {
      navigate(url)

      if (url === "/") {
        window.location.href = ""
      }
    }
  }

  if (!ready) {
    return null
  }
  return (
    <div
      className={classNames("support-links", {
        "support-links--mobile": !isDesktop,
      })}
    >
      <h4 className="support-links__title">{t("store.supportLinks.title")}</h4>
      {showAffordabilityCalculator &&
        !isBurgerMenu &&
        !isDesktop &&
        !isLifeStyleSaving && (
          <AffordabilityCalculatorLink
            setDrawerOpen={setDrawerOpen}
            key="affordability-calculator"
          />
        )}
      {linkList.map((linkItem, index) => (
        <React.Fragment key={index}>
          {((linkItem.visibility == "mobile" && !isDesktop) ||
            (linkItem.visibility == "desktop" && isDesktop) ||
            linkItem.visibility == null) && (
            <>
              {linkItem.popup == false ? (
                <>
                  {isDesktop ? (
                    <Mainlinkbutton
                      onClick={() => handleAction(linkItem.url, false)}
                      sx={{
                        svg: {
                          marginLeft: "-0.75rem",
                        },
                      }}
                    >
                      <ChevronRightIcon />

                      {linkItem.label}
                    </Mainlinkbutton>
                  ) : (
                    <DrawerItem
                      text={linkItem.label ? linkItem.label : ""}
                      divider
                      onClick={() => handleAction(linkItem.url, false)}
                      onKeyDown={(event) =>
                        onEnter(event, () => handleAction(linkItem.url, false))
                      }
                      key={`drawer-item-${index}`}
                    />
                  )}
                </>
              ) : (
                <Modal
                  buttonLabel={linkItem.label ? linkItem.label : ""}
                  key={`modal-${index}`}
                >
                  <>
                    {linkItem.url ? (
                      <iframe src={linkItem.url} aria-label={linkItem.label} />
                    ) : (
                      <>
                        {linkItem.content && (
                          <div
                            className="w-100"
                            dangerouslySetInnerHTML={{
                              __html: linkItem.content,
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                </Modal>
              )}
            </>
          )}
        </React.Fragment>
      ))}
      <>
        {quoteType === "NHS" ||
        quoteType === "Council LGPS" ||
        quoteType === "Teachers' Pension" ? (
          isDesktop ? (
            <Mainlinkbutton
              key="pension-calculator-button"
              onClick={() => setOpenPensionCalculator(true)}
              variant="invisible"
              sx={{
                svg: {
                  marginLeft: "-0.75rem",
                },
              }}
            >
              <ChevronRightIcon />

              {quoteType === "NHS" && "NHS Pension Scheme calculator"}
              {quoteType === "Council LGPS" &&
                "Local Government Pension Scheme Calculator"}
              {quoteType === "Teachers' Pension" &&
                "Teachers' Pension Scheme calculator"}
            </Mainlinkbutton>
          ) : (
            <DrawerItem
              key="pension-calculator-drawer-item"
              text={getQuoteText(quoteType)}
              divider
              onClick={() => setOpenPensionCalculator(true)}
              onKeyDown={(event) =>
                onEnter(event, () => setOpenPensionCalculator(true))
              }
            />
          )
        ) : null}
        <PensionCalculatorModal
          open={openPensionCalculator}
          setOpen={setOpenPensionCalculator}
          quoteType={quoteType}
          key="pension-calculator-modal"
        />
      </>
    </div>
  )
}

export default SupportLinks
