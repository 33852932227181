import { styled } from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material"

export const getStyledArrow = (muiIcon: SvgIconComponent) => {
  return styled(muiIcon)`
    color: #302557;
    z-index: 1;

    &:hover {
      color: #302557;
    }
  `
}
