import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { FormikValue, initialValues } from "./initialValues"
import { Box, Button, css, styled, Typography } from "@mui/material"
import { useState } from "react"
// import { DisplayFormikState } from "../formik-helper"
import { validationSchema } from "./validationSchema"
import {
  CreatePayrollGivingQuote,
  useDefaultUserData,
  useFormData,
} from "./PayrollGivingPageContext"
import { flushSync } from "react-dom"
import { useTranslation } from "react-i18next"
import scrollToElement from "../cycle-to-work/quote-page/scrollToElement"
import { YourDetails } from "./YourDetails"
import SubmitOrder from "./SubmitOrder"

import OrderDetails from "./OrderDetails"
import { Footnote } from "../cycle-to-work/quote-page/Footnote"
import Alert from "../shared/Alert"
import Loader from "../shared/Loader"
import { Disclaimer } from "../cycle-to-work/quote-page/Disclaimer"
import Tabs from "./Tabs"
import parseError, {
  ParsedErrorsType,
} from "../cycle-to-work/quote-page/parseErrors"
import ThankYou, { ThankYouProps } from "./Thankyou"
import Layout from "./PayrollGivingBreadCrumbs"

const initSuccessfulOrderDetails: ThankYouProps = {
  orderId: "",
}
const PayrollGivingQuotePage = () => {
  const { t, ready } = useTranslation("quotePage")
  const { userData } = useDefaultUserData()
  const { formData } = useFormData()
  const [activeTab, setActiveTab] = useState(0)
  const isLastStep = activeTab === 2
  const [alertMessage, setAlertMessage] = useState("")
  const [errorCount, setErrorCount] = useState(0) // for re-rendering alert snackbar
  const [successfulOrderDetails, setSuccessfulOrderDetails] =
    useState<ThankYouProps>(initSuccessfulOrderDetails)
  const [values, setValues] = useState(initialValues)
  const [createPayrollGivingQuoteMutation, { loading: isProcessingOrder }] =
    CreatePayrollGivingQuote(values)

  // handleFormSubmit() fires only after formik.handleSubmit()
  const handleFormSubmit = (
    values: FormikValue,
    formikHelper: FormikHelpers<FormikValue>
  ) => {
    // force rendering, otherwise setValues() won't update the values right away (due to React 18 batching)
    flushSync(() => {
      setValues(values)
    })
    createPayrollGivingQuoteMutation().then(({ data }) => {
      if (
        data?.createPayrollGivingQuote?.errors &&
        data?.createPayrollGivingQuote?.errors.length > 0
      ) {
        // global errors
        const globalErrors = data.createPayrollGivingQuote.errors
          .filter(({ fieldName }) => fieldName == "global")
          .map(({ message }) => {
            return { message }
          })

        if (globalErrors.length > 0) {
          setAlertMessage(globalErrors.map(({ message }) => message).join(", "))
          setErrorCount(errorCount + 1)
        }

        // field specific errors
        const parsedErrors: ParsedErrorsType = parseError(
          data?.createPayrollGivingQuote?.errors
        )

        // deal with the first error only
        if (parsedErrors.length > 0) {
          const { id, tab } = parsedErrors[0]
          setActiveTab(tab)
          // validate all the fields on that tab so error message show
          formikHelper.validateForm()
          // scroll to that element
          scrollToElement(`#${id}`)
        }

        formikHelper.setSubmitting(false)
      }

      if (data?.createPayrollGivingQuote?.order?.id) {
        setSuccessfulOrderDetails({
          ...successfulOrderDetails,
          orderId: data?.createPayrollGivingQuote?.order?.id,
        })
      }
    })
  }

  async function handleStepValidation({
    index,
    formik,
  }: {
    index: number
    formik: FormikProps<FormikValue>
  }) {
    formik.isValidating = true

    const validationErrors = await formik.validateForm() // validate the current page

    if (Object.keys(validationErrors).length > 0) {
      // set each error field as touch=true, so error message shows
      formik.setTouched(setNestedObjectValues(validationErrors, true))
      formik.isValidating = false
      scrollToElement(".Mui-error, [data-error]")
    } else {
      formik.setTouched({})
      setActiveTab(index + 1)
      formik.isValidating = false
      window.scrollTo(0, 0)
    }
  }

  function handleLastStep(formik: FormikProps<FormikValue>) {
    const allChecked = formik.values.checkboxes.every(
      (checkbox) => checkbox.checked
    )
    if (!allChecked) {
      setAlertMessage(t("quotePage.alertMessage"))
      setErrorCount(errorCount + 1)
      return
    }
    if (!formik.values.checkedTC) {
      setAlertMessage(t("quotePage.alertMessage"))
      setErrorCount(errorCount + 1)
      return
    }
    // handleSubmit() comes with formik which runs a series of actions
    formik.handleSubmit()
  }

  function handleButtonClick(formik: FormikProps<FormikValue>) {
    if (isLastStep) {
      handleLastStep(formik)
    } else {
      handleStepValidation({ index: activeTab, formik })
    }
  }

  if (userData.loading || formData.loading || !ready) {
    return <Loader />
  }

  if (successfulOrderDetails.orderId) {
    const { orderId } = successfulOrderDetails

    return <ThankYou orderId={orderId} />
  }

  // Set pre filled values
  else {
    const init = { ...initialValues }

    init.placeOfWork = userData.placeOfWork
    init.employeeNumber = userData.employeeNumber
    init.niNumber = userData.niNumber
    init.title = userData.title
    init.dateOfBirth = userData.dateOfBirth
    init.firstName = userData.firstName
    init.lastName = userData.lastName
    init.mobileNumber = userData.mobileNumber
    init.phoneNumber = userData.phoneNumber
    init.email = userData.email
    init.lineOne = userData.lineOne
    init.lineTwo = userData.lineTwo
    init.town = userData.town
    init.county = userData.county
    init.postCode = userData.postCode
    init.loading = userData.loading
    init.checkboxes = userData.checkboxes

    const prefilledInitialValues = init

    return (
      <Layout breadcrumbsItems={formData.breadcrumbs}>
        <Instruction variant="h2">
          {t("quotePage.informationHeader")}
        </Instruction>

        <Formik
          initialValues={prefilledInitialValues}
          validationSchema={validationSchema[activeTab]}
          onSubmit={(values, formikHelper) =>
            handleFormSubmit(values, formikHelper)
          }
        >
          {(formik) => {
            return (
              <Form>
                {alertMessage && (
                  <Alert
                    severity={"error"}
                    message={alertMessage}
                    key={errorCount}
                  />
                )}
                {/* className="form" is for CheckBoxes styling  */}
                <FormContainer className="form">
                  <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

                  {activeTab == 0 && <OrderDetails formikValue={formik} />}
                  {activeTab == 1 && <YourDetails />}
                  {activeTab == 2 && <SubmitOrder />}

                  {activeTab !== 0 && <Footnote />}

                  <ContinueButton
                    variant="gradient"
                    onClick={() => handleButtonClick(formik)}
                    disabled={
                      formik.isSubmitting ||
                      formik.isValidating ||
                      isProcessingOrder
                    }
                  >
                    {activeTab === 2
                      ? t("quotePage.submitOrder")
                      : t("quotePage.continue")}
                  </ContinueButton>

                  {activeTab === 2 && <Disclaimer />}
                </FormContainer>
              </Form>
            )
          }}
        </Formik>
      </Layout>
    )
  }
}

export default PayrollGivingQuotePage

const FormContainer = styled(Box)`
  ${({ theme }) => css`
    width: 89.4%;
    margin: 0 auto;
    margin-bottom: 3rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 33.5rem;
      padding: 3.75rem;
      padding-bottom: 32px;
      border: 1px solid ${theme.palette.grey[200]};
      border-radius: ${theme.boxBorderRadius};
      box-shadow: ${theme.boxShadow};
    }
  `}
`

const ContinueButton = styled(Button)`
  ${({ theme }) => css`
    background: linear-gradient(90deg, #872074, ${theme.palette.primary.main});
    display: block;
    width: 100%;
    margin-top: 32px;

    &:hover,
    &:disabled {
      background: linear-gradient(
        90deg,
        #872074,
        ${theme.palette.primary.main}
      );
      opacity: 0.75;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 0 auto;
      max-width: 13.25rem;
    }
  `}
`

const Instruction = styled(Typography)`
  ${({ theme }) => css`
    width: 77.8%;
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3.125rem;
    max-width: 25rem;
    font-weight: 500;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      line-height: 1.875rem;
      max-width: 41.125rem;
    }
  `}
`
