import { TextFieldProps } from "@mui/material"
import i18n from "../../../i18n"

export const getSelectedReason = (): TextFieldProps => ({
  select: true,
  id: "selectReason",
  name: "selectReason",
  label: i18n.t("registration:registration.havingTroubleSignIn.selectReason"),
  required: true,
  fullWidth: true,
})
export const getTroubleEmailField = (): TextFieldProps => ({
  id: "troubleEmail",
  name: "troubleEmail",
  label: i18n.t("bikeQuote:yourDetails.emailAddress"),
  fullWidth: true,
  required: true,
  type: "text",
})
