import { styled, css } from "@mui/material"
import {
  MainHeader,
  PropositionSpaceOneSubHeader,
  HeaderSectionPadding1,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"

export function Header({ onlyCarBenefit }: { onlyCarBenefit: boolean }) {
  const isMobile = useIsMobileView()

  if (onlyCarBenefit && !isMobile) {
    return <Content />
  }
  return (
    <HeaderSection>
      <HeaderSectionPadding1>
        <Content />
      </HeaderSectionPadding1>
    </HeaderSection>
  )
}

function Content() {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <>
      <MainHeader variant="h3" align="left">
        {t("carBenefit.mainHeader")}
      </MainHeader>
      <SubHeader variant="h4" align="left">
        {t("carBenefit.subHeader")}
      </SubHeader>
    </>
  )
}

const HeaderSection = styled("section")`
  ${({ theme }) => css`
    padding: 28px 12px 19px 12px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 28px 18px 19px 18px;
    }
  `}
`
const SubHeader = styled(PropositionSpaceOneSubHeader)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 70%;
    }
  `}
`
