import { Link, styled } from "@mui/material"
import { Container as HighFiveContainer } from "../../highfive-banner/HighFiveBanner"

import useIsMobileView from "../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

type GymWidgetPropType = {
  gymUrl?: string
}

const GymWidget = ({ gymUrl }: GymWidgetPropType) => {
  const isMobile = useIsMobileView()
  const { i18n } = useTranslation()
  const gym_widget_mobile_image = useBuildAwsImageUrl(
    i18n.language,
    "gym_widget_mobile_image.png"
  )
  const gym_widget_desktop_image = useBuildAwsImageUrl(
    i18n.language,
    "gym_widget_desktop_image.png"
  )
  return (
    <Container
      href={gymUrl ? gymUrl : undefined}
      component="a"
      data-cy="portal-gym-widget"
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: "1rem",
        }}
        src={isMobile ? gym_widget_mobile_image : gym_widget_desktop_image}
        alt={
          isMobile
            ? "Enjoy discounted membership at over 3,700 participating clubs across the country. Find a gym"
            : "Our Gym membership staff benefit enables you to enjoy discounted membership at over 3,700 participating clubs across the country. Find a gym"
        }
      />
    </Container>
  )
}

export default GymWidget

const Container = styled(HighFiveContainer)`
  max-width: 56.1875rem; // 900px
  height: 9.5rem; // 152px
` as typeof Link
