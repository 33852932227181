import { FamilyPay as FamilyPayType } from "../../../../graphqGenaretedTypes"
import { MobileWhiteBox, OneProposition } from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"
import { MobileGrid } from "../family-care/FamilyCare"
import { Content } from "./Content"
import { MORE_BUTTON_COLOR } from "../../shared/family-care-and-family-pay/ContentStyles"

const FamilyPay = ({ data }: { data: FamilyPayType | undefined }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <MobileGrid data-cy="portal-family-pay-mobile">
          <Content data={data} />
        </MobileGrid>
      </MobileWhiteBox>
    )
  }
  return (
    <div>
      <OneProposition data-cy="portal-family-pay-desktop">
        <Content data={data} />
      </OneProposition>
      {data?.usefulLinks && (
        <ExpandableList
          links={data.usefulLinks}
          moreButtonColor={MORE_BUTTON_COLOR}
          isExternalLink
        />
      )}
    </div>
  )
}

export default FamilyPay
