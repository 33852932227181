import * as yup from "yup"
import i18n from "../../i18n"

const MAX_FILE_SIZE = 1e7 // 10mb
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/jpe",
  "image/png",
  "application/pdf",
]

const fileUploadEmptyWarning = () =>
  i18n.t("bikeQuote:validations.fileUploadEmptyWarning")
const fileUploadSizeWarning = () =>
  i18n.t("bikeQuote:validations.fileUploadSizeWarning")
const fileUploadFormatWarning = () =>
  i18n.t("bikeQuote:validations.fileUploadFormatWarning")

const singleFileValidation = yup
  .mixed()
  .required(fileUploadEmptyWarning)
  .test(
    "fileSize",
    fileUploadSizeWarning,
    (value) => value && value.size <= MAX_FILE_SIZE
  )
  .test(
    "format",
    fileUploadFormatWarning,
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
  )

export const filesUploadValidation = yup
  .array()
  .of(singleFileValidation)
  .min(1, fileUploadEmptyWarning)
  .required(fileUploadEmptyWarning)
