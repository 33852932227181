import React from "react"
import Item from "./Item"
import CheckboxItem from "../CheckboxItem"
import { motion } from "framer-motion"

export type ItemType = {
  label: string
  count: number
  checked?: boolean
  onClick: (state: boolean) => void
}

export type AnimationType = "ltr" | "rtl" | "disabled"

export type DHFListProps = {
  title: string
  backLabel?: string
  backChange: () => void
  items: ItemType[]
  checkboxes: boolean
  loading: boolean
  animation: AnimationType
}

export default function Example({
  title,
  backLabel,
  backChange,
  items,
  checkboxes,
  loading,
  animation,
}: DHFListProps) {
  const list = (
    <>
      {backLabel && (
        <div onClick={() => backChange()}>
          <Item text={backLabel} back />
        </div>
      )}
      <div className="step-menu__title">{title}</div>

      {items?.map((item, index) => (
        <React.Fragment key={index}>
          {checkboxes ? (
            <CheckboxItem
              text={`${item.label} (${item.count})`}
              value={`${item.label} (${item.count})`}
              onChange={() => item.onClick(!item.checked)}
              checked={item.checked || false}
            />
          ) : (
            <div onClick={() => item.onClick(true)}>
              <Item text={`${item.label} (${item.count})`} />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  )

  let initialXPosition = ""
  const opacity = animation === "disabled" ? 1 : 0

  switch (animation) {
    case "disabled":
      initialXPosition = "0"
      break
    case "rtl":
      initialXPosition = "100%"
      break
    case "ltr":
      initialXPosition = "-100%"
      break
  }

  return (
    <>
      {loading ? (
        <div style={{ opacity: opacity, padding: "0 0.75rem" }}>{list}</div>
      ) : (
        <motion.div
          initial={{ x: initialXPosition }}
          animate={{ x: 0 }}
          transition={{ type: "spring", bounce: 0 }}
          style={{ padding: "0 0.75rem" }}
        >
          {list}
        </motion.div>
      )}
    </>
  )
}
