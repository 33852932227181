const scrollToElement = (selector: string) => {
  // selector: id with "#" as prefix or class with "." prefix
  const el = document.querySelector(selector)
  el?.parentElement?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  })
}

export default scrollToElement
