import { Box, css, styled } from "@mui/material"
import SavingCalculatorCard, {
  SavingCalculatorCardType,
} from "./SavingCalculatorCard"
import { useParams } from "react-router-dom"

const BikeQuote = ({
  calculatedResult,
  quoteType,
}: SavingCalculatorCardType) => {
  const { schemeType } = useParams()
  const isNetBikeShop = schemeType === "bike_shop"

  if (isNetBikeShop) {
    return (
      <NetBikeQuoteContainer>
        <SavingCalculatorCard
          calculatedResult={calculatedResult}
          quoteType={quoteType}
          isQuotePage={true}
        />
      </NetBikeQuoteContainer>
    )
  }
  return (
    <BikeQuoteContainer>
      <SavingCalculatorCard
        calculatedResult={calculatedResult}
        quoteType={quoteType}
        isQuotePage={true}
      />
    </BikeQuoteContainer>
  )
}

export default BikeQuote

const BikeQuoteContainer = styled(Box)`
  ${({ theme }) => css`
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 24px;
    border: 1px solid ${theme.palette.grey[200]};
    box-shadow: ${theme.boxShadow};
    border-radius: ${theme.boxBorderRadius};

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 495px;
      height: 348px;
    }
    &&&&&&& {
      /* override the margin-top and bottom in SavingCalculatorCard.tsx */
      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  `}
`

const NetBikeQuoteContainer = styled(BikeQuoteContainer)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 495px;
      height: 148px;
    }
  `}
`
