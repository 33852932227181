import { TitleHeading } from "../registration/shared/headingStyles"

const Header = ({ content }: { content: string }) => {
  return (
    <TitleHeading variant="h1" sx={{ textAlign: "left" }}>
      {content}
    </TitleHeading>
  )
}
export default Header
