import { createContext } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
  GetLocalBenefitStoreDataQueryHookResult,
  useGetLocalBenefitStoreDataQuery,
} from "../../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../../shared/CustomBreadcrumbs"
import { BenefitCardDataType } from "./benefit-card/BenefitCard"
import { useTranslation } from "react-i18next"

export type LocalBenefitStoreContextData = {
  discounts: ReadonlyArray<BenefitCardDataType>
  totalPages: number
  totalEntries: number
  breadcrumbs: Array<BreadcrumbsType>
  loading: boolean
}

const defaultValue: LocalBenefitStoreContextData = {
  discounts: [],
  totalPages: 0,
  totalEntries: 0,
  breadcrumbs: [],
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToLocalDiscounts: (
  queryData: GetLocalBenefitStoreDataQueryHookResult
) => BenefitCardDataType[] = function (queryData) {
  let result: BenefitCardDataType[] = []
  const data = queryData.data
  if (
    data?.employeeOrganisation?.lifestyleSavings?.localDiscountStore
      ?.localDiscounts
  ) {
    result =
      data?.employeeOrganisation?.lifestyleSavings?.localDiscountStore?.localDiscounts.map(
        (discount) => {
          return {
            id: discount.id,
            name: discount.name,
            image: discount.image,
            link: discount.link,
            savingText: discount.savingText,
          }
        }
      )
  }

  return result
}

const queryDataToTotalPages: (
  queryData: GetLocalBenefitStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.localDiscountStore
      ?.totalPages || 0
  )
}

const queryDataToTotalEntries: (
  queryData: GetLocalBenefitStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.localDiscountStore
      ?.totalEntries || 0
  )
}

const queryDataToBreadcrumbs: (
  queryData: GetLocalBenefitStoreDataQueryHookResult
) => BreadcrumbsType[] = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.localDiscountStore
      ?.breadcrumbs || []
  )
}

export const LocalBenefitStoreContext = createContext(defaultValue)

export const useLocalBenefitStoreData: () => {
  data: LocalBenefitStoreContextData
} = function () {
  const { organisationId } = useParams()
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const filters = {
    search: searchParams.get("search"),
  }

  const data = useGetLocalBenefitStoreDataQuery({
    variables: {
      organisationId: organisationId || "",
      filters: filters,
      page: parseInt(searchParams.get("page") || "1"),
      discountsPerPage: parseInt(searchParams.get("results_per_page") || "0"),
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  if (!data.loading) {
    const discounts = queryDataToLocalDiscounts(data)
    const totalPages = queryDataToTotalPages(data)
    const totalEntries = queryDataToTotalEntries(data)
    const breadcrumbs = queryDataToBreadcrumbs(data)
    const loading = data.loading

    state = {
      ...defaultValue,
      discounts,
      totalPages,
      totalEntries,
      breadcrumbs,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
