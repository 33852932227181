import MapContainer from "./MapContainer.js"

function Maps(BikeShops) {
  return (
    <MapContainer
      coordinates={BikeShops.BikeShops}
      searchTerm={BikeShops.searchTerm}
    />
  )
}

export default Maps
