import { Box, styled } from "@mui/material"
import Carousel from "react-material-ui-carousel"
import Roundel, { RoundelProps } from "../../../shared/Roundel"
import { arrayToSubArrays } from "../../../../utils/arrayToSubArrays"
import { useTheme } from "@mui/material/styles"

const C2WRoundel = ({
  roundelArray,
  roundelBackground,
  roundelPerPage,
  isHomeAndElectronicSection,
  isCycleToWork,
  cycleToWorkStore,
}: {
  roundelArray: RoundelProps[]
  roundelBackground: "gray"
  roundelPerPage: number
  isCycleToWork?: boolean
  isHomeAndElectronicSection?: boolean
  cycleToWorkStore?: boolean
}) => {
  const subArrays = arrayToSubArrays(roundelArray, roundelPerPage)
  const theme = useTheme()

  return (
    <Carousel
      autoPlay={false}
      indicators={subArrays.length > 1 ? true : false}
      activeIndicatorIconButtonProps={{
        style: { color: theme.palette.primary.main },
      }}
      cycleNavigation={false}
      navButtonsAlwaysInvisible={true}
      animation="slide"
    >
      {subArrays.map((subArray, index) => {
        return (
          <RoundelCarouselContainer
            key={`mobile-roundel-carousel-page-${index}`}
          >
            {subArray.map(({ label, imageUrl, link }) => {
              return (
                <Roundel
                  key={`portal-homepage-roundel-${label}`}
                  imageUrl={imageUrl}
                  link={link}
                  background={roundelBackground}
                  label={label}
                  isHomeAndElectronicSection={isHomeAndElectronicSection}
                  isCycleToWork={isCycleToWork}
                  cycleToWorkStore={cycleToWorkStore}
                />
              )
            })}
          </RoundelCarouselContainer>
        )
      })}
    </Carousel>
  )
}

const RoundelCarouselContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
`

export default C2WRoundel
