import {
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  css,
  Box,
} from "@mui/material"
import {
  MainContainer,
  Cardlayout,
  CardBox,
  TextContainer,
  ImageConatiner,
  ImageContentContainer,
  SectionText,
} from "../store/cycle-to-work/cycle-to-work-hardcoded-component/FourSimpleSteps"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import i18n from "../../i18n"
const FamilyCareThreeStep = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const childrenSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ChildrenSupport.png"
  )
  const elderCareSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/ElderCareSupport.png"
  )
  const petCareSupport = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/PetCareSupport.png"
  )

  return (
    <MainContainerStyle>
      <SectionText sx={{ textAlign: "center" }}>
        Access a range of discreet emotional support for care challenges and
        concerns:
      </SectionText>
      <Cardlayout>
        <CardBoxstyle
          sx={{
            height: isDesktop ? "" : "250px !important",
            paddingRight: isDesktop && "40px",
          }}
        >
          <div>
            <ImageContentContainer sx={{ paddingTop: "0rem !important" }}>
              <ImageConatiner>
                <ChildrenSupportImage
                  src={childrenSupport}
                  alt="ChildrenSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Childcare Support</Label>

            <TextContainer sx={{ paddingTop: "20px !important" }}>
              <TextStyle>Early years care</TextStyle>
              <TextStyle>Behaviour</TextStyle>
              <TextStyle>Neurodiversity</TextStyle>
              <TextStyle>Teen support</TextStyle>
            </TextContainer>
          </div>
        </CardBoxstyle>
        <ImageWrap> </ImageWrap>
        <CardBox
          sx={{
            paddingLeft: isDesktop && "40px",
            paddingRight: isDesktop && "40px",
            paddingTop: isDesktop ? "" : "20px",
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <ElderCareSupportImage
                  src={elderCareSupport}
                  alt="ElderCareSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Eldercare Support</Label>
            <TextContainer sx={{ paddingTop: "20px !important" }}>
              <div>
                Sourcing and securing short and long-term care arrangements
              </div>
              <TextStyle>Financial funding</TextStyle>
              <TextStyle>Legal advice</TextStyle>
            </TextContainer>
          </div>
        </CardBox>
        <ImageWrap> </ImageWrap>

        <CardBox
          sx={{
            paddingLeft: isDesktop && "40px",
            paddingTop: isDesktop ? "" : "50px",
          }}
        >
          <div>
            <ImageContentContainer>
              <ImageConatiner>
                <PetCareSupportImage
                  src={petCareSupport}
                  alt="PetCareSupport"
                />
              </ImageConatiner>
            </ImageContentContainer>
            <Label>Petcare Support</Label>
            <TextContainer sx={{ paddingTop: "20px !important" }}>
              <div>24/7 video vet</div>
              <TextStyle>Pet welfare assistance</TextStyle>
              <TextStyle>Behaviour specialists</TextStyle>
            </TextContainer>
          </div>
        </CardBox>
      </Cardlayout>
    </MainContainerStyle>
  )
}
export default FamilyCareThreeStep

const ChildrenSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 50.6px;
      height: 54.73px;
    }
  `}
`
export const ImageWrap = styled(Box)`
  ${({ theme }) => css`
    height: 285px;
    display: block;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column;
      display: none;
    }
  `}
`
const ElderCareSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 44.37px;
      height: 38.15px;
    }
  `}
`

const PetCareSupportImage = styled("img")`
  ${({ theme }) => css`
    width: 100px;
    height: 100px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 47px;
      height: 47px;
    }
  `}
`

const MainContainerStyle = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        height: 900px;
      }
    }
  `}
`

const CardBoxstyle = styled(CardBox)`
  ${({ theme }) => css`
    &&& {
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 10px;
      }
    }
  `}
`

const Label = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-align: center;
    padding-top: 40px;
    font-weight: bold;
    font-size: 16px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 20px;
    }
  `}
`
const TextStyle = styled("div")`
  ${({ theme }) => css`
    && {
      padding-top: 8px;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 4px;
      }
    }
  `}
`
