import React from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { getStyledArrow } from "./getStyledArrow"
import { NoticeBoardArrowProps } from "./NoticeBoardArrowProps"

export const LeftArrow = ({
  // eslint-disable-next-line
  currentSlide,
  // eslint-disable-next-line
  slideCount,
  tabFocusIndex,
  sliderRef,
  setTabFocusIndex,
  ...props
}: NoticeBoardArrowProps) => {
  return (
    <StyledLeftArrow
      {...props}
      onClick={(e: React.MouseEvent) => {
        const activeSlide = currentSlide && Math.floor(currentSlide)
        if (activeSlide && tabFocusIndex && tabFocusIndex > activeSlide) {
          sliderRef?.current && sliderRef.current.slickGoTo(activeSlide)
          setTabFocusIndex && setTabFocusIndex(0)
        } else {
          // else use the onClick method provided by React-slick
          props.onClick && props.onClick(e)
        }
      }}
    />
  )
}
const StyledLeftArrow = getStyledArrow(ChevronLeftIcon)
