export function getNhsContributionTiers(annualPay: number): number {
  if (annualPay > 56164) return 0.125
  else if (annualPay > 43806) return 0.107
  else if (annualPay > 29180) return 0.098
  else if (annualPay > 23949) return 0.083
  else if (annualPay > 13247) return 0.065
  else return 0.052
}

export function getTeachersPensionContributionTiers(annualPay: number): number {
  if (annualPay > 84193.99) return 0.117
  else if (annualPay > 61742.99) return 0.113
  else if (annualPay > 46586.99) return 0.102
  else if (annualPay > 39290.99) return 0.096
  else if (annualPay > 29187.99) return 0.086
  else return 0.074
}

export function getLGPSPensionContributionTiers(annualPay: number): number {
  if (annualPay > 170101) return 0.125
  else if (annualPay > 113401) return 0.114
  else if (annualPay > 96201) return 0.105
  else if (annualPay > 67901) return 0.099
  else if (annualPay > 48501) return 0.085
  else if (annualPay > 38301) return 0.068
  else if (annualPay > 23601) return 0.065
  else if (annualPay > 15001) return 0.058
  else return 0.055
}
