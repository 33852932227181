import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import classNames from "classnames"
import { gtmLifestyleSavingsFilterClick } from "../../shared/gtm-events/CustomGtmEvents"

export type CheckboxItemProps = {
  text: string
  value: string
  checked: boolean
  onChange: (state: boolean, value: string) => void
  originalText?: string
  isLifestylingStore?: boolean
}

export default function CheckboxItem({
  text,
  value,
  checked,
  originalText = text,
  isLifestylingStore = false,
  onChange,
}: CheckboxItemProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, event.target.value)
    isLifestylingStore && gtmLifestyleSavingsFilterClick()
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={value}
              checked={checked}
              data-original-text={originalText}
              onChange={handleChange}
              sx={{
                color: "inherit",
                padding: "0.125rem",
                marginRight: "0.25rem",

                svg: {
                  width: "1.5rem",
                  height: "1.5rem",
                },
              }}
            />
          }
          label={text}
          className={classNames("body-2 filter-step-menu__checkbox-label", {
            "filter-step-menu__checkbox-label--checked": checked,
          })}
          disableTypography
          sx={{
            marginLeft: "-0.25rem",
          }}
        />
      </FormGroup>
    </>
  )
}
