import { CheckboxType } from "../../store/checkout-page/Checkboxes"

export type FormikValue = {
  bikeShop: {
    supplierId: string
    label: string
    bikeShopQuoteInformation: string
  }
  bikes: {
    brandOfBike: string | undefined
    descriptionOfBike: string | undefined
    typeOfBike: string | undefined
    bikeValue: string | undefined
  }[]
  documents: Array<File>
  totalAccessoriesValue: string
  brochureGroup: string
  placeOfWork: string
  employeeNumber: string
  niNumber: string
  title: string
  dateOfBirth: string
  firstName: string
  lastName: string
  mobileNumber: string
  phoneNumber: string
  email: string
  lineOne: string
  lineTwo: string
  county: string
  town: string
  postCode: string
  loading: boolean
  checkboxes: CheckboxType[]
  quoteLimit: number
}

export type InitFormikValueKey = keyof FormikValue

export const bikeInitValue = {
  brandOfBike: "" as const,
  descriptionOfBike: "" as const,
  typeOfBike: "" as const,
  bikeValue: "" as const,
}

export const initialValues: FormikValue = {
  bikeShop: {
    supplierId: "",
    label: "",
    bikeShopQuoteInformation: "",
  },
  bikes: [bikeInitValue],
  documents: [],
  totalAccessoriesValue: "",
  brochureGroup: "",
  placeOfWork: "",
  employeeNumber: "",
  niNumber: "",
  title: "",
  dateOfBirth: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  phoneNumber: "",
  email: "",
  lineOne: "",
  lineTwo: "",
  county: "",
  town: "",
  postCode: "",
  loading: false,
  checkboxes: [],
  quoteLimit: 0,
}
