import * as Yup from "yup"
import { ImageUploadValidation } from "../../utils/yup-validation-schema/ImageUploadValidation"
import {
  benefitSubTitleValidation,
  noticeboardSubTitleValidation,
  familyCareSubTitleValidation,
  wellbeingSubTitleValidation,
  familyPaySubTitleValidation,
} from "../../utils/yup-validation-schema/subTitleValidation"
import {
  benefitUrlValidation,
  noticeboardUrlValidation,
  familyCareUrlValidation,
  wellbeingUrlValidation,
  familyPayUrlValidation,
} from "../../utils/yup-validation-schema/urlValidation"
import {
  benefitTitleValidation,
  noticeboardTitleValidation,
  familyCareTitleValidation,
  wellbeingTitleValidation,
  familyPayTitleValidation,
} from "../../utils/yup-validation-schema/titleValidation"

export type NewBenefitTileValue = {
  BenefitSubTitle: string
  BenefitTitle: string
  SelectAnOption: string
  ShowOnPortalPage: boolean
  document: File | string
  PopUpRequired: boolean
  position: string
  editorContent: string
  popUpContent: string
  BenefitUrl: string
}
type NewBenefitTileValueFieldsWithValidationKeys<
  T extends keyof NewBenefitTileValue
> = T[]

export const NewBenefitTileFieldsWithValidation: NewBenefitTileValueFieldsWithValidationKeys<
  "BenefitTitle" | "document"
> = ["BenefitTitle", "document"]

export const NewBenefitTileInitialValues: NewBenefitTileValue = {
  BenefitSubTitle: "",
  BenefitTitle: "",
  SelectAnOption: "",
  ShowOnPortalPage: true,
  document: "",
  PopUpRequired: true,
  position: "",
  editorContent: "",
  popUpContent: "",
  BenefitUrl: "",
}

Yup.addMethod(Yup.string, "htmlMax", function (errorMessage) {
  return this.test(`test-html-max`, errorMessage, function (value) {
    const { path, createError } = this
    const regex = /(<p>&nbsp;<\/p>|<([^>]+)>)/gi
    return (
      (value || "").replace(regex, "").length <= 1000 ||
      createError({ path, message: errorMessage })
    )
  })
})

export const NewBenefitTileValidationSchema = Yup.object().shape({
  BenefitTitle: benefitTitleValidation,
  BenefitSubTitle: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "landing_page") {
      return benefitSubTitleValidation
    }
    return Yup.string()
  }),
  BenefitUrl: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "link") {
      return benefitUrlValidation
    }
    return Yup.string()
  }),
  document: ImageUploadValidation,
  popUpContent: Yup.string().when(["PopUpRequired"], (PopUpRequired) => {
    if (PopUpRequired) {
      return Yup.string().htmlMax("length exceeded 1000 characters")
    }
    return Yup.string()
  }),
})

export const NewNoticeboardTileValidationSchema = Yup.object().shape({
  BenefitTitle: noticeboardTitleValidation,
  BenefitSubTitle: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "landing_page") {
      return noticeboardSubTitleValidation
    }
    return Yup.string()
  }),
  BenefitUrl: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "link") {
      return noticeboardUrlValidation
    }
    return Yup.string()
  }),
})
export const NewFamilyCareValidationSchema = Yup.object().shape({
  BenefitTitle: familyCareTitleValidation,
  BenefitSubTitle: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "landing_page") {
      return familyCareSubTitleValidation
    }
    return Yup.string()
  }),
  BenefitUrl: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "link") {
      return familyCareUrlValidation
    }
    return Yup.string()
  }),
})
export const NewWellbeingValidationSchema = Yup.object().shape({
  BenefitTitle: wellbeingTitleValidation,
  BenefitSubTitle: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "landing_page") {
      return wellbeingSubTitleValidation
    }
    return Yup.string()
  }),
  BenefitUrl: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "link") {
      return wellbeingUrlValidation
    }
    return Yup.string()
  }),
})
export const NewFamilyPayValidationSchema = Yup.object().shape({
  BenefitTitle: familyPayTitleValidation,
  BenefitSubTitle: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "landing_page") {
      return familyPaySubTitleValidation
    }
    return Yup.string()
  }),
  BenefitUrl: Yup.string().when(["SelectAnOption"], (SelectAnOption) => {
    if (SelectAnOption === "link") {
      return familyPayUrlValidation
    }
    return Yup.string()
  }),
})
