import { styled } from "@mui/material"
import { useParams } from "react-router-dom"
import { gtmPortalHomepageExploreCarBenefitButtonClick } from "../../../shared/gtm-events/CustomGtmEvents"
import {
  ButtonBase,
  ButtonText,
  SpaceOneButtonContainer,
} from "../../shared/SharedStyles"
import { useTranslation } from "react-i18next"

export function ExploreCarBenefitButton() {
  const params = useParams()
  const CarBenefitUrl = `/organisations/${params.organisationId}/employee/benefits/car_scheme`
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }
  return (
    <SectionContainer>
      <SpaceOneButtonContainer
        onClick={() => {
          gtmPortalHomepageExploreCarBenefitButtonClick()
        }}
      >
        <ButtonBase variant="gradient" component={"a"} href={CarBenefitUrl}>
          <ButtonText variant="body2" align="center">
            {t("carBenefit.exploreButton")}
          </ButtonText>
        </ButtonBase>
      </SpaceOneButtonContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled("section")`
  padding: 23px 0.75rem;
`
