import { FieldArray, getIn, useFormikContext } from "formik"
import { FormikValue } from "./initialValues"
import getTextFieldsProps from "./fields/orderDetailsFields"
import { useFormData } from "./QuotePageContext"
import {
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import { AddOrRemoveButton } from "./AddOrRemoveButton"
import InfoTooltip from "../../shared/InfoTooltip"
import React from "react"
import { useTranslation } from "react-i18next"

type BikeDetailsProps = {
  handleDecimalInput: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

const BikeDetails = ({ handleDecimalInput }: BikeDetailsProps) => {
  const { formData } = useFormData()
  const formik = useFormikContext<FormikValue>()

  const BIKE_TYPES = formData.bikeTypes

  const { t, ready } = useTranslation("bikeQuote")
  const {
    brandOfBikeProps,
    typeOfBikeProps,
    bikeValueProps,
    descriptionOfBikeProps,
  } = getTextFieldsProps()

  if (!formData.loading && ready) {
    return (
      <FieldArray name="bikes">
        {(arrayHelpers) => (
          <>
            {formik.values.bikes.map((bike, index) => {
              const brandOfBike = `bikes[${index}].brandOfBike`
              const isBrandOfBikeTouched = getIn(formik.touched, brandOfBike)
              const brandOfBikeErrorMessage = getIn(formik.errors, brandOfBike)
              const isBrandOfBikeInvalid = Boolean(
                isBrandOfBikeTouched && brandOfBikeErrorMessage
              )

              const descriptionOfBike = `bikes[${index}].descriptionOfBike`
              const isDescriptionOfBikeTouched = getIn(
                formik.touched,
                descriptionOfBike
              )
              const descriptionOfBikeErrorMessage = getIn(
                formik.errors,
                descriptionOfBike
              )
              const isDescriptionOfBikeInvalid = Boolean(
                isDescriptionOfBikeTouched && descriptionOfBikeErrorMessage
              )

              const typeOfBike = `bikes[${index}].typeOfBike`
              const isTypeOfBikeTouched = getIn(formik.touched, typeOfBike)
              const typeOfBikeErrorMessage = getIn(formik.errors, typeOfBike)
              const isTypeOfBikeInvalid = Boolean(
                isTypeOfBikeTouched && typeOfBikeErrorMessage
              )

              const bikeValue = `bikes[${index}].bikeValue`
              const isBikeValueTouched = getIn(formik.touched, bikeValue)
              const bikeValueErrorMessage = getIn(formik.errors, bikeValue)
              const isBikeValueInvalid = Boolean(
                isBikeValueTouched && bikeValueErrorMessage
              )

              const shouldShowBikeHeader = formik.values.bikes.length === 2

              return (
                <React.Fragment key={`bike-details-${index}`}>
                  {shouldShowBikeHeader && (
                    <BikeHeader variant="h3">
                      {t("bike")} {index + 1}
                    </BikeHeader>
                  )}
                  <StyledTextField
                    {...brandOfBikeProps}
                    name={brandOfBike}
                    id={`brandOfBike${index}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={isBrandOfBikeInvalid}
                    helperText={isBrandOfBikeInvalid && brandOfBikeErrorMessage}
                    value={bike.brandOfBike}
                    required={true}
                  />

                  <StyledTextField
                    {...descriptionOfBikeProps}
                    name={descriptionOfBike}
                    id={`descriptionOfBike${index}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={isDescriptionOfBikeInvalid}
                    helperText={
                      isDescriptionOfBikeInvalid &&
                      descriptionOfBikeErrorMessage
                    }
                    value={bike.descriptionOfBike}
                    required={true}
                  />

                  <StyledTextField
                    {...typeOfBikeProps}
                    name={typeOfBike}
                    id={`typeOfBike${index}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={isTypeOfBikeInvalid}
                    helperText={isTypeOfBikeInvalid && typeOfBikeErrorMessage}
                    value={bike.typeOfBike}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                      },
                    }}
                    inputProps={{
                      id: `typeOfBike${index}`,
                    }}
                    InputLabelProps={{
                      htmlFor: `typeOfBike${index}`,
                    }}
                    required={true}
                  >
                    {BIKE_TYPES.map((type) => {
                      return (
                        <MenuItem value={type} key={`bike-type-${type}`}>
                          {type}
                        </MenuItem>
                      )
                    })}
                  </StyledTextField>

                  <StyledTextField
                    {...bikeValueProps}
                    name={bikeValue}
                    id={`bikeValue${index}`}
                    onChange={(e) => handleDecimalInput(e)}
                    onBlur={formik.handleBlur}
                    error={isBikeValueInvalid}
                    helperText={isBikeValueInvalid && bikeValueErrorMessage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoTooltip
                            content={t("bikeValueToolTip")}
                            colorProp="CTW"
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={bike.bikeValue}
                    required={true}
                  />
                </React.Fragment>
              )
            })}
            <AddOrRemoveButton arrayHelpers={arrayHelpers} />
          </>
        )}
      </FieldArray>
    )
  }
  return <></>
}

export default BikeDetails

const BikeHeader = styled(Typography)`
  color: #92237f;
  font-size: 17px;
  line-height: 1.5rem;
  margin-bottom: 17px;
  padding-left: 14px;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 1.875rem;
`
