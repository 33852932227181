import { useTranslation } from "react-i18next"
import {
  SpaceTwoButtonContainer,
  WhiteButtonText,
} from "../../shared/SharedStyles"
import { FamilyPayButtonContainer } from "../../shared/family-care-and-family-pay/ContentStyles"

export function Button({ link }: { link: string }) {
  const { t } = useTranslation("portalHomepage")

  return (
    <SpaceTwoButtonContainer>
      <FamilyPayButtonContainer
        component={"a"}
        href={link}
        target="_blank"
        disableFocusRipple
      >
        <WhiteButtonText>{t("familyPay.buttonText")}</WhiteButtonText>
        <span className="screen-reader-only">
          {t("healthAndWellbeing.screenReader")}
        </span>
      </FamilyPayButtonContainer>
    </SpaceTwoButtonContainer>
  )
}
