const extractSubdomain = (url: string) => {
  let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))

  if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
    subdomain = "Vivup"
  }

  return subdomain
}
export default extractSubdomain
