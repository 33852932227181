import { styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export function Footnote() {
  const { t, ready } = useTranslation("bikeQuote")

  if (!ready) {
    return null
  }
  return <StyledText>{t("mandatory")}</StyledText>
}
const StyledText = styled(Typography)`
  font-size: 0.625rem; // 10px
  line-height: 0.625rem; // 10px
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-top: 14px;
  margin-bottom: 4px;
`
