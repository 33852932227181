import { styled, css } from "@mui/material"
interface CycleToWorkMessageProps {
  benefit: string
  quoteLimit: number
}
const CycleToWorkMessage: React.FC<
  React.PropsWithChildren<CycleToWorkMessageProps>
> = (props) => {
  const { benefit, quoteLimit } = props
  return (
    <div>
      <ImportantText>
        <BoldText>Please note:</BoldText> Your organisation has a&nbsp;
        <span>{benefit}</span>&nbsp;limit of{" "}
        <BoldText>£{quoteLimit.toLocaleString()}</BoldText>. This has been
        applied to your results
      </ImportantText>
    </div>
  )
}
export default CycleToWorkMessage

const BoldText = styled("span")`
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  font-family: "Raleway", sans-serif;
  color: #92237f;
  font-weight: bold;
`
const ImportantText = styled("div")`
  ${({ theme }) => css`
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: "Raleway", sans-serif;
    color: #92237f;
    max-width: 600px;
    width: fit-content;
    margin: 0 auto;
    height: 14px;
    text-align: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-bottom: 0;
      margin-bottom: 20px;
    }
  `}
`
