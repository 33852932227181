import React from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom"
import { LinkProps } from "@mui/material/Link"
import { createTheme } from "@mui/material/styles"
import { styleVars } from "../style.js"
import hexToRgba from "../utils/hexToRgba"

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true
    gray: true
    invisible: true
  }
}

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
})

const Theme = createTheme({
  boxShadow: styleVars.boxShadow,
  boxBorderRadius: styleVars.boxBorderRadius,
  spacing: [0, 4, 8, 12, 16, 20, 24, 32, 43],
  breakpoints: {
    values: {
      xs: parseInt(styleVars.xsBreakpoint),
      sm: parseInt(styleVars.smBreakpoint),
      md: parseInt(styleVars.mdBreakpoint),
      lg: parseInt(styleVars.lgBreakpoint),
      xl: parseInt(styleVars.xlBreakpoint),
    },
  },
  typography: {
    fontFamily: styleVars.fontFamily,
    fontSize: 16,
    fontWeightRegular: parseInt(styleVars.fontWeightBase),
    h1: {
      fontSize: styleVars.h1FontSize,
      lineHeight: styleVars.h1LineHeight,
    },
    h2: {
      fontSize: styleVars.h2FontSize,
      lineHeight: styleVars.h2LineHeight,
    },
    h3: {
      fontSize: styleVars.h3FontSize,
      lineHeight: styleVars.h3LineHeight,
    },
    h4: {
      fontSize: styleVars.h4FontSize,
      lineHeight: styleVars.h4LineHeight,
    },
    h5: {
      fontSize: styleVars.h5FontSize,
      lineHeight: styleVars.h5LineHeight,
    },
    body1: {
      fontSize: styleVars.body1FontSize,
      lineHeight: styleVars.body1LineHeight,
    },
    body2: {
      fontSize: styleVars.body2FontSize,
      lineHeight: styleVars.body2LineHeight,
    },
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: styleVars.primary,
    },
    secondary: {
      main: styleVars.secondary,
    },
    success: {
      main: styleVars.success,
    },
    error: {
      main: styleVars.error,
    },
    warning: {
      main: styleVars.warning,
    },
    info: {
      main: "#0B7EB0",
    },
    grey: {
      200: styleVars.lightGray,
      700: styleVars.darkGray,
    },
    white: {
      main: styleVars.white,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "1.5rem",
          "&:disabled": {
            cursor: "not-allowed",
          },
          "&:focus": {
            outline: `${styleVars.secondary} solid 0.2rem !important`,
          },
        },
      },
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: `linear-gradient(90deg, ${styleVars.secondary}, ${styleVars.primary})`,
            color: styleVars.white,
            borderRadius: "2.1875rem",
            padding: "0.6875rem 0.625rem",
            margin: "0.25rem",
            ":hover": {
              background: `linear-gradient(90deg, ${hexToRgba(
                styleVars.secondary,
                "0.7"
              )}, ${hexToRgba(styleVars.primary, "0.7")})`,
            },
            "&:disabled": {
              background: `linear-gradient(90deg, ${hexToRgba(
                styleVars.secondary,
                "0.7"
              )}, ${hexToRgba(styleVars.primary, "0.7")})`,
              color: styleVars.white,
            },
          },
        },
        {
          props: { variant: "gray" },
          style: {
            background: styleVars.white,
            color: "#707070",
            borderRadius: "2.1875rem",
            padding: "0.5625rem 0.625rem",
            border: "solid #707070 0.125rem",
            margin: "0.25rem",

            ":hover": {
              background: "#707070",
              color: styleVars.white,
            },
          },
        },
        {
          props: { variant: "invisible" },
          style: {
            ":hover": {
              backgroundColor: "inherit",
            },
          },
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          "&$spacing": {
            margin: 20,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: styleVars.white,
          borderRadius: "0.25rem",
          color: styleVars.primary,
          boxShadow: styleVars.boxShadow,
          opacity: 1,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: styleVars.primary,
        },
        previousNext: {
          backgroundColor: styleVars.white,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: styleVars.white,
        },
        deleteIcon: {
          color: styleVars.darkGray,
          fontSize: "1.05rem",
        },
        labelMedium: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: styleVars.backdropPrimary,
        },
        invisible: {
          backgroundColor: "transparent",
        },
      },
    },
  },
})

export default Theme
