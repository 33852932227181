import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import snakeToCamel from "../../../utils/snakeToCamel"

export const useCycleToWorkButtonsData = (
  scheme_type?: string,
  bikeDepartmentId?: number
) => {
  const params = useParams()
  const { t } = useTranslation(snakeToCamel(params.schemeType || "bike_shop"))

  return [
    {
      label: t("landing.topButtons.browseBikesOnline"),
      link: `/organisations/:organisationId/employee/benefits/${scheme_type}/departments/${bikeDepartmentId}`,
    },
    {
      label: t("landing.topButtons.findBikeShop"),
      link: `/organisations/:organisationId/employee/benefits/${scheme_type}/find_a_bike_shop`,
    },
    {
      label: t("landing.topButtons.orderWithQuote"),
      link: `/organisations/:organisationId/employee/benefits/${scheme_type}/enter_bike_quote`,
    },
    {
      label: t("landing.topButtons.calculateYourSavings"),
      link: "#",
    },
  ]
}
