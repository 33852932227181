import React, { useState, useEffect } from "react"
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api"
import Pin from "./Pin"
import { useSearchParams } from "react-router-dom"

function MapContainer({ coordinates, options, center, searchTerm }) {
  const [searchParams] = useSearchParams()
  const selectedPinId = searchParams.get("supplier_id") || null
  const [zoom, setZoom] = useState(selectedPinId ? 10 : 5)
  const [centerPosition, setCenterPosition] = useState(center)
  const handleZoom = React.useCallback(() => {
    setZoom((currentZoom) => (currentZoom === 10 ? currentZoom + 0.0001 : 10))
  }, [])
  useEffect(() => {
    if (selectedPinId && coordinates.length > 0) {
      const pin = coordinates.find((pin) => pin.id === selectedPinId)
      setCenterPosition({ lat: pin.lat, lng: pin.lng })
      handleZoom()
    }
  }, [selectedPinId, handleZoom, coordinates])

  const renderClusterer = React.useCallback(
    function renderClusterer(clusterer) {
      return coordinates.map(function mapper(location) {
        return (
          <Pin
            searchTerm={searchTerm}
            key={location.id}
            position={location}
            clusterer={clusterer}
            handleZoom={handleZoom}
            selectedPinId={selectedPinId}
          />
        )
      })
    },
    [coordinates, handleZoom, selectedPinId, searchTerm]
  )

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <GoogleMap
        mapContainerClassName="mapContainerStyle"
        id="marker-example"
        zoom={zoom}
        center={centerPosition}
      >
        <MarkerClusterer options={options}>{renderClusterer}</MarkerClusterer>
      </GoogleMap>
    </div>
  )
}

MapContainer.defaultProps = {
  coordinates: [],
  mapContainerStyle: {
    width: "55.0625rem",
    height: "29.5rem",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
    overflow: "hidden",
    [`@media screen and (max-width: 900px`]: {
      width: "347px",
      height: "400px",
    },
  },
  options: {
    imagePath: "",
  },
  center: {
    lat: 54.8,
    lng: -4.6,
  },
}

export default MapContainer
