import { css, styled } from "@mui/material"
import Switch from "@mui/material/Switch"
import { useEffect, useState } from "react"
import { FormikProps } from "formik"
import { NewBenefitTileValue } from "./formikUtils"
import { useTranslation } from "react-i18next"

const ShowOnPortalPageButton = ({
  formikValue,
}: {
  formikValue: FormikProps<NewBenefitTileValue>
}) => {
  const formik = formikValue
  const [checked, setChecked] = useState(true)
  const { t, ready } = useTranslation("newBenefitTile")

  useEffect(() => {
    setChecked(formik.values.ShowOnPortalPage)
  }, [formik.values.ShowOnPortalPage])

  const checkBoxClick = () => {
    setChecked(!checked)
    formik.setFieldValue("ShowOnPortalPage", !checked)
  }
  const label = {
    inputProps: { "aria-label": t("buttonLabel") },
  }
  if (!ready) {
    return null
  }
  return (
    <>
      <ContainerStyle>
        <StyledSwitch {...label} checked={checked} onClick={checkBoxClick} />
        <StyledPara>
          {checked ? t("isOnPortalPage") : t("isHiddenFromPortal")}
        </StyledPara>
      </ContainerStyle>
    </>
  )
}
export default ShowOnPortalPageButton
export const ContainerStyle = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`
export const StyledPara = styled("p")`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-weight: 600;
  `}
`
export const StyledSwitch = styled(Switch)`
  &&&&& .MuiSwitch-track {
    background-color: ${({ checked, theme }) =>
      checked ? theme.palette.secondary.main : theme.palette.grey[900]};
  }
`
