import { Box, Link, Typography, css, styled } from "@mui/material"
import useIsMobileView from "../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getPromoLink } from "./PromoModal"

const PromoColorStrip = ({ stage }: { stage: string }) => {
  const params = useParams()
  const promoLink = getPromoLink(params.organisationId, stage)
  return (
    <ColorStrip
      sx={
        stage === "fourthStage"
          ? { backgroundColor: "#FEFA50" }
          : { backgroundColor: "black" }
      }
    >
      <FlexContainer>
        <Link href={promoLink}>
          <PromoText
            sx={
              stage === "fourthStage" ? { color: "black" } : { color: "white" }
            }
          >
            <BlackFridayText stage={stage} />
          </PromoText>
        </Link>
      </FlexContainer>
    </ColorStrip>
  )
}

export default PromoColorStrip

function BlackFridayText({ stage }: { stage: string }) {
  const isMobile = useIsMobileView()
  const { t, ready } = useTranslation("blackFriday")

  if (!ready) {
    return null
  }
  return (
    <>
      {t(`${stage}.strip.partOne`)}
      {isMobile && stage !== "fourthStage" ? <br /> : " "}
      {stage !== "fourthStage" && t(`${stage}.strip.partTwo`)}

      {(stage === "firstStage" || stage === "secondStage") && (
        <span style={{ color: "#E4003A" }}>{" >"}</span>
      )}
    </>
  )
}

const ColorStrip = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
    }
  `}
`

const FlexContainer = styled(Box)`
  display: flex;
  width: 73%;
  align-items: center;
  justify-content: center;
`

const PromoText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    line-height: normal;
    font-size: 0.875rem;
    font-weight: bold;
    margin-left: 0.25rem;
    text-align: center;
    padding: 0.5rem 0;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  `}
`
