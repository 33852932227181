import { string } from "yup"
import i18n from "../../i18n"
import * as yup from "yup"

export const benefitUrlValidation = yup
  .string()
  .test(
    "https-check",
    () => {
      const errorMessage = i18n.t("newBenefitTile:httpCheckWarning")
      return errorMessage
    },
    (value) => {
      return value ? value.startsWith("https://") : true
    }
  )
  .url(() => i18n.t("newBenefitTile:invalidUrlWarning"))
  .required(() => i18n.t("newBenefitTile:emptyUrlWarning"))

export const noticeboardUrlValidation = string()
  .test(
    "https-check",
    () => {
      const errorMessage = i18n.t("newBenefitTile:httpCheckWarning")
      return errorMessage
    },
    (value) => {
      return value ? value.startsWith("https://") : true
    }
  )
  .url(() => i18n.t("newNoticeboardTile:invalidUrlWarning"))
  .required(() => i18n.t("newNoticeboardTile:emptyUrlWarning"))
export const familyCareUrlValidation = string()
  .test(
    "https-check",
    () => {
      const errorMessage = i18n.t(i18n.t("newBenefitTile:httpCheckWarning"))
      return errorMessage
    },
    (value) => {
      return value ? value.startsWith("https://") : true
    }
  )
  .url(() => i18n.t("newFamilyCare:invalidUrlWarning"))
  .required(() => i18n.t("newFamilyCare:emptyUrlWarning"))
export const wellbeingUrlValidation = string()
  .test(
    "https-check",
    () => {
      const errorMessage = i18n.t(i18n.t("newBenefitTile:httpCheckWarning"))
      return errorMessage
    },
    (value) => {
      return value ? value.startsWith("https://") : true
    }
  )
  .url(() => i18n.t("newWellbeingItem:invalidUrlWarning"))
  .required(() => i18n.t("newWellbeingItem:emptyUrlWarning"))
export const familyPayUrlValidation = string()
  .test(
    "https-check",
    () => {
      const errorMessage = i18n.t(i18n.t("newFamilyPay:httpCheckWarning"))
      return errorMessage
    },
    (value) => {
      return value ? value.startsWith("https://") : true
    }
  )
  .url(() => i18n.t("newFamilyPay:invalidUrlWarning"))
  .required(() => i18n.t("newFamilyPay:emptyUrlWarning"))
