import { useState } from "react"
import { Box, css, styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getPasswordField, getReEnterPasswordField } from "./fields"
import PasswordField from "./PasswordField"
import PasswordProgressBar from "./PasswordProgressBar"
import { useFormikContext } from "formik"

export function PasswordFields() {
  const { t, ready } = useTranslation("regForm")
  const [showPassword, setShowPassword] = useState(false)
  const [showReEnterPassword, setShowReEnterPassword] = useState(false)
  const formik = useFormikContext()
  const password = (formik.values as { password: string }).password
  const toggleShowPassword = () => {
    setShowPassword((prev) => {
      return !prev
    })
  }

  const toggleShowReEnterPassword = () => {
    setShowReEnterPassword((prev) => {
      return !prev
    })
  }
  if (!ready) {
    return null
  }
  return (
    <>
      <PasswordField
        passwordFieldProps={getPasswordField()}
        showPassword={showPassword}
        toggleShowPassword={toggleShowPassword}
      />

      <Box style={{ marginBottom: "1.875rem", marginTop: "-1rem" }}>
        <PasswordText>{t("regForm:password.instruction")}</PasswordText>
      </Box>
      {password !== "" && <PasswordProgressBar password={password} />}
      <PasswordField
        passwordFieldProps={getReEnterPasswordField()}
        showPassword={showReEnterPassword}
        toggleShowPassword={toggleShowReEnterPassword}
      />
    </>
  )
}
const PasswordText = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    color: ${theme.palette.primary.main};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
    }
  `}
`
