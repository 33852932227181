import { useSearchParams } from "react-router-dom"
import { Pagination } from "@mui/material"

import updatedSearchParams from "../../utils/updatedSearchParams"

export type PaginationProps = {
  paramName?: string
  pageCount: number
}

export default function PaginationControlled({
  paramName = "page",
  pageCount,
}: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get(paramName) || "1")

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setSearchParams(
      updatedSearchParams(searchParams, [
        { paramName: paramName, value: value.toString() },
      ])
    )
  }

  return (
    <Pagination
      count={pageCount}
      page={page}
      onChange={handleChange}
      color="primary"
      size="small"
      siblingCount={2}
      sx={{
        PaginationItem: {
          color: "inherit",
        },
      }}
    />
  )
}
