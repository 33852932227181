import OuterContainer from "../../shared/card/OuterContainer"
import { StyledInnerContainer } from "../../shared/card/InnerContainer"
import { ImageContainer } from "../../shared/card/card-image/CardImageContainer"
import CardPopUp from "./CardPopUp"
import { Box, css, styled } from "@mui/material"
import {
  SharedVoucherContainer,
  Text,
  VoucherText,
  SharedSavePercentage,
  BrandName,
  SharedTextContainer,
  LSProductCardImage,
} from "./SharedStyles"
import { DiscountType } from "../LSStoreContext"
import Shiitake from "shiitake"
import { useState } from "react"
import { gtmLifestyleSavingTileClick } from "../../shared/gtm-events/CustomGtmEvents"

export const SmallerCard = ({
  data,
  indexCarousel,
  categories,
}: {
  data: DiscountType
  indexCarousel: number
  categories?: string
}) => {
  const {
    id,
    discountCode: promoCode,
    image,
    kind,
    name,
    savingType,
    savingsText: mainText,
    value,
    link,
    index,
    showSavingType,
    featuredTagImage,
    featuredTagName,
  } = data

  const [popUpOpen, setPopUpOpen] = useState(false)
  const shouldShowSavingsText = Boolean(kind)

  const savingText =
    value &&
    (kind === "percent"
      ? `${value.replace(/\.?00+$/, "")}%`
      : `£${value.replace(/\.?00+$/, "")}`)

  const CardPopUpOpenClick = () => {
    setPopUpOpen(true)
    gtmLifestyleSavingTileClick(
      id,
      savingType,
      name,
      indexCarousel + 1,
      index,
      value,
      categories
    )
  }
  return (
    <OuterContainer
      featuredTagImage={featuredTagImage}
      featuredTagAltText={featuredTagName}
      isSmallCard
    >
      <InnerContainer onClick={CardPopUpOpenClick}>
        <ImageWrapper>
          <LSProductCardImage src={image} alt={name} />
        </ImageWrapper>

        <FlexContainer>
          <Box title={name} component="div">
            <Brand>
              <Shiitake lines={1} tagName="span">
                {name}
              </Shiitake>
            </Brand>
          </Box>

          <Box title={mainText}>
            <Content>
              <Shiitake lines={2} tagName="span">
                {mainText}
              </Shiitake>
            </Content>
          </Box>
          {savingText && (
            <SavingText variant="h3">
              {shouldShowSavingsText && `Save ${savingText}`}
            </SavingText>
          )}
        </FlexContainer>

        {showSavingType && (
          <BottomVoucherRibbon>
            <VoucherText variant="body2">{savingType}</VoucherText>
          </BottomVoucherRibbon>
        )}
      </InnerContainer>
      <CardPopUp
        id={id}
        value={value}
        indexCarousel={indexCarousel}
        index={index}
        title={name}
        description={mainText}
        savingText={shouldShowSavingsText ? `Save ${savingText}` : undefined}
        discountCode={promoCode}
        link={link}
        open={popUpOpen}
        setOpen={setPopUpOpen}
        savingType={savingType}
        parseAmount={""}
      />
    </OuterContainer>
  )
}
export const InnerContainer = styled(StyledInnerContainer)`
  ${({ theme }) => css`
    height: 14.5625rem;
    max-width: 15.875rem;
    max-height: 19.6875rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 15.875rem;
      max-height: 19.6875rem;
      align-items: center;
    }
  `}
`

export const ImageWrapper = styled(ImageContainer)`
  height: 50%;
  width: 100%;
`

export const BottomVoucherRibbon = styled(SharedVoucherContainer)`
  width: 100%;
  height: 1.1875rem;
`
export const Content = styled(Text)`
  && {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.primary.main};
    padding-top: 0.125rem;
  }
`

export const TextContainer = styled(SharedTextContainer)`
  padding: 0;
`

const FlexContainer = styled(SharedTextContainer)`
  display: grid;
  height: 35%;
  width: 82%;
  grid-template-rows: auto auto 1fr;
  padding-top: 0;
`

const Brand = styled(BrandName)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const SavingText = styled(SharedSavePercentage)`
  align-self: end;
  padding-top: 0.125rem;
`
