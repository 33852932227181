import { InputAdornment, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../utils/useIsMobileView"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../registration/sign-up/SignUp"
import GenericSelect from "../shared/form/GenericSelect"
import InfoTooltip from "../shared/InfoTooltip"
import { getBenefitOptionField } from "./fields"
import { NewBenefitTileValue } from "./formikUtils"
import { useMemo } from "react"

export function BenefitDropDownField({
  translation,
  isEditScreen = false,
}: {
  translation: string
  isEditScreen?: boolean
}) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation(translation)
  const { setFieldValue } = useFormikContext()
  const formik = useFormikContext()
  const selectBenefitOption = useMemo(
    () => [
      { label: t("optionOne"), id: "landing_page" },
      { label: t("optionTwo"), id: "link" },
    ],
    [t]
  )
  const defaultSelect = formik.values as NewBenefitTileValue

  useEffect(() => {
    if (!defaultSelect.SelectAnOption) {
      setFieldValue("SelectAnOption", selectBenefitOption[0].id)
    }
  }, [setFieldValue, selectBenefitOption, defaultSelect.SelectAnOption])

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip sx={{ width: { xs: "100%", sm: "50%" } }}>
      <label htmlFor="SelectAnOption" style={{ display: "none" }}>
        Select a benefit option
      </label>
      <GenericSelect
        props={getBenefitOptionField(translation)}
        sx={
          isMobileView
            ? {
                "& .MuiSelect-icon": {
                  position: "relative",
                  right: 0,
                  cursor: "pointer",
                },
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
              }
            : undefined
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                required: true,
                disabled: isEditScreen ? true : false,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={t("benefitDropDownTooltip")} />
                    </InputAdornment>
                  ),
                },
              }
            : {
                required: true,
                disabled: isEditScreen ? true : false,
              }
        }
      >
        {selectBenefitOption.map((options) => (
          <MenuItem key={options.id} value={options.id}>
            {options.label}
          </MenuItem>
        ))}
      </GenericSelect>

      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t("benefitDropDownTooltip")} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
