import { useTheme } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { gtmRequestCallBackClick } from "../shared/gtm-events/CustomGtmEvents"
import { ClientAdminLayoutContext } from "./ClientAdminLayoutContext"

type RequestCallBackProps = {
  schemeType: string
  number: number
}
const RequestCallBack = ({ schemeType, number }: RequestCallBackProps) => {
  const theme = useTheme()
  const params = useParams()
  const { organisationName, user } = useContext(ClientAdminLayoutContext)
  const { t, ready } = useTranslation("clientAdmin")
  const mailTo = `mailto:sales@vivup.co.uk?subject=Request%20a%20call%20back&body=Ref:%20${organisationName}%0A%0ABenefit:%20${schemeType}%0A%0AHi,%0A%0AI%20would%20like%20to%20make%20you%20aware%20that%20a%20number%20of%20our%20employees%20have%20shown%20interest%20in%20the%20${schemeType}%20benefit%20and%20have%20requested%20this%20benefit%20be%20opened%20and%20made%20available%20to%20them.%0A%0AWe%20are%20interested%20in%20hearing%20more%20about%20this%20benefit%20and%20would%20like%20to%20request%20someone%20from%20Vivup%20to%20contact%20us%20for%20further%20discussion.%0A%0ALooking%20forward%20to%20speaking%20with%20you%20soon.%0A%0AMany%20thanks.%0A%0A${
    params.organisationId
  }%0A${user?.email}${user?.mobileNumber ? ", " + user.mobileNumber : ""}`

  const event = "request_a_call_back"

  if (!ready) {
    return null
  }
  return (
    <div>
      <p>
        <a
          href={mailTo}
          style={{ color: theme.palette.secondary.main }}
          target="_top"
          onClick={() => {
            gtmRequestCallBackClick(event)
          }}
        >
          <span className="screen-reader-only">
            {number.toString() +
              " " +
              t("clientAdmin:benefitManagement.requestsToOpen.card.text") +
              " " +
              schemeType}
          </span>
          {t("clientAdmin:benefitManagement.requestsToOpen.card.button")} {">"}
        </a>
      </p>
    </div>
  )
}
export default RequestCallBack
