import { styled, css, Box } from "@mui/material"
import {
  ButtonContainer,
  ContentGrid,
  ImageGrid,
  MainGrid,
  ParagraphText,
  TextContainer,
  TextTitle,
} from "../cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import { ButtonStyle } from "../cycle-to-work/BikeShopHardCodeComponent"
import { useTranslation } from "react-i18next"
import { PayRollGivingProps } from "./StartExploringPayRollGiving"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
import i18n from "../../../i18n"

const AboutCharities: React.FC<PayRollGivingProps> = ({ link }) => {
  const { t, ready } = useTranslation("payrollGiving")
  const Charities_image = useBuildAwsImageUrl(i18n.language, "Charities.png")
  if (!ready) {
    return null
  }
  return (
    <MainBox>
      <ContentContainer>
        <MainGrid container>
          <ContentGrid item xs={12} md={5}>
            <TextContainer>
              <TextTitle>{t("payrollGiving.aboutCharities.header")}</TextTitle>
              <ParagraphText>
                {t("payrollGiving.aboutCharities.mainPara")}
              </ParagraphText>

              <ButtonContainer>
                <ButtonStyle
                  href={link}
                  component={"a"}
                  aria-label="Get started button"
                  rel="noopener"
                >
                  {t("payrollGiving.aboutCharities.getStartedButton")}
                </ButtonStyle>
              </ButtonContainer>
            </TextContainer>
          </ContentGrid>
          <ImageGrid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageContainer
              src={Charities_image}
              alt="Charities and trust image"
            />
          </ImageGrid>
        </MainGrid>
      </ContentContainer>
    </MainBox>
  )
}
export default AboutCharities

export const ImageContainer = styled("img")`
  max-width: 24.25rem;
  width: 100%;
  height: auto;
  color: white;
  border-radius: 1rem;
  object-fit: cover;
  margin-bottom: 3rem;
  @media screen and (max-width: 899px) {
    max-width: 13.875rem;
    margin-top: 4.5rem;
    margin-bottom: 0rem;
  }
`
export const MainBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 3rem;
    color: ${theme.palette.white.main};
    height: auto;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    @media screen and (max-width: 899px) {
      margin-top: 0rem;
    }
  `}
`
export const ContentContainer = styled(Box)`
  max-width: 1043px;
  width: 100%;
  height: 342px;
  @media screen and (max-width: 899px) {
    height: 576px;
  }
`
