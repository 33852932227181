import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { Link } from "@mui/material"
import { gtmPortalHomepageHealthAndWellbeingLinkClick } from "../gtm-events/CustomGtmEvents"
const HealthAndWellbeingColumn = ({
  items,
  setActive,
}: {
  items: { label: string; link: string }[]
  setActive: (state: boolean) => void
}) => {
  const clickLocation = "Header Mega Menu"
  return (
    <>
      {items.map((item) => (
        <Link
          component={"a"}
          href={item.link}
          target={
            ["Nursery", "Childminder", "Holiday Clubs"].includes(item.label)
              ? "_blank"
              : undefined
          }
          key={item.label}
          className={"mega-menu-tab__category"}
          onClick={() => {
            setActive(false)
            gtmPortalHomepageHealthAndWellbeingLinkClick(
              item.label,
              item.link,
              clickLocation
            )
          }}
        >
          {item.label}

          <ArrowForwardIcon
            fontSize="inherit"
            className="mega-menu-tab__forward-arrow"
            color="inherit"
          />
        </Link>
      ))}
    </>
  )
}

export default HealthAndWellbeingColumn
