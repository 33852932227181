import { styled, Button as MuiButton, css } from "@mui/material"
import { useParams } from "react-router-dom"
import { gtmPortalHomepageLifestyleSavingsButtonClick } from "../../../shared/gtm-events/CustomGtmEvents"
import {
  ButtonBase,
  WhiteButtonText,
  SpaceTwoButtonContainer,
} from "../../shared/SharedStyles"
import { useTranslation } from "react-i18next"

export function Button() {
  const { t, ready } = useTranslation("portalHomepage")
  const params = useParams()
  const url = `/organisations/${params.organisationId}/employee/lifestyle_savings/landing`
  const gtmDataHandler = () => {
    return gtmPortalHomepageLifestyleSavingsButtonClick()
  }

  if (!ready) {
    return null
  }
  return (
    <SpaceTwoButtonContainer id="button-container" onClick={gtmDataHandler}>
      <BlueButtonContainer component={"a"} href={url} disableFocusRipple>
        <WhiteButtonText sx={{ fontSize: "0.95rem" }}>
          {t("lifestyleSavings.buttonText")}
        </WhiteButtonText>
      </BlueButtonContainer>
    </SpaceTwoButtonContainer>
  )
}
const BlueButtonContainer = styled(ButtonBase)`
  ${({ theme }) => css`
    background-color: ${theme.palette.secondary.main};
    display: inline-flex;
    margin-bottom: 2.1875rem;
    text-align: center;

    &:hover {
      background-color: ${theme.palette.secondary.main};
      opacity: 1;

      .MuiTouchRipple-root {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 1.5rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 0;
      padding: 0;

      &:hover {
        background-color: ${theme.palette.secondary.main};
        opacity: 0.75;

        .MuiTouchRipple-root {
          background-color: initial;
        }
      }
    }
  `}
` as typeof MuiButton
