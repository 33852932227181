import { Box, Link, styled, Typography, css } from "@mui/material"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

export const CarouselContainer = ({
  title,
  Slider,
  url,
  showArrow = true,
}: {
  title?: string
  Slider: JSX.Element
  url?: string
  showArrow?: boolean
}) => {
  return (
    <CenterAligned>
      <Container>
        {title && url ? (
          <HeadingContainer>
            <Heading variant="h3" component={Link} href={url} underline="none">
              {title}
            </Heading>
            {showArrow && <Arrow />}
          </HeadingContainer>
        ) : (
          title && (
            <HeadingContainer>
              <Heading variant="h3">{title}</Heading>
              {showArrow && <Arrow />}
            </HeadingContainer>
          )
        )}

        {Slider}
      </Container>
    </CenterAligned>
  )
}
const Arrow = styled(ArrowRightIcon)`
  font-size: 2rem;
`
const HeadingContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.palette.primary.main};
  `}
`
const Heading = styled(Typography)`
  ${({ theme }) => css`
    &&& {
      font-size: ${theme.typography.h3.fontSize};
      font-weight: 700;
      padding-left: 0.5rem;
      font-family: ${theme.typography.fontFamily};
    }
  `}
` as typeof Link

const Container = styled(Box)`
  /* following two lines create a stacking-context for carousel arrows pseudo elements */
  ${({ theme }) => css`
    position: relative;
    z-index: 0;
    padding-top: 1.6875rem;
    padding-left: 1.6875rem;
    padding-bottom: 0;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-right: 1.6875rem;
    }
    /* media query makes the container snap to left side of CenterAligned, but make carousel stick out on the right hand side with width: 110%, with which each card inside carousel will match design dimension of 254*315px */
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 110%;
      padding: 1.6875rem 0 0 0;
    }
  `}
`

const CenterAligned = styled(Box)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 0 auto;
      min-width: 50rem; // 800px, account for padding on left and right
      max-width: 69.125rem; // 1106px
    }
  `}
`
