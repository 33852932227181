import { TextFieldProps } from "@mui/material"
import i18n from "../../../../i18n"

// Define the return type
interface TextFieldsProps {
  brandOfBikeProps: TextFieldProps
  descriptionOfBikeProps: TextFieldProps
  typeOfBikeProps: TextFieldProps
  bikeValueProps: TextFieldProps
  totalAccessoriesValueProps: TextFieldProps
  totalOrderValueProps: TextFieldProps
  brochureGroupProps: TextFieldProps
}

export const getTextFieldsProps = (): TextFieldsProps => ({
  brandOfBikeProps: {
    label: i18n.t("bikeQuote:fieldLabels.brandOfBike"),
    fullWidth: true,
    required: false,
    type: "text",
  },
  descriptionOfBikeProps: {
    label: i18n.t("bikeQuote:fieldLabels.descriptionOfBike"),
    fullWidth: true,
    required: false,
    type: "textarea",
    multiline: true,
    minRows: 3,
    maxRows: 4,
    inputProps: { maxLength: 200 },
  },
  typeOfBikeProps: {
    select: true,
    label: i18n.t("bikeQuote:fieldLabels.typeOfBike"),
    fullWidth: true,
  },
  bikeValueProps: {
    label: i18n.t("bikeQuote:fieldLabels.bikeValue"),
    fullWidth: true,
    type: "text",
    inputProps: {
      inputMode: "numeric",
      pattern: "^[0-9]*\\.?[0-9]*$",
    },
  },
  totalAccessoriesValueProps: {
    id: "totalAccessoriesValue",
    name: "totalAccessoriesValue",
    label: i18n.t("bikeQuote:fieldLabels.totalAccessoriesValue"),
    fullWidth: true,
    type: "text",
    inputProps: {
      inputMode: "numeric",
      pattern: "^[0-9]*\\.?[0-9]*$",
    },
  },
  totalOrderValueProps: {
    id: "totalOrderValue",
    name: "totalOrderValue",
    placeholder: i18n.t("bikeQuote:fieldLabels.totalOrderValue"),
    disabled: true,
    type: "string",
    fullWidth: true,
  },
  brochureGroupProps: {
    select: true,
    id: "brochureGroup",
    name: "brochureGroup",
    fullWidth: true,
    required: true,
    value: "",
  },
})

export default getTextFieldsProps
