import { Box, css, styled } from "@mui/material"
import { MainContainer } from "../../explore-your-benefits/BenefitCardModal"
import MailOutlineTwoToneIcon from "@mui/icons-material/MailOutlineTwoTone"
import { useTranslation } from "react-i18next"
import Layout from "../Layout"
import {
  useGetCurrentUserDataQuery,
  useHaveTroubleLoggingInMutation,
} from "../../../graphqGenaretedTypes"
import { useContext, useState } from "react"
import Alert from "../../shared/Alert"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import LoggedOutBanner from "../LoggedOutBanner"
import { getShouldShowBanner } from "../registrationUtils"

const RegThankYou = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const { t } = useTranslation("registration")
  const { data } = useGetCurrentUserDataQuery()
  const [haveTroubleLoggingInMutation] = useHaveTroubleLoggingInMutation()
  const { showBanner: portalProviderShowBanner, banner } = useContext(
    RegistrationLayoutContext
  )

  const globalShowBanner = banner?.show
  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  const email = data?.currentUser?.email
  const reason = "Didn't receive confirmation instructions"

  const handleClick = async () => {
    if (email) {
      const { data } = await haveTroubleLoggingInMutation({
        variables: {
          reason,
          email,
        },
      })

      const errors = data?.haveTroubleLoggingIn?.errors
      const info = data?.haveTroubleLoggingIn?.info

      // show error on snack bar, close the modal
      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount((prev) => prev + 1)
        return
      }

      // show info on snack bar, close the modal
      if (info) {
        setAlertMessage(info)
        setAlertSeverity("info")
        setErrorCount((prev) => prev + 1)
        return
      }
    }
  }
  return (
    <Layout>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "81vh",
          }}
        >
          {alertMessage && (
            <Alert
              severity={alertSeverity}
              message={alertMessage}
              key={errorCount}
            />
          )}
          <MainContainerBox
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            <div>
              <StyleMailIcon />

              <MainHeading>{t("registration.thankyou.heading")}</MainHeading>
              <SecondPara>{t("registration.thankyou.secondPara")}</SecondPara>
              <ThirdPara>{t("registration.thankyou.thirdPara")}</ThirdPara>
              <FourthPara>
                <ClickToReSend onClick={handleClick}>
                  {t("registration.thankyou.fourthPara")}
                </ClickToReSend>
              </FourthPara>
            </div>
          </MainContainerBox>
        </Box>
        {shouldShowBanner && banner?.heading && banner?.images && (
          <LoggedOutBanner
            bannerHeading={banner.heading}
            images={banner.images}
          />
        )}
      </>
    </Layout>
  )
}
export default RegThankYou
const StyleMailIcon = styled(MailOutlineTwoToneIcon)`
  ${({ theme }) => css`
    font-size: 1.25rem;
    margin-top: 2.2331rem;
    margin-bottom: 1.6706rem;
    width: 100%;
    height: 100%;
    max-width: 5.4375rem;
    max-height: 3.75rem;
    padding: 0px;
    color:${theme.palette.primary.main};
    && {
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        margin-top:2.9206rem;
        margin-bottom:1.8581rem;
        font-size: large;
        max-width: 6.9169rem;
        max-height: 6.9169rem;
        width:100%;
        height:100%
        padding: 0px;
    }
  `}
`

export const MainContainerBox = styled(MainContainer)`
  ${({ theme }) => css`
    && {
      width: 92%;
      height: 100%;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        width: 40.89rem;
      }
    }
  `}
`

export const MainHeading = styled("h3")`
  ${({ theme }) => css`
    margin: auto;
    text-align: center;
    margin-bottom: 1.4694rem;
    padding-left: 1.4688rem;
    padding-right: 1.4688rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 34.4375rem;
      width: 100%;
      margin-bottom: 2.1875rem;
    }
  `}
`
export const BasicParaStyle = styled("p")`
  ${({ theme }) => css`
    margin: auto;
    margin-bottom: 1rem;
    color: ${theme.palette.primary.main};
    text-align: center;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
    }
  `}
`
export const SecondPara = styled(BasicParaStyle)`
  ${({ theme }) => css`
    font-size: 14px;
    padding-left: 2.6563rem;
    padding-right: 2.6563rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 24.1875rem;
      width: 100%;
    }
  `}
`
export const ThirdPara = styled(BasicParaStyle)`
  ${({ theme }) => css`
    margin-top: 1.625rem;
    height: 2.4375rem;
    font-size: 0.875rem;
    padding: auto;
    padding-left: 2.0313rem;
    padding-right: 2.0313rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 27.8125rem;
      width: 100%;
    }
  `}
`
const FourthPara = styled(BasicParaStyle)`
  ${({ theme }) => css`
    margin-top: 1.8125rem;
    height: 2.4375rem;
    margin-bottom: 2.25rem;
    font-size: 12px;
    padding-left: 4.5938rem;
    padding-right: 4.5938rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 29.8125rem;
      width: 100%;
      margin-top: 2rem;
    }
  `}
`
const ClickToReSend = styled("a")`
  text-decoration: underline;
  cursor: pointer;
`
