import { Box, styled } from "@mui/material"
import Roundel, { RoundelProps } from "../../../shared/Roundel"

const ROUNDEL_PER_PAGE = 4

export const TwoPropositionsRoundel = ({
  roundelArray,
  roundelBackground,
  cycleToWorkStore,
}: {
  roundelArray: RoundelProps[]
  roundelBackground: "gray"
  cycleToWorkStore?: boolean
}) => {
  const roundelsToRender = roundelArray.slice(0, ROUNDEL_PER_PAGE)

  return (
    <GridContainer>
      {roundelsToRender.map(({ label, imageUrl, link }) => {
        return (
          <Roundel
            key={`portal-homepage-HE-roundel-${label}`}
            imageUrl={imageUrl}
            link={link}
            background={roundelBackground}
            label={label}
            cycleToWorkStore={cycleToWorkStore}
          />
        )
      })}
    </GridContainer>
  )
}

const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(176px, 300px));
  place-items: center;

  /* adjust later after having the img and fine print */
  .roundel {
    margin: 6px;
  }
`
