import { Container, Grid } from "@mui/material"
import Roundel, { RoundelProps } from "./Roundel"

export type RoundelGridProps = {
  roundels: RoundelProps[]
  title?: string
  background: "gray" | "white"
  gtmActionName?: string
  isCycleToWork?: boolean
}

export default function RoundelGrid({
  roundels,
  title,
  background,
  gtmActionName,
  isCycleToWork,
}: RoundelGridProps) {
  return (
    <div
      className={"roundel__container " + `roundel--bg-${background}`}
      data-cy="roundel-container"
    >
      {title && <h3 className="roundel__title">{title}</h3>}
      <Container>
        <Grid container justifyContent="center">
          {roundels.map((roundel, index) => (
            <Grid item key={index}>
              <Roundel
                isCycleToWork={isCycleToWork}
                gtmActionName={gtmActionName}
                label={roundel.label}
                imageUrl={roundel.imageUrl}
                background={roundel.background}
                link={roundel.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}
