import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetAdminLayoutDataQueryHookResult,
  useGetAdminLayoutDataQuery,
} from "../../graphqGenaretedTypes"

export type User = {
  email?: string
  firstName?: string
  lastName?: string
  mobileNumber?: number
}

export type ClientAdminLayoutContextData = {
  organisationPrivacyNotice: boolean
  currentUserId?: number
  customPrivacyNoticeUrl?: string
  organisationName?: string
  loading: boolean
  reporter: boolean
  backendUser: boolean
  user?: User
  canEditBenefits: boolean
  isOrganisationSettingUser: boolean
  commsHubLink?: string
  pendingOrdersCount?: number
  organisations: {
    name: string
    changePortalLink: string
  }[]
}

const defaultValue: ClientAdminLayoutContextData = {
  organisationPrivacyNotice: false,
  organisationName: "",
  customPrivacyNoticeUrl: "",
  loading: true,
  backendUser: false,
  reporter: false,
  canEditBenefits: false,
  isOrganisationSettingUser: false,
  currentUserId: undefined,
  organisations: [],
  user: {
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: 0,
  },
}
const valueFromQueryResult = function (
  result: GetAdminLayoutDataQueryHookResult
): ClientAdminLayoutContextData {
  const data = result.data
  const loading = result.loading

  return {
    organisationName: data?.employeeOrganisation?.name ?? undefined,
    customPrivacyNoticeUrl:
      data?.employeeOrganisation?.customPrivacyNoticeUrl ?? undefined,
    organisationPrivacyNotice:
      data?.employeeOrganisation?.organisationPrivacyNotice || false,
    reporter: data?.employeeOrganisation?.reporter || false,
    currentUserId: data?.currentUser?.id,
    backendUser: data?.currentUser?.backendUser || false,
    isOrganisationSettingUser:
      data?.currentUser?.isOrganisationSettingUser || false,
    organisations:
      data?.currentUser?.employerOrganisations?.map((organisation) => ({
        name: organisation.name,
        changePortalLink: organisation.employerPortalLink || "",
      })) || [],
    commsHubLink: data?.employerOrganisation?.commsHubLink || undefined,
    pendingOrdersCount: data?.employerOrganisation?.pendingOrdersCount || 0,
    canEditBenefits:
      data?.currentUser?.rolesForOrganisation?.includes("organisation_admin") ||
      data?.currentUser?.backendUser ||
      false,
    loading: loading,
    user: {
      email: data?.currentUser?.email || undefined,
      firstName: data?.currentUser?.firstName || undefined,
      lastName: data?.currentUser?.lastName || undefined,
      mobileNumber: data?.currentUser?.mobileNumber || 0,
    },
  }
}

export const useClientAdminLayoutData =
  function (): ClientAdminLayoutContextData {
    const params = useParams()
    const userData = useGetAdminLayoutDataQuery({
      variables: { organisationId: params.organisationId ?? "" },
      errorPolicy: "all",
    })

    return valueFromQueryResult(userData)
  }

export const ClientAdminLayoutContext = createContext(defaultValue)
