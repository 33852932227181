import { styled, Box } from "@mui/material"
import { useTranslation } from "react-i18next"

const PromoTag = ({
  featuredTagImage,
  featuredTagAltText,
  isSmallCard,
}: {
  featuredTagImage: string
  featuredTagAltText?: string | undefined | null
  isSmallCard?: boolean
}) => {
  const { t, ready } = useTranslation("lifestyleSavings")

  if (!ready) {
    return null
  }
  return (
    <PromoTagContainer isSmallCard={isSmallCard}>
      <PromoImage
        src={featuredTagImage}
        alt={`${featuredTagAltText} ${t("landingPage.carousel.promoTagAlt")}`}
      />
    </PromoTagContainer>
  )
}

export default PromoTag

export const PromoTagContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSmallCard",
})<{ isSmallCard: boolean | undefined }>`
  position: absolute;
  z-index: 1;
  top: -0.75rem;
  right: -0.75rem;
  height: ${({ isSmallCard }) => (isSmallCard ? "4rem" : "5rem")};
  width: ${({ isSmallCard }) => (isSmallCard ? "4rem" : "5rem")};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.boxShadow};
`

const PromoImage = styled("img")`
  width: 100%;
`
