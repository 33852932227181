import { InputAdornment } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../../utils/useIsMobileView"
import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../../registration/sign-up/SignUp"
import GenericField from "../../shared/form/GenericField"
import InfoTooltip from "../../shared/InfoTooltip"
import { getField } from "./fields"

export default function Field({ fieldName }: { fieldName: string }) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("newLocalDiscount")
  const textFieldProps = getField(fieldName, "newLocalDiscount")

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip>
      <GenericField
        textFieldProps={{
          ...textFieldProps,
        }}
        sx={
          isMobileView
            ? {
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
                marginBottom: 0,
              }
            : { marginBottom: 0 }
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                label: textFieldProps.label,
                required: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={t(`${fieldName}Tooltip`)} />
                    </InputAdornment>
                  ),
                },
              }
            : {
                label: textFieldProps.label,
                required: true,
              }
        }
      />
      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t(`${fieldName}Tooltip`)} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
