import ScrollToTop from "../shared/ScrollToTop"
import { useContext, useEffect, useState } from "react"
import { LSStoreLayoutContext } from "../layout/lifestyle-savings/LSStoreLayoutContext"
import BottomBanner from "../store/BottomBanner"
import { Box, Grid } from "@mui/material"
import LifestyleProductCard from "./product-card/Card"
import { Container } from "@mui/material"
import classNames from "classnames"
import { useMediaQuery, useTheme } from "@mui/material"
import Pagination from "../shared/Pagination"
import updatedSearchParams from "../../utils/updatedSearchParams"
import Sidebar from "../shared/store/Sidebar"
import Topbar from "../shared/store/topbar/Topbar"
import { useStoreData, LSStoreContext } from "./LSStoreContext"
import { useSearchParams } from "react-router-dom"
import NoResults from "../store/NoResults"
import {
  gtmLsProductPageLoad,
  gtmPlacedStore,
  triggerGtmLifestyleSavingsSearch,
  gtmOnPageland,
} from "../shared/gtm-events/CustomGtmEvents"
export const Store = function () {
  const { data } = useStoreData()
  const { supplierDesktopBanner, supplierMobileBanner } =
    useContext(LSStoreLayoutContext)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [searchParams, setSearchParams] = useSearchParams()
  const pageNumberInUrl = Number(searchParams.get("page"))
  const searchTerm = searchParams.get("search")
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const store = "lifestyle_savings"
  useEffect(() => {
    // update "page" in url when the state of totalPages has changed
    // with current architect, a page is re-rendered if url param is updated
    // so this useEffect is redundant now but it exists for future proof purpose
    if (data.totalPages !== 0 && pageNumberInUrl > data.totalPages) {
      const paramsToUpdate = updatedSearchParams(searchParams, [
        { paramName: "page", value: data.totalPages.toString() },
      ])

      setSearchParams(paramsToUpdate)
    }
  })
  useEffect(() => {
    if (data.loading) {
      gtmOnPageland(store)
    }
  }, [data.loading, store])
  useEffect(() => {
    // scroll to top of the page after clicking on pagination number buttons
    window.scrollTo(0, 0)
    if (!data.loading && !window.triggerGtmSearchEvent) {
      triggerGtmLifestyleSavingsSearch(data.totalEntries)
    }
  }, [data.discounts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPageLoaded && !data.loading) {
      gtmLsProductPageLoad()
      setIsPageLoaded(true)
      gtmPlacedStore(store)
    }
  }, [data.loading, isPageLoaded])

  return (
    <LSStoreContext.Provider value={data}>
      <>
        <div className="global-main-container--gray">
          <Container>
            <div className="topbar-container">
              <Topbar
                isLifestyleFilters={true}
                showTopFilters={true}
                loading={data.loading}
                totalEntries={data.totalEntries}
              />
            </div>
            <div>
              <div
                className={classNames("store-container", {
                  "store-container--mobile": !isDesktop,
                })}
              >
                {isDesktop && (
                  <Sidebar isLifestyleFilters={true} isLifeStyleStore />
                )}
                {/* below skeleton from ProductCardGrid.tsx */}
                <Box
                  sx={{
                    flexGrow: 1,
                    margin: {
                      md: "1.5rem",
                      xs: "0 0 1.5rem 0",
                    },
                    position: "relative",
                    maxWidth: "80rem",
                  }}
                  data-cy="lss-grid-container"
                >
                  {data.loading && <div className="blur-overlay" />}
                  {data?.discounts && data.discounts.length > 0 ? (
                    <Grid container spacing={4}>
                      {data.discounts.map((discountItem, indexCarousel) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            md={4}
                            lg={3}
                            key={discountItem.id}
                            sx={{
                              marginBottom: "2rem",
                              paddingLeft: isDesktop ? " 1rem" : "0rem",
                            }}
                          >
                            <LifestyleProductCard
                              isMobileStore
                              data={discountItem}
                              indexCarousel={indexCarousel}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  ) : (
                    <>
                      {!data.loading && searchTerm && (
                        <NoResults searchTerm={searchTerm} />
                      )}
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "2rem 0 1.5rem 0",
                    }}
                  >
                    {data.totalPages > 1 && (
                      <div>
                        <Pagination pageCount={data.totalPages} />
                      </div>
                    )}
                  </Grid>
                </Box>
              </div>
            </div>

            <div>
              <ScrollToTop showBelow={500} />
            </div>
          </Container>
        </div>
        {(supplierMobileBanner || supplierDesktopBanner) && (
          <BottomBanner
            mobileBannerUrl={supplierMobileBanner}
            desktopBannerUrl={supplierDesktopBanner}
          />
        )}
      </>
    </LSStoreContext.Provider>
  )
}
