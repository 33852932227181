import * as Yup from "yup"
import { bikeTitleValidation } from "../../../utils/yup-validation-schema/titleValidation"
import {
  firstNameValidation,
  lastNameValidation,
} from "../../../utils/yup-validation-schema/nameValidation"
import {
  mobileNumberValidation,
  mobileNumberValidationRequired,
} from "../../../utils/yup-validation-schema/mobileNumberValidation"
import {
  colleagueEmailValidation,
  emailValidation,
} from "../../../utils/yup-validation-schema/emailValidation"
import {
  passwordValidation,
  reEnterPasswordValidation,
} from "../../../utils/yup-validation-schema/passwordValidation"
import { organisationValidation } from "../../../utils/yup-validation-schema/organisationValidation"
import { salaryBracketValidation } from "../../../utils/yup-validation-schema/salaryBracketValidation"
import { validationNumberValidation } from "../../../utils/yup-validation-schema/validationNumberValidation"

export const defaultSalaryBracketState = "optional"
export const defaultValidationNumberRequired = false
export const defaultMobileNumberState = "optional"

export type SignUpFormikValue = {
  organisation: {
    id: string
    label: string
  }
  title: string
  firstName: string
  lastName: string
  mobileNumberState: "required" | "optional" | "hidden"
  mobileNumber: string
  email: string
  password: string
  reEnterPassword: string
  salaryBracketState: "required" | "optional" | "hidden"
  validationNumberRequired: boolean
  validationNumberType: string
  salaryBracket: string
  validationNumber: string
  isReferred: boolean
  colleagueEmail: string | undefined
  checkedTC: boolean
}

type FieldsWithValidationKeys<T extends keyof SignUpFormikValue> = T[]

export const fieldsWithValidation: FieldsWithValidationKeys<
  | "organisation"
  | "title"
  | "firstName"
  | "lastName"
  | "mobileNumber"
  | "email"
  | "password"
  | "reEnterPassword"
> = [
  "organisation",
  "title",
  "firstName",
  "lastName",
  "mobileNumber",
  "email",
  "password",
  "reEnterPassword",
]

export const initialValues: SignUpFormikValue = {
  organisation: {
    id: "",
    label: "",
  },
  title: "",
  firstName: "",
  lastName: "",
  mobileNumberState: defaultMobileNumberState,
  mobileNumber: "",
  email: "",
  password: "",
  reEnterPassword: "",
  salaryBracketState: defaultSalaryBracketState,
  validationNumberRequired: defaultValidationNumberRequired,
  validationNumberType: "Employee number",
  salaryBracket: "",
  validationNumber: "",
  isReferred: false,
  colleagueEmail: undefined,
  checkedTC: false,
}

export const validationSchema = Yup.object().shape({
  organisation: organisationValidation,
  title: bikeTitleValidation,
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  mobileNumber: Yup.string().when("mobileNumberState", (mobileNumberState) => {
    if (mobileNumberState === "required") {
      return mobileNumberValidationRequired
    }
    if (mobileNumberState === "optional") {
      return mobileNumberValidation
    }
    return Yup.string()
  }),
  validationNumber: Yup.string().when(
    ["validationNumberRequired", "validationNumberType"],
    (validationNumberRequired, validationNumberType) => {
      if (validationNumberRequired) {
        return validationNumberValidation(validationNumberType)
      }
      return Yup.string()
    }
  ),
  email: emailValidation,
  password: passwordValidation,
  reEnterPassword: reEnterPasswordValidation,
  salaryBracket: Yup.string().when("salaryBracketState", {
    is: "required",
    then: salaryBracketValidation,
  }),
  isReferred: Yup.boolean(),
  colleagueEmail: Yup.string().when("isReferred", {
    is: true,
    then: colleagueEmailValidation,
    // otherwise: Yup.string().notRequired(),
  }),
})
