export const validateEmail = (email: string) => {
  if (email.length === 0) {
    return { isValid: false, message: "Please enter your email address" }
  }

  const isValid = /^([^´@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email)
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter a valid email address",
  }
}

export const validatePostCode = (postCode: string) => {
  if (postCode.length === 0) {
    return { isValid: false, message: "Please enter your post code" }
  }

  const isValid = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i.test(
    postCode
  )
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter a valid post code",
  }
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const stripedNumber = phoneNumber.replace(/[()-]|\s/g, "")
  if (stripedNumber.length === 0) {
    return { isValid: false, message: "Please enter your phone number" }
  }

  const isValid = /^0\d{9}\d?$/.test(stripedNumber)
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter a valid phone number",
  }
}

export const validateMobileNumber = (mobileNumber: string) => {
  const stripedNumber = mobileNumber.replace(/[()-]|\s/g, "")
  if (stripedNumber.length === 0) {
    return { isValid: false, message: "Please enter your mobile number" }
  }

  const isValid = /^(07\d{9})$/.test(stripedNumber)
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter a valid mobile number",
  }
}

export const validateName = (name: string) => {
  if (name.length < 2 || name.length > 64) {
    return { isValid: false, message: "Please enter a minimum of 2 characters" }
  }

  const isValid = /^([a-z]|[A-Z]|\s|'|-){2,64}$/.test(name)
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter only valid characters",
  }
}

export const validateNiNumber = (niNumber: string) => {
  if (niNumber.length === 0) {
    return { isValid: false, message: "Please enter your NI number" }
  }

  const isValid = /^[A-Z]{2}\d{6}[A-Z]$/.test(niNumber)
  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter a valid NI number",
  }
}

export const validateDateOfBirth = (
  dateOfBirth: string,
  rawValue: string | null
) => {
  const isValid =
    dateOfBirth !== "Invalid Date" && dateOfBirth !== "" && dateOfBirth !== null

  if (rawValue) {
    const isValidFormat = /^\d{2}\/\d{2}\/\d{4}$/.test(rawValue)
    if (!isValidFormat) {
      return {
        isValid: false,
        message: "The date format is dd/mm/yyyy",
      }
    }

    const year = rawValue.split("/").map(Number)[2]
    if (!isNaN(year) && year < 1900) {
      return {
        isValid: false,
        message: "Please enter a valid date",
      }
    }
  }

  if (isValid && new Date(dateOfBirth) > new Date()) {
    return {
      isValid: false,
      message: "Please enter a date in the past",
    }
  }

  return {
    isValid: isValid,
    message: isValid ? "" : "Please enter your date of birth",
  }
}
