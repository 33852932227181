import { Button } from "@mui/material"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import {
  useDeleteLocalBenefitMutation,
  useDeleteLocalDiscountMutation,
} from "../../graphqGenaretedTypes"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Alert from "../shared/Alert"
import ConfirmationModal from "./ConfirmationModal"
import { useTranslation } from "react-i18next"

export type DeleteLocalBenefitButtonProps = {
  id: string
  translation: string
  redirectUrl: string
}

export default function DeleteLocalBenefitButton({
  id,
  translation,
  redirectUrl,
}: DeleteLocalBenefitButtonProps) {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [deleteLocalBenefitMutation] = useDeleteLocalBenefitMutation()
  const [deleteLocalDiscountMutation] = useDeleteLocalDiscountMutation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const navigate = useNavigate()
  const { t, ready } = useTranslation(translation)

  const handleDelete = async () => {
    setIsDeleteModalOpen(false)

    if (translation === "newLocalDiscount") {
      const { data } = await deleteLocalDiscountMutation({
        variables: {
          localDiscountId: id,
        },
      })

      const errors = data?.deleteLocalDiscount?.errors

      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount(errorCount + 1)
      } else {
        navigate(redirectUrl)
      }

      return
    }

    const { data } = await deleteLocalBenefitMutation({
      variables: {
        localBenefitId: id,
      },
    })
    const errors = data?.deleteLocalBenefit?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    } else {
      navigate(redirectUrl)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertSeverity}
          message={alertMessage}
          key={errorCount}
        />
      )}
      <Button onClick={() => setIsDeleteModalOpen(true)}>
        <DeleteOutlinedIcon />
        Delete
      </Button>
      <ConfirmationModal
        openModal={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleAction={() => handleDelete()}
        label={t("popUpModal.deleteModalHeading")}
        firstLine={t("popUpModal.deleteModalFirstLine")}
        buttonLabel={t("popUpModal.deleteButtonLabel")}
      />
    </>
  )
}
