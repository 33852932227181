export type SpecialPromotionType = {
  label: string
  url: string
  mobilePopUpUrl: string
  desktopPopUpUrl: string
}

export function specialPromotionAvailable(specialPromotion) {
  return specialPromotion?.url && specialPromotion?.label
}

export function promotionalItem(name: string) {
  return name === "Black Friday" || name === "Cyber Monday"
}
