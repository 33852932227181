import React from "react"
import { Marker, InfoWindow } from "@react-google-maps/api"
import { Box } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { gtmCycleToWorkLinkClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useMediaQuery, useTheme, styled, css, Link } from "@mui/material"
import { useParams, createSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const Pin = ({
  position,
  clusterer,
  handleZoom,
  selectedPinId,
  searchTerm,
}) => {
  const [, setSearchParams] = useSearchParams()
  const params = useParams()
  const theme = useTheme()
  const { t, ready } = useTranslation("findBikeShop")
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const quotePageLink = `/organisations/${params.organisationId}/employee/benefits/cycle_to_work/enter_bike_quote`
  const bikeshopQuotePageLink = `/organisations/${params.organisationId}/employee/benefits/bike_shop/enter_bike_quote`

  const handleNavigation = (supplier) => {
    if (supplier) {
      const queryParams = createSearchParams({ supplier })
      const href = `${
        params.schemeType === "cycle_to_work"
          ? quotePageLink
          : bikeshopQuotePageLink
      }?${queryParams}`
      return href
    }
    return quotePageLink
  }

  const handleMarkerClick = () => {
    handleZoom()
    if (selectedPinId !== position.id) {
      setSearchParams({ supplier_id: position.id })
    } else {
      handleClose()
    }
  }

  const handleClose = () => {
    setSearchParams({})
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <Marker
        key={position.id}
        position={position}
        clusterer={clusterer}
        onClick={handleMarkerClick}
        aria-label="maps-pin"
      />
      {selectedPinId === position.id && (
        <InfoWindow
          style={{
            marginTop: "-1rem",
            backgroundColor: "black",
            top: "-1rem !important",
          }}
          position={position}
          onCloseClick={handleClose}
          pixelOffset={{ x: 0, y: 0 }} // Set the pixelOffset to move the InfoWindow up by 50 pixels
          top={"-2rem"}
        >
          {/* Content of the InfoWindow */}
          <Box>
            <div>
              <h1 style={{ fontSize: "18px" }}>{position.name}</h1>
              <p style={{ fontSize: "13px" }}>
                {position.formattedAddressString}
                <br />
                {position.tel}
              </p>
              <p
                style={{
                  display: isDesktop ? "flex" : "block",
                  justifyContent: "space-between",
                }}
              >
                <WebsiteLink
                  className="popup"
                  style={{ color: "#551A8B" }}
                  component={"a"}
                  href={position.supplierWebsite}
                  onClick={() => {
                    const storeName = position.name
                    gtmCycleToWorkLinkClick(searchTerm, storeName)
                  }}
                >
                  {position.supplierWebsite}
                </WebsiteLink>
                <WebsiteLink
                  href={handleNavigation(position.id)}
                  style={{
                    cursor: "pointer",
                    paddingLeft: isDesktop ? "10px" : "",
                    display: isDesktop ? "" : "block",
                    paddingTop: isDesktop ? "" : "10px",
                  }}
                >
                  {t("findBikeShop.map.orderWithQuotes")}
                </WebsiteLink>
              </p>
            </div>
          </Box>
        </InfoWindow>
      )}
    </>
  )
}

export default Pin

const WebsiteLink = styled(Link)`
  ${({ theme }) => css`
    &&&& {
      color: ${theme.palette.secondary.main};
      text-decoration: none;
    }
  `}
`
