import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import {
  gtmPopularSuggestion,
  gtmLifestyleSavingsSearch,
} from "../gtm-events/CustomGtmEvents"
import { useNavigate } from "react-router"
import { useParams, useSearchParams } from "react-router-dom"
import onEnter from "../../../utils/onEnter"

export function SearchWidget({
  searchPath,
  suggestionList,
  paramName,
  setValue,
  setIsWidgetOpen,
  listRef,
  handleKeyDown,
}: {
  searchPath: string
  suggestionList: string[]
  paramName: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  listRef: React.MutableRefObject<HTMLDivElement[]>
  handleKeyDown: (e: React.KeyboardEvent) => void
}) {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const params = useParams()
  const store = params.schemeType
  const handleSubmit = (keyword: string) => {
    setValue(keyword)
    setIsWidgetOpen(false)
    if (window.location.pathname === searchPath) {
      setSearchParams({ [paramName]: keyword })
      gtmPopularSuggestion(keyword, store)
      gtmLifestyleSavingsSearch(keyword)
    } else {
      gtmLifestyleSavingsSearch(keyword)
      navigate(searchPath + `?${paramName}=` + encodeURIComponent(keyword))
    }
  }

  return (
    <div className="search-box__widget">
      <div className="search-box__widget-flex-container">
        <div className="search-box__widget-top">
          <Typography variant="body2" className="search-box__widget-header">
            People are also searching for:
          </Typography>
          <Button
            onClick={() => setIsWidgetOpen(false)}
            sx={{
              height: "1.25rem",
              width: "1.25rem",
              padding: 0,
              marginRight: "-0.25rem",
            }}
            tabIndex={-1}
          >
            <CloseIcon sx={{ height: "1.25rem", width: "1.25rem" }} />
            <div className="screen-reader-only">Close</div>
          </Button>
        </div>
        <List component={"div"} sx={{ paddingBottom: 0 }}>
          {suggestionList &&
            suggestionList.map((keyword, i) => {
              // input field has index {0}, first item on suggestion has index {1}
              const index = i + 1
              return (
                <div
                  key={`widget-keyword-${keyword}`}
                  onKeyDown={(event) =>
                    onEnter(event, () => handleSubmit(keyword))
                  }
                  onClick={() => handleSubmit(keyword)}
                >
                  <ListItem disablePadding component={"div"}>
                    <ListItemButton
                      disableGutters
                      divider={index !== suggestionList.length} // suggestion index starts from 1, therefore no need length-1
                      id={`search-widget-item-${index}`}
                      data-text={keyword} // for `suggestionText` state in SearchBox.tsx
                      onKeyDown={(e) => handleKeyDown(e)} // this handleKeyDown won't trigger handleSubmit because `value` is empty
                      ref={(e) => {
                        if (e) {
                          listRef.current[index] = e
                        }
                      }}
                      tabIndex={-1} // not tab-focusable
                    >
                      <SearchIcon
                        color="secondary"
                        fontSize="small"
                        sx={{ paddingRight: "0.5rem" }}
                      />
                      <ListItemText primary={keyword} />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            })}
        </List>
      </div>
    </div>
  )
}
