import { Grid } from "@mui/material"
import { BenefitCard } from "./benefit-card/BenefitCard"
import { BenefitCardDataType } from "./benefit-card/BenefitCard"

export const DesktopGrid = ({
  benefitCardData,
}: {
  benefitCardData: BenefitCardDataType[]
}) => {
  return (
    <Grid container spacing={2}>
      {benefitCardData.map((data, index) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2.4}
          key={index}
          sx={{
            marginBottom: "2rem",
            paddingLeft: "1rem",
          }}
        >
          <BenefitCard benefitCardData={data} index={index} />
        </Grid>
      ))}
    </Grid>
  )
}
