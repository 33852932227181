import { IconButton, InputAdornment, TextFieldProps } from "@mui/material"
import GenericField from "../../shared/form/GenericField"
import { Visibility, VisibilityOff } from "@mui/icons-material"

type PasswordFieldType = {
  passwordFieldProps: TextFieldProps
  showPassword: boolean
  toggleShowPassword: () => void
  inlineTextFieldProps?: TextFieldProps
}

const PasswordField = ({
  passwordFieldProps,
  showPassword,
  toggleShowPassword,
  inlineTextFieldProps,
}: PasswordFieldType) => {
  return (
    <GenericField
      textFieldProps={passwordFieldProps}
      inlineTextFieldProps={{
        type: showPassword ? "text" : "password",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={showPassword ? "Hide password" : "Show password"}
                edge="end"
                onClick={toggleShowPassword}
              >
                {showPassword ? (
                  <VisibilityOff sx={{ fontSize: "1.5rem" }} />
                ) : (
                  <Visibility sx={{ fontSize: "1.5rem" }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        },
        ...inlineTextFieldProps,
      }}
    />
  )
}

export default PasswordField
