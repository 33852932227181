import { styled, css } from "@mui/material"
import { colourBox } from "../../shared/SharedStyles"
import { Button } from "./Button"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const BlueBox = () => {
  const { i18n } = useTranslation()
  const isMobileView = useIsMobileView()

  return (
    <BlueBoxContainer i18n={i18n}>
      {isMobileView && <Button />}
    </BlueBoxContainer>
  )
}

export default BlueBox

const BlueBoxContainer = styled(colourBox)`
  ${({ theme, i18n }) => css`
    height: 264px;
    background-image: url(${useBuildAwsImageUrl(
      i18n.language,
      "lss_mobile.png"
    )});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      background-position: center 40%;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      background-image: url(${useBuildAwsImageUrl(
        i18n.language,
        "lss_desktop.png"
      )});
      background-position: center center;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  `}
`
