import { styled, Box, css } from "@mui/material"
import PromoTag from "./PromoTag"

const OuterContainer: React.FC<
  React.PropsWithChildren<{
    isSmallCard?: boolean
    featuredTagImage?: string | null
    featuredTagAltText?: string | undefined | null
  }>
> = ({ children, featuredTagImage, featuredTagAltText, isSmallCard }) => {
  return (
    <StyledOuterContainer>
      {featuredTagImage ? (
        <PromoTagContainer>
          <PromoTag
            featuredTagImage={featuredTagImage}
            featuredTagAltText={featuredTagAltText}
            isSmallCard={isSmallCard}
          />
          {children}
        </PromoTagContainer>
      ) : (
        children
      )}
    </StyledOuterContainer>
  )
}

export default OuterContainer

export const StyledOuterContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  /* on focus here refers to tab keyboard focus*/
  /* ProductCard has a isTabPressed state, not sure if it's necessary to implement one in LS-ProductCard */
  /* which is related to onKeyUp and onKeyDown event */
  &:hover,
  &:focus {
    transform: scale(1.03);
  }
`

const PromoTagContainer = styled(Box)`
  ${({ theme }) => css`
    /* Anchor container for the PromoTag with absolute position, that's why need to copy some styles from InnerContainer to have proper alignment as InnerContainer */

    width: 100%; // This prop is here for view under 375px
    position: relative;

    @media screen and (min-width: 375px) {
      /* The width prop here because there's an extra PromoTagContainer when a promo tag presents */
      width: initial;
    }

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 100%;
      max-width: 15.875rem;
    }
  `}
`
