import { getStorePriceTooltip } from "../data/constants"
import i18n from "../i18n"

export function getDynamicInfoToolTip(organisation: string): string {
  if (
    organisation == "NHS" ||
    organisation == "Bronze NHS" ||
    organisation == "Gold NHS" ||
    organisation == "Platinum NHS"
  ) {
    return getStorePriceTooltip("NHS")
  } else if (organisation == "Council LGPS") {
    return getStorePriceTooltip("LGPS")
  } else {
    return i18n.t("constants:constants.StorePriceTooltipNonNhs")
  }
}

export function getDynamicCheckoutPageMessage(organisation: string): string {
  if (organisation == "NET") return i18n.t("constants:constants.netDisclaimer")
  else return i18n.t("constants:constants.disclaimer")
}
