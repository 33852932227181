import { createContext } from "react"
import {
  useGetSignUpDataQuery,
  useGetSignUpParametersQuery,
} from "../../../graphqGenaretedTypes"

export type SignUpContextData = {
  organisations: {
    id: string
    label: string
  }[]
  salaryBrackets: {
    id: number
    description: string
  }[]
}

const defaultValue: SignUpContextData = {
  salaryBrackets: [],
  organisations: [],
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useSignUpData: () => {
  data: SignUpContextData
} = function () {
  const url = location.href
  const subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
  const data = useGetSignUpDataQuery({
    variables: {
      subdomain: subdomain,
    },
    errorPolicy: "all",
  })

  let organisations: {
    id: string
    label: string
  }[] = []

  if (data?.data?.organisations) {
    organisations = data?.data.organisations.map((org) => ({
      id: org.id,
      label: org.name,
    }))
  }

  if (!data.loading) {
    state = {
      ...defaultValue,
      salaryBrackets: data.data?.salaryBrackets,
      organisations: organisations,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const SignUpContext = createContext(defaultValue)

export function GetSignUpParameters(id: string) {
  return useGetSignUpParametersQuery({
    variables: {
      id,
    },
  })
}
