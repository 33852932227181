import { useState, useMemo } from "react"
import { ApolloProvider } from "@apollo/client"
import { initApolloClient } from "../../utils/apolloClient"
import Alert from "./Alert"

export default function ApolloErrorNotifier({
  children,
}: {
  children: JSX.Element
}) {
  const [error, setError] = useState<string | null>(null)
  const apolloClient = useMemo(() => initApolloClient(setError), [])

  return (
    <ApolloProvider client={apolloClient}>
      {error && (
        <Alert
          severity="error"
          message={error}
          key={location.pathname + error}
        />
      )}
      {children}
    </ApolloProvider>
  )
}
