import type { Identifier } from "dnd-core"
import { Button, Link, css, styled, useTheme } from "@mui/material"
import { LocalDiscount as DiscountType } from "../../../graphqGenaretedTypes"
import { EditOutlined, OpenWith } from "@mui/icons-material"
import { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useNavigate } from "react-router-dom"
import { MainIconContainer } from "../../explore-your-benefits/Card"
import { IconContainer } from "../../benefit-managment/Card"
import { useTranslation } from "react-i18next"

export const ItemTypes = {
  DISCOUNT: "discount",
}

interface DragItem {
  index: number
  id: string
  type: string
}

export default function Discount({
  discount,
  index,
  moveDiscount,
  isEditing,
  hidden,
}: {
  discount: DiscountType
  index: number
  isEditing: boolean
  hidden: boolean
  moveDiscount: (dragIndex: number, hoverIndex: number) => void
}) {
  const discountRef = useRef<HTMLAnchorElement | null>(null)
  const navigate = useNavigate()
  const theme = useTheme()
  const { t, ready } = useTranslation("manageLocalDiscounts")

  const iconRef = useRef<HTMLDivElement>(null)
  const id = discount.id
  const lock = false
  const [, drop] = useDrop<
    DragItem,
    { hoverIndex: number },
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.DISCOUNT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!discountRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
    },
    drop() {
      return { hoverIndex: index }
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: ItemTypes.DISCOUNT,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        return
      }

      const dropResult = monitor.getDropResult<{ hoverIndex: number }>()
      if (dropResult) {
        const { hoverIndex } = dropResult
        moveDiscount(item.index, hoverIndex)
      }
    },
    options: {
      dropEffect: "copy",
    },
  })
  const renderIcon = () => (
    <div
      ref={iconRef}
      {...drag(iconRef)}
      style={{ height: "1.125rem", width: "1.125rem" }}
    >
      <>
        <OpenWith sx={{ height: "1.125rem", width: "1.125rem" }} />
        <span className="screen-reader-only">Move benefit</span>
      </>
    </div>
  )

  const renderHiddenIcon = () => (
    <div style={{ position: "relative" }}>
      <MainIconContainer>
        <IconContainer style={{ backgroundColor: theme.palette.grey[700] }}>
          <div>
            <p
              style={{
                fontSize: "0.75rem",
                padding: "0.625rem",
                fontWeight: "bold",
              }}
            >
              {t("hidden")}
            </p>
          </div>
        </IconContainer>
      </MainIconContainer>
      <span className="screen-reader-only">Edit benefit</span>
    </div>
  )

  const renderButton = () => (
    <Button
      color="primary"
      variant="contained"
      sx={{
        borderRadius: "50%",
        padding: "0.25rem",
        marginTop: "0.5rem",
        display: lock && "none",
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!isEditing) {
          navigate(`${discount.id}/edit`)
        }
      }}
    >
      {isEditing ? (
        renderIcon()
      ) : (
        <>
          <EditOutlined sx={{ height: "1.125rem", width: "1.125rem" }} />
          <span className="screen-reader-only">Edit benefit</span>
        </>
      )}
    </Button>
  )

  if (!ready) {
    return null
  }

  return (
    <DiscountContainer
      ref={(node) => {
        discountRef.current = node
        preview(drop(node))
      }}
      data-handler-id={discountRef.current}
      style={{
        opacity: lock ? "0.7" : opacity,
        // pointerEvents: lock ? "none" : "auto",
        position: "relative",
      }}
      href={discount.link}
      component={"a"}
      target="_blank"
    >
      <ImageContainer>
        <Image src={discount.image} alt={discount.name} />
      </ImageContainer>
      <p className="bold">{discount.name}</p>
      <p>{discount.savingText}</p>
      <ButtonContainer>
        {hidden && renderHiddenIcon()}
        {renderButton()}
      </ButtonContainer>
    </DiscountContainer>
  )
}

const DiscountContainer = styled(Link)`
  ${({ theme }) => css`
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    padding: 1rem 1rem 2.375rem 1rem;
    background-color: ${theme.palette.white.main};
    color: ${theme.palette.primary.main};
    font-size: 0.875rem;
    min-width: 13.3rem;
    height: 13.925rem;
    display: block;
    text-decoration: none;

    p {
      margin: 0;
    }
  `}
` as typeof Link

const ImageContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.3rem;
  height: 8.2rem;
  margin: auto;
  margin-bottom: 1rem;
`

const Image = styled("img")`
  ${({ theme }) => css`
    max-width: 100%;
    display: block;
    max-height: 100%;
    border-radius: 0.375rem;
    box-shadow: ${theme.boxShadow};
  `}
`

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
`
