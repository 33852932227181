import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"

export type DepartmentHeaderProps = {
  title: string
  leftColour?: string
  rightColour?: string
}

export default function Example({
  title,
  leftColour,
  rightColour,
}: DepartmentHeaderProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <div
      className={classNames("department-header content-center", {
        "department-header--mobile": !isDesktop,
      })}
      style={{
        background: `linear-gradient(0.25turn, ${
          leftColour || theme.palette.primary.main
        }, ${rightColour || theme.palette.primary.main})`,
      }}
    >
      {title}
    </div>
  )
}
