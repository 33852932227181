export const LogoSection = ({ logos }: { logos: (string | undefined)[] }) => {
  const cleanLogos = logos.filter(Boolean)
  return (
    <>
      {cleanLogos.length > 0 && (
        <div
          className="d-flex"
          style={{
            height: "6.25rem",
            justifyContent: "space-between",
            marginBottom: "2.5rem",
          }}
        >
          {cleanLogos.map((logo) => (
            <img
              key={logo}
              src={logo}
              alt="logo"
              style={{
                maxWidth: "33%",
                maxHeight: "100%",
                margin: cleanLogos.length === 1 ? "auto" : "0",
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
