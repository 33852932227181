import React from "react"
import ReactDOM from "react-dom/client"
import { ThemeProvider } from "@mui/material"
import { LoadScript } from "@react-google-maps/api"

import initAppSignal from "./utils/appSignal"
import { ErrorBoundary } from "@appsignal/react"

import "./main.scss"
import Main from "./components/Main"

import Theme from "./theme/mui-theme"

import ApolloErrorNotifier from "./components/shared/ApolloErrorNotifier"

import "./i18n"

const appSignal = initAppSignal()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <>
    {/* Google Maps LoadScript placed outside strict mode */}
    <LoadScript
      id="script-loader"
      googleMapsApiKey={"AIzaSyC5uDON3ZLhsJV-ClkRcJLbH22bWDxzIMM"}
      language="en"
      region="EN"
      version="weekly"
      loadingElement={<div></div>}
    />

    <React.StrictMode>
      <ErrorBoundary instance={appSignal}>
        <ApolloErrorNotifier>
          <ThemeProvider theme={Theme}>
            <Main />
          </ThemeProvider>
        </ApolloErrorNotifier>
      </ErrorBoundary>
    </React.StrictMode>
  </>
)
