import { useEffect } from "react"
import Layout from "../Layout"
import { CookieAccordions } from "./CookieAccordions"
import { useTranslation } from "react-i18next"

const CookieList = () => {
  const { t, ready } = useTranslation("cookieList")
  useEffect(() => {
    document.title = `Cookie List`
  })

  if (!ready) {
    return null
  }
  return (
    <Layout bannerText={t("bannerText")}>
      <CookieAccordions />
    </Layout>
  )
}

export default CookieList
