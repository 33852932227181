import { useParams } from "react-router"
import {
  useGetSchemeSuppliersDataQuery,
  Supplier,
} from "../../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../../shared/CustomBreadcrumbs"
import { useTranslation } from "react-i18next"

type GetSchemeSuppliersParams = {
  searchTerm?: string
}

type SupplierType = {
  id: string
  name: string
  formattedAddressString: string
  lat: number
  lng: number
  tel: string
  supplierWebsite: string
  county: string
  isOnline: boolean
  bikeShopQuoteInformation: string
}

export type SchemeSupplierData = {
  suppliers: Supplier[]
  mapSuppliers: SupplierType[]
  breadcrumbs: BreadcrumbsType[]
  bikeShopsListBreadcrumbs: BreadcrumbsType[]
  loading: boolean
  bikeDepartmentId?: number
}

const defaultValue: SchemeSupplierData = {
  suppliers: [],
  mapSuppliers: [],
  breadcrumbs: [],
  bikeShopsListBreadcrumbs: [],
  loading: true,
}

export const GetSchemeSuppliersData = ({
  searchTerm,
}: GetSchemeSuppliersParams) => {
  const { organisationId = "", schemeType = "" } = useParams()
  const variables = {
    organisationId,
    schemeType,
    searchTerm: searchTerm,
  }

  return useGetSchemeSuppliersDataQuery({
    variables,
    errorPolicy: "all",
  })
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useSchemeSuppliersData: ({
  searchTerm,
}: GetSchemeSuppliersParams) => {
  data: SchemeSupplierData
} = function ({ searchTerm }) {
  const { organisationId = "", schemeType = "" } = useParams()
  const { i18n } = useTranslation()
  const variables = {
    organisationId,
    schemeType,
    searchTerm: searchTerm,
    locale: i18n.language,
  }
  const data = useGetSchemeSuppliersDataQuery({
    variables,
    errorPolicy: "all",
  })

  if (!data.loading) {
    const suppliers = data.data?.employeeOrganisation?.scheme?.suppliers
    const mapSuppliers =
      data.data?.employeeOrganisation?.scheme?.schemeSuppliers
    const breadcrumbs =
      data?.data?.employeeOrganisation?.scheme?.findBikeShopBreadcrumbs
    const bikeShopsListBreadcrumbs =
      data?.data?.employeeOrganisation?.scheme?.bikeShopsListBreadcrumbs
    const loading = data.loading
    const bikeDepartmentId =
      data.data?.employeeOrganisation?.scheme?.bikeDepartmentId

    state = {
      ...defaultValue,
      suppliers,
      bikeDepartmentId,
      mapSuppliers,
      breadcrumbs,
      bikeShopsListBreadcrumbs,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
