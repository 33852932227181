import { Box, styled } from "@mui/material"
import BottomWave from "./assets/BottomWave"
import TopWave from "./assets/TopWave"

const WaveContainer = ({
  showTopWave = false,
  topWaveColour,
  showBottomWave = false,
  bottomWaveColour,
  children,
  background,
}: {
  showTopWave?: boolean
  topWaveColour?: string
  showBottomWave?: boolean
  bottomWaveColour?: string
  background: string
  children: JSX.Element
}) => {
  return (
    <>
      <div style={{ background: background }}>
        {showTopWave && <TopWave backgroundColor={topWaveColour || "gray"} />}
        <PaddingBox>{children}</PaddingBox>
        {showBottomWave && (
          <BottomWave backgroundColor={bottomWaveColour || "gray"} />
        )}
      </div>
    </>
  )
}

export default WaveContainer

const PaddingBox = styled(Box)`
  padding-top: 3rem;
  padding-bottom: 3rem;
`
