import { Box, styled } from "@mui/material"
import Roundel, { RoundelProps } from "../../shared/Roundel"
import { arrayToSubArrays } from "../../../utils/arrayToSubArrays"
import { StyledSlider } from "../../explore-your-benefits/BenefitCardCarouselGrid"
import disableCarouselAriaHidden from "../../../utils/disableCarouselAriaHidden"
import { useEffect } from "react"

const HEMobileRoundel = ({
  roundelArray,
  roundelBackground,
  roundelPerPage,
  isHomeAndElectronicSection,
  isCycleToWork,
  cycleToWorkStore,
  hEMobileRoundelId,
}: {
  roundelArray: RoundelProps[]
  roundelBackground: "gray"
  roundelPerPage: number
  isCycleToWork?: boolean
  isHomeAndElectronicSection?: boolean
  cycleToWorkStore?: boolean
  hEMobileRoundelId: string
}) => {
  const subArrays = arrayToSubArrays(roundelArray, roundelPerPage)

  useEffect(() => {
    disableCarouselAriaHidden(hEMobileRoundelId)
  }, [hEMobileRoundelId])

  return (
    <StyledSlider
      autoplay={true}
      arrows={false}
      dots={true}
      slidesToScroll={1}
      speed={500}
      autoplaySpeed={10000}
      afterChange={() => disableCarouselAriaHidden(hEMobileRoundelId)}
    >
      {subArrays.map((subArray, index) => {
        return (
          <div key={`mobile-roundel-carousel-page-${index}`}>
            <RoundelCarouselContainer>
              {subArray.map(({ label, imageUrl, link }) => {
                return (
                  <div key={`portal-homepage-roundel-${label}`}>
                    <Roundel
                      imageUrl={imageUrl}
                      link={link}
                      background={roundelBackground}
                      label={label}
                      isHomeAndElectronicSection={isHomeAndElectronicSection}
                      isCycleToWork={isCycleToWork}
                      cycleToWorkStore={cycleToWorkStore}
                    />
                  </div>
                )
              })}
            </RoundelCarouselContainer>
          </div>
        )
      })}
    </StyledSlider>
  )
}

const RoundelCarouselContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
`

export default HEMobileRoundel
