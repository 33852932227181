import { string } from "yup"
import i18n from "../../i18n"

export const benefitSubTitleValidation = string().required(() =>
  i18n.t("newBenefitTile:emptySubTitleWarning")
)

export const noticeboardSubTitleValidation = string().required(() =>
  i18n.t("newNoticeboardTile:emptySubTitleWarning")
)
export const familyCareSubTitleValidation = string().required(() =>
  i18n.t("newFamilyCare:emptySubTitleWarning")
)
export const wellbeingSubTitleValidation = string().required(() =>
  i18n.t("newWellbeingItem:emptySubTitleWarning")
)
export const familyPaySubTitleValidation = string().required(() =>
  i18n.t("newFamilyPay:emptySubTitleWarning")
)
