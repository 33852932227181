import Slider from "react-slick"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ProductCard, { ProductType } from "../product-card/ProductCard"
import { Grid } from "@mui/material"
import { checkProductImpressions } from "../../shared/gtm-events/CustomGtmEvents"
import disableCarouselAriaHidden from "../../../utils/disableCarouselAriaHidden"
import { useEffect } from "react"

export type CarouselProps = {
  productCardCarousel: ProductType[]
  slidesToShow: number
  showAddToOrder?: boolean
  changeStateFromChild?: (totalItems: number, monthlyPrice: number) => void
  productCarouselPopupGridId: string
  isRecommendedTab?: boolean
  isFrequentlyBrought?: boolean
  isSimilarProduct?: string
  youMayAlsoLike?: string
  frequentlyBrought?: string
}

export default function ProductCarousel({
  productCardCarousel,
  slidesToShow,
  showAddToOrder,
  changeStateFromChild,
  productCarouselPopupGridId,
  isRecommendedTab,
  isFrequentlyBrought,
  isSimilarProduct,
  youMayAlsoLike,
  frequentlyBrought,
}: CarouselProps) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <ChevronLeftIcon />,
    nextArrow: <ChevronRightIcon />,
    draggable: true,
    focusOnSelect: false,
    className: "productCarousel",
    afterChange: () => {
      checkProductImpressions()
      disableCarouselAriaHidden(productCarouselPopupGridId)
    },
    onInit: () => checkProductImpressions(),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  useEffect(() => {
    disableCarouselAriaHidden(productCarouselPopupGridId)
  }, [productCarouselPopupGridId])

  return (
    <>
      <Slider {...settings}>
        {productCardCarousel.map((product, index) => (
          <div className="card" key={index}>
            <div className="inner-card">
              <Grid item xs={12} lg={12} key={index}>
                <ProductCard
                  frequentlyBrought={frequentlyBrought}
                  youMayAlsoLike={youMayAlsoLike}
                  isSimilarProduct={isSimilarProduct}
                  isFrequentlyBrought={isFrequentlyBrought}
                  isRecommendedTab={isRecommendedTab}
                  product={product}
                  showAddToOrder={showAddToOrder}
                  changeStateFromChild={(totalItems, monthlyPrice) => {
                    if (changeStateFromChild) {
                      changeStateFromChild(totalItems, monthlyPrice)
                    }
                  }}
                />
              </Grid>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}
