import { Link } from "@mui/material"
import { KeyboardEvent } from "react"

const Column = ({
  items,
  resetRef,
}: {
  items: { label: string; link: string }[]
  resetRef: (e: KeyboardEvent) => void
}) => {
  return (
    <>
      {items.map((item) => (
        <Link
          key={item.label}
          className="mega-menu-tab__subcategory"
          href={item.link}
          sx={{ display: "block" }}
          onKeyDown={(e) => resetRef(e)}
        >
          {item.label}
        </Link>
      ))}
    </>
  )
}

export default Column
