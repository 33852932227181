import { Theme } from "@mui/material"

/**
 * Class name ends with `Base` is the base style
 * which is a full sized H&E/C2W card without shrinking,
 * Class name ends with `Responsive` are applied to H&E card that is responsive
 */
export const getProductStyles = (theme: Theme) => ({
  CardBase: {
    position: "relative",
    width: "100%",
    maxWidth: "15.875rem",
    marginRight: "1rem",
    height: "22.125rem",
    borderRadius: "1rem",
    boxShadow: theme.boxShadow,
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
    },
    alignItems: "flex-end",
  },
  CardResponsive: {
    width: "15.875rem",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: "10.4375rem",
      height: "18.75rem",
      margin: "0",
    },
  },
  AddBtnCardBase: {
    height: "26.5625rem",
  },
  AddBtnCardResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: "22.5rem",
    },
  },
  CardContentBase: {
    width: "85%",
    alignSelf: "center",
    height: "8.6rem",
  },
  CardContentResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: "9.8rem",
    },
  },
  CardContentAddOrder: {
    width: "85%",
    alignSelf: "center",
    height: "13rem",
  },
  CardImgContainerBase: {
    width: "100%",
    height: "55%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CardImgContainerResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: "40%",
    },
  },
  AddBtnCardImgContainerBase: {
    height: "50%",
  },
  AddBtnCardImgContainerResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: "40%",
    },
  },
  CardImg: {
    maxHeight: "85%",
    maxWidth: "85%",
    objectFit: "fill",
    "@media(-ms-high-contrast: none),(-ms-high-contrast: active)": {
      maxWidth: "13.14rem",
      maxHeight: "10.465rem",
    },
  },
  titleContainerBase: {
    height: "3.125rem",
    marginBottom: "0.75rem",
  },
  titleContainerResponsive: {
    [theme.breakpoints.down("sm")]: {
      height: "3.75rem",
      marginBottom: "0.9395rem",
    },
  },
  titleBase: {
    lineHeight: "1rem",
    display: "box",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  titleResponsive: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      display: "-webkit-box",
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
    },
  },
  price: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    lineHeight: "1rem",
    color: theme.palette.secondary.main,
  },
  priceTagLineBase: {
    lineHeight: "1rem",
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
  },
  priceTagLineResponsive: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  lowerTagBase: {
    position: "absolute",
    bottom: "0",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    justifyContent: "center",
    alignItems: "center",
    height: "0.815rem",
    width: "40%",
    borderRadius: "1rem 0 1rem 0",
  },
  lowerTagResponsive: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  lowerTagTextBase: {
    fontSize: "0.7rem",
  },
  lowerTagTextResponsive: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.6rem",
    },
    ["@media (max-width:59.375rem)"]: {
      fontSize: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
  },
  flashDealTagBase: {
    position: "absolute",
    zIndex: "9",
    BoxShadow: "0rem 0.1875rem 0.375rem",
    [theme.breakpoints.up("sm")]: {
      width: "4.75rem",
      height: "4.75rem",
      top: "17%",
      left: "75%",
      transform: "translate(-10%,-95%)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "4.75rem",
      height: "4.75rem",
      top: "17%",
      left: "76%",
    },
  },
  flashDealTagResponsive: {
    [theme.breakpoints.down("sm")]: {
      width: "3.438rem",
      height: "3.438rem",
      top: "-4%",
      left: "70%",
    },
  },
  flashDealTagImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  TabPressed: {
    transform: "scale(1.03)",
  },
  orderBtnBase: {
    margin: "0.5rem 0",
    borderRadius: "1rem",
    fontSize: "0.875rem",
  },
  orderBtnResponsive: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 0",
    },
  },
  showAddToOrderBtn: {
    padding: "0.1875rem 0.625rem",
    backgroundColor: theme.palette.white.main,
    border: "0.0625rem solid #00000029",
    color: "#333333",
    "&:hover": {
      backgroundColor: "#0000001F",
    },
  },
  addedOrderBtnBase: {
    padding: "0.09375rem 0.625rem",
    color: theme.palette.secondary.main,
    border: `0.0625rem solid ${theme.palette.secondary.main}`,
    backgroundColor: "#EEF6FA",
  },
  addedOrderBtnResponsive: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
  },
  addedOrderIconBase: {
    width: "1.19375rem",
  },
  addedOrderIconResponsive: {
    [theme.breakpoints.down("sm")]: {
      width: "1.00625rem",
    },
  },
})
