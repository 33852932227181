import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import InfoIcon from "@mui/icons-material/Info"
import { useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"

export type InfoTooltipProps = {
  content: string
}

export default function InfoTooltip({ content }: InfoTooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <>
      <Tooltip
        title={content}
        placement={isDesktop ? "right-start" : "bottom"}
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        leaveTouchDelay={6000000}
      >
        <Button
          sx={{ ":hover": { backgroundColor: "inherit" } }}
          onClick={() => setShowTooltip(!showTooltip)}
        >
          <InfoIcon
            sx={{
              color: theme.palette.secondary.main,
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        </Button>
      </Tooltip>
    </>
  )
}
