import { useEffect, useState } from "react"

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up")
  const [lastScrollTop, setLastScrollTop] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition > lastScrollTop) {
        setScrollDirection("down")
      } else {
        setScrollDirection("up")
      }
      setLastScrollTop(currentScrollPosition <= 0 ? 0 : currentScrollPosition)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollTop])

  return scrollDirection
}

export default useScrollDirection
