import { InputAdornment, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import useIsMobileView from "../../../utils/useIsMobileView"
import { useFormikContext } from "formik"
import { useEffect } from "react"
import {
  FieldWithTooltip,
  ToolTipContainer,
} from "../../registration/sign-up/SignUp"
import GenericSelect from "../../shared/form/GenericSelect"
import InfoTooltip from "../../shared/InfoTooltip"
import { getOptionField } from "./fields"
import { NewLocalDiscountValue } from "./formikUtils"
import { useMemo } from "react"

export function TypeDropdownField({ isEditScreen = false }) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("newLocalDiscount")
  const { setFieldValue } = useFormikContext()
  const formik = useFormikContext()
  const selectDiscountOption = useMemo(
    () => [
      { label: t("optionOne"), id: "external_link" },
      { label: t("optionTwo"), id: "file_upload" },
    ],
    [t]
  )
  const defaultSelect = formik.values as NewLocalDiscountValue

  useEffect(() => {
    if (!defaultSelect.typeOfLink) {
      setFieldValue("typeOfLink", selectDiscountOption[0].id)
    }
  }, [setFieldValue, selectDiscountOption, defaultSelect.typeOfLink])

  if (!ready) {
    return null
  }

  return (
    <FieldWithTooltip sx={{ width: { xs: "100%", sm: "50%" } }}>
      <label htmlFor="SelectAnOption" style={{ display: "none" }}>
        Select a benefit option
      </label>
      <GenericSelect
        props={getOptionField("newLocalDiscount")}
        sx={
          isMobileView
            ? {
                "& .MuiSelect-icon": {
                  position: "relative",
                  right: 0,
                  cursor: "pointer",
                },
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
              }
            : undefined
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                disabled: isEditScreen ? true : false,
                required: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip content={t("typeTooltip")} />
                    </InputAdornment>
                  ),
                },
              }
            : {
                disabled: isEditScreen ? true : false,
                required: true,
              }
        }
      >
        {selectDiscountOption.map((options) => (
          <MenuItem key={options.id} value={options.id}>
            {options.label}
          </MenuItem>
        ))}
      </GenericSelect>

      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t("typeTooltip")} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
