import Howbenifitworks from "./Howbenifitworks"
import Ofsted from "./Ofsted"
import ThreeSimpleSteps from "./ThreeSimpleSteps"
import { TopBanner } from "../lifestyle-savings/homepage/TopBanner"
import FamilyCare from "./FamilyCare"
import { useGetBenefitDataQuery } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

const FamilyPay = () => {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetBenefitDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      relativeUrl: "family_pay",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  const link = data.data?.employeeOrganisation?.benefit?.ssoLink
  const faqs_url = data.data?.employeeOrganisation?.benefit?.faqsUrl
  const desktopImage = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/DesktopImage.png"
  )
  const mobileImage = useBuildAwsImageUrl(
    i18n.language,
    "benefitLandingPages/MobileImage.png"
  )

  return (
    <div>
      <TopBanner
        desktopImageUrl={desktopImage}
        mobileImageUrl={mobileImage}
        link={null}
      />
      <FamilyCare link={link} />
      <ThreeSimpleSteps link={link} />
      <Howbenifitworks link={link} />
      <Ofsted link={faqs_url} />
    </div>
  )
}
export default FamilyPay
