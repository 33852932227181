import React, { useContext, useEffect } from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
// import Banner from "./Banner"
import Pagination from "../shared/Pagination"
import { StoreContext } from "./StoreContext"
import NoResults from "./NoResults"
import { useParams, useSearchParams } from "react-router-dom"
import updatedSearchParams from "../../utils/updatedSearchParams"
import ProductCard, { ProductType } from "./product-card/ProductCard"
import { checkProductImpressions } from "../shared/gtm-events/CustomGtmEvents"
import CalculatorBanner from "./cycle-to-work/CalculatorBanner"
const ProductCardGrid = ({ isCycleToWork }: { isCycleToWork?: boolean }) => {
  const { products, totalPages, loading, algoliaQueryId } =
    useContext(StoreContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const pageNumberInUrl = Number(searchParams.get("page"))
  const searchTerm = searchParams.get("search")

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    // update "page" in url when the state of totalPages has changed
    // with current architect, a page is re-rendered if url param is updated
    // so this useEffect is redundant now but it exists for future proof purpose
    if (totalPages !== 0 && pageNumberInUrl > totalPages) {
      const paramsToUpdate = updatedSearchParams(searchParams, [
        { paramName: "page", value: totalPages.toString() },
      ])

      setSearchParams(paramsToUpdate)
    }
  }, [totalPages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // scroll to top of the page after clicking on pagination number buttons
    window.scrollTo(0, 0)
    window.productImpressionsData = []
  }, [products])

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    return () => window.removeEventListener("scroll", scrollHandler)
  }, [])

  const scrollHandler = () => {
    checkProductImpressions()
  }
  const params = useParams()
  const splitProducts = [
    products.slice(0, 8),
    ...products
      .slice(8)
      .reduce((resultArray: ProductType[][], product: ProductType, index) => {
        const chunkIndex = Math.floor(index / 16)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(product)

        return resultArray
      }, []),
  ]

  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: {
          md: "1.5rem",
          xs: "0 0 1.5rem 0",
        },
        position: "relative",
      }}
      style={{ minHeight: "100vh" }}
      data-cy="store-grid-container"
    >
      {params.schemeType === "cycle_to_work" ? <CalculatorBanner /> : ""}

      {loading && <div className="blur-overlay" />}
      {products.length > 0 ? (
        <>
          <Grid container spacing={4} sx={{ minHeight: "100vh" }}>
            {splitProducts.flat().map((product, index) => (
              <Grid item xs={6} md={4} lg={3} key={index}>
                <ProductCard
                  index={index + 1} // Add 1 to start positions from 1
                  product={product}
                  isCycleToWork={isCycleToWork}
                  algoliaQueryId={algoliaQueryId}
                />
              </Grid>
            ))}
          </Grid>
          {totalPages > 1 && (
            <div className="store-pagination-container">
              <Pagination pageCount={totalPages} />
            </div>
          )}
        </>
      ) : (
        <>{!loading && searchTerm && <NoResults searchTerm={searchTerm} />}</>
      )}
    </Box>
  )
}

export default ProductCardGrid
