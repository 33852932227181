import * as yup from "yup"
import i18n from "../../i18n"

const MAX_FILE_SIZE = 1e7 // 10 MB

const fileUploadEmptyWarning = (translationLabel: string) =>
  i18n.t(translationLabel)
const fileUploadSizeWarning = (translationLabel: string) =>
  i18n.t(translationLabel)

export const ImageUploadValidation = yup
  .mixed()
  .required(() => fileUploadEmptyWarning("newBenefitTile:emptyFieldWarning"))
  .test(
    "fileSize",
    () => fileUploadSizeWarning("newBenefitTile:fileToLarge"),
    (value) =>
      (value && value.size <= MAX_FILE_SIZE) || typeof value === "string"
  )

export const FileUploadValidation = (translation: string, fieldName: string) =>
  yup
    .mixed()
    .required(fileUploadEmptyWarning(`${translation}:${fieldName}Empty`))
    .test(
      "fileSize",
      fileUploadSizeWarning(`${translation}:${fieldName}TooLarge`),
      (value) =>
        (value && value.size <= MAX_FILE_SIZE) || typeof value === "string"
    )
