import {
  Box,
  Button,
  Modal,
  TextField,
  Grid,
  GridProps,
  InputAdornment,
  styled,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { useContext, useEffect, useState } from "react"
import { MINIMUM_WAGE } from "../../../data/constants"
import { useSearchParams } from "react-router-dom"
import { useParams } from "react-router"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import InfoTooltip from "../InfoTooltip"
import { gtmAffordabilityCalculator } from "../gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

const LabelGrid = (props: GridProps) => (
  <Grid
    item
    xs={12}
    md={8}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: {
        xs: "center",
        md: "start",
      },
      margin: "unset",
    }}
    {...props}
  />
)

export type AffordabilityCalculatorModalProps = {
  open: boolean
  setOpen: (state: boolean) => void
  buttonPresent?: boolean
  setDrawerOpen?: (state: boolean) => void
}

export default function AffordabilityCalculatorModal({
  open,
  setOpen,
  setDrawerOpen,
}: AffordabilityCalculatorModalProps) {
  const params = useParams()
  const { currentUserId } = useContext(StoreLayoutContext) // undefined
  const [searchParams, setSearchParams] = useSearchParams()
  const [monthlyAffordability, setMonthlyAffordability] = useState<
    number | undefined
  >(undefined)
  const [loaded, setLoaded] = useState(false)
  const [hoursPerWeek, setHoursPerWeek] = useState("")
  const [annualSalary, setAnnualSalary] = useState("")
  const [salarySacrifice, setSalarySacrifice] = useState("")
  const { t, ready } = useTranslation("productDetail")
  const store = params.schemeType
  const styledInputProps = {
    sx: {
      paddingLeft: "0.75rem",
    },
    className: "hidden-arrows",
    inputProps: {
      className: "hidden-arrows",
      sx: {
        paddingRight: "0.75rem",
        paddingY: "0.532rem",
      },
    },
  }

  const currencyInputProps = {
    ...styledInputProps,
    startAdornment: (
      <InputAdornment
        position="start"
        sx={{
          marginRight: "0.25rem",
          color: (theme) => theme.palette.grey[200],
        }}
        disableTypography
      >
        {t("productDetail.StickyACButton.Modal.currency")}
      </InputAdornment>
    ),
  }

  const buttonPresent =
    window.location.pathname ===
    `/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/products`

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    if (currentUserId && params.schemeType) {
      setAnnualSalary(() => {
        // getting stored value
        const saved = localStorage.getItem("annualSalary")
        const initialValue = JSON.parse(saved || '""')
        return initialValue[currentUserId?.toString()]
          ? initialValue[currentUserId?.toString()][params.schemeType || ""] ||
              ""
          : ""
      })
      setHoursPerWeek(() => {
        // getting stored value
        const saved = localStorage.getItem("hoursPerWeek")
        const initialValue = JSON.parse(saved || '""')
        return initialValue[currentUserId?.toString()]
          ? initialValue[currentUserId?.toString()][params.schemeType || ""] ||
              ""
          : ""
      })
      setSalarySacrifice(() => {
        // getting stored value
        const saved = localStorage.getItem("salarySacrifice")
        const initialValue = JSON.parse(saved || '""')
        return initialValue[currentUserId?.toString()]
          ? initialValue[currentUserId?.toString()][params.schemeType || ""] ||
              ""
          : ""
      })
      setLoaded(true)
    }
  }, [currentUserId, open]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    if (currentUserId && loaded && params.schemeType) {
      localStorage.setItem(
        "hoursPerWeek",
        JSON.stringify({
          [currentUserId.toString()]: { [params.schemeType]: hoursPerWeek },
        })
      )
      localStorage.setItem(
        "annualSalary",
        JSON.stringify({
          [currentUserId.toString()]: { [params.schemeType]: annualSalary },
        })
      )
      localStorage.setItem(
        "salarySacrifice",
        JSON.stringify({
          [currentUserId.toString()]: { [params.schemeType]: salarySacrifice },
        })
      )
    }
    calculate()
  }, [hoursPerWeek, annualSalary, salarySacrifice]) // eslint-disable-line react-hooks/exhaustive-deps

  const calculate = () => {
    if (hoursPerWeek && annualSalary) {
      const salary = parseFloat(hoursPerWeek) * MINIMUM_WAGE * 52
      const totalOrderValue =
        parseFloat(annualSalary) -
        salary -
        (parseFloat(salarySacrifice) || 0) * 12
      setMonthlyAffordability(totalOrderValue / 12)
    } else {
      setMonthlyAffordability(undefined)
    }
  }

  const applyResults = () => {
    if ((monthlyAffordability || 0) > 0) {
      setSearchParams(
        updatedSearchParams(searchParams, [
          {
            paramName: "max",
            value: "£" + monthlyAffordability?.toFixed(2),
          },
        ])
      )

      gtmAffordabilityCalculator({
        monthlyAffordability: monthlyAffordability?.toFixed(2) || "",
        store: store,
      })

      if (setDrawerOpen) {
        setDrawerOpen(false)
      }
    }
  }

  if (!ready) {
    return null
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <MainBox
        sx={{
          width: {
            xs: "80%",
            md: "32rem",
          },
        }}
        data-cy="affordability-modal"
      >
        <Button
          onClick={() => setOpen(false)}
          sx={{ alignSelf: "flex-end" }}
          title={t("productDetail.StickyACButton.title")}
        >
          <Close />
        </Button>
        <div className="affordability-calculator">
          <div className="affordability-calculator__title">
            {t("productDetail.StickyACButton.Modal.label")}
          </div>
          <p>{t("productDetail.StickyACButton.Modal.subTitle")}</p>
          <div className="affordability-calculator__form">
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <LabelGrid>
                <label htmlFor="hours-per-week">
                  <span>
                    {t("productDetail.StickyACButton.Modal.hourPerWeek")}
                  </span>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="hours-per-week"
                  value={hoursPerWeek || ""}
                  InputProps={styledInputProps}
                  onChange={(event) => {
                    if (/^\d*\.?\d*$/.test(event.target.value)) {
                      setHoursPerWeek(event.target.value)
                    }
                  }}
                  sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                />
              </Grid>
              <LabelGrid>
                <label htmlFor="annual-salary">
                  <span>
                    {t("productDetail.StickyACButton.Modal.annualSalary")}
                  </span>
                  <div className="body-3">
                    {t("productDetail.StickyACButton.Modal.ExclusingOvertime")}
                  </div>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="annual-salary"
                  value={annualSalary || ""}
                  InputProps={currencyInputProps}
                  onChange={(event) => {
                    if (/^\d*\.?\d*$/.test(event.target.value)) {
                      setAnnualSalary(event.target.value)
                    }
                  }}
                  sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                />
              </Grid>
              <LabelGrid>
                <label htmlFor="salary-sacrifice">
                  <span>
                    {t("productDetail.StickyACButton.Modal.monthlySalary")}
                  </span>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <div className="d-flex content-center">
                  <TextField
                    id="salary-sacrifice"
                    value={salarySacrifice || ""}
                    InputProps={currencyInputProps}
                    onChange={(event) => {
                      if (/^\d*\.?\d*$/.test(event.target.value)) {
                        setSalarySacrifice(event.target.value)
                      }
                    }}
                    sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                  />
                  <div style={{ width: 0 }}>
                    <div style={{ marginLeft: "0.625rem" }}>
                      <InfoTooltip
                        content={t(
                          "productDetail.StickyACButton.Modal.TOOLTIP_CONTENT"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <p>{t("productDetail.StickyACButton.Modal.montlyAffordability")}</p>
          <MonthlyAffordability>
            {t("productDetail.StickyACButton.Modal.currency")}
            {monthlyAffordability && monthlyAffordability > 0
              ? monthlyAffordability.toFixed(2)
              : "0"}
          </MonthlyAffordability>
          {buttonPresent && (
            <ApplyResultButton
              variant="gradient"
              disabled={(monthlyAffordability || 0) <= 0}
              onClick={() => {
                applyResults()
                setOpen(false)
              }}
            >
              {t("productDetail.StickyACButton.Modal.applyResult")}
            </ApplyResultButton>
          )}
        </div>
        <CloseWindowButton variant="invisible" onClick={() => setOpen(false)}>
          {t("productDetail.StickyACButton.Modal.CloseWindow")}
        </CloseWindowButton>
      </MainBox>
    </Modal>
  )
}
export const CloseWindowButton = styled(Button)`
  & {
    color: ${({ theme }) => theme.palette.grey[700]};
    text-decoration: underline;
    align-self: center;
    padding-right: 0.75rem; // there's no padding-y attribute in ordinary css
    padding-left: 0.75rem;
    &:hover {
      text-decoration: underline;
    }
  }
`
export const ApplyResultButton = styled(Button)`
  & {
    width: 17.75rem;
    margin-top: 1.5rem;
  }
`
export const MainBox = styled(Box)`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 85vh;
    overflow: auto;
    background: ${({ theme }) => theme.palette.white.main};
    border-radius: 1rem;
    padding-top: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

export const MonthlyAffordability = styled("h2")`
  color: ${({ theme }) => theme.palette.secondary.main};
`
