const hardCodedData = [
  { imageName: "insurance.png", label: "insurance" },
  { imageName: "servicing.png", label: "servicing" },
  {
    imageName: "replacement-tyres.png",
    label: "replacementTyres",
  },
  { imageName: "mot.png", label: "mot" },
  { imageName: "road-tax.png", label: "roadTax" },
  {
    imageName: "breakdown-cover.png",
    label: "breakdownCover",
  },
  {
    imageName: "lifestyle-protection.png",
    label: "lifestyleProtections",
  },
]

export default hardCodedData
