import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { ListItemText, ListItemButton } from "@mui/material"

export type ItemProps = {
  text: string
  link?: string
  hide?: boolean
  back?: boolean
}

export default function NavItem({ text, link, back }: ItemProps) {
  return (
    <>
      <ListItemButton
        component="a"
        href={link}
        sx={{
          padding: 0,

          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
        className="step-menu__item"
      >
        {back && <ArrowBackIosIcon className="step-menu__arrow" />}
        <ListItemText
          primary={text}
          className="body-2"
          disableTypography
          sx={{
            marginY: back ? 0 : "0.375rem",

            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        />
        {back === undefined && (
          <ArrowForwardIosIcon className="step-menu__arrow" />
        )}
      </ListItemButton>
    </>
  )
}
