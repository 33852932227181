import { Box, Typography, styled } from "@mui/material"

export const Container = styled(Box)`
  width: 92.3%;
  margin: 0 auto 5rem auto;
  display: flex;
  flex-direction: column;
  /* row-gap: 1.5rem; */
  max-width: 75rem;

  ${({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 7.0625rem;
  }`}

  p:last-of-type {
    margin-bottom: 0;
  }
`

export const Paragraph = styled(Typography)`
  line-height: 1.5rem;
  text-align: left;

  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 1.5rem;
`

export const ListContainer = Paragraph.withComponent(Box)

export const Header = styled(Paragraph)`
  font-weight: 700;
`
export const StyledUnorderedList = styled("ul")`
  padding-inline-start: 20px;
  margin: 0;
`

export const StyledOrderedList = styled("ol")`
  padding-inline-start: 20px;
  margin: 0;
`

export const TableOrderedList = styled("ol")`
  counter-reset: list;
  > li {
    list-style: none;
    position: relative;
  }
  > li:before {
    counter-increment: list;
    content: "(" counter(list, lower-alpha) ") ";
    position: absolute;
    left: -1.4em;
  }
`

export function InlineLinks({
  href,
  children,
}: {
  href: string
  children?: string
}) {
  return (
    <>
      <InlineLinksText
        href={href}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noopener"
      >
        {children || href}
      </InlineLinksText>
    </>
  )
}

export const OrderListText = styled("span")`
  padding-left: 10px;
`

export const InlineLinksText = styled("a")`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
`

export const Table = styled("table")`
  border-collapse: collapse;
  display: block;
  overflow-x: auto;

  thead {
    th {
      border-bottom: ${({ theme }) =>
        `0.0625rem solid ${theme.palette.primary.main}`};
      border-collapse: collapse;
    }
  }
  tbody {
    tr:nth-of-type(2n) {
      background-color: ${({ theme }) => `${theme.palette.grey[200]}`};
    }
  }
  td,
  th {
    padding: 0.5rem 0.625rem;
    min-width: 200px;
  }
`
