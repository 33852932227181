import { TextFieldProps } from "@mui/material"
import i18n from "../../../i18n"

export const getPasswordField = (): TextFieldProps => {
  return {
    id: "password",
    name: "password",
    label: i18n.t("regForm:password.label"),
    "aria-label": i18n.t("regForm:password.label"),
    fullWidth: true,
    required: true,
  }
}

export const getReEnterPasswordField = (): TextFieldProps => {
  return {
    id: "reEnterPassword",
    name: "reEnterPassword",
    label: i18n.t("regForm:reEnterPassword.label"),
    "aria-label": i18n.t("regForm:reEnterPassword.label"),
    fullWidth: true,
    required: true,
  }
}

export const getNewPasswordField = (): TextFieldProps => {
  return {
    id: "password",
    name: "password",
    label: i18n.t("regForm:changeYourPassword.newPasswordLabel"),
    fullWidth: true,
    required: true,
  }
}

export const getConfirmNewPasswordField = (): TextFieldProps => {
  return {
    id: "reEnterPassword",
    name: "reEnterPassword",
    label: i18n.t("regForm:changeYourPassword.confirmNewPasswordLabel"),
    fullWidth: true,
    required: true,
  }
}

export const getCurrentPasswordField = (): TextFieldProps => {
  return {
    id: "currentPassword",
    name: "currentPassword",
    label: i18n.t("regForm:renewPassword.currentPasswordLabel"),
    fullWidth: true,
    required: true,
  }
}

export const getSalaryBracketField = (): TextFieldProps => {
  return {
    id: "salaryBracket",
    name: "salaryBracket",
    select: true,
    label: i18n.t("regForm:salaryBracket.label"),
    "aria-label": i18n.t("regForm:salaryBracket.label"),
    fullWidth: true,
  }
}

export const getValidationNumberField = (): TextFieldProps => {
  return {
    id: "validationNumber",
    name: "validationNumber",
    label: i18n.t("regForm:validationNumber.label"), // default === "Employee number"
    "aria-label": i18n.t("regForm:validationNumber.label"),
    fullWidth: true,
  }
}

export const getColleagueEmailField = (): TextFieldProps => {
  return {
    id: "colleagueEmail",
    name: "colleagueEmail",
    label: i18n.t("regForm:colleagueEmail.label"),
    "aria-label": i18n.t("regForm:colleagueEmail.label"),
    fullWidth: true,
    required: true,
    type: "text",
  }
}
