import classNames from "classnames"

export type BurgerMenuAnimatedIconProps = {
  open: boolean
}

export default function BurgerMenuAnimatedIcon({
  open,
}: BurgerMenuAnimatedIconProps) {
  return (
    <div className="burger-menu-animated-icon__container">
      <div className={classNames("burger-menu-animated-icon", { open: open })}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
