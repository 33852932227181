import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import List, { ItemType, AnimationType } from "./List"
import { StoreContext } from "../../StoreContext"
import { useContext, useState } from "react"
import updatedSearchParams from "../../../../utils/updatedSearchParams"
import {
  gtmProductCategorySelected,
  gtmStoreFilter,
  gtmstoreFilterDepartment,
} from "../../../shared/gtm-events/CustomGtmEvents"

export type Department = {
  __typename?: "Department" | undefined
  id: string
  name: string
  count: number
  categories: Array<{
    __typename?: "Category"
    id: string
    name: string
    count: number
    subcategories: Array<{
      __typename?: "Subcategory"
      id: string
      name: string
      count: number
    }>
  }>
}

export type DepartmentHierarchyFilterProps = {
  className?: string
  storeLink: string
}

function DepartmentHierarchyFilter({
  className = "filter-box",
  storeLink,
}: DepartmentHierarchyFilterProps) {
  const {
    filters: { departments },
    loading,
  } = useContext(StoreContext)
  const [searchParams, setSearchParams] = useSearchParams()

  // Setting default values
  const [animation, setAnimation] = useState<AnimationType>("disabled")
  const navigate = useNavigate()
  let title = "Browse by department"
  let backLabel: string | undefined = "Departments"
  if (searchParams.get("search")) {
    backLabel = undefined
    title = "Search Results"
  }
  let backChange = () => {
    setAnimation("ltr")
    navigate(storeLink)
  }
  let items: ItemType[] = []
  let checkboxes = false

  const updateSearchParams = (paramName: string, value: string[]) => {
    setSearchParams(
      updatedSearchParams(searchParams, [
        {
          paramName,
          value,
        },
      ])
    )
  }
  const params = useParams()
  const store = params.schemeType
  const CycletoWorkStore = params.schemeType === "cycle_to_work"
  if (searchParams.get("departments")) {
    const activeDepartment = departments.find(
      (department) => department.id === searchParams.get("departments")
    )

    if (searchParams.get("categories")) {
      // Subcategory level
      const activeCategory = activeDepartment?.categories.find(
        (category) => category.id === searchParams.get("categories")
      )
      title = activeCategory?.name || ""
      backLabel = activeDepartment?.name || ""
      backChange = () => {
        setAnimation("ltr")
        setSearchParams(
          updatedSearchParams(searchParams, [], ["categories", "subcategories"])
        )
      }
      const activeSubcategories = searchParams.getAll("subcategories")
      checkboxes = true

      items =
        activeCategory?.subcategories.map((subcategory) => ({
          label: subcategory.name,
          count: subcategory.count,
          checked: activeSubcategories.includes(subcategory.id),
          onClick: (state) => {
            if (state === true) {
              setAnimation("disabled")
              updateSearchParams("subcategories", [
                ...searchParams.getAll("subcategories"),
                subcategory.id.toString(),
              ])

              !CycletoWorkStore
                ? gtmStoreFilter({
                    filterType: "category",
                    selectedOption: subcategory.name,
                    store: store,
                  })
                : gtmstoreFilterDepartment({
                    filterType: "category",
                    selectedOption: subcategory.name,
                    activeCategory: activeCategory.name,
                    store: store,
                  })
            } else {
              setAnimation("disabled")
              updateSearchParams(
                "subcategories",
                searchParams
                  .getAll("subcategories")
                  .filter((paramId) => paramId !== subcategory.id)
              )
            }
          },
        })) || []
    } else {
      // Category level
      title = activeDepartment?.name || ""
      backLabel = "Browse by department"
      items =
        activeDepartment?.categories.map((category) => ({
          label: category.name,
          count: category.count,
          onClick: () => {
            setAnimation("rtl")
            updateSearchParams("categories", [category.id])
            gtmProductCategorySelected(category.name)
          },
        })) || []
    }
  } else {
    // Department level
    items =
      departments.map((department) => ({
        label: department.name,
        count: department.count,
        onClick: () => {
          setAnimation("rtl")
          setSearchParams(
            updatedSearchParams(searchParams, [
              {
                paramName: "departments",
                value: [department.id.toString()],
              },
            ])
          )
        },
      })) || []
  }

  return (
    <div className={className}>
      <List
        title={title}
        backLabel={backLabel}
        backChange={backChange}
        items={items}
        checkboxes={checkboxes}
        loading={loading}
        animation={animation}
      />
    </div>
  )
}

export default DepartmentHierarchyFilter
