import { useState } from "react"
import { useFormikContext } from "formik"
import { NewBenefitTileValue } from "./formikUtils"
import {
  ContainerStyle,
  StyledPara,
  StyledSwitch,
} from "./ShowOnPortalPageButton"
import { useTranslation } from "react-i18next"

const PopUpRequired = () => {
  const formik = useFormikContext<NewBenefitTileValue>()
  const [checked, setChecked] = useState(formik.values.PopUpRequired)
  const { t, ready } = useTranslation("newBenefitTile")

  const checkBoxClick = () => {
    setChecked(!checked)
    formik.setFieldValue("PopUpRequired", !checked)
  }
  const label = {
    inputProps: { "aria-label": t("popUp") },
  }
  if (!ready) {
    return null
  }
  return (
    <>
      <ContainerStyle>
        <StyledSwitch {...label} checked={checked} onClick={checkBoxClick} />
        <StyledPara>{t("popUp")}</StyledPara>
      </ContainerStyle>
    </>
  )
}
export default PopUpRequired
