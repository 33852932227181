import {
  Box,
  styled,
  Typography,
  Button,
  FormHelperText,
  css,
} from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { FormikProps } from "formik"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { NewLocalDiscountValue } from "./formikUtils"
import useIsMobileView from "../../../utils/useIsMobileView"

const IMAGE_CONTENT_TYPES = "image/png, image/jpeg"
const DOCUMENT_CONTENT_TYPES =
  "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"

type FileUpload = {
  formikValue: FormikProps<NewLocalDiscountValue>
  fieldName: "logo" | "file"
}

const getInitialFileName = ({ formikValue, fieldName }: FileUpload) => {
  if (fieldName === "file") {
    if (typeof formikValue.values.file !== "string") {
      return formikValue.values.file.name
    } else {
      return formikValue.values.file
    }
  }
  if (fieldName === "logo") {
    if (typeof formikValue.values.logo !== "string") {
      return formikValue.values.logo.name
    } else {
      return formikValue.values.logo
    }
  }
  return ""
}

const FileUpload = ({ formikValue, fieldName }: FileUpload) => {
  const formik = formikValue

  const [fileName, setFileName] = useState<string>(
    getInitialFileName({ formikValue, fieldName })
  )
  const isMobile = useIsMobileView()
  const inputRef = useRef<HTMLInputElement>(null)
  const { t, ready } = useTranslation("newLocalDiscount")
  const contentType =
    fieldName === "logo"
      ? IMAGE_CONTENT_TYPES
      : `${DOCUMENT_CONTENT_TYPES}, ${IMAGE_CONTENT_TYPES}`

  // useEffect(() => {
  //   if (formik.isSubmitting) {
  //     setFileName("")
  //     formik.setFieldValue(fieldName, "")
  //   }
  //   // eslint-disable-next-line
  // }, [formik.isSubmitting])

  const handleDeleteFile = () => {
    setFileName("")
    formik.setFieldValue(fieldName, "")
  }

  const handleUpload = (file: File) => {
    const reader = new FileReader()

    if (file) {
      reader.onloadend = () => {
        setFileName(file.name)

        const image = new Image()
        image.onload = () => (image.src = URL.createObjectURL(file))

        formik.setFieldValue(fieldName, file)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
    setTimeout(() => {
      formik.setFieldTouched(fieldName, true)
    }, 2000)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const selectedFile = event.currentTarget.files[0]
      handleUpload(selectedFile)
      formik.setFieldTouched(fieldName, true)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <Container>
      {fileName && (
        <FirstRow>
          <MainText
            sx={{ marginLeft: fileName && isMobile ? undefined : "15px" }}
          >
            {fileName}
          </MainText>
          <ButtonWrapper aria-label={`Remove ${fileName} file`}>
            <StyledCancelIcon onClick={handleDeleteFile} />
          </ButtonWrapper>
        </FirstRow>
      )}

      <SecondRow>
        {!fileName && <MainText>{t(fieldName)}</MainText>}

        <div>
          <input
            ref={inputRef}
            style={{ display: "none" }}
            id={fieldName}
            name={fieldName}
            type="file"
            accept={contentType}
            onChange={handleChange}
            onBlur={() => formik.setFieldTouched(fieldName, true)}
            disabled={!!fileName}
            onClick={(event) => {
              event.currentTarget.value = ""
            }}
          />

          <label htmlFor={fieldName}>
            <UploadButton
              disabled={!!fileName}
              onClick={handleButtonClick}
              sx={{ marginLeft: fileName && isMobile ? undefined : "15px" }}
            >
              {t("chooseFile")}
            </UploadButton>
          </label>
        </div>
        <div style={{ display: "block" }}>
          <SupportText
            sx={{
              marginLeft: isMobile ? "" : "15px",
              lineHeight: isMobile ? "0.5rem" : "1.5rem",
            }}
          >
            {t(`${fieldName}SupportedFormats`)}
          </SupportText>
        </div>
      </SecondRow>

      {formik.errors[fieldName] && formik.touched[fieldName] && (
        <FormHelperText error sx={{ marginTop: isMobile ? "30px" : "" }}>
          {formik.errors[fieldName]}
        </FormHelperText>
      )}
    </Container>
  )
}

export default FileUpload

const Container = styled(Box)`
  ${({ theme }) => css`
    margin-bottom: 3.125rem; // only mobile view, adjust again
    margin-top: 2.4375rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 41px;
    }
  `}
`

const FirstRow = styled(Box)`
  display: flex;
  column-gap: 1.125rem;
  align-items: center;
`

const StyledCancelIcon = styled(CancelIcon)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 1.25rem;
`

const SecondRow = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 1.8125rem;
`

const MainText = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  word-wrap: break-word;
  max-width: 90%;
`

const SupportText = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const UploadButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: ${({ theme }) => theme.palette.secondary.main[300]};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  padding: 0 0.75rem;
  border-radius: 1rem;
  height: 1.875rem;

  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`

const ButtonWrapper = styled(Button)`
  padding: 0;

  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`
