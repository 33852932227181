import { Formik, FormikHelpers } from "formik"
import i18n from "../../i18n"
import {
  passwordValidation,
  reEnterPasswordValidation,
} from "../../utils/yup-validation-schema/passwordValidation"
import Layout from "./Layout"
import * as Yup from "yup"
import {
  FormContainer,
  StyledForm,
  SubmitButton,
} from "./shared/mainPageStyles"
import Alert from "../shared/Alert"
import { useState } from "react"
import { Headers, SubHeading } from "./sign-in/Headers"
import { useTranslation } from "react-i18next"
import PasswordField from "./sign-up/PasswordField"
import {
  getConfirmNewPasswordField,
  getCurrentPasswordField,
  getNewPasswordField,
} from "./sign-up/fields"
import PasswordProgressBar from "./sign-up/PasswordProgressBar"
import { TextWithLink, TextWithLinkContainer } from "./sign-in/SignIn"
import useIsMobileView from "../../utils/useIsMobileView"
import {
  useExpiredPasswordMutation,
  useGetCurrentUserDataQuery,
  useHaveTroubleLoggingInMutation,
} from "../../graphqGenaretedTypes"

const FORGOTTEN_PASSWORD = "Forgotten password"

type RenewPasswordForm = {
  currentPassword: string
  password: string
  reEnterPassword: string
}

const initialValues: RenewPasswordForm = {
  currentPassword: "",
  password: "",
  reEnterPassword: "",
}

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(() =>
    i18n.t("regForm:renewPassword.currentPasswordEmptyWarning")
  ),
  password: passwordValidation,
  reEnterPassword: reEnterPasswordValidation,
})

const RenewPassword = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const { t, ready } = useTranslation("regForm")
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const isMobile = useIsMobileView()
  const { data } = useGetCurrentUserDataQuery()
  const [haveTroubleLoggingInMutation] = useHaveTroubleLoggingInMutation()
  const [expiredPasswordMutation] = useExpiredPasswordMutation()

  const handleSubmit = async (
    values: RenewPasswordForm,
    formikHelpers: FormikHelpers<RenewPasswordForm>
  ) => {
    const { data } = await expiredPasswordMutation({
      variables: {
        currentPassword: values.currentPassword,
        newPassword: values.password,
        confirmNewPassword: values.reEnterPassword,
      },
    })

    const errors = data?.expiredPassword?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    } else {
      const authToken = data?.expiredPassword?.authToken

      if (authToken) {
        const queryString = new URLSearchParams({
          auth_token: authToken,
        }).toString()

        window.location.href = `/users/authorize_user?${queryString}`
      }
    }
    formikHelpers.setSubmitting(false)
  }

  const handleForgottenPassword = async () => {
    const userEmail = data?.currentUser?.email
    // Necessary to deal with the case when the user isn't logged in?
    if (userEmail) {
      const { data } = await haveTroubleLoggingInMutation({
        variables: {
          reason: FORGOTTEN_PASSWORD,
          email: userEmail,
        },
      })

      const errors = data?.haveTroubleLoggingIn?.errors
      if (errors && errors.length > 0) {
        setAlertMessage(errors[0])
        setAlertSeverity("error")
        setErrorCount((prev) => prev + 1)
        return
      }

      const info = data?.haveTroubleLoggingIn?.info
      if (info) {
        setAlertMessage(info)
        setAlertSeverity("info")
        setErrorCount((prev) => prev + 1)
        return
      }
    }
  }

  if (!ready) {
    return null
  }

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
      >
        {(formik) => {
          return (
            <StyledForm>
              {alertMessage && (
                <Alert
                  severity={alertSeverity}
                  message={alertMessage}
                  key={errorCount}
                />
              )}
              <FormContainer>
                <Headers
                  header={t("renewPassword.header")}
                  content={t("renewPassword.content")}
                />
                <PasswordField
                  passwordFieldProps={getCurrentPasswordField()}
                  showPassword={showCurrentPassword}
                  toggleShowPassword={() =>
                    setShowCurrentPassword((prev) => !prev)
                  }
                  inlineTextFieldProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        formik.submitForm()
                      }
                    },
                  }}
                />
                <SubHeading style={{ marginBottom: "1rem" }}>
                  {t("renewPassword.subHeading")}
                </SubHeading>

                <PasswordField
                  passwordFieldProps={getNewPasswordField()}
                  showPassword={showNewPassword}
                  toggleShowPassword={() => setShowNewPassword((prev) => !prev)}
                  inlineTextFieldProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        formik.submitForm()
                      }
                    },
                  }}
                />

                {formik.values.password !== "" && (
                  <PasswordProgressBar password={formik.values.password} />
                )}

                <PasswordField
                  passwordFieldProps={getConfirmNewPasswordField()}
                  showPassword={showConfirmNewPassword}
                  toggleShowPassword={() =>
                    setShowConfirmNewPassword((prev) => !prev)
                  }
                  inlineTextFieldProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        formik.submitForm()
                      }
                    },
                  }}
                />

                <SubmitButton
                  variant="gradient"
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting || formik.isValidating}
                >
                  {t("changeYourPassword.buttonLabel")}
                </SubmitButton>

                <TextWithLinkContainer sx={{ textAlign: "center" }}>
                  <TextWithLink
                    component={"button"}
                    type="button"
                    onClick={handleForgottenPassword}
                    sx={{ fontSize: !isMobile ? "1rem" : "0.75rem" }}
                  >
                    {t("renewPassword.forgottenCurrentPassword")}
                  </TextWithLink>
                </TextWithLinkContainer>
              </FormContainer>
            </StyledForm>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default RenewPassword
