import { string, object } from "yup"
import i18n from "../../i18n"

export const bikeShopValidation = object().shape({
  supplierId: string().required(() =>
    i18n.t("bikeQuote:validations.bikeShopEmptyWarning")
  ),
  label: string().required(() =>
    i18n.t("bikeQuote:validations.bikeShopEmptyWarning")
  ),
})
