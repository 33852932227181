import { useState } from "react"
import { Tabs, Tab, Box } from "@mui/material"
import { PreBackOrderTextStyle } from "../store/ProductDetails"
import { useTranslation } from "react-i18next"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export type ModuleTabsProps = {
  summary: string
  techSpecs: string
  releaseDate?: string
  status: "preOrder" | "backOrder" | "default"
  preBackorderMessage?: string
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Module-tabpanel-${index}`}
      aria-labelledby={`Module-tab-${index}`}
      {...other}
      className="moduleTabContent"
    >
      {value === index && (
        <div className="tabContent">
          <div className="tabContentData" tabIndex={0}>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

const tabbuttonProps = (index: number) => {
  return {
    id: `Module-tab-${index}`,
    "aria-controls": `Module-tabpanel-${index}`,
  }
}

const ModuleTabs = ({
  summary,
  techSpecs,
  releaseDate,
  status,
  preBackorderMessage,
}: ModuleTabsProps) => {
  const [value, setValue] = useState(0)
  const { t, ready } = useTranslation("productDetail")

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (!ready) {
    return null
  }
  return (
    <Box className="module-tab">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={t("productDetail.ModulasTab.ariaLabel")}
        >
          <Tab
            tabIndex={0}
            label={t("productDetail.ModulasTab.ProductLabel")}
            {...tabbuttonProps(0)}
          />
          <Tab
            tabIndex={0}
            label={t("productDetail.ModulasTab.Specification")}
            {...tabbuttonProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {(status === "preOrder" || status === "backOrder") && releaseDate && (
          <PreBackOrderTextStyle>
            {status === "preOrder"
              ? t("productDetail.launchDate")
              : t("productDetail.expectedDate")}
            : {releaseDate}
          </PreBackOrderTextStyle>
        )}
        {(status === "preOrder" || status === "backOrder") &&
          preBackorderMessage && (
            <div
              className="w-100"
              dangerouslySetInnerHTML={{
                __html: preBackorderMessage,
              }}
            />
          )}
        <div dangerouslySetInnerHTML={{ __html: summary }} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div dangerouslySetInnerHTML={{ __html: techSpecs }} />
      </TabPanel>
    </Box>
  )
}

export default ModuleTabs
