import PreviewPage from "../../client-admin/PreviewPage"
import { LocalBenefitContext, useLocalBenefitData } from "./LocalBenefitContext"

export const LocalBenefit = function () {
  const { data } = useLocalBenefitData()
  return (
    <LocalBenefitContext.Provider value={data}>
      <PreviewPage
        title={data.title}
        subHeader={data.subheader}
        content={data.content}
      />
    </LocalBenefitContext.Provider>
  )
}
