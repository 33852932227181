import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import GalleryCarousel from "../shared/GalleryCarousel"
import ProductDetails from "./ProductDetails"
import { ProductType } from "./product-card/ProductCard"
import {
  gtmProductStoreView,
  gtmProductView,
} from "../shared/gtm-events/CustomGtmEvents"
import { useEffect } from "react"
import { BrochureGroup } from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"

export type GalleryCarouselGridProps = {
  id: number
  title: string
  description: string
  price: string
  image: string
  productImages: string[]
  recommendations: ProductType[]
  productId: number
  brochureGroupId: number
  availableSizes: string
  totalAmount: string
  isNhs: boolean
  brand: string
  variant: string
  category: string
  sku: string
  storeName: string
  productTagImage: string
  department: string
  brochureGroups: BrochureGroup[]
  supplierName?: string
  releaseDate?: string
  status: "preOrder" | "backOrder" | "default"
  reachedMaximumQuantity: boolean
  maximumQuantity: number
}

export default function GalleryCarouselGrid({
  id,
  title,
  description,
  price,
  image,
  productImages,
  recommendations,
  productId,
  brochureGroupId,
  availableSizes,
  totalAmount,
  isNhs,
  brand,
  variant,
  category,
  sku,
  storeName,
  productTagImage,
  department,
  brochureGroups,
  supplierName,
  releaseDate,
  status,
  reachedMaximumQuantity,
  maximumQuantity,
}: GalleryCarouselGridProps) {
  const params = useParams()
  const CycletoWorkStore = params.schemeType === "cycle_to_work"
  !CycletoWorkStore
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        gtmProductView({
          id: id,
          title: title,
          totalAmount: totalAmount,
          brand: brand,
          category: category,
          variant: variant,
          sku: sku,
          storeName: storeName,
          department: department,
          supplierName: supplierName,
        })
      })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        gtmProductStoreView({
          id: id,
          title: title,
          totalAmount: totalAmount,
          brand: brand,
          category: category,
          variant: variant,
          sku: sku,
          storeName: storeName,
          department: department,
          supplierName: supplierName,
        })
      })

  return (
    <>
      <Box
        sx={{
          flexGrow: 1, // having a flex-grow without width expands the div without limit, keeps growing?
          // why is there a flexGrow here in the first place?
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Grid item xs={10} sx={{ margin: "auto" }}>
              <GalleryCarousel
                title={title}
                selectedImageUrl={image}
                productCardCarousel={productImages}
                slidesToShow={3}
                productTagImage={productTagImage ? productTagImage : undefined}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              item
              xs={12}
              md={8}
              lg={9.322}
              data-brand={brand}
              data-variant={variant}
              data-category={category}
            >
              <ProductDetails
                id={id}
                title={title}
                description={description}
                price={price}
                brand={brand}
                variant={variant}
                category={category}
                sku={sku}
                storeName={storeName}
                recommendations={recommendations}
                productId={productId}
                brochureGroupId={brochureGroupId}
                availableSizes={availableSizes}
                totalAmount={totalAmount}
                isNhs={isNhs}
                department={department}
                brochureGroups={brochureGroups}
                supplierName={supplierName}
                status={status}
                releaseDate={releaseDate}
                reachedMaximumQuantity={reachedMaximumQuantity}
                maximumQuantity={maximumQuantity}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
