import { createContext } from "react"
import {
  useGetManageLocalDiscountsDataQuery,
  LocalDiscount as LocalDiscountType,
} from "../../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"

export type LocalDiscountsContextData = {
  discounts: LocalDiscountType[]
  enableLocalDiscounts: boolean
  loading: boolean
}

const defaultValue: LocalDiscountsContextData = {
  discounts: [],
  enableLocalDiscounts: true,
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const useLocalDiscountsData: () => {
  data: LocalDiscountsContextData
} = function () {
  const { organisationId } = useParams()
  const data = useGetManageLocalDiscountsDataQuery({
    variables: { organisationId: organisationId || "" },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      discounts: data.data?.employerOrganisation?.localDiscounts || [],
      enableLocalDiscounts:
        data.data?.employerOrganisation?.enableLocalDiscounts,
      loading: false,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}

export const LocalDiscountsContext = createContext(defaultValue)
