import yourCareImg from "./assets/yourCare.png"
import familyCare from "./assets/familyCare.png"
import homeAndElectronics from "./assets/homeAndElectronics.png"
import lifestyleSavings from "./assets/lifestyleSavings.png"
import cycleToWork from "./assets/cycleToWork.png"
import careBenefit from "./assets/carBenefit.png"
import travelAndLeisure from "./assets/travelAndLeisure.png"
import gymMembership from "./assets/gymMembership.png"
import salaryFinance from "./assets/salaryFinance.png"
import onDemandPay from "./assets/onDemandPay.png"
import vivupHighfive from "./assets/vivupHighfive.png"
import familyPay from "./assets/familyPay.png"
import healthCashPlan from "./assets/healthCashPlan.png"
import customBenefitTile from "./assets/customBenefitTile.png"
import i18n from "../../i18n"

type MentorPartnershipData = {
  [key: string]: {
    image: string
    heading: string
    content: string
  }
}

const getMentorPartnershipData = (): MentorPartnershipData => ({
  employee_assistance_provision_level_3: {
    image: yourCareImg,
    heading: i18n.t("mentorPartnership:yourCareWellbeing.heading"),
    content: i18n.t("mentorPartnership:yourCareWellbeing.content"),
  },
  family_care: {
    image: familyCare,
    heading: i18n.t("mentorPartnership:familyCare.heading"),
    content: i18n.t("mentorPartnership:familyCare.content"),
  },
  home_and_electronics: {
    image: homeAndElectronics,
    heading: i18n.t("mentorPartnership:homeAndElectronics.heading"),
    content: i18n.t("mentorPartnership:homeAndElectronics.content"),
  },
  lifestyle_savings: {
    image: lifestyleSavings,
    heading: i18n.t("mentorPartnership:lifestyleSavings.heading"),
    content: i18n.t("mentorPartnership:lifestyleSavings.content"),
  },
  cycle_to_work: {
    image: cycleToWork,
    heading: i18n.t("mentorPartnership:cycleToWork.heading"),
    content: i18n.t("mentorPartnership:cycleToWork.content"),
  },
  car_scheme: {
    image: careBenefit,
    heading: i18n.t("mentorPartnership:careBenefit.heading"),
    content: i18n.t("mentorPartnership:careBenefit.content"),
  },
  travel_and_leisure: {
    image: travelAndLeisure,
    heading: i18n.t("mentorPartnership:travelAndLeisure.heading"),
    content: i18n.t("mentorPartnership:travelAndLeisure.content"),
  },
  gym_membership: {
    image: gymMembership,
    heading: i18n.t("mentorPartnership:gymMembership.heading"),
    content: i18n.t("mentorPartnership:gymMembership.content"),
  },
  salaryfinance: {
    image: salaryFinance,
    heading: i18n.t("mentorPartnership:salaryFinance.heading"),
    content: i18n.t("mentorPartnership:salaryFinance.content"),
  },
  onDemandPay: {
    image: onDemandPay,
    heading: i18n.t("mentorPartnership:onDemandPay.heading"),
    content: i18n.t("mentorPartnership:onDemandPay.content"),
  },
  vivupHighfive: {
    image: vivupHighfive,
    heading: i18n.t("mentorPartnership:vivupHighfive.heading"),
    content: i18n.t("mentorPartnership:vivupHighfive.content"),
  },
  family_pay: {
    image: familyPay,
    heading: i18n.t("mentorPartnership:familyPay.heading"),
    content: i18n.t("mentorPartnership:familyPay.content"),
  },
  health_cash_plan: {
    image: healthCashPlan,
    heading: i18n.t("mentorPartnership:healthCashPlan.heading"),
    content: i18n.t("mentorPartnership:healthCashPlan.content"),
  },
  customBenefitTile: {
    image: customBenefitTile,
    heading: i18n.t("mentorPartnership:customBenefitTile.heading"),
    content: i18n.t("mentorPartnership:customBenefitTile.content"),
  },
})

export default getMentorPartnershipData
