import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PowerBIEmbed } from "powerbi-client-react"
import { models } from "powerbi-client"
import {
  PbiReportEmbed,
  useGetAnalyticsReportDataQuery,
} from "../../graphqGenaretedTypes"

const Reporting = () => {
  const [pbiReportEmbed, setPbiReportEmbed] = useState<
    PbiReportEmbed | undefined | null
  >(undefined)
  const { organisationId = "" } = useParams()
  const variables = {
    organisationId,
  }

  const data = useGetAnalyticsReportDataQuery({
    variables,
    errorPolicy: "all",
  })

  useEffect(() => {
    if (!data.loading) {
      setPbiReportEmbed(data.data?.employeeOrganisation?.pbiReportEmbed)
    }
  }, [data])

  return (
    <div className="global-main-container--gray">
      {pbiReportEmbed && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
            id: pbiReportEmbed.id || "",
            embedUrl: pbiReportEmbed.embedUrl || "",
            accessToken: pbiReportEmbed.token || "", // Keep as empty string, null or undefined
            tokenType: models.TokenType.Embed,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
              background: models.BackgroundType.Transparent,
            },
          }}
          cssClassName="pbi-container"
          getEmbeddedComponent={(embeddedReport) => {
            embeddedReport.setComponentTitle("Analytics report")
          }}
        />
      )}
    </div>
  )
}

export default Reporting
