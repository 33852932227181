import { styled, Typography, useMediaQuery, useTheme, css } from "@mui/material"
import Shiitake from "shiitake"

type AppProps = {
  title: string
  truncate?: boolean
}

const CardTitle = ({ title, truncate = false }: AppProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")) // mobile should be down("md"), < 900px, but this is copied from original

  return (
    <StyledTitle gutterBottom variant="h6">
      {/* Not sure if I should include Shiitake here to truncate the line */}
      {/* LSProductCard does not have long text */}
      {truncate ? <Shiitake lines={isMobile ? 4 : 3}>{title}</Shiitake> : title}
    </StyledTitle>
  )
}

export default CardTitle

export const StyledTitle = styled(Typography)`
  ${({ theme }) => css`
    /* need to add gutterBottom and variant="h6" in jsx */
    /* static attributes across different widths */
    line-height: 1rem;
    display: box;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    /* attributes < 600px */
    font-weight: 400;
    line-clamp: 4;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    font-size: 0.75rem;
    color: ${theme.palette.primary.main};

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      font-size: ${theme.typography.body2.fontSize};
      font-weight: bold;
      color: ${theme.palette.primary.main};
      line-clamp: 3;
      display: -webkit-box;
      -webkit-line-clamp: 3;
    }
  `}
`
