import { TextFieldProps } from "@mui/material"
import i18n from "../../i18n"
export const getWorkInfoFields = (): TextFieldProps[] => [
  {
    id: "placeOfWork",
    name: "placeOfWork",
    label: i18n.t("bikeQuote:yourDetails.placeOfWork"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "employeeNumber",
    name: "employeeNumber",
    label: i18n.t("bikeQuote:yourDetails.employeeNumber"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "niNumber",
    name: "niNumber",
    label: i18n.t("bikeQuote:yourDetails.niNumber"),
    fullWidth: true,
    required: true,
    type: "text",
    inputProps: { style: { textTransform: "uppercase" } },
  },
]

export const getPersonalInfoFields = (): TextFieldProps[] => [
  {
    id: "firstName",
    name: "firstName",
    label: i18n.t("bikeQuote:yourDetails.firstName"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "lastName",
    name: "lastName",
    label: i18n.t("bikeQuote:yourDetails.lastName"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "mobileNumber",
    name: "mobileNumber",
    label: i18n.t("bikeQuote:yourDetails.mobileNumber"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "phoneNumber",
    name: "phoneNumber",
    label: i18n.t("bikeQuote:yourDetails.phoneNumber"),
    fullWidth: true,
    required: true,
    type: "text",
  },
  {
    id: "email",
    name: "email",
    label: i18n.t("bikeQuote:yourDetails.emailAddress"),
    fullWidth: true,
    required: true,
    type: "text",
  },
]

// select field needs "select: true"
export const getTitleProps = (): TextFieldProps => ({
  select: true,
  id: "title",
  name: "title",
  label: i18n.t("bikeQuote:yourDetails.title"),
  required: true,
})

export const getDateOfBirthProps = (): TextFieldProps => ({
  select: true,
  id: "dateOfBirth",
  name: "dateOfBirth",
  label: i18n.t("bikeQuote:yourDetails.dateOfBirth"),
  required: true,
})
