import { ExploreButton } from "../../lifestyle-savings/shared-styles"
import { styled, Typography, css, Box, Link } from "@mui/material"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
export type items = {
  label: string
  link?: string
  __typename: string
}
export type MegaProps = {
  link?: string
  items: items[]
  setActive: (state: boolean) => void
}
const FamilyCareCard = ({ items, setActive }: MegaProps) => {
  const params = useParams()
  const { t, i18n } = useTranslation("portalHomepage")

  const childcare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Childcare.png"
  )
  const eldercare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Eldercare.png"
  )
  const petcare = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/Petcare.png"
  )
  const familyCareIconImage = useBuildAwsImageUrl(
    i18n.language,
    "globalMegaMenu/FamilyCareIconImage.png"
  )

  return (
    <>
      <div
        className="mega-menu-tab__health-and-wellbeing-image"
        style={{ textAlign: "center", width: "40%" }}
      >
        <img
          src={familyCareIconImage}
          alt="FamilyCareIconImage"
          className="mega-menu-tab__health-and-wellbeing-image"
          style={{ paddingBottom: "1rem" }}
        />
        <Cardlayout style={{ textAlign: "center" }}>
          {items.some((item) => item.label === "Childcare Support") && (
            <Link
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
              }}
              onClick={() => {
                setActive(false)
              }}
              href={
                items.find((item) => item.label === "Childcare Support")?.link
              }
            >
              <FamilyCareIcon src={childcare} alt="ChildcareImage" />
              <TextContainer>
                {t("portalHomepage:familyCare.services.childcare")}
              </TextContainer>
            </Link>
          )}

          {items.some((item) => item.label === "Eldercare Support") && (
            <Link
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
                textDecoration: "none",
              }}
              onClick={() => {
                setActive(false)
              }}
              href={
                items.find((item) => item.label === "Eldercare Support")?.link
              }
            >
              <FamilyCareIcon src={eldercare} alt="EldercareImage" />
              <TextContainer>
                {t("portalHomepage:familyCare.services.eldercare")}
              </TextContainer>
            </Link>
          )}

          {items.some((item) => item.label === "Petcare Support") && (
            <Link
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textDecoration: "none",
              }}
              onClick={() => {
                setActive(false)
              }}
              href={
                items.find((item) => item.label === "Petcare Support")?.link
              }
            >
              <FamilyCareIcon src={petcare} alt="PetCareImage" />
              <TextContainer>
                {t("portalHomepage:familyCare.services.petcare")}
              </TextContainer>
            </Link>
          )}
        </Cardlayout>

        <FamilyCareButton
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setActive(false)
          }}
          href={`/organisations/${params.organisationId}/employee/benefits/family_care`}
          component={Link}
        >
          {t("familyCare.buttonText")}
        </FamilyCareButton>
      </div>
    </>
  )
}
export default FamilyCareCard
export const FamilyCareButton = styled(ExploreButton)`
  ${({ theme }) => css`
    &&& {
      max-width: 100%;
      width: 19.7881rem;
      background-color: #6a6ba8;
      height: 3.125rem;
      z-index: 10;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        font-size: 0.6rem;
        height: 2.125rem;
      }
      @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
        font-size: 1rem;
        height: 3.125rem;
      }
      &:hover {
        opacity: 75%;
        background-color: #6a6ba8;
      }
    }
  `}
`
export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
  }
`
export const ContentContainerHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;

    font-size: 0.9375rem;
    line-height: 1.375rem;
    margin-bottom: 0.25rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 1.3125rem;
    }
  `}
`

export const FamilyCareIcon = styled("img")`
  width: 3rem;
  height: 3rem;
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    padding-bottom: 1.5rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      max-width: 20.875rem;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-weight: 700;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 4px;
    }
  `}
`
