import { createTheme } from "@mui/material/styles"
import { styleVars } from "../style.js"
import theme from "./mui-theme"
import hexToRgba from "../utils/hexToRgba"

const Theme = createTheme(theme, {
  palette: {
    secondary: {
      main: styleVars.c2wSecondary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "1.5rem",
          "&:disabled": {
            cursor: "not-allowed",
          },
        },
      },
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: `linear-gradient(90deg, ${styleVars.c2wSecondary}, ${styleVars.primary})`,
            color: styleVars.white,
            borderRadius: "2.1875rem",
            padding: "0.6875rem 0.625rem",
            margin: "0.25rem",
            ":hover": {
              background: `linear-gradient(90deg, ${hexToRgba(
                styleVars.c2wSecondary,
                "0.7"
              )}, ${hexToRgba(styleVars.primary, "0.7")})`,
            },
            "&:disabled": {
              background: `linear-gradient(90deg, ${hexToRgba(
                styleVars.c2wSecondary,
                "0.7"
              )}, ${hexToRgba(styleVars.primary, "0.7")})`,
              color: styleVars.white,
            },
          },
        },
        {
          props: { variant: "gray" },
          style: {
            background: styleVars.white,
            color: "#707070",
            borderRadius: "2.1875rem",
            padding: "0.5625rem 0.625rem",
            border: "solid #707070 0.125rem",
            margin: "0.25rem",

            ":hover": {
              background: "#707070",
              color: styleVars.white,
            },
          },
        },
        {
          props: { variant: "invisible" },
          style: {
            ":hover": {
              backgroundColor: "inherit",
            },
          },
        },
      ],
    },
  },
})

export default Theme
