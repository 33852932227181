import { useEffect } from "react"
import { InputAdornment, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import GenericSelect from "../../shared/form/GenericSelect"
import { getSalaryBracketField } from "./fields"
import InfoTooltip from "../../shared/InfoTooltip"
import useIsMobileView from "../../../utils/useIsMobileView"
import { FieldWithTooltip, ToolTipContainer } from "./SignUp"
import { useFormikContext } from "formik"

type SalaryBracketFieldType = {
  salaryBrackets: { id: number; description: string }[]
  salaryBracketState: string
}

export function SalaryBracketField({
  salaryBrackets,
  salaryBracketState,
}: SalaryBracketFieldType) {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("regForm")
  const formik = useFormikContext()

  // add required * to the field
  const isFieldRequired = salaryBracketState === "required" ? true : false

  useEffect(() => {
    // update the salaryBracketState in formik.values for validationSchema
    // formik.setFieldValue triggers re-render, need to be inside useEffect
    formik.setFieldValue("salaryBracketState", salaryBracketState)
    // eslint-disable-next-line
  }, [isFieldRequired])

  if (!ready || salaryBracketState === "hidden") {
    return null
  }

  return (
    <FieldWithTooltip>
      <GenericSelect
        props={getSalaryBracketField()}
        sx={
          isMobileView
            ? {
                "& .MuiSelect-icon": {
                  position: "relative",
                  right: 0,
                  cursor: "pointer",
                },
                "& .MuiInputBase-root": {
                  paddingRight: 0,
                },
              }
            : undefined
        }
        inlineTextFieldProps={
          isMobileView
            ? {
                required: isFieldRequired,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip
                        content={t("regForm:salaryBracket.tooltip")}
                      />
                    </InputAdornment>
                  ),
                },
              }
            : {
                required: isFieldRequired,
              }
        }
      >
        {salaryBrackets.map((salaryBracket) => (
          <MenuItem key={salaryBracket.id} value={salaryBracket.id}>
            {salaryBracket.description}
          </MenuItem>
        ))}
      </GenericSelect>

      {!isMobileView && (
        <ToolTipContainer>
          <InfoTooltip content={t("regForm:salaryBracket.tooltip")} />
        </ToolTipContainer>
      )}
    </FieldWithTooltip>
  )
}
