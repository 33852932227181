import { useState, useEffect } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import { Typography, ListItem, List, Button } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { styleVars } from "../../style"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import theme from "../../theme/mui-theme"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles(() => ({
  color_dropdownbtn: {
    width: "100%",
    padding: "0rem 0.75rem",
    height: "2rem",
    [theme.breakpoints.up("md")]: {
      height: "2.4375rem",
    },
    textTransform: "capitalize",
    minWidth: "3.0625rem",
    color: styleVars.primary,
  },

  ColorDropdown_title: {
    margin: 0,
    fontSize: "0.75rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.063rem",
    },
  },
}))

type DropDown = {
  title: string
  value: string
}

export type colorDropdownProps = {
  selected: string
  dropdownOptions: DropDown[]
  onChange?: (value: string) => void
}

function StoreDropdown({
  selected,
  dropdownOptions,
  onChange,
}: colorDropdownProps) {
  const [expanded, setExpanded] = useState(false)
  const [selectedOption, setSelectedOption] = useState(selected)
  const selectedItem = dropdownOptions
    .filter((item) => item?.value == selectedOption)
    .map((op) => op)[0]
  const selectedValue = selectedItem?.value || dropdownOptions[0]?.value
  const selectedTitle = selectedItem?.title || dropdownOptions[0]?.title

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    if (location.search.length) {
      const bg = new URL(window.location.href).searchParams.get("bg")
      if (bg) {
        setSelectedOption(bg)
      }
    }
  }, [location.search])

  const applyDropdownFilters = (value: string) => {
    setExpanded(!expanded)
    setSelectedOption(value)
    if (onChange) {
      onChange(value)
    }
  }

  const handleClick =
    (value: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
      event.preventDefault()
      applyDropdownFilters(value)
    }

  const onKeyUp = (value: string) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      applyDropdownFilters(value)
    }
  }

  const listContents = (
    <List className="ColorDropdown-ul" role="list">
      {dropdownOptions
        .filter((item) => item?.value != selectedValue)
        .map((item, index) => {
          {
            return (
              <ListItem
                key={index}
                className="ColorDropdown_links"
                tabIndex={0}
                onClick={handleClick(item.value)}
                onKeyUp={onKeyUp(item.value)}
                role="listitem"
                aria-label={item.title}
              >
                <div className="colorDropdown">{item.title}</div>
              </ListItem>
            )
          }
        })}
    </List>
  )

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <div className="ColorDropdownContainer">
        <div className="ColorDropdownBox">
          <div className="ColorDropdown">
            <Button
              className={classes.color_dropdownbtn}
              onClick={() => setExpanded(!expanded)}
            >
              <div className="ColorDropdown-box__title">
                <Typography
                  variant="body1"
                  className={classes.ColorDropdown_title}
                >
                  <div className="colorDropdown">{selectedTitle}</div>
                </Typography>
                <ArrowDropDownIcon
                  className={classNames("ColorDropdown-box__arrow", {
                    "ColorDropdown-box__arrow--expanded": expanded,
                  })}
                  style={{
                    height: isDesktop ? "1em" : "0.79em",
                    width: isDesktop ? "1em" : "0.79em",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </div>
            </Button>
            <AnimatePresence>
              {expanded && (
                <motion.div
                  className="ColorDropdown-box__content"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                  }}
                >
                  {listContents}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default StoreDropdown
