import { styled, Button, Box, css, useTheme } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { useTranslation } from "react-i18next"

export function MoreButton({
  isExpanded,
  moreButtonColor,
  handleClick,
}: ButtonType) {
  const { t, ready } = useTranslation("portalHomepage")
  const theme = useTheme()

  if (!ready) {
    return null
  }
  return (
    <MoreButtonContainer>
      <ToggleButton
        disableFocusRipple
        startIcon={
          isExpanded ? (
            <ArrowDropUpIcon sx={{ color: moreButtonColor }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: moreButtonColor }} />
          )
        }
        onClick={handleClick}
        sx={{ color: moreButtonColor || theme.palette.primary.main }}
        data-cy="widget-btn"
      >
        {isExpanded
          ? t("healthAndWellbeing.buttons.less")
          : t("healthAndWellbeing.buttons.more")}
      </ToggleButton>
    </MoreButtonContainer>
  )
}
const MoreButtonContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    margin-top: 1rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 0.5rem;
    }
  `}
`
const ToggleButton = styled(Button)`
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
`
type ButtonType = {
  isExpanded: boolean
  moreButtonColor?: string
  handleClick: () => void
}
