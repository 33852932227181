import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  SpaceTwoButtonContainer,
  WhiteButtonText,
} from "../../shared/SharedStyles"
import { PurpleButtonContainer } from "../../shared/family-care-and-family-pay/ContentStyles"

export function Button() {
  const params = useParams()
  const url = `/organisations/${params.organisationId}/employee/benefits/family_care`
  const { t } = useTranslation("portalHomepage")

  return (
    <SpaceTwoButtonContainer>
      <PurpleButtonContainer component={"a"} href={url} disableFocusRipple>
        <WhiteButtonText>{t("familyCare.buttonText")}</WhiteButtonText>
      </PurpleButtonContainer>
    </SpaceTwoButtonContainer>
  )
}
