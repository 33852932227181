import { Box } from "@mui/system"
import { styled, Link, css } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Background } from "../store/thank-you/Background"
import { GlobalLayoutContext } from "../layout/GlobalLayoutContext"
import { Content } from "../store/thank-you/Content"
import { MyOrderButton } from "../store/thank-you/MyOrderButton"

export type ThankYouProps = {
  orderId: string
}
// TODO - fix eslint warnings/errors
export default function ThankYou({ orderId }: ThankYouProps) {
  const { currentUserId, loading } = useContext(GlobalLayoutContext) // undefined
  const { t, ready } = useTranslation("checkoutPage")

  if (!ready) {
    return null
  }
  return (
    <Background storeName={"payroll_giving"}>
      <CenterContainer>
        <MessageContainer>
          <MessageHeader>{t("checkoutPage.thankYou.title")}</MessageHeader>
          <Content storeName={"payroll_giving"} />
        </MessageContainer>

        <OrderId>
          {t("checkoutPage.thankYou.orderId")} {orderId}
        </OrderId>
        <ButtonsContainer className="content-center">
          <MyOrderButton
            storeName={"payroll_giving"}
            href={loading ? "#" : `/users/${currentUserId}/orders`}
          />
          <HomeButton component={"a"} href="/">
            {t("checkoutPage.thankYou.takeMeHome")}
          </HomeButton>
        </ButtonsContainer>
      </CenterContainer>
    </Background>
  )
}

const OrderId = styled("h3")`
  text-align: center;
  margin: 2.2rem;
`

const MessageHeader = styled("h3")`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 15rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: none;
    }
  `}
`

const HomeButton = styled(Link)`
  text-align: center;
  cursor: pointer;
  margin-top: 1.25rem;
` as typeof Link

const ButtonsContainer = styled(Box)`
  justify-content: center;
  flex-direction: column;
`

const CenterContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.main};
    border-radius: 0.9375rem;
    margin-top: 4.6875rem;
    margin-bottom: 3rem;
    box-shadow: ${theme.boxShadow};
    width: 21.5rem;
    height: 28rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 40.9375rem;
      height: 23.3125rem;
    }
  `}
`
const MessageContainer = styled(Box)`
  margin-top: 2.5rem;
  text-align: center;
`
