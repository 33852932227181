import { useEffect } from "react"

type UseFocusSuggestionType = {
  focusIndex: number | null | undefined
  listRef: React.MutableRefObject<HTMLDivElement[]>
  setSuggestionText: React.Dispatch<React.SetStateAction<string | undefined>>
}

const useFocusSuggestion = ({
  focusIndex,
  listRef,
  setSuggestionText,
}: UseFocusSuggestionType) => {
  useEffect(() => {
    if (typeof focusIndex === "number") {
      listRef.current[focusIndex].focus()
      // when navigate back to inputField, `text` is undefined
      const { text } = listRef.current[focusIndex].dataset
      setSuggestionText(text)
    }
  }, [focusIndex, listRef, setSuggestionText])
}

export default useFocusSuggestion
