import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

const useGetDefaultBanners = (locale = "en-GB" as string) => {
  return [
    {
      desktopImageUrl:
        useBuildAwsImageUrl(locale, "home_and_electronics_store_banner.png") ||
        "https://vivup-development-assets.s3.eu-west-2.amazonaws.com/en-GB/home_and_electronics_store_banner.png",
      link: null,
      mobileImageUrl:
        useBuildAwsImageUrl(
          locale,
          "home_and_electronics_store_banner_mobile.png"
        ) ||
        "https://vivup-development-assets.s3.eu-west-2.amazonaws.com/en-GB/home_and_electronics_store_banner_mobile.png",
      subtitle: undefined,
      title: undefined,
    },
    {
      desktopImageUrl:
        useBuildAwsImageUrl(locale, "steps_banner.png") ||
        "https://vivup-development-assets.s3.eu-west-2.amazonaws.com/en-GB/steps_banner.png",
      link: null,
      mobileImageUrl:
        useBuildAwsImageUrl(locale, "steps_banner_mobile.png") ||
        "https://vivup-development-assets.s3.eu-west-2.amazonaws.com/en-GB/steps_banner_mobile.png",
      subtitle: undefined,
      title: undefined,
    },
  ]
}

export default useGetDefaultBanners
