import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

function AccessibleNavigationAnnouncer() {
  // the message that will be announced
  const [message, setMessage] = useState("")
  // get location from router
  const location = useLocation()

  // only run this when location change (note the dependency [location])
  useEffect(() => {
    const trimmedIndex = location.pathname.indexOf("/employee/")
    setMessage("")
    if (location.pathname.slice(1)) {
      const trimmedPath =
        trimmedIndex === -1
          ? location.pathname.slice(1)
          : location.pathname.slice(trimmedIndex + 10)
      setTimeout(() => setMessage(`Navigated to ${trimmedPath} page.`), 200)
    } else {
      // in my case, / redirects to /dashboard, so I found it better to
      // just ignore the / route
      setMessage("")
    }
  }, [location])

  return (
    <span
      className="screen-reader-only"
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      {message}
    </span>
  )
}

export default AccessibleNavigationAnnouncer
