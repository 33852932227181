import { Box, styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import RequestCallBack from "./RequestCallBack"
import Theme from "../../theme/mui-theme"

export type CardData = {
  totalNumber: number
  label: string
}

export const RequestCallBackCard = ({ totalNumber, label }: CardData) => {
  const { t, ready } = useTranslation("clientAdmin")
  if (!ready) {
    return null
  }

  return (
    <div>
      <StyledCard>
        <StyledNumberText>{totalNumber}</StyledNumberText>
        <StyledStatusText>
          {t("clientAdmin:benefitManagement.requestsToOpen.card.text")}
        </StyledStatusText>
        <CardLabel>{label}</CardLabel>
        <RequestCallBackText>
          <RequestCallBack number={totalNumber} schemeType={label} />
        </RequestCallBackText>
      </StyledCard>
    </div>
  )
}
const StyledNumberText = styled(Typography)`
  & {
    font-size: 2.9rem;
    position: relative;
    top: 1.3rem;
    font-weight: bold;
    color: ${Theme.palette.secondary.main};
  }
`
const StyledStatusText = styled(Typography)`
  & {
    font-size: 1rem;
    position: relative;
    top: 2.9rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    color: ${Theme.palette.primary.main};
  }
`
const CardLabel = styled(Typography)`
  && {
    position: relative;
    top: 2.8rem;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    color: ${Theme.palette.primary.main};
  }
`

const RequestCallBackText = styled(Box)`
  & {
    font-size: 1rem;
    position: relative;
    top: 2.8rem;
    font-weight: bold;
  }
`

const StyledCard = styled(Box)`
  text-align: center;
  cursor: mouse;
  position: relative;
  max-width: 13rem;
  height: 10rem;
  padding: 0.2rem;
  margin: 0rem;
  border-radius: 1rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.1607843137);
  background-color: ${Theme.palette.white.main};
  color: white;
`
