import { Box, styled } from "@mui/material"
import Roundel, { RoundelProps } from "../../shared/Roundel"

const MAX_ROUNDEL_ON_DESKTOP = 5

export const DesktopRoundel = ({
  roundelArray,
  roundelBackground,
  isHomeAndElectronicSection,
  cycleToWorkStore,
}: {
  roundelArray: RoundelProps[]
  roundelBackground: "gray"
  isHomeAndElectronicSection: boolean
  cycleToWorkStore?: boolean
}) => {
  const roundelsToRender = roundelArray.slice(0, MAX_ROUNDEL_ON_DESKTOP)
  return (
    <RoundelContainer>
      {roundelsToRender.map(({ label, imageUrl, link }) => {
        return (
          <Roundel
            key={`portal-homepage-HE-roundel-${label}`}
            label={label}
            imageUrl={imageUrl}
            link={link}
            background={roundelBackground}
            isHomeAndElectronicSection={isHomeAndElectronicSection}
            cycleToWorkStore={cycleToWorkStore}
          />
        )
      })}
    </RoundelContainer>
  )
}
const RoundelContainer = styled(Box)`
  /* desktop only */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2.125rem auto;

  .roundel {
    /* override setting defined in Roundel component */
    margin: 0;
  }
`
