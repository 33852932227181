import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const PageTitle = ({ title }: { title: string }) => {
  const location = useLocation()

  const url = window.location.href
  let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
  if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
    subdomain = "Vivup"
  }

  useEffect(() => {
    document.title = `${subdomain}: ${title}`
  }, [location, title, subdomain])

  return null
}

export default PageTitle
