import { useState } from "react"
import classNames from "classnames"
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import HealthAndWellbeingColumn from "./HealthAndWellbeingColumn"
import { Link } from "@mui/material"
import FamilyCareCard from "./FamilyCareCard"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export type MegaMenuHealthAndWellbeingProps = {
  name?: string
  link?: string
  categories: DepartmentType[]
  active?: boolean
  setActive: (tabActive: boolean) => void
}

function FamilyCareMegaMenu({
  link,
  categories,
  active = false,
  setActive,
}: MegaMenuHealthAndWellbeingProps) {
  const numberOfCategories = Object.keys(categories).length
  const [, setActiveCategories] = useState(
    Array(numberOfCategories).fill(false)
  )
  const params = useParams()
  const { t } = useTranslation("portalHomepage")
  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false)
          setActiveCategories(Array(numberOfCategories).fill(false))
        }}
      >
        <div className="mega-menu-tab__categories">
          {
            <Link
              component={"a"}
              className="mega-menu-tab__category mega-menu-tab__title"
              onMouseEnter={() =>
                setActiveCategories(Array(numberOfCategories).fill(false))
              }
              href={`/organisations/${params.organisationId}/employee/benefits/family_care`}
              onClick={() => {
                setActive(false)
              }}
            >
              <div> {t("familyCare.familyCareHeading")}</div>

              <ArrowForwardIcon
                fontSize="inherit"
                className="mega-menu-tab__forward-arrow"
                color="inherit"
              />
            </Link>
          }
          <div>
            <HealthAndWellbeingColumn
              setActive={setActive}
              items={categories}
            />
          </div>
        </div>

        <FamilyCareCard link={link} setActive={setActive} items={categories} />
      </div>

      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default FamilyCareMegaMenu
