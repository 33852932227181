import { string } from "yup"
import i18n from "../../i18n"

const customEmailRegex = /^([^´@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/

const invalidMessage = () =>
  i18n.t("bikeQuote:validations.emailAddress.invalidEmailWarning")
const requiredMessage = () =>
  i18n.t("bikeQuote:validations.emailAddress.emptyEmailWarning")
const requireColleagueEmailMessage = () =>
  i18n.t("regForm:colleagueEmail.emptyWarning")

export const emailValidation = string()
  .email(invalidMessage)
  .required(requiredMessage)

// Test if out of the box email validation from Yup works, if not use the custom version
export const customEmailValidation = string()
  .matches(customEmailRegex, invalidMessage)
  .required(requiredMessage)

export const colleagueEmailValidation = string()
  .email(invalidMessage)
  .required(requireColleagueEmailMessage)
export const regConfirmPersonalEmailValidation = string().email(invalidMessage)
