import {
  MobileContainer,
  TwoPropositionsContainer,
  OnePropositionContainer,
} from "./SharedStyles"
import useIsMobileView from "../../../utils/useIsMobileView"

export function DynamicPropositionSpace1({
  inColumns,
  children,
}: {
  inColumns?: boolean
  children: JSX.Element
}) {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return <MobileContainer>{children}</MobileContainer>
  }
  if (inColumns) {
    return <TwoPropositionsContainer>{children}</TwoPropositionsContainer>
  }
  return <OnePropositionContainer>{children}</OnePropositionContainer>
}
