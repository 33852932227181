import { styled, css, Link } from "@mui/material"
import { gtmPortalHomepageHighFiveBannerClick } from "../shared/gtm-events/CustomGtmEvents"
import BannerCarousel from "../shared/banner-carousel/BannerCarousel"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

export type HighFiveBannerPropType = {
  show: boolean
  link?: string | null
}
const HighFiveBanner = ({ show = false, link }: HighFiveBannerPropType) => {
  const { i18n } = useTranslation()
  const high_five_desktop_image = useBuildAwsImageUrl(
    i18n.language,
    "high_five_desktop_image.png"
  )
  const high_five_mobile_image = useBuildAwsImageUrl(
    i18n.language,
    "high_five_mobile_image.png"
  )
  const handleBannerClick = () => {
    gtmPortalHomepageHighFiveBannerClick()
  }

  if (show) {
    return (
      <div data-cy="portal-hi5">
        <Container
          href={link ? link : undefined}
          component="a"
          target="_blank"
          rel="noopener"
          onClick={handleBannerClick}
        >
          <BannerCarousel
            desktopImageUrl={high_five_desktop_image}
            mobileImageUrl={high_five_mobile_image}
            showWelcomeText={false}
            link={null}
          />
          <span className="screen-reader-only">
            See our latest HighFive Portal (opens in a new tab)
          </span>
        </Container>
      </div>
    )
  }
  return <></>
}
export default HighFiveBanner

export const Container = styled(Link)`
  ${({ theme }) => css`
    width: 93%;
    max-width: 29.375rem; // 470px
    margin: 0 auto;
    display: block;
    height: 12.625rem; // 202px

    & > div {
      background-size: cover;
      border-radius: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 11.8125rem;
      max-width: 69.25rem; // 1108px
    }
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      max-height: 200px;
      height: 138px;
      max-width: 315px;
    }
  `}
` as typeof Link
