import { Box, Button, styled, Typography, css, Theme } from "@mui/material"
import hexToRgba from "../../../utils/hexToRgba"

// TODO: turn all px into rem after finalised

export const DESKTOP_CONTAINER_WIDTH = 1108 // px

const commonContainerStyles = styled(Box)`
  ${({ theme }) => css`
    /*
  Base style for the following components:
  - MobileWhiteBox
  - TwoPropositionsBox
  - OnePropositionBox
  */
    background: ${theme.palette.white.main};
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
    position: relative; // Anchoring container for TopRightChip
  `}
`

export const MobileWhiteBox = styled(commonContainerStyles)`
  ${({ theme }) => css`
    width: 97%;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 93%;
    }
  `}
`

export const TwoPropositionsBox = styled(commonContainerStyles)`
  width: 47.2%; // 523 ÷ 1108
  max-width: 523px;
  height: 890px;
`

export const OnePropositionBox = styled(commonContainerStyles)`
  width: 100%;
  /* height: 660px; */
  max-width: ${DESKTOP_CONTAINER_WIDTH}px;
`

export const PaddingContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 28px 0.75rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 28px 18px 18px 18px;
    }
  `}
`

export const OnePropositionPadding = styled(Box)`
  padding: 38px 40px;
`

export const HeaderSectionPadding1 = styled("section")`
  ${({ theme }) => css`
    padding-left: 5px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: 12px;
    }
  `}
`
export const HeaderSectionPadding2 = styled("section")`
  padding: 11px 15px;
`

export const Space2HeaderMobilePadding = styled(Box)`
  padding: 26px 27px 25px 27px;
`

export const MainHeader = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 700;
    line-height: ${theme.typography.h2
      .lineHeight}; // h3 === 30px, h2 === 40px, same as in design
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: ${theme.typography.h2.fontSize};
      margin-bottom: 1.25rem; // 20px
    }
  `}
`
export const SubHeader = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.h3.lineHeight}; // 30px

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: ${theme.typography.body1.fontSize};
    }
  `}
`
export const PropositionSpaceOneSubHeader = styled(SubHeader)`
  ${({ theme }) => css`
    width: 58.9%; // 191 ÷ 324
    line-height: 1rem;
    margin-top: 5px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      line-height: 20px;
    }
  `}
`

export const PropositionSpaceTwoSubHeader = styled(SubHeader)`
  ${({ theme }) => css`
    line-height: 1rem;
    margin-top: 23px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 20px;
      line-height: 20px;
    }
  `}
`

export const SharedTopRightChip = styled(Box)`
  ${({ theme }) => css`
    border-radius: 50%;
    height: 100px;
    width: 100px;
    position: absolute;
    color: ${theme.palette.white.main};
    box-shadow: ${theme.boxShadow};
    top: -2rem; // adjust later
    right: 1rem; // adjust later

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 134px;
      height: 134px;
    }
  `}
`
export const OnePropositionTopRightChip = styled(SharedTopRightChip)`
  /* desktop view, single proposition */
  top: -1rem;
  right: -2rem;
`

export const ButtonBase = styled(Button)`
  ${({ theme }) => css`
    /*
    Used as based in the following components:
    - ExploreCarBenefitButton.tsx
    - ExploreC2WButton.tsx
    - health-and-wellbeing/Button.tsx
    - lifestyle-savings/Button.tsx
    - home-and-electronics-section/ViewAllButton.tsx

    Should use a wrapper/container around ButtonBase
    - width, max-width and height of each button should be defined in the container instead
  */
    padding: 0.9375rem 1.375rem; // 15px 22px
    margin: 0 auto;
    display: block;
    border-radius: 2.625rem; // 42px, visually it doesn't make much difference to default 35px
    width: 100%;
    min-height: 50px;

    &:hover {
      opacity: 75%;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0.9375rem 5.625rem; // 15px 90px
    }
  `}
` as typeof Button

export const SpaceOneButtonContainer = styled(Box)`
  /* Used in ExploreC2WButton and ExploreCarBenefitButton */
  width: 100%;
  max-width: 23.4rem;
  margin: 0 auto;
`

export const SpaceTwoButtonContainer = styled(Box)`
  /* Used in health-and-wellbeing/Button and lifestyle-savings/Button */
  width: 95%;
  max-width: 316px;
  place-self: center;
  margin: 0 auto;
`

export const ButtonText = styled(Typography)`
  ${({ theme }) => css`
    line-height: 1rem;
    text-decoration-line: none;
    color: white;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: unset;
      line-height: ${theme.typography.body1.lineHeight};
    }
  `}
`

export const WhiteButtonText = styled(Typography)`
  color: ${({ theme }) => theme.palette.white.main};
`

// middle container height = 420 - 150 = 270px

// dynamic proposition 2

export const colourBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%; // because the height is defined in the grid box
    border-radius: ${theme.boxBorderRadius};
  `}
`

export const MobileGridContainer = styled(Box)`
  min-height: 500px;
  display: grid;
  grid-template-rows: auto 264px 101px;
`

export const OneProposition = styled(OnePropositionBox)`
  min-height: 274px;
  height: fit-content;
  display: grid;
  grid-template-columns: 45% 55%;
`

export const TwoProposition = styled(TwoPropositionsBox)`
  height: 590px;
  display: grid;
  grid-template-rows: 184px 270px auto;
  // grid-template-columns here is to prevent react-slick to calculate container to infinite wide
  grid-template-columns: minmax(0, 1fr);
`

export const Space2HeaderDesktopPadding = styled(Box)`
  ${({ theme }) => css`
    padding: 49px 30px 35px 30px;

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding: 49px 40px 35px 40px;
    }
  `}
`

export const OnePropositionLeftBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const OnePropositionButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
`

export const carouselDotStyling = (theme: Theme) => css`
  .slick-dots > li:first-of-type {
    /* shift the dots to center */
    margin-left: 0.5rem;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }

  .slick-dots li button {
    width: 100%;
    height: 100%;
  }

  .slick-dots li button:before {
    font-size: 0.625rem; //10px
    opacity: 1;
    color: ${hexToRgba(theme.palette.primary.main, "0.3")};
    width: 100%;
    height: 100%;
  }

  .slick-dots li.slick-active button:before {
    color: ${theme.palette.primary.main};
    opacity: 1;
    width: 100%;
    height: 100%;
  }
`
