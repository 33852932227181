import { Box, styled } from "@mui/material"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

export const Background: React.FC<
  React.PropsWithChildren<{ storeName: string }>
> = ({ storeName, children }) => {
  const homeAndElectronicsImage = useBuildAwsImageUrl(
    "en-GB",
    "thankYou/thank-you.jpg"
  )
  const c2wImage = useBuildAwsImageUrl("en-GB", "thankYou/c2w-thank-you.png")
  return (
    <BackgroundImage
      className="globalheader-main-container"
      style={{
        backgroundImage:
          storeName === "Home and Electronics"
            ? homeAndElectronicsImage
            : c2wImage,
      }}
    >
      {children}
    </BackgroundImage>
  )
}

const BackgroundImage = styled(Box)`
  display: flex;
  justify-content: center;
  background-size: cover;
  width: 100%;
  height: 100%;
`
