import { Modal, Box, Typography, Button, css } from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import { CloseWindowButton } from "../shared/affordability-calculator/Modal"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import RequestToOpenButton from "./RequestToOpenButton"
import { gtmPortalHomepageExploreBenefitButtonClick } from "../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

type CardData = {
  buttonLabel?: string | null
  image?: string | null
  para1?: string | null
  para2?: string | null
  text?: string | null
  para3?: string | null
  para4?: string | null
  para5?: string | null
  para6?: string | null
  para7?: string | null
  relativeUrl?: string | null
}
export type BenefitCardModalProps = {
  openModal: boolean
  handleClose(): void
  label?: string | null
  cardData?: CardData | undefined
  relativeUrl?: string | null
  status?: string | null
  link?: string | null
  content?: string | null
  kind: string | null
}

const BenefitCardModal = ({
  openModal,
  handleClose,
  label,
  cardData,
  relativeUrl,
  status,
  link,
  content,
  kind,
}: BenefitCardModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const handleModalClose = () => {
    handleClose()
  }

  const datePattern = /(\w+\s\d+(?:st|nd|rd|th)\s\w+\s\d{4})/

  const match = status?.match(datePattern)
  const date = match ? match[0] : ""

  const { t, i18n, ready } = useTranslation("portalHomepage")

  const imageSrc = useBuildAwsImageUrl(i18n.language, cardData?.image || "")

  if (!ready) {
    return null
  }

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MainContainer
        sx={{
          maxHeight: "97vh",
          display: "flex",
          flexDirection: "column-reverse",
        }}
        data-cy="explore-your-benefit-popup"
      >
        <div>
          <TextContainerBox>
            <MainText id="dialog_label">
              <span className="screen-reader-only">
                {t("exploreYourBenefit.screenReader.opened")}
              </span>
              {label}
              <span className="screen-reader-only">
                {t("exploreYourBenefit.screenReader.popUp")}
              </span>
            </MainText>
          </TextContainerBox>

          <ParagraphStyle
            style={{
              paddingBottom: isDesktop ? "1.3rem" : "1rem",
            }}
          >
            {content ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            ) : (
              <>
                <InnerPara>{cardData ? cardData.para1 : ""}</InnerPara>

                <InnerPara>{cardData ? cardData.para2 : ""}</InnerPara>
                <InnerPara>{cardData ? cardData.para6 : ""}</InnerPara>
                {cardData && cardData.text && (
                  <Heading
                    style={{
                      fontSize: isDesktop ? "1rem" : "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {cardData.text}
                  </Heading>
                )}
                <InnerPara>{cardData ? cardData.para3 : ""}</InnerPara>
                <InnerPara>{cardData ? cardData.para4 : ""}</InnerPara>
                <InnerPara>{cardData ? cardData.para7 : ""}</InnerPara>
                {status?.includes("Request to open") && (
                  <StatusPara>
                    <InnerPara>
                      {t("exploreYourBenefit.innerPara.requestToOpen", {
                        label,
                      })}
                    </InnerPara>
                  </StatusPara>
                )}

                {status?.includes("Open until") && (
                  <StatusPara>
                    <InnerPara>
                      {t("exploreYourBenefit.innerPara.closing", {
                        label,
                        date,
                      })}
                    </InnerPara>
                  </StatusPara>
                )}
                {status?.includes("Opening") && (
                  <StatusPara>
                    <InnerPara>
                      {t("exploreYourBenefit.innerPara.openAgain", {
                        label,
                        date,
                      })}
                    </InnerPara>
                  </StatusPara>
                )}
                {cardData?.para5 && (
                  <p
                    style={{
                      fontSize: isDesktop ? "12px" : "10px",
                      color: "#2f1754",
                      paddingBottom: "0.1rem",
                      lineHeight: isDesktop ? "0.9rem" : "0.8rem",
                      fontFamily: "raleway",
                    }}
                  >
                    {cardData.para5}
                  </p>
                )}
                {cardData?.image && (
                  <div className="content-center">
                    <img
                      src={imageSrc}
                      alt={t("exploreYourBenefit.bannerAltText")}
                      className="logo"
                      style={{ maxWidth: "10rem" }}
                    />
                  </div>
                )}
              </>
            )}
          </ParagraphStyle>

          {status?.includes("Open") ? (
            <ExploreButton
              tabIndex={0}
              style={{ marginBottom: isDesktop ? "0.5rem" : "0.3rem" }}
              component={"a"}
              target={kind === "link" ? "_blank" : "_self"}
              href={link}
              onClick={() => {
                gtmPortalHomepageExploreBenefitButtonClick(label)
              }}
            >
              {cardData?.buttonLabel || `Explore ${label}`}
            </ExploreButton>
          ) : (
            <RequestToOpenButton
              relativeUrl={relativeUrl || ""}
              benefitName={label || ""}
            />
          )}

          <CloseButtonContainer>
            <CloseButton variant="invisible" onClick={handleClose}>
              {t("exploreYourBenefit.buttons.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </div>
        <IconContainer>
          <Button
            onClick={handleModalClose}
            aria-label={t("exploreYourBenefit.ariaLabel.close")}
          >
            <Close
              style={{
                paddingRight: isDesktop ? "1.121rem" : "1rem",
                fontSize: "1.515rem",
                color: "#33235A",
              }}
            />
          </Button>
        </IconContainer>
      </MainContainer>
    </Modal>
  )
}
export default BenefitCardModal
export const ExploreButton = styled(Button)`
  ${({ theme }) => css`
    && {
      display: block;
      width: 17.375rem;
      height: 3.125rem;
      margin: 2rem auto;
      margin-top: 1rem;
      margin-bottom: 2.188rem;
      color: ${theme.palette.white.main};
      font-size: 1rem;
      line-height: 1.125rem;
      text-align: center;
      border-radius: 2.625rem;
      box-shadow: ${theme.boxShadow};
      opacity: 1;
      background: transparent linear-gradient(90deg, #772a73 0%, #35185f 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 15.625rem;
        height: 2.875rem;
        font-size: 0.875rem;
      }
      &:hover {
        opacity: 75%;
      }
    }
  `}
`

export const CloseButton = styled(CloseWindowButton)`
  && {
    display: flex;
    justify-content: center;
    text-align: "center";
    margin-bottom: 1rem;
    font-size: 0.75rem;
    color: #666666;
  }
`
export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      overflow: auto;
      border-radius: 0.938rem;
      background-color: white;
      padding-top: 1rem;
      width: 33.813rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 21.313rem;
      }
    }
  `}
`
export const IconContainer = styled(Box)`
  && {
    display: flex;
    justify-content: right;
  }
`
export const TextContainerBox = styled(Box)`
  && {
    text-align: center;
  }
`
export const MainText = styled("h2")`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: #35185f;
    font-family: raleway;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-bottom: 1rem;
    }
  `}
`
export const CloseButtonContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 1rem;
  }
`
const ParagraphStyle = styled(Box)`
  ${({ theme }) => css`
    color: #2f1754;

    padding: 1.3rem;

    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      height: 423px;
      padding-top: 0rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
  `}
`
const StatusPara = styled(Typography)`
  color: #c5291c;
`
const InnerPara = styled("p")`
  ${({ theme }) => css`
    && {
      font-size: 1rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        font-size: 14px;
      }
    }
  `}
`

const Heading = styled("p")`
  ${({ theme }) => css`
    && {
      font-size: 1rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        font-size: 14px;
      }
    }
  `}
`
