import { Box, Button, styled, Typography } from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { FieldArrayRenderProps, useFormikContext } from "formik"
import { bikeInitValue, FormikValue } from "./initialValues"
import { useTranslation } from "react-i18next"

export function AddOrRemoveButton({
  arrayHelpers,
}: {
  arrayHelpers: FieldArrayRenderProps
}) {
  const { t, ready } = useTranslation("bikeQuote")

  const formik = useFormikContext<FormikValue>()

  const hasOneBike = formik.values.bikes.length === 1
  const text = hasOneBike ? t("addAnotherBike") : t("removeBike")

  const handleClick = () => {
    if (hasOneBike) {
      arrayHelpers.push(bikeInitValue)
    } else {
      // remove the index 1 object, max 2 bikes allowed
      arrayHelpers.remove(1)
    }
  }
  if (!ready) {
    return null
  }

  return (
    <StyledButton onClick={handleClick}>
      <FlexRow>
        {hasOneBike ? <AddIcon /> : <RemoveIcon />}
        <AddBikeText>{text}</AddBikeText>
      </FlexRow>
    </StyledButton>
  )
}
const FlexRow = styled(Box)`
  padding-left: 0.75rem;
  display: flex;
  column-gap: 6.5px;
  align-items: center;
`
const AddIcon = styled(AddCircleOutlineIcon)`
  font-size: 16px;
  color: #717171;
`
const RemoveIcon = styled(RemoveCircleOutlineIcon)`
  font-size: 16px;
  color: #717171;
`
const AddBikeText = styled(Typography)`
  color: #717171;
  font-size: 0.75rem;
  line-height: 1.5rem;
`
const StyledButton = styled(Button)`
  margin-bottom: 32px;
  padding: 0;
  align-self: flex-start;
`
