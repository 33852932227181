import { Box, styled } from "@mui/material"
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs"
import { BannerText } from "../cycle-to-work/BannerText"
import { useContext } from "react"
import { StaticLayoutContext } from "../layout/StaticLayoutContext"
import { getBreadcrumbs } from "./getBreadcrumbs"

type PageLayoutType = {
  bannerText: string
  children: JSX.Element
}

const Layout = ({ bannerText, children }: PageLayoutType) => {
  const { currentUserId } = useContext(StaticLayoutContext)
  const breadcrumbs = getBreadcrumbs(currentUserId, bannerText)
  return (
    <>
      <BannerContainer>
        <BannerText title={bannerText} />
      </BannerContainer>
      <BreadcrumbsContainer>
        <CustomBreadcrumbs breadcrumbsItems={breadcrumbs} />
      </BreadcrumbsContainer>
      {children}
    </>
  )
}

export default Layout

const BannerContainer = styled(Box)`
  ${({ theme }) => `
    background: linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main});
    width: 100%;
    height: 5.875rem;

    & > div {
      height: 5.875rem;

      & > h2 {
        font-weight: 700;
        font-size: 2rem;
      }
    }
  }`}
`

const BreadcrumbsContainer = styled(Box)`
  ${({ theme }) => `
    width: 93.3%;
    margin: 1.5rem auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 75rem;
      margin: 3.75rem auto;
    }
  }`}
`
