import { Box, styled, IconButton, Typography, Link } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { BreadcrumbsType } from "./CustomBreadcrumbs"

const MobileBreadCrumbs = ({
  breadcrumbsItems,
}: {
  breadcrumbsItems: BreadcrumbsType[]
}) => {
  const lastIndex = breadcrumbsItems.length - 1
  const isProductPage = breadcrumbsItems[lastIndex]?.forceLink

  const index = isProductPage ? lastIndex : breadcrumbsItems.length - 2

  if (breadcrumbsItems.length > 0 && index >= 0) {
    const { label, link } = breadcrumbsItems[index]

    return (
      <Container
        className="mobile-breadcrumbs-container"
        isProductPage={isProductPage}
      >
        <StyledIconButton href={link} aria-label="back to previous page">
          <ChevronLeftIcon />
        </StyledIconButton>
        <Link
          component={/^.*cycle_to_work$/.test(link) ? "a" : Link} //TODO remove this hack once we have a react cycle_to_work landing page
          underline="hover"
          href={link}
        >
          <Text>{label}</Text>
        </Link>
      </Container>
    )
  }
  // return an empty div if length is 0
  return <div></div>
}

export default MobileBreadCrumbs

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
` as typeof IconButton

const Text = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.main};
`

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isProductPage",
})<{ isProductPage?: boolean | null }>`
  display: flex;
  align-items: center;
  height: 1.8rem; // slightly higher than left icon, so that the container won't jump when there's no left icon

  // to override or compensate the padding-left from parent container when there's a left arrow
  margin-left: ${({ isProductPage }) => (isProductPage ? "-2rem" : "-0.5rem")};
`
