import { styled, Typography } from "@mui/material"

const CardPrice: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Price gutterBottom mt={1} variant="h1">
      {/* didn't include below part so this component can be reused without constrained to showing price */}
      {/* £{parseFloat(product.price).toFixed(2)} */}
      {children}
    </Price>
  )
}

export default CardPrice

export const Price = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
`
