type AvailableLanguage = {
  name: string // English || Cymraeg
  locale: string // en-GB || cy
  code: string // ENG || CYM
}

export type AvailableLanguagesType = Array<AvailableLanguage>

export type DefaultLanguageType = {
  locale: string // en-GB || cy
}

export function getLanguageCode(
  availableLanguages: AvailableLanguagesType,
  currentLocale: string
) {
  const lngCode = availableLanguages.find(
    ({ locale }) => locale === currentLocale
  )?.code
  return lngCode || ""
}

export function getLngCookie() {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("lng="))
    ?.split("=")[1]
}

export function setLanguageCookie(language: string) {
  const age = 365 * 24 * 60 * 60 // 1 year
  document.cookie = `lng=${language}; max-age=${age}; path=/`
}

export const fallbackLanguage = { locale: "en-GB" }

export function getLanguageToRenderOnMount(
  isLngToggled: boolean,
  userPreferredLng: string | undefined, // en-GB || cy
  defaultLanguage: DefaultLanguageType | undefined,
  availableLanguages: AvailableLanguagesType | undefined
) {
  const isUserPreferredLngAvailable =
    availableLanguages &&
    availableLanguages.length > 0 &&
    availableLanguages.some(({ locale }) => locale === userPreferredLng)

  return isLngToggled && isUserPreferredLngAvailable
    ? userPreferredLng
    : defaultLanguage?.locale
}

export function getBooleanFromLocalStorage(key: string): boolean {
  const value = localStorage.getItem(key)
  return value === "true"
}
