import { createContext } from "react"
import { useParams } from "react-router"
import { useGetLocalBenefitDataQuery } from "../../../graphqGenaretedTypes"

export type LocalBenefitContextData = {
  content: string
  title: string
  subheader: string
  loading: boolean
}

const defaultValue: LocalBenefitContextData = {
  content: "",
  title: "",
  subheader: "",
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const LocalBenefitContext = createContext(defaultValue)

export const useLocalBenefitData: () => {
  data: LocalBenefitContextData
} = function () {
  const { organisationId, id } = useParams()

  const data = useGetLocalBenefitDataQuery({
    variables: {
      organisationId: organisationId || "",
      localBenefitId: id || "",
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  })

  if (!data.loading) {
    state = {
      ...defaultValue,
      title: data.data?.employeeOrganisation?.localBenefit?.name || "",
      content: data.data?.employeeOrganisation?.localBenefit?.content || "",
      subheader: data.data?.employeeOrganisation?.localBenefit?.headline || "",
      loading: false,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
