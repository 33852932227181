import { Formik, FormikHelpers } from "formik"
import Layout from "../Layout"
import { FormContainer, StyledForm } from "../shared/mainPageStyles"
import { TitleHeading } from "../shared/headingStyles"
import { useTranslation } from "react-i18next"
import { ProceedButton, Subheader } from "./sharedStyles"
import useIsMobileView from "../../../utils/useIsMobileView"
import GenericField from "../../shared/form/GenericField"
import { TextFieldProps } from "@mui/material"
import DateOfBirth from "../../shared/form/DateOfBirth"
import { toCamelCase } from "../toCamelCase"
import { object, string } from "yup"
import { RequiredStringSchema } from "yup/lib/string"
import { AnyObject } from "yup/lib/types"
import { dateOfBirthValidation } from "../../../utils/yup-validation-schema/dateOfBirthValidation"
import { RequiredDateSchema } from "yup/lib/date"
import {
  SecurityQuestionAnswer,
  useAnswerSecurityQuestionMutation,
} from "../../../graphqGenaretedTypes"
import { useContext, useState } from "react"
import Alert from "../../shared/Alert"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import {
  RegQuestionsContext,
  RegQuestionsContextData,
  useRegQuestionsData,
} from "./RegQuestionsContext"
import { RegistrationLayoutContext } from "../RegistrationLayoutContext"
import LoggedOutBanner from "../LoggedOutBanner"
import { getShouldShowBanner } from "../registrationUtils"

function getWarningMessage(label: string) {
  if (label === "NI Number") {
    const parts = label.split(" ")
    return `Please enter your ${parts[0]} ${parts[1].toLocaleLowerCase()} `
  }
  return `Please enter your ${label.toLowerCase()}`
}

function getFormInfo(
  securityQuestions?: RegQuestionsContextData["securityQuestions"]
) {
  if (!securityQuestions) {
    return {
      fieldsArray: undefined,
      mapping: undefined,
      initialValues: undefined,
    }
  }

  // DOB field needs to be in its own array otherwise it won't be rendered correctly
  const mapping: Record<string, string> = {}
  const initialValues: Record<string, string> = {}
  const fieldsArray = []
  let currentSubArray: TextFieldProps[] = []
  const schemaFields: Record<
    string,
    | RequiredDateSchema<Date | undefined, AnyObject>
    | RequiredStringSchema<string | undefined, AnyObject>
  > = {}

  securityQuestions.forEach(({ question: label, id: securityQuestionId }) => {
    const fieldId = toCamelCase(label)

    mapping[fieldId] = securityQuestionId
    initialValues[fieldId] = ""
    schemaFields[fieldId] =
      label === "Date of Birth"
        ? dateOfBirthValidation
        : string().required(getWarningMessage(label))

    const fieldProps = {
      id: fieldId,
      name: fieldId,
      label: label,
      fullWidth: true,
    }

    if (label === "Date of Birth") {
      if (currentSubArray.length > 0) {
        fieldsArray.push(currentSubArray)
        currentSubArray = []
      }
      fieldsArray.push([{ ...fieldProps, select: true }])
    } else {
      currentSubArray.push({ ...fieldProps, type: "text" })
    }
  })

  if (currentSubArray.length > 0) {
    fieldsArray.push(currentSubArray)
  }

  return { fieldsArray, mapping, initialValues, schemaFields } // mapping is for id on mutation
}

const RegQuestions = () => {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation(["regForm", "bikeQuote"])
  const [answerSecurityQuestionMutation] = useAnswerSecurityQuestionMutation()
  const [alertMessage, setAlertMessage] = useState("")
  const [errorCount, setErrorCount] = useState(0) // for re-rendering alert snackbar
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<undefined | boolean>()
  const navigate = useNavigate()
  const { user_id } = useParams()
  const { data } = useRegQuestionsData()
  const { showBanner: portalProviderShowBanner, banner } = useContext(
    RegistrationLayoutContext
  )

  const globalShowBanner = banner?.show
  const shouldShowBanner = getShouldShowBanner(
    globalShowBanner,
    portalProviderShowBanner
  )

  const { fieldsArray, initialValues, schemaFields, mapping } = getFormInfo(
    data.securityQuestions
  )

  const validationSchema = schemaFields ? object().shape(schemaFields) : {}

  const handleSubmit = async (
    values: Record<string, string>,
    formikHelper: FormikHelpers<Record<string, string>>,
    mapping: Record<string, string>
  ) => {
    const securityQuestionsAnswers:
      | SecurityQuestionAnswer
      | SecurityQuestionAnswer[] = []
    Object.keys(values).forEach((key) => {
      securityQuestionsAnswers.push({
        securityQuestionId: mapping[key],
        value:
          key === "dateOfBirth"
            ? moment(values[key]).format("DD/MM/YYYY")
            : values[key],
      })
    })

    const { data } = await answerSecurityQuestionMutation({
      variables: {
        answers: securityQuestionsAnswers,
      },
    })

    const errors = data?.answerSecurityQuestions?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setErrorCount(errorCount + 1)
    } else {
      setIsSubmitSuccess(true)
    }

    formikHelper.setSubmitting(false)
  }

  if (!ready || !initialValues || !fieldsArray) {
    return null
  }

  if (isSubmitSuccess) {
    navigate(`/users/${user_id}/validation_numbers/approval_required`)
  }

  return (
    <RegQuestionsContext.Provider value={data}>
      <Layout>
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelper) =>
              handleSubmit(values, formikHelper, mapping)
            }
          >
            {(formik) => {
              return (
                <StyledForm>
                  {alertMessage && (
                    <Alert
                      severity={"error"}
                      message={alertMessage}
                      key={errorCount}
                    />
                  )}
                  <FormContainer>
                    <TitleHeading sx={{ marginBottom: "1.125rem" }}>
                      {t("regValidation.header")}
                    </TitleHeading>
                    {isMobileView ? (
                      <Subheader>
                        {t("validationQuestions.subHeaderLineOne")}{" "}
                        {t("validationQuestions.subHeaderLineTwo")}
                      </Subheader>
                    ) : (
                      <Subheader>
                        {t("validationQuestions.subHeaderLineOne")} <br />
                        <span style={{ width: "103%", display: "block" }}>
                          {t("validationQuestions.subHeaderLineTwo")}
                        </span>
                      </Subheader>
                    )}

                    {fieldsArray.map((subarray) => {
                      if (
                        subarray.length === 1 &&
                        subarray[0].label === "Date of Birth"
                      ) {
                        return (
                          <DateOfBirth
                            props={subarray[0]}
                            sx={{ width: "100%", marginBottom: "1.875rem" }}
                            key={subarray[0].id}
                          />
                        )
                      } else {
                        return subarray.map((textFieldProps) => (
                          <GenericField
                            textFieldProps={textFieldProps}
                            key={textFieldProps.id}
                          />
                        ))
                      }
                    })}

                    <ProceedButton
                      variant="gradient"
                      sx={{ marginBottom: "1.125rem" }}
                      type="submit"
                      disabled={formik.isSubmitting || formik.isValidating}
                    >
                      {t("regValidation.proceed")}
                    </ProceedButton>
                  </FormContainer>
                </StyledForm>
              )
            }}
          </Formik>
          {shouldShowBanner && banner?.heading && banner?.images && (
            <LoggedOutBanner
              bannerHeading={banner.heading}
              images={banner.images}
            />
          )}
        </>
      </Layout>
    </RegQuestionsContext.Provider>
  )
}

export default RegQuestions
