import {
  MainHeader,
  PropositionSpaceTwoSubHeader,
  Space2HeaderMobilePadding,
  Space2HeaderDesktopPadding,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { Box } from "@mui/material"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"

export const Header = () => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <Space2HeaderMobilePadding>
        <Content />
      </Space2HeaderMobilePadding>
    )
  }
  return (
    <Space2HeaderDesktopPadding>
      <Content />
    </Space2HeaderDesktopPadding>
  )
}

function Content() {
  const isMobile = useIsMobileView()
  const { t } = useTranslation("portalHomepage")

  return (
    <>
      <MainHeader variant="h3" align="left">
        {t("familyCare.header")}
      </MainHeader>
      <PropositionSpaceTwoSubHeader variant="h4" align="left">
        {t("familyCare.subHeader")}
      </PropositionSpaceTwoSubHeader>
      {!isMobile && (
        <Box sx={{ marginTop: "38px" }}>
          <Button />
        </Box>
      )}
    </>
  )
}
