import { CheckboxType } from "../store/checkout-page/Checkboxes"

export type FormikValue = {
  totalValue: string
  picked: boolean
  placeOfWork: string
  employeeNumber: string
  niNumber: string
  title: string
  dateOfBirth: string
  firstName: string
  lastName: string
  mobileNumber: string
  phoneNumber: string
  email: string
  lineOne: string
  lineTwo: string
  county: string
  town: string
  postCode: string
  loading: boolean
  checkboxes: CheckboxType[]
  checkedTC: boolean
}

export type InitFormikValueKey = keyof FormikValue

export const initialValues: FormikValue = {
  placeOfWork: "",
  employeeNumber: "",
  niNumber: "",
  title: "",
  dateOfBirth: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  phoneNumber: "",
  email: "",
  lineOne: "",
  lineTwo: "",
  county: "",
  town: "",
  postCode: "",
  loading: false,
  checkboxes: [],
  totalValue: "",
  picked: false,
  checkedTC: false,
}
